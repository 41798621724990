import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import {
	enrollModal,
	enrollmentPopup,
	lockPopup,
	showModal
} from "../../redux/actions";
import {
	courseEnrollment,
	courseEnrollmentWithSession,
	enrollCourseJourny,
	enrollFromCourseJourney,
	getDimensionUpdatePlan,
	getJournyCourseData,
	getJournyCourses,
	getStartEnrollCourses
} from "../../redux/actions/APIs";
import * as image from "../../resources/images";
import { PATHS } from "../../utils";
import {
	formatDate,
	getCalculatePlanDate,
	getCalenderViewData,
	getCapitalized,
	getSequnceSort,
	getUrlSegment,
	getdateWithCurretnTime,
	textTrim
} from "../../utils/helper";
import { ShimmerTable, useForm } from "../../utils/Packages";
import DeletePlanActivities from "../learnerplan/DeletePlanActivities";
import Success from "../notifications/Success";

const Enrollment = ({ data }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [check, setChecked] = useState(true);
	const [confirm, setConfirm] = useState(!data.isSensitive);
	const [isDisable, setIsDisable] = useState(data.isSensitive || true);
	const [loader, setLoader] = useState(false);
	const [isFilter, setIsFilter] = useState(data?.isAcademic || !data?.support);
	const [isEnrol, setEnrol] = useState(isFilter || data.jEnroll ? 3 : 1);
	const [isOption, setIsoption] = useState();
	const [showEnrollmentRewardPopup, setShowEnrollmentRewardPopup] = useState(
		data.jEnroll ? true : false
	);
	const [isSupport, setIsSupport] = useState(false);
	const [isEnrollCourse, setIsEnrollCourse] = useState(data?.isEditPlan ? "edit" : "update");

	const {
		defaultChildData,
		dimension,
		courseEnrollResponse,
		response,
		enrollCourseResponse,
		loggedInUser,
		setting_responseOK,
		modalData
	} = useSelector((state) => state.collections);

	const {
		register,
		control,
		handleSubmit,
		watch,
		getValues,
		setValue,
		reset,
		setError,
		clearErrors,
		unregister,
		formState: { errors },
	} = useForm({ mode: "onTouched" });

	const [collabEmailData, setCollabEmailData] = useState({
		userId: loggedInUser?.id,
		childFirstName: defaultChildData?.firstName,
		childName: defaultChildData?.name,
		courseName: data?.name,
		parentCollabScreen: data?.collabScreen,
		parentCollabEmail: data?.collabEmail,
	});

	//	From Journey enroll
	useEffect(() => {
		if (enrollCourseResponse) {
			closePopup();
			toast.info(<Success response={enrollCourseResponse} />, {});
			dispatch(enrollCourseJourny());
			dispatch(getJournyCourseData());
			dispatch(showModal());
			//	history.push(PATHS.LEARNER_JOURNEY_STR + data?.userId);
			history.push(PATHS.LEARNER_JOURNEY_STR + defaultChildData?.id);
		}
	}, [enrollCourseResponse]);

	//	For single course enroll
	useEffect(() => {
		if (courseEnrollResponse && !data?.isEditPlan) {
			//	dispatch(showModal({ isHighLIghtCourseId: data.id }));
			data.isEnrolled = true;
			let activitySeq = getSequnceSort(data?.activities);
			if (data?.activities?.length > 0) {
				activitySeq?.map((vl, key) => {
					if (
						activitySeq[key]?.sequence === 1 &&
						activitySeq[key]?.completion >= 100
					) {
						if (activitySeq[1]?.sequence === 2) {
							activitySeq[1].isLocked = false;
						}
					}
				});
			}
			setLoader(false);
			//	closePopup();
			dispatch(lockPopup());
			//	dispatch(resetEnroll());
			//	dispatch(parentToolsModal({ isConfirmation: true, data: data }));
			setIsEnrollCourse("update")
			if (getUrlSegment()[0] === "home") {
				dispatch(getStartEnrollCourses(defaultChildData.id));
			}
			//	dispatch(getMyPlan(defaultChildData?.id));
			/*
						if (data?.collabEmail) {
							dispatch(sendCollabrationEmail(loggedInUser?.id, collabEmailData));
						}
						*/
		}
	}, [courseEnrollResponse]);

	useEffect(() => {
		if (response) {
			setLoader(false);
			closePopup();
			dispatch(lockPopup());
			data.isEditPlan = false;
			dispatch(getJournyCourses(defaultChildData?.id, data?.journeyId));
		}
	}, [response]);

	const closePopup = () => {
		dispatch(enrollModal());
		dispatch(enrollmentPopup());
	};

	const handleOption = (value) => {
		setIsoption(value);
		if (confirm) {
			setIsDisable(false);
		}
		if (value === "option1") {
			setIsSupport(true);
		} else {
			setIsSupport(false);
		}
	};

	let currentDate = new Date();
	currentDate.setDate(currentDate.getDate() + 4);
	let dateObj = [];
	for (let i = 0; i < 3; i++) {
		let futureDate = new Date(currentDate);
		futureDate.setDate(currentDate.getDate() + 4);
		if (i === 0) {
			dateObj.push(formatDate(currentDate));
		}
		dateObj.push(formatDate(futureDate));
		currentDate = futureDate;
	}

	const _kcChecked = () => {
		setChecked(!check);
	};
	const _confirm = () => {
		if (isOption) {
			setIsDisable(false);
		}
		setConfirm(!confirm);
	};

	// useEffect(() => {
	//   if (setting_responseOK?.success) {
	//     dispatch(showModal());
	//     dispatch(parentLoginAction());
	//     // dispatch(resetResponse());

	//     if (data?.isJourneyEnroll) {
	//       console.log("hiiiii1111")
	//       setLoader(true);
	//       dispatch(
	//         enrollFromCourseJourney(
	//           defaultChildData?.id,
	//           data?.journeyId,
	//           data?.courseIds,
	//           check
	//         )
	//       );
	//     } else if (data?.isJourneyModelEnroll) {
	//       console.log("hiiiii22222")
	//       setLoader(true);
	//       dispatch(
	//         enrollCourseJourny(defaultChildData?.id, data?.courseIds, "", "", check)
	//       );
	//     } else {
	//       console.log("hiiiii3333")
	//       if (data?.dimId) {
	//         data.dimension = { key: data.dimId, name: data.dimName };
	//       }
	//       setLoader(true);
	//       dispatch(
	//         courseEnrollment(
	//           defaultChildData?.id,
	//           data?.dimension,
	//           data?.skillId,
	//           data?.id,
	//           dimension,
	//           data?.dimension?.name,
	//           check,
	//           isSupport
	//         )
	//       );
	//     }
	//   }
	// }, [setting_responseOK]);

	const _enroll = () => {
		if (getValues("planName") === "") {
			setError("planName", {
				type: "required",
				message: "Plan name is required",
			});
			return;
		}
		if (selctedDays?.length === 0 && getFlexibLeDays?.length === 0) {
			setDeletePlan({ title: "Select Days", message: `Please select one of the options for the days your child will do coursework. You can select ‘I’m Flexible’ if you are not sure.` })
			return;
		} else if (selctedDays?.length != selectedSitting && getFlexibLeDays?.length != selectedSitting) {
			setDeletePlan({ title: "Select Days", message: `Please select ${selectedSitting - selctedDays?.length} more day${(selectedSitting - selctedDays?.length) > 1 ? 's' : ''}.` })
			return;
		}
		setLoader(true);
		let enrollCourseData = handleCalculateStartDateEndDate(
			data,
			selectedSitting,
			selectedMinutes,
			getFlexibLeDays?.length > 0 ? getFlexibLeDays : selctedDays,
			getValues("planName")
		);
		if (isEnrollCourse === "enroll") {
			console.log('courseEnrollResponse : 1')
			dispatch(
				courseEnrollmentWithSession(
					defaultChildData?.id,
					data?.skillId,
					data?.id,
					enrollCourseData
				)
			);
		} else {
			dispatch(courseEnrollmentWithSession());
			dispatch(getDimensionUpdatePlan(loggedInUser?.id, enrollCourseData));
		}
		return;
		/*
		dispatch(
			courseEnrollmentWithSession(
				defaultChildData?.id,
				data?.skillId,
				data?.id,
				enrollCourseData
			)
		);*/
		// if(data.isSensitive){
		// 	dispatch(parentLoginAction("verifyUser"))
		// 	console.log("hiiiiii1111", data)
		// 	return false;
		// }
		// dispatch(parentLoginAction(true));
		// return false;
		/*
				if (data.dim) {
					setLoader(true);
					dispatch(
						courseEnrollment(
							defaultChildData?.id,
							data.dim,
							data.skillId,
							data.id,
							dimension,
							data.dim.name,
							check,
							isSupport
						)
					);
				}
				*/
		if (data?.isJourneyEnroll) {
			setLoader(true);
			dispatch(
				enrollFromCourseJourney(
					defaultChildData?.id,
					data?.journeyId,
					data?.courseIds,
					check
				)
			);
		} else if (data?.isJourneyModelEnroll) {
			setLoader(true);
			dispatch(
				enrollCourseJourny(defaultChildData?.id, data?.courseIds, "", "", check)
				/*
								enrollCourseJourny(
									data?.userId,
									data?.courseIds,
									data?.dimDetails[0],
									data?.dimDetails[1],
									check
								)
								*/
			);
		} else {
			if (data?.dimId) {
				data.dimension = { key: data.dimId, name: data.dimName };
			}
			setLoader(true);
			dispatch(
				courseEnrollment(
					defaultChildData?.id,
					data?.dimension,
					data?.skillId,
					data?.id,
					dimension,
					data?.dimension?.name,
					check,
					isSupport
				)
			);
		}
	};

	let sittings = ["1", "2", "3", "4", "5", "6", "7"];
	let minutes = ["15", "30", "45", "60"];

	const timezoneObj = [
		{ key: "US/Eastern (ET)", value: "US/Eastern" },
		{ key: "US/Central (CT)", value: "US/Central" },
		{ key: "US/Mountain (MT)", value: "US/Mountain" },
		{ key: "US/Pacific (PT)", value: "US/Pacific" },
	];

	const [selectedSitting, setSelectedSitting] = useState(2);
	const [selectedMinutes, setSelectedMinutes] = useState(30);
	const [selectedTimeZone, setSelectedTimeZone] = useState(0);
	const [userDate, setUserDate] = useState(new Date());
	const [selctedDays, setSeleccted] = useState([]);
	const [isFlexible, setIsFlexible] = useState(false);
	const [getFlexibLeDays, setFlexibLeDays] = useState([]);
	const [deletePlan, setDeletePlan] = useState()

	let weekDays = [
		{ value: "Monday", key: "Mon" },
		{ value: "Tuesday", key: "Tue" },
		{ value: "Wednesday", key: "Wed" },
		{ value: "Thursday", key: "Thu" },
		{ value: "Friday", key: "Fri" },
		{ value: "Saturday", key: "Sat" },
		{ value: "Sunday", key: "Sun" },
	];

	const weeks = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

	const handleSitting = (value) => {
		setSelectedSitting(value);
		setSeleccted([]);
		setIsFlexible(true);
		//	setFlexibLeDays([]);
		//	if (true) {
		calculateUpcomingDays(value);
		//	}
	};
	const handleMinutes = (value) => {
		setSelectedMinutes(value);
		if (value == "60" && ["5", "6", "7"]?.includes(selectedSitting)) {
			setSelectedSitting(4);
			setSeleccted([]);
			handleFlexible(4);
		}
	};

	const handleWeekDays = (weekKey, index) => {
		if (selctedDays?.length >= selectedSitting && !selctedDays?.includes(weekKey)) {
			setDeletePlan({ title: "Select Days", message: `You have already selected ${selectedSitting} days for ${selectedSitting} sessions.` })
			return;
		}
		let daysOfArr = [...selctedDays];
		const key = daysOfArr?.findIndex((item) => item === weekKey);
		if (key !== -1) {
			daysOfArr?.splice(key, 1);
		} else {
			daysOfArr?.push(weekKey);
		}
		setSeleccted(daysOfArr);
		setIsFlexible(false);
		if (daysOfArr?.length > 0) {
			setFlexibLeDays([]);
		} else {
			handleFlexible();
		}
	}

	useEffect(() => {
		handleFlexible();
		let weekArr = [];
		let todayDate = new Date();
		for (let i = 0; i < 2; i++) {
			let weekDay = new Date(todayDate).toLocaleString("en-us", { weekday: 'short' });
			weekArr?.push(weekDay);
			todayDate.setDate(todayDate.getDate() + 1);
		}
		let enrollCourseData = handleCalculateStartDateEndDate(data, 2, 30, weekArr, data?.name);
		setValue("planName", data?.name);
		dispatch(courseEnrollmentWithSession(defaultChildData?.id, data?.skillId, data?.id, enrollCourseData));
	}, [])

	const handleFlexible = (val) => {
		setSeleccted([]);
		setIsFlexible(!isFlexible);
		if (!isFlexible) {
			calculateUpcomingDays(val ? val : selectedSitting);
		} else {
			//	setFlexibLeDays([]);
		}
	}

	const calculateUpcomingDays = (value) => {
		let weekArr = [];
		let currentDate = new Date(userDate);
		for (let i = 0; i < value; i++) {
			let weekDay = new Date(currentDate).toLocaleString("en-us", { weekday: 'short' });
			weekArr?.push(weekDay);
			currentDate.setDate(currentDate.getDate() + 1);
		}
		setFlexibLeDays(weekArr);
	}

	const handleChangeDate = (value) => {
		setUserDate(getdateWithCurretnTime(value))
		// handleSitting(selectedSitting);
		if (isFlexible) {
			let weekArr = [];
			let currentDate = new Date(value);
			for (let i = 0; i < selectedSitting; i++) {
				let weekDay = new Date(currentDate).toLocaleString("en-us", {
					weekday: "short",
				});
				weekArr?.push(weekDay);
				currentDate.setDate(currentDate.getDate() + 1);
			}
			setFlexibLeDays(weekArr);
		}

	}

	const closePlan = () => {
		setDeletePlan();
	}
	/*
	const handleCalculateStartDateEndDate = (courses, sitting, duration, planDays, planName) => {
		if (true) {
			let courseArr = {
				id: courses?.id,
				name: courses?.name,
				duration: duration,
				sittings: sitting,
				sequence: 1,
				startDate: "",
				endDate: "",
				activities: [],
			};
			getSequnceSort(courses?.activities)?.map((module, key) => {
				let getDay = handleSeatingsDate(
					parseInt(sitting),
					parseInt(duration),
					0,
					key + 1
				);
				let currentDate = new Date();
				if (key === 0) {
					currentDate = userDate;
				} else {
					currentDate = getDateByAddDays(
						courses?.activities[key - 1]?.endDate,
						1
					);
				}
				module.startDate = new Date(currentDate)
					.toUTCString()
					.toLocaleString("en-us", {
						month: "short",
						year: "numeric",
						day: "numeric",
					});
				module.endDate = new Date(
					getDateByAddDays(module.startDate, getDay - 1)
				)
					.toUTCString()
					.toLocaleString("en-us", {
						month: "short",
						year: "numeric",
						day: "numeric",
					});

				let obj = {
					id: module?.id,
					name: module?.name,
					startDate: module?.startDate,
					endDate: module?.endDate,
					duration: duration,
					sittings: sitting,
				};
				courseArr.activities.push(obj);
			});

			courses.startDate = courses?.activities[0]?.startDate;
			courses.endDate = courses?.activities[3]?.endDate;
			courses.sittings = sitting;
			courses.duration = duration;
			// date fill in custome Arr
			courseArr.startDate = courses.startDate;
			courseArr.endDate = courses.endDate;

			if (courseArr.activities?.length > 0) {
				let array = {
					name: planName,
					//	name: data?.name,
					sittings: sitting,
					duration: duration,
					planCount: "",
					timeZone: loggedInUser?.timeZone
						? loggedInUser?.timeZone
						: timezoneObj[selectedTimeZone]?.value,
					planType: "",
					planId: courseEnrollResponse ? courseEnrollResponse?.recordId : data?.planId,
					userId: defaultChildData?.id,
					iscourseupdate: true,
					//	daysOfWeek: getFlexibLeDays?.length > 0 ? getFlexibLeDays : selctedDays,
					daysOfWeek: planDays,
					courses: [courseArr],
				};
				if (array?.courses?.length > 0 && sitting % 2 == 1) {
					let course = array?.courses[array?.courses?.length - 1];
					course.activities[3].endDate = getCourseEndDate(array?.courses, course, array?.daysOfWeek);
					course.endDate = course?.activities[3]?.endDate;
				}
				return array;
			}
		}
	};
	*/

	const handleCalculateStartDateEndDate = (courses, sitting, duration, planDays, planName) => {
		let getPlanDateArr = getCalculatePlanDate([courses], duration, sitting, userDate, planDays)
		if (getPlanDateArr.length > 0) {
			let array = {
				name: planName,
				//	name: data?.name,
				sittings: sitting,
				duration: duration,
				planCount: "",
				timeZone: loggedInUser?.timeZone
					? loggedInUser?.timeZone
					: timezoneObj[selectedTimeZone]?.value,
				planType: "",
				planId: courseEnrollResponse ? courseEnrollResponse?.recordId : data?.planId,
				userId: defaultChildData?.id,
				iscourseupdate: true,
				//	daysOfWeek: getFlexibLeDays?.length > 0 ? getFlexibLeDays : selctedDays,
				daysOfWeek: planDays,
				courses: getPlanDateArr,
			};
			return array;
		}
	}


	useEffect(() => {
		if (data && selectedSitting && selectedMinutes) {
			let enrollCourseData = handleCalculateStartDateEndDate(
				data,
				selectedSitting,
				selectedMinutes,
				getFlexibLeDays?.length > 0 ? getFlexibLeDays : selctedDays,
				data?.name
			);
			console.log("data@@@@@@@ : ", enrollCourseData);
			dispatch(showModal({
				type: "",
				data: enrollCourseData?.courses,
				getUserTimeZone: enrollCourseData?.timeZone,
				dayOfWeek: enrollCourseData?.daysOfWeek,
				holidays: [],
			}));

		}
	}, [data, getFlexibLeDays, selctedDays, selectedSitting, selectedMinutes, userDate])

	const [calenderData, setCalenderData] = useState([]);
	const [getDayOfWeek, setDayOfWeek] = useState();
	useEffect(() => {
		if (modalData?.data?.length > 0) {
			let calenderArr = getCalenderViewData(modalData);
			let calenderSesonsData = calenderArr?.filter((week) => week?.find((item) => item?.courseName))
			setCalenderData(calenderSesonsData);
			//	setCalenderData(getCalenderViewData(modalData));
			let seqWeekDay = weeks?.filter((wkDay) => modalData?.dayOfWeek?.find((day) => wkDay === day))
			setDayOfWeek(seqWeekDay);
		}
	}, [modalData])
	return (
		<>
			<div className="newinfo_popupdimension enrollZindex newEnrollCoursPopup halfPagePOpup">
				<div className="modal show d-flex">
					<div className="modal-dialog modal-lg">
						<div className="modal-content course_plan__Information">
							<div className="modal-header">
								<div className="heading border-0 p-0 w-100">
									<h2 className="flex">
										<span className="flexone">
											<i class="fa-light fa-rocket-launch mr-2 mt-0"></i>
											{data.name}
										</span>
										<button
											className="btn btn-primary"
											onClick={() => closePopup()}
										>
											<i className="fa-regular fa-xmark m-0"></i>
										</button>
									</h2>
								</div>
							</div>

							{data?.isAcademic ? (
								{/*
								<div className="modal-body p-0modal-body parents_Support p-0">
									 <div className="newenrolledpopup ">
										<h3
											data-toggle="collapse"
											href="#newenrollepopup2"
											aria-expanded="true"
											className="flex HomeProgresstilt"
										>
											Course Evaluation
										</h3>
										<div
											className="newenrollepopup panel-collapse show"
											id="newenrollepopup2"
										>
											<h4 className="mb-2 text-left">
												Your course progress is calculated based on Proficiency,
												Completion, and Pace.{" "}
											</h4>
											<div className="Course_evaluation">
												<div className="skillprogrs">
													<span>Progress</span>
												</div>

												<div className="progressstyl">
													<span className="proficencyprog">
														<img src={image.blueArrow} alt="" />
														<div className="position-relative Coursecompprcent m-auto">
															<p className="">
																<RoundProgress data={60} className="m-1" />
															</p>
														</div>
														<p>Proficiency</p>
													</span>
													<span className="CompltProgress">
														<img src={image.smallArrow} alt="" />
														<div className="position-relative Coursecompprcent m-auto">
															<p className="">
																<RoundProgress data={30} className="m-1" />
															</p>
														</div>
														<p>Completion</p>
													</span>
													<span className="TimProgress">
														<img src={image.blueArrow} alt="" />
														<div className="position-relative Coursecompprcent m-auto">
															<p className="">
																<RoundProgress data={10} className="m-1" />
															</p>
														</div>
														<p>Pace</p>
													</span>
												</div>
											</div>
											<h4 className="recommendtxt">Recommended Pace </h4>
											<div className="coursesteps">
												<ul className="flex">
													<li>
														<span className="startsteps">Start</span>

														<span className="Completeindays">
															<p>4 days</p>
															<img src={image.smallArrow} alt="" />
														</span>
														<p className="m-0">
															<strong>Today</strong>
														</p>
													</li>
													{dateObj.map((vl, ky) => (
														<li className={ky === 3 ? "laststep" : ""}>
															<span className="Coursesteps">S{ky + 1}</span>
															<span className="Completeindays">
																<p>4 days</p>
																<img src={image.smallArrow} alt="" />
															</span>
															<p>{vl}</p>
														</li>
													))}
												</ul>
											</div>
										</div>
									</div>
								</div>
								*/}
							) : (
								<div className="modal-body p-0modal-body parents_Support p-2 pt-0">
									{
										isEnrollCourse !== "update" && (
											<>
												<div className="newenrollepopup">
													<h3
														class="courseschettl pl-0 pb-3 mt-2 flexone"
													>
														<img src={image.my_plan_icons} className="mr-2" alt="" />

														Course Plan
													</h3>
													{/*<div className="newenrollepopup">
									
										
											 <h4 className="mb-3 mt-3">
												For optimal learning, we recommend you keep knowledge
												checks and module exercises required:
											
											</h4>
											<div className="newcoursesetting flex  align-items-start">
												<div>
													<h4 className="p-0">
														Knowledge Checks and Module Exercises Required
													</h4>
													<p>
														You can change this from the settings module in
														your profile.
													</p>
												</div>
												<div class="form-check form-switch m-0 flex  p-0 mt-2">
													<label
														class="form-check-label pointer"
														for="flexSwitchCheckDefault"
													></label>
													<input
														className="form-check-input avltogglebutton pointer"
														type="checkbox"
														checked={check}
														role={"switch"}
														id="flexSwitchCheckDefault"
														onChange={() => _kcChecked()}
													/>
												</div>
											</div> */}
													{/* <h5 class="text-left pl-0 pt-3 pb-3"><i class="fa-regular fa-note mr-2"></i>
											Note: Watch the entire video / illustration to earn 10 
											points and get completion credit!</h5> 
										</div>
										*/}

												</div>
												<div className="newenrollepopup">
													<div className="w-100 flex">
														<div className={` ${loggedInUser?.timeZone === null ? "w-30" : "w-48"}`}>
															<div className="input-group">
																<h3 className="mr-3 text-nowrap HomeProgresstilt pl-0 border-0 pb-2 mb-1">Plan Name: <span className="mandatoryField">*</span></h3>
																<div className="form-group">
																	<input
																		type="text"
																		// placeholder="Last Name"
																		className={`form-control pr-2 ${errors.planName ? "is-invalid" : ""
																			}`}
																		defaultValue={data?.name}
																		{...register("planName", {
																			required: {
																				value: true,
																				message: "Plan name is required",
																			},
																		})}
																	/>
																	<div className="invalid-feedback">
																		{errors?.planName?.message}
																	</div>
																</div>
															</div>
														</div>
														<div className={`plan_startdate ${loggedInUser?.timeZone === null ? "w-30" : "w-48"} `}>
															<div className={`input-group calender`}>
																<div className={`form-group  `}>
																	<h3 className="mr-3 text-nowrap HomeProgresstilt pl-0 border-0 pb-2 mb-1"> Start Date: <span class="mandatoryField">*</span>
																		{/* <span
																// onClick={() => handlePopUpOpen("startdate")}
																>
																	<img src={image.chat_icon} className="ml-2 ichat_icon pointer" />
																</span> */}
																	</h3>
																	<label className={`flexone position-relative w-100 mt-0`}>
																		<span className="clenderIcon">
																			<img src={image.Calendericon} />
																		</span>
																		<DatePicker className="form-control signupChildClass p-0 w-100"
																			clearIcon={null}
																			// oneTap
																			onChange={(value) => {
																				handleChangeDate(value)
																			}}
																			value={userDate}
																			dayPlaceholder={"dd"}
																			monthPlaceholder={"mm"}
																			yearPlaceholder={"yyyy"}
																			format="MM/dd/yyyy"
																			placement={"topEnd"}
																			minDate={new Date()}
																		/>
																	</label>

																</div>
															</div>
														</div>

														{loggedInUser?.timeZone === null && (
															<div className="plan_Time_Zonedrp w-30">
																<div className="plan_startdate ">
																	<div className="input-group calender learner_pLan_dropdown ">
																		<div className="form-group   text-nowrap">
																			<h3 className="mr-3 text-nowrap HomeProgresstilt pl-0 border-0 pb-2 mb-1"> Time Zone:<span class="mandatoryField">*</span> </h3>
																			<label className="flexone position-relative w-100 timezonelabel mt-0">
																				<span aria-haspopup="true"
																					className="pointer dropdownDiv flex w-100"
																					aria-expanded="false"
																					data-toggle="dropdown">
																					<span className="pe-2 dropdownLabel">
																						{timezoneObj[selectedTimeZone]?.key}
																						{/* {timezoneObj[selectedTimeZone]?.key} */}
																					</span>
																					<i className="fa fa-angle-down fa-lg" aria-hidden="true"></i>
																				</span>
																				<ul className="dropdown-menu dropdown-menu-end lPDropdwnList" aria-labelledby="">
																					{timezoneObj?.map((value, key) => (
																						<li
																							onClick={() => setSelectedTimeZone(key)}
																						>
																							{value?.key}
																						</li>
																					))}
																				</ul>
																			</label>
																		</div>
																	</div>
																</div>
															</div>
														)}
													</div>
												</div>

												<div className="newenrollepopup pace_screen_">
													<div className="flex PaceModeSel paceSteps align-items-start m-0 mt-2">

														<div className="sessioprweek">
															<h3 class="HomeProgresstilt  border-0 pl-0"> Sessions Per Week: <span class="mandatoryField">*</span></h3>
															<ul className={`panel-collapse collapse show`}
															>
																<li>
																	<div className="selectecPaceWeek border-0 signupType flex-wrap newenrollepopup panel-collapse collapse show "
																		id="newenrollepopup"
																	>
																		{sittings?.map((value, index) => (
																			<label
																				class="Selcheckbox m-0 ActiveQQst"
																				key={index}
																			>
																				<input
																					type="radio"
																					name="skill0"
																					onClick={() => handleSitting(value)}
																					checked={value == selectedSitting}
																					disabled={["5", "6", "7"]?.includes(value) && selectedMinutes == "60" ? true : false}
																				/>
																				{value}{" "}
																				<span>Session{value === "1" ? "" : "s"}</span>
																				<span class="checkmark"> </span>
																				{value === "2" && (
																					<p className="recummended">(Recommended)</p>
																				)}
																			</label>
																		))}
																	</div>
																</li>
															</ul>
														</div>
														<div className="durationprsessn">
															<h3 class="HomeProgresstilt  border-0 pl-0 pr-0"

															> Duration Per Session: <span class="mandatoryField">*</span></h3>
															<ul className={`panel-collapse collapse show`}

															>
																<li>
																	<div class="selectecPaceWeek signupType flex-wrap">
																		{minutes?.map((value, index) => (
																			<label
																				class="Selcheckbox m-0 ActiveQQst"
																				key={index}
																			>
																				<input
																					type="radio"
																					name="minutes"
																					checked={value == selectedMinutes}
																					onClick={() => handleMinutes(value)}
																				/>
																				{value} <span>minutes</span>
																				<span class="checkmark"> </span>
																				{value === "30" && (
																					<p className="recummended">(Recommended)</p>
																				)}
																			</label>
																		))}
																	</div>
																</li>
															</ul>
														</div>
														<div class="ScenecerelateddQuiz  mb-0 selec_Days_Custom pl-0 p-0 border-0 w-48 selsctdayasinwek mb-0">
															<div class="signupType m-0 w-100">
																<div class="PaceModeSel w-100">
																	<h3 class="HomeProgresstilt pl-0 flex border-0"
																		data-toggle="collapse"
																		href="#selectdaysbase"
																		aria-expanded="false"
																	> <span>Select ({selectedSitting}) Days you want to work on Bloomster. <span class="mandatoryField">*</span></span></h3>
																	{/* <i class="fa fa-angle-down ps-1 fa-sm" aria-hidden="true"></i> */}
																	<div className={`panel-collapse collapse show`}
																		id="">
																		<div class="selectecPaceWeek w-100 mb-2">
																			<label class="Selcheckbox m-0 ActiveQQst iamflexiblelabel border-0 pb-0">
																				<input type="checkbox" name="skill0" onClick={() => handleFlexible()} checked={isFlexible && selctedDays?.length === 0} />
																				<span className="flexible">I'm flexible:</span><span class="checkmark"> </span>

																			</label>
																			<p class="onlinertxt pb-1" data-for="completion" data-event-off="" data-tip >
																				We select consecutive days starting the 'Start Date' and the 'Sessions Per Week'.
																				{/* This Options will be select random days of the week. Plan can be edited at any time. */}
																				{/* <ReactTooltip id="completion" place='top' className={"tooltip text-center"} >
																	<p>
																		This Options will be select random days of the week. Plan can be edited at any time.
																	</p>
																</ReactTooltip> */}
																			</p>
																		</div>
																		<h3 className="HomeProgresstilt pl-0 flex border-0">Or, select specific days you would like Vinita to take the course(s) in this Plan.</h3>
																		<div class="selectecPaceWeek select_days_week w-100 flex-wrap">
																			{
																				weekDays?.map((day, key) => (
																					<label class="Selcheckbox m-0 ActiveQQst w-50" key={key}>
																						<input type="checkbox" name="skill0" onClick={() => handleWeekDays(day?.key, key)}
																							checked={selctedDays?.includes(day?.key)}
																						/>
																						<span>{day?.value}</span>
																						<span class="checkmark"> </span>
																					</label>
																				))
																			}
																		</div></div></div></div>
														</div>
													</div>
												</div>
											</>
										)
									}
									{isEnrollCourse === "update" && (
										<div className="mb-4 mt-4 flexone align-items-start courseenroll_Confirmation newenrollepopup pace_screen_ parents_Support">
											<img src={image.newcheckicon} />
											<div>
												<h3 className="mb-2 pl-3 text-left w-100">
													Congratulations on enrolling {getCapitalized(defaultChildData?.firstName)} in {data?.name}. We have used the following recommended
													assumptions to build a plan for {getCapitalized(defaultChildData?.firstName)} to maximize learning and complete this course.
													{/* Congratulations! You have successfully created a plan {selectedMinutes}{" "}
												minutes per <br /> sitting for {selectedSitting} sittings a week of {" "}
												{getDayOfWeek?.map((day, key) => (
													<span key={key}>
														{day}
														{(key === getDayOfWeek?.length - 1) ? "." : ", "}
													</span>

												))} */}
												</h3>
												<div className="flexone PaceModeSel paceSteps align-items-baseline">
													<div class="sessioprweek">
														<h3 class="HomeProgresstilt  border-0 pl-0"> Sessions Per Week: <span class="mandatoryField">*</span>
														</h3>
														<ul class="panel-collapse collapse show">
															<li>
																<div class="selectecPaceWeek border-0 signupType flex-wrap newenrollepopup panel-collapse collapse show" id="newenrollepopup">
																	<label class="Selcheckbox m-0 ActiveQQst">
																		<input type="radio" name="skill0" checked="true" disabled />2 <span>Sessions</span>
																		<span class="checkmark"> </span>
																		{/* <p class="recummended">(Recommended)</p> */}
																	</label>
																</div>
															</li>
														</ul>
													</div>
													<div class="durationprsessn ml-3">
														<h3 class="HomeProgresstilt  border-0 pl-0 pr-0"> Duration Per Session: <span class="mandatoryField">*</span></h3>
														<ul class="panel-collapse collapse show"><li>
															<div class="selectecPaceWeek signupType flex-wrap">
																<label class="Selcheckbox m-0 ActiveQQst">
																	<input type="radio" name="minutes" checked="true" disabled />30 <span>minutes</span><span class="checkmark"></span>
																	{/* <p class="recummended">(Recommended)</p> */}
																</label>
															</div>
														</li>
														</ul>
													</div>
													<div class="ScenecerelateddQuiz mb-0 selec_Days_Custom pl-0 p-0 border-0 w-48 selsctdayasinwek">
														<div class="signupType m-0 w-100">
															<div class="PaceModeSel w-100">
																<h3 class="HomeProgresstilt pl-0 flex border-0" data-toggle="collapse" href="#selectdaysbase" aria-expanded="false"> <span>Select two (2) Days you want to work on Bloomster. <span class="mandatoryField">*</span></span></h3>
																<div class="panel-collapse collapse show" id="">
																	<div class="selectecPaceWeek w-100 mb-2">
																		<label class="Selcheckbox m-0 ActiveQQst iamflexiblelabel border-0 pb-0">
																			<input type="checkbox" name="skill0" defaultChecked={true} disabled={true} /><span class="flexible">I'm flexible:</span>
																			<span class="checkmark"> </span>
																		</label>
																		<p class="onlinertxt pb-1" data-for="completion" data-event-off="" data-tip="true">We select consecutive days starting the 'Start Date' and the 'Sessions Per Week'.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<p className="edipt_cours_plan">If you wish to make changes to the schedule below, click on the edit icon. <span class="editcalenderIcon active pointer" onClick={() => setIsEnrollCourse("edit")}><i class="fa-light fa-pencil active ml-2"></i></span> </p>
											</div>

										</div>
									)}

									{/*<div className="newenrollepopup parent_Support mb-3 mt-2">
										<h3 class=" HomeProgresstilt pl-0">
											<img src={image.Supporticon} alt="" /> Parent Support
										</h3>
										<div class="ScenecerelateddQuiz p-0  pl-2 w-100 border-0">
											<div class="signupType mt-3">
												<h4 class="mb-3">
													<strong class="">
														Select how you'd like to support{" "}
														{getCapitalized(defaultChildData?.firstName)}'s
														learning:
														<span class="mandatoryField">*</span>
													</strong>
												</h4>

												<div class="flexone mb-3">
													<label class="Selcheckbox m-0 ActiveQQst">
														<span class="QQtitle">
															I will collaborate with and support{" "}
															{getCapitalized(defaultChildData?.firstName)}
														</span>
														<input
															type="radio"
															name="skill0"
															onClick={() => handleOption("option1")}
														/>
														<span class="checkmark"></span>
													</label>
												</div>
												<div class="flexone mb-3">
													<label class="Selcheckbox m-0 ActiveQQst">
														<span class="QQtitle">
															I am ok with {getCapitalized(defaultChildData?.firstName)}{" "} managing on their own
														</span>
														<input
															type="radio"
															name="skill0"
															onClick={() => handleOption("option2")}
														/>
														<span class="checkmark"></span>
													</label>
												</div>
												{data?.collabEmail && (
													<p className="mt-3">
														We will send curated tips and insights to support{" "}
														{getCapitalized(defaultChildData?.firstName)}{" "}
														directly to your inbox. You can also find them in
														the{" "}
														<span className="heghlightedstar">
															{" "}
															<img src={image.gensettings} />
															Parent Tools
														</span>{" "}
														menu at the bottom left of the course card.
														{/* You can find helpful tips for supporting {getCapitalized(defaultChildData?.firstName)} in the <span className="heghlightedstar"> <img src={image.gensettings} />Parent Tools</span> menu at the bottom left of the course card. */}
									{/* We will send curated tips and insights to support {getCapitalized(defaultChildData?.firstName)} directly to your inbox.
															You can also find them in the <span className="heghlightedstar"> <img src={image.gensettings} />Parent Tools</span> menu at the bottom left of the
															course card. */}
									{/*</p>
												)}
											</div>
										</div>
										{/* <div class="dropdownsec heigh_light_star pb-3 mt-3">
											    <span>*</span>
												<img src={image.parrent_support} className="w-100 border" />
												<i class="fa-solid fa-arrow-up-long"></i>

											</div> */}
									{/* </div> */}
									{
										(getFlexibLeDays?.length > 0 || selctedDays?.length > 0) && (
											<div className="newenrollepopup mt-3 pt-2">
												<div id="planCalendarView" className="mt-4 pb-4">
													<span className="d-flex align-items-center pb-3">
														<h3 className="courseschettl flexone"> <img src={image.calenderIcon1} alt="" className="mr-2" />Schedule</h3>
													</span>
													{calenderData?.length > 0 ? (
														<div className="tableResponsive">
															<table className="border-0 w-100 mt-2">
																<thead>
																	<tr>
																		<th>Week</th>
																		<th>Mon</th>
																		<th>Tue</th>
																		<th>Wed</th>
																		<th>Thu</th>
																		<th>Fri</th>
																		<th>Sat</th>
																		<th>Sun</th>
																	</tr>
																</thead>
																<tbody>
																	{calenderData?.map((weeks, index) => {
																		const currentWeek = weeks?.find((item) => item?.courseName)
																		return (
																			<>
																				{
																					currentWeek && (
																						<tr key={index}>
																							<td class="bg-color1">
																								<p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>{weeks[0]?.date} - {weeks[6]?.date}</p>
																								<p className="week">Week {index + 1}</p>
																							</td>
																							{weeks?.map((days, key) => (
																								<td class={new Date(days?.currentDate).toDateString() === new Date().toDateString() ? "current" : "bg-color1"}>
																									<div className="d-flex align-items-baseline justify-content-between flex-wrap">
																										<p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
																											{days?.date}</p>
																										{days?.inProress && days?.sittings && (
																											<p className="upcoming">In Progress</p>
																										)}
																										{days?.isCompleted && days?.sittings && (
																											<p className="completed">Complete</p>
																										)}
																									</div>
																									{
																										days?.courseName && (
																											<>
																												<div className="d-flex pt-2 align-items-center course_on_calndr ">
																													<span className="courseCircle me-1">C{days.courseKey}</span>
																													<React.Fragment>
																														{days?.courseName?.length > 17 ? (
																															<ReactTooltip id={"course" + days?.date}>
																																<p>{days?.courseName}</p>
																															</ReactTooltip>
																														) : (
																															""
																														)}
																														<div className="courseName" data-for={"course" + days?.date} data-event-off="" data-tip>
																															{textTrim(days?.courseName, 17)}
																														</div>
																													</React.Fragment>
																												</div>
																												<div className="d-flex pt-2 align-items-center module_on_calndr">
																													<span className="moduleCircle me-1">M{days?.moduleKey}</span>
																													<React.Fragment>
																														{days?.moduleName?.length > 17 ? (
																															<ReactTooltip id={"module" + days?.date}>
																																<p>{days?.moduleName}</p>
																															</ReactTooltip>
																														) : (
																															""
																														)}
																														<div className="moduleName" data-for={"module" + days?.date} data-event-off="" data-tip>
																															{textTrim(days?.moduleName, 17)}
																														</div>
																													</React.Fragment>
																												</div>
																											</>
																										)
																									}
																								</td>
																							)
																							)}
																						</tr>
																					)
																				}
																			</>
																		)
																	})}
																</tbody>
															</table>
														</div>
													) : (
														<ShimmerTable row={4} col={4} />
													)}
												</div>
											</div>
										)
									}
									{/* {data?.isSensitive && (
										<div className="newenrollepopup paddingbtmpopup mb-3">
											<h3 class="HomeProgresstilt">Course Confirmation</h3>
											<div className="newenrollepopup">
												<div className="signupType mb-2 mt-3">
													<label className={`Selcheckbox ActiveQQst m-0`}>
														<span className="QQtitle">
															{" "}
															I have reviewed the course description and approve
															of my child enrolling in this course.
															<span class="mandatoryField">*</span>
														</span>
														<input
															type="radio"
															id="Public"
															name="dimensionQuestion"
															value="PUBLIC"
															onClick={() => _confirm()}
															checked={confirm}
														></input>
														<span className="checkmark"></span>
													</label>
												</div>
											</div>
										</div>
									)} */}
								</div>
							)}
							<div className="modal-footer dontshowagain">
								<div className="form-group BDsubmitbutton m-0 d-flex">
									<div className="buttonDistribotion">
										{!loader ? (
											<>
												<button
													type="button"
													className="btn-blue btn-login d-block mb-5 cancelbutton"
													onClick={() => closePopup()}
												>
													<i className="fa-solid fa-xmark"></i>Close
												</button>
												{isEnrollCourse === "update" ? (
													<button
														type="button"
														className="btn-blue btn-login d-block mb-5"
														onClick={() => setIsEnrollCourse("edit")}
													>
														<i class="fa-light fa-pencil active"></i>Edit
													</button>
												) : (
													<button
														type="button"
														className="btn-blue btn-login d-block mb-5"
														disabled={false}
														onClick={() => _enroll()}
														key={Math.random()}
													>
														<i className="fa-solid fa-paper-plane mr-2"></i>
														{isEnrollCourse === "enroll" ? "Enroll" : "Update"}
													</button>
												)}
											</>
										) : (
											<div className="buttonDistribotion justify-content-end">
												<button
													className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
													disabled
												>
													<span className="RounAnimation mr-1"></span> Please
													Wait...
												</button>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{deletePlan && (
					<DeletePlanActivities
						closePlan={closePlan}
						deletePlan={deletePlan}
					/>
				)}
			</div>
		</>
	);
};
export default Enrollment;
