import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ShimmerCategoryList } from "react-shimmer-effects";
import ReactTooltip from "react-tooltip";
import useSound from "use-sound";
import {
	selectOptionPre,
	selectTab,
	setSurveyAss,
	showModal,
	showTeacherReport,
} from "../../../../redux/actions";
import {
	Knowladgecheck,
	KnowladgecheckMultiSelectCheckbox,
	myJournal,
	sendCollabrationEmail,
	sendRewardEmail,
} from "../../../../redux/actions/APIs";
import * as image from "../../../../resources/images";
import correct from "../../../../resources/sound/right.mp3";
import wrong from "../../../../resources/sound/wrong.mp3";
import { PATHS } from "../../../../utils";
import { getSequnceSort, kFormatter, textTrim } from "../../../../utils/helper";
import Vicky from "../../../controls/Vicky";
import Sidebar from "../../../profile/empathy-quiz/Sidebar";
import ConfirmationPopup from "../assessment/ConfirmationPopup";
import GrowthSurveySidebar from "./GrowthSurveySidebar";
import MyJournal from "./MyJournal";

const LessonSidebar = ({ data }) => {
	const param = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		selectedDim,
		knowladge_check_response,
		socialActivityData,
		getdimension,
		next_scene_response,
		loggedInUser,
		kcStateResponse,
		modalData,
		defaultChildData,
		growthSurveyData,
		selectOptData,
		surveyAssOpen,
		showReviewData,
		socialActivityDataForMyJournal
	} = useSelector((state) => state.collections);

	const [selectedTab, setSelectedTab] = useState(data?.tab);
	const [quizData, setQuizData] = useState({ pre: {}, post: {} });
	const [isValidOption, setValidOption] = useState(false);
	const [nuggetTitle, setNuggetTitle] = useState();
	const [rightNugget, setRightNugget] = useState(false);
	const [isRecentCorrent, setRecentCorrect] = useState(false);
	const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
	const [nugget, setNugget] = useState("");
	const [earnPoint, setEarnPoint] = useState(data?.points);
	const [isNuggetDisplay, setNuggetDisplay] = useState(false);
	const [showData, setShowData] = useState();
	const [summary, setSummary] = useState("");
	const [changePoint, setChangePoint] = useState(false);
	const [attemptedAns, setAttemptedAns] = useState();
	const [QueIndex, setQueIndex] = useState();
	const [instructions, setInstructions] = useState();
	const [selectedRadioOptionId, setSelectedRedioOptionId] = useState("");
	const [showCongratulationModel, setShowCongratulationModel] = useState(false);
	const [totalExceriseAttempted, setTotalExceriseAttempted] = useState(0);
	const [totalQuestionAttempted, setTotalQuestionAttempted] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isExerciseSubmit, setExeriseSubmit] = useState(false);
	const [showPleaseWait, setShowPleaseWait] = useState(false);
	const [exceriseLearnPointState, setExceriseLearnPointState] = useState(0);
	const [isRewardPopupShow, setRewardPopupShow] = useState(false);
	const [isCourseRewardPopupShow, setCourseRewardPopupShow] = useState(false);
	const [rewardData, setRewardData] = useState([]);
	const [currentIncrasePoint, setCurrentIncreasePoint] = useState(0);
	const [collabEmailData, setCollabEmailData] = useState([]);
	const [completeSceneArray, setCompleteSceneArray] = useState([]);
	const [allSceneDataArray, setAllSceneDataArray] = useState([]);
	const [sceneTextMessage, setSceneTextMessage] = useState(
		"View all scenes, including the current one, before answering the questions."
	);
	const [quizObjData, setQuizObj] = useState([]);
	const [tab, setTab] = useState("");
	const [completedItems, setCompletedItems] = useState(false);
	const [startPopup, setStartPopup] = useState(false);
	// const [invalidAttempt, setInvalidAttempt] = useState(0);
	const [hideQues, setHideQues] = useState(true);
	// const [showRightOptions, setShowRightOptions] = useState(false);
	const [questionIndexVal, setQuestionIndexVal] = useState();
	const [optionIndexVal, setOptionIndexVal] = useState();
	const [nuggetVal, setNuggetVal] = useState();

	const [isNext, setIsNext] = useState(false);
	const [isOptionTrue, setIsOptionTrue] = useState(false);
	const [inval, setInval] = useState();
	const [isMultiOptionChecked, setIsMultiOptionChecked] = useState(false);
	const [optionArr, setOptionArr] = useState([]);
	const [preSelectedIds, setPreSelectedIds] = useState([])
	// const [isNextClick, setIsNextClick] = useState(false);
	const [oneOpt, setOneOpt] = useState();
	const [optionIdsArr, setOptionIdsArr] = useState([]);


	useEffect(() => {
		// const completedItems =
		//   socialActivityData?.records[0]?.series?.scenes?.filter(
		//     (item) => item.isCompleted
		//   );
		setCompletedItems(socialActivityData?.records[0]?.completion === 100);
	}, []);

	useEffect(() => {
		setCompletedItems(socialActivityData?.records[0]?.completion === 100);
		if (socialActivityData?.records) {
			if (loggedInUser?.role?.name != "PROVIDER") {
				let completeScene =
					socialActivityData?.records[0]?.sceneCount?.split(",");
				setCompleteSceneArray(completeScene);
				let allScene = getSequnceSort(
					socialActivityData?.records[0]?.series?.scenes
				);
				let isPreviousTrue = true;
				for (let i = 1; i <= allScene?.length; i++) {
					if (completeScene?.includes(i.toString())) {
						if (isPreviousTrue) {
							allScene[i - 1].showTooltip = false;
						} else {
							allScene[i - 1].showTooltip = true;
						}
					} else {
						allScene[i - 1].showTooltip = true;
						isPreviousTrue = false;
					}
				}
				setAllSceneDataArray(allScene);
			}
			let obj = {
				userId: loggedInUser?.id,
				childFirstName: defaultChildData?.firstName,
				childName: defaultChildData?.name,
				courseName: socialActivityData?.records[0]?.courseName,
				parentCollabScreen: "",
				parentCollabEmail: socialActivityData?.records[0]?.parentCollabEmail,
				childId: param?.id,
				courseId: param?.courseId,
			};
			setCollabEmailData(obj);
			//	setAllSceneDataArray(allScene);
		}
	}, [socialActivityData?.records]);

	let counter = 0;
	const _redirectLesson = (type) => {
		let dimName = socialActivityData?.records[0]?.dimName;
		let selectedDim1 = getdimension.records.find(
			(data) => data?.name === dimName
		);

		if (selectedDim1?.id) {
			dispatch(
				selectTab({
					key: selectedDim1?.id,
					value: selectedDim1?.name,
				})
			);
			history.push({
				pathname: PATHS.DIMENSION_STR + selectedDim1?.id + "/" + param.id,
				state: { skillId: param?.skillId },
			});
		}
	};

	useEffect(() => {
		if (!data?.showCongratulationModel) {
			setShowCongratulationModel(false);
		}
	}, [data?.showCongratulationModel]);

	useEffect(() => {
		setNuggetDisplay(false);
		setSelectedQuestionIndex(0);
		setRecentCorrect(false);
		setIsResult(true);
		setRightOptionSelected(false);
		setShowMyJournal(false);
		handleCloseNugget();
		//	setEarnPoint(data?.points);
		//	setEarnPoint(earnPoint);
	}, [data?.currentScene]);

	useEffect(() => {
		if (kcStateResponse !== undefined) {
			setSelectedQuestionIndex(kcStateResponse?.records[0]?.currentKc - 1);
			setIsLoading(false);
		}
	}, [kcStateResponse?.records]);



	const [play] = useSound(correct);
	const [play1] = useSound(wrong);

	useEffect(() => {
		//	data?.handleNextButtonDisable(true);
		if (loggedInUser?.role?.name != "PROVIDER") {
			if (next_scene_response !== undefined) {
				handleSectionRewardPopup(next_scene_response, false);
				handleCourseRewardPopup(next_scene_response, false);
				setNuggetDisplay(false);
				setSelectedQuestionIndex(selectedQuestionIndex);
				setRecentCorrect(false);
				let currentPoint = next_scene_response?.records[0]?.points;
				let point =
					earnPoint > data?.previousPoint ? earnPoint : data?.previousPoint;
				//
				if (point != currentPoint) {
					data?.handleNextButtonDisable(true);
					setChangePoint(true);
					play();
					setTimeout(() => {
						setChangePoint(false);
						data?.handleNextButtonDisable(false);
					}, 3000);
				} else {
					data?.handleNextButtonDisable(false);
				}

				if (next_scene_response?.records[0]?.compScene) {
					let allSceneArray = getSequnceSort(
						socialActivityData?.records[0]?.series?.scenes
					);
					let sequence = allSceneArray[data?.currentScene]?.sequence;
					let sceneArray = completeSceneArray;
					if (!sceneArray?.includes(sequence)) {
						sceneArray.push(sequence);
						setCompleteSceneArray(sceneArray);
					}

					for (let i = 1; i <= allSceneArray?.length; i++) {
						if (sceneArray?.includes(i.toString()) || sceneArray?.includes(i)) {
							allSceneArray[i - 1].showTooltip = false;
						} else {
							break;
							allSceneArray[i - 1].showTooltip = true;
						}
					}

					setAllSceneDataArray(allSceneArray);
				}
				/*
					if (!sceneArray?.includes(sequence)) {
					  console.log("In If");
					  sceneArray.push(sequence);
					  setCompleteSceneArray(sceneArray);
					}
					console.log("EEE : ", sceneArray);
					for (let i = 1; i <= allSceneArray?.length; i++) {
					  if (sceneArray?.includes(i.toString())) {
						console.log("EEE : If");
						allSceneArray[i - 1].showTooltip = false;
					  } else {
						console.log("EEE : else", i);
						allSceneArray[i - 1].showTooltip = true;
					  }
					}
					*/
				setEarnPoint(currentPoint);
				if (
					socialActivityData?.records[0]?.isEnrolled &&
					!socialActivityData?.records[0]?.isPCEmailSent &&
					socialActivityData?.records[0]?.parentCollabEmail
				) {
					dispatch(sendCollabrationEmail(loggedInUser.role.name === 'LEARNER' || loggedInUser.role.name === 'STUDENT' ? loggedInUser?.parentId : loggedInUser?.id, collabEmailData));
					// dispatch(sendCollabrationEmail(loggedInUser?.id, collabEmailData));
					socialActivityData.records[0].isPCEmailSent = true;
				}
			}
		}
	}, [next_scene_response?.records]);

	const handleSectionRewardPopup = (responses, isContinue) => {
		let activityData = socialActivityData?.records[0];
		let isPopupAlreadyShow = isRewardPopupShow;
		let showTextLineOnCongratulationModel = false;
		if (activityData?.points >= activityData?.rewardTargetPoints) {
			isPopupAlreadyShow = true;
			setRewardPopupShow(true);
		} else if (
			!isPopupAlreadyShow &&
			responses?.records[0]?.points >= activityData?.rewardTargetPoints
		) {
			if (!isContinue) {
				data?.handleShowRewardUnlockPopup(
					true,
					activityData?.rimageUrl,
					activityData?.rname
				);
			} else {
				showTextLineOnCongratulationModel = true;
			}
			isPopupAlreadyShow = true;
			setRewardPopupShow(true);
			handleSendRewardEmail(true, false, activityData);
		}
		return showTextLineOnCongratulationModel;
	};

	const handleCourseRewardPopup = (responses, isContinue) => {
		let activityData = socialActivityData?.records[0];
		let tempPoint = responses?.records[0]?.points - activityData?.points;
		let isPopupAlreadyShow = isCourseRewardPopupShow;
		let showTextLineOnCongratulationModel = false;
		if (activityData?.ucPoints >= activityData?.rewardCTargetPoints) {
			isPopupAlreadyShow = true;
			setCourseRewardPopupShow(true);
		} else if (
			!isPopupAlreadyShow &&
			activityData?.ucPoints + tempPoint >= activityData?.rewardCTargetPoints &&
			activityData?.rcShowRewardPop
		) {
			if (!isContinue) {
				data?.handleShowRewardUnlockPopup(
					true,
					activityData?.rcImageUrl,
					activityData?.rcName
				);
			} else {
				showTextLineOnCongratulationModel = true;
			}
			setCourseRewardPopupShow(true);
			handleSendRewardEmail(false, true, activityData);
		}
		//	console.log('Test 2 : ', showTextLineOnCongratulationModel);
		return showTextLineOnCongratulationModel;
	};

	const handleSendRewardEmail = (isActivity, isCourse, activityData) => {
		let values = {
			userCourseId: activityData?.ucId,
			userActivityId: activityData?.uaId,
			learnerId: defaultChildData?.id,
			courseId: activityData?.courseId,
			isCourse: isCourse,
			isActivity: isActivity,
		};
		dispatch(sendRewardEmail(values));
	};

	const handleShowRewardPopup = (responses, isContinue) => {
		//	userScpoints
		let testPoint =
			responses?.records[0]?.points - socialActivityData?.records[0]?.points;
		setCurrentIncreasePoint(testPoint);
		//	For Section PoPup
		let isRewardPopupDisplay = isRewardPopupShow;
		if (
			socialActivityData?.records[0]?.points >=
			socialActivityData?.records[0]?.rewardTargetPoints
		) {
			isRewardPopupDisplay = true;
			setRewardPopupShow(true);
		} else if (
			responses?.records[0]?.points >=
			socialActivityData?.records[0]?.rewardTargetPoints &&
			!isRewardPopupDisplay
		) {
			if (!isContinue) {
				data?.handleShowRewardUnlockPopup(
					true,
					socialActivityData?.records[0]?.rimageUrl,
					socialActivityData?.records[0]?.rname
				);
			}
			setRewardPopupShow(true);
			let values = {
				userCourseId: socialActivityData?.records[0]?.ucId,
				userActivityId: socialActivityData?.records[0]?.uaId,
				learnerId: defaultChildData?.id,
				courseId: socialActivityData?.records[0]?.courseId,
				isCourse: false,
				isActivity: true,
			};
			dispatch(sendRewardEmail(values));
		}

		//	userScpoints
		//	For Course PoPup
		let isCourseRewardPopupDisplay = isCourseRewardPopupShow;
		if (
			socialActivityData?.records[0]?.ucPoints >=
			socialActivityData?.records[0]?.rewardCTargetPoints
		) {
			isCourseRewardPopupDisplay = true;
			setCourseRewardPopupShow(true);
		} else if (
			socialActivityData?.records[0]?.ucPoints + testPoint >=
			socialActivityData?.records[0]?.rewardCTargetPoints &&
			!isCourseRewardPopupDisplay
		) {
			if (!isContinue) {
				data?.handleShowRewardUnlockPopup(
					true,
					socialActivityData?.records[0]?.rcImageUrl,
					socialActivityData?.records[0]?.rcName
				);
			}
			setCourseRewardPopupShow(true);
			let values = {
				userCourseId: socialActivityData?.records[0]?.ucId,
				userActivityId: socialActivityData?.records[0]?.uaId,
				learnerId: defaultChildData?.id,
				courseId: socialActivityData?.records[0]?.courseId,
				isCourse: true,
				isActivity: false,
			};
			dispatch(sendRewardEmail(values));
		}
	};
	//	console.log("KnowladgecheckHandler", earnPoint, knowladge_check_response?.records[0]?.points, data.kc[selectedQuestionIndex],)
	useEffect(() => {
		//	console.log("KnowladgecheckHandler 1111", earnPoint, knowladge_check_response?.records[0]?.points, data.kc[selectedQuestionIndex],)
		if (knowladge_check_response !== undefined) {
			if (earnPoint < knowladge_check_response?.records[0]?.points) {
				data.kc[selectedQuestionIndex].attempted = true;
				console.log("KnowladgecheckHandler 2222", earnPoint, knowladge_check_response?.records[0]?.points, data.kc[selectedQuestionIndex],)
			}
			if (data?.currentScene == data?.totalSence - 1) {
				let isAllNotAttempted = false;
				if (data?.seriesScenesData[data?.currentScene]?.kcRequired) {
					data?.kc?.map((val, index) => {
						if (!val?.attempted) {
							isAllNotAttempted = true;
						}
					});
					data?.handleKcEnableDisabled(isAllNotAttempted);
				}
				/*
								  isAllNotAttempted = false;
								  data?.kc?.map((val, index) => {
								  if (!val?.attempted) {
								  isAllNotAttempted = true;
								  }
								  });
								  data?.handleKcSceneComplete(isAllNotAttempted);
								  */
			}
			setShowPleaseWait(false);
			setIsLoading(false);
			setExeriseSubmit(false);
			if (showCongratulationModel) {
				let sectionResult = handleSectionRewardPopup(
					knowladge_check_response,
					true
				);
				let courseResult = handleCourseRewardPopup(
					knowladge_check_response,
					true
				);

				let activityData = socialActivityData?.records[0];
				let obj = {
					imageUrl: activityData?.rimageUrl,
					rewardName: activityData?.rname,
				};
				if (courseResult) {
					obj.imageUrl = activityData?.rcImageUrl;
					obj.rewardName = activityData?.rcName;
					data?.handleCongratulationModel(0, 0, 0, 0, courseResult, obj);
				} else {
					data?.handleCongratulationModel(0, 0, 0, 0, sectionResult, obj);
				}
			} else {
				handleSectionRewardPopup(knowladge_check_response, false);
				handleCourseRewardPopup(knowladge_check_response, false);
			}
			setSelectedQuestionIndex(selectedQuestionIndex);
			let currentPoint = knowladge_check_response?.records[0]?.points;


			let point = data?.previousPoint;
			if (point != currentPoint) {
				if (data?.currentScene != data?.totalSence - 1) {
					if (isNuggetDisplay) {
						setNuggetDisplay(true);
					}
					setRecentCorrect(true);
				}
				setChangePoint(true);
				play();
				setTimeout(() => {
					setChangePoint(false);
				}, 3000);
			}

			setEarnPoint(knowladge_check_response?.records[0]?.points);
			if (loggedInUser?.role?.name != "PROVIDER") {
				if (
					socialActivityData?.records[0]?.isEnrolled &&
					!socialActivityData?.records[0]?.isPCEmailSent &&
					socialActivityData?.records[0]?.parentCollabEmail
				) {
					dispatch(sendCollabrationEmail(loggedInUser.role.name === 'LEARNER' || loggedInUser.role.name === 'STUDENT' ? loggedInUser?.parentId : loggedInUser?.id, collabEmailData));
					// dispatch(sendCollabrationEmail(loggedInUser?.id, collabEmailData));
					socialActivityData.records[0].isPCEmailSent = true;
				}
			}
		}
	}, [knowladge_check_response?.records]);

	useEffect(() => {
		setEarnPoint(data?.previousPoint);
		if (kcStateResponse !== undefined) {
			setSelectedQuestionIndex(kcStateResponse?.records[0]?.currentKc - 1);
		}
	}, [data?.previousPoint, kcStateResponse?.records]);

	const [prevPoint, setPrevPoint] = useState(data?.providerPoints);

	useEffect(() => {
		//	setEarnPoint(data?.points);
		//	setEarnPoint(loggedInUser?.role?.name != "PROVIDER" ? data?.points : data?.providerPoints)
		if (data?.providerPoints != 0 && loggedInUser?.role?.name === "PROVIDER") {
			play();
		}
		if (
			loggedInUser?.role?.name === "PROVIDER" &&
			data?.providerPoints != 0 &&
			prevPoint != data?.providerPoints
		) {
			data?.handleNextButtonDisable(true);
			setChangePoint(true);
			//play();
			setEarnPoint(data?.providerPoints);
			setPrevPoint(data?.providerPoints);
			setTimeout(() => {
				setChangePoint(false);
				data?.handleNextButtonDisable(false);
			}, 3000);
		} else if (
			loggedInUser?.role?.name === "PROVIDER" &&
			prevPoint == data?.providerPoints
		) {
			setEarnPoint(data?.providerPoints);
		}
	}, [play, data?.providerPoints]);
	//	console.log("data?.kc[selectedQuestionIndex] : ", selectedQuestionIndex, data?.kc[selectedQuestionIndex]);
	useEffect(() => {
		console.log('data new log : ', data);
		if (data?.kc[selectedQuestionIndex]?.attempted) {
			setIsNext(true)
		} else {
			setIsNext(false)
		}
	}, [selectedQuestionIndex])


	const KnowladgecheckHandler = async (isCorrectAttemptBody) => {
		let isSkill = false;
		if (
			socialActivityData?.records[0]?.dimName == "Social" ||
			socialActivityData?.records[0]?.dimName == "Emotional" ||
			socialActivityData?.records[0]?.dimName == "Spiritual"
		) {
			isSkill = true;
		}
		if (loggedInUser?.role?.name != "PROVIDER") {
			if (data?.currentScene == data?.totalSence - 1) {
				setExeriseSubmit(true);
			}
			setIsLoading(true);

			if (isCorrectAttemptBody?.isKc) {
				dispatch(
					KnowladgecheckMultiSelectCheckbox(
						param.id,
						data?.kc[selectedQuestionIndex]?.surveyId,
						param?.courseId,
						param?.skillId,
						param?.activityId,
						isSkill,
						isCorrectAttemptBody
					)
				);
			} else {
				dispatch(
					Knowladgecheck(
						param.id,
						data?.kc[selectedQuestionIndex]?.surveyId,
						param?.courseId,
						param?.activityId,
						param?.skillId,
						isCorrectAttemptBody,
						isSkill
					)
				);
			}
		}
	};


	const [isResult, setIsResult] = useState(true);
	const [prevSelectedOption, setPrevSelectedOption] = useState([]);
	const [invalidAttemptCount, setInvalidAttemptCount] = useState(0);
	const [isRightOptionSelected, setRightOptionSelected] = useState(false);
	const [prevRadioSelected, setPrevRadioSelected] = useState("");
	const handleNext2 = (isNext, isContinue, type, instructions, kcdata) => {
		if (type === "RADIOQ") {
			let selectedQuestion = data?.kc[selectedQuestionIndex];
			//	For excersie
			if (data?.currentScene == data?.totalSence - 1) {
				let selectedOptionId = '';
				getSequnceSort(selectedQuestion?.options)?.map((opt, index) => {
					if (opt?.attempted) {
						selectedOptionId = opt?.id;
						if (opt?.help && opt?.help != "None") {
							setNuggetTitle("Food for Thought!");
							setShowNuggetArray([opt]);
						}
					}
				})
				const exceriseBody = {
					questionId: selectedQuestion?.id,
					invalidAttempt: 0,
					type: type,
					answer: "",
					optionIds: [selectedOptionId],
				};
				KnowladgecheckHandler(exceriseBody);
				selectedQuestion.attempted = true;
				console.log('exceriseBody: ', exceriseBody);
				if (selectedQuestionIndex == data?.kc?.length - 1) {
					data?.handleCongratulationModel();
				}
				return true;
			}
			let selectedOptionId = '';
			getSequnceSort(selectedQuestion?.options)?.map((opt, index) => {
				if (opt?.attempted) {
					selectedOptionId = opt?.id;
					opt.isUserSelected = true;
				}
			})
			let result = selectedQuestion?.options?.some((item) => item?.attempted && item.isCorrect);
			setNuggetTitle(result ? 'Well Done!' : 'Close! Keep Going!');
			setNuggetDisplay(true);
			if (prevRadioSelected == selectedOptionId) {
				return true;
			}
			setPrevRadioSelected(selectedOptionId);
			selectedQuestion.invalidAttempt += 1
			let kcpoints = 0;
			if (result || selectedQuestion?.invalidAttempt == 3) {
				kcpoints = selectedQuestion?.invalidAttempt == 1 ? 10 : (selectedQuestion?.invalidAttempt == 2 ? 5 : 1);
				setRightOptionSelected(true);
				selectedQuestion.attempted = true;
				let isAllNotAttempted = false;
				//	add new for button enabled disabled for kc
				if (data?.seriesScenesData[data?.currentScene]?.kcRequired) {
					data["kc"]?.map((val, index) => {
						if (!val?.attempted) {

							isAllNotAttempted = true;
						}
					});
					data?.handleKcEnableDisabled(isAllNotAttempted);
				}
			}
			const body = {
				questionId: selectedQuestion?.id,
				invalidAttempt: selectedQuestion?.invalidAttempt,
				type: type,
				answer: "",
				optionIds: [selectedOptionId],
				kcpoints: kcpoints
			};
			KnowladgecheckHandler(body);
			//	console.log('body: ', body);
		}
		if (type === "CHECKBOXQ") {
			//	setShowPleaseWait(true);
			let selectedOptionArray = [];
			let selectedQuestion = data?.kc[selectedQuestionIndex];
			if (data?.currentScene == data?.totalSence - 1) {
				let selectedOptionId = '';
				getSequnceSort(selectedQuestion?.options)?.map((opt, index) => {
					if (opt?.attempted) {
						selectedOptionArray?.push(opt?.id);
						if (opt?.help && opt?.help != "None") {
							setNuggetTitle("Food for Thought!");
							setShowNuggetArray([opt]);
						}
					}
				})
				const exceriseBody = {
					questionId: selectedQuestion?.id,
					invalidAttempt: 0,
					type: type,
					answer: "",
					optionIds: selectedOptionArray,
				};
				KnowladgecheckHandler(exceriseBody);
				selectedQuestion.attempted = true;
				console.log('exceriseBody: ', exceriseBody);
				if (selectedQuestionIndex == data?.kc?.length - 1) {
					data?.handleCongratulationModel();
				}
				return true;
			}
			let totalCorrect = 0, totalSelectedCorrect = 0, totalIncorrectCount = 0;
			selectedQuestion?.options?.map((item, index) => {
				if (item?.isCorrect) {
					totalCorrect++;
				}
				if (item?.attempted && item?.isCorrect) {
					totalSelectedCorrect++;
				}
				if (item?.attempted && !item?.isCorrect) {
					totalIncorrectCount++;
				}
			})
			console.log('my test result: ', totalCorrect, totalSelectedCorrect);
			let result = (totalCorrect === totalSelectedCorrect) && totalIncorrectCount === 0;
			setNuggetTitle(result ? 'Well Done!' : 'Close! Keep Going!');
			setNuggetDisplay(true);
			getSequnceSort(selectedQuestion?.options)?.map((opt, index) => {
				if (opt?.attempted) {
					selectedOptionArray?.push(opt?.id);
					opt.isUserSelected = true;
				}
			})
			if (JSON.stringify(selectedOptionArray) == JSON.stringify(prevSelectedOption)) {
				return true;
			}
			selectedQuestion.invalidAttempt += 1
			let kcpoints = 0;
			if (result || selectedQuestion?.invalidAttempt == 3) {
				kcpoints = selectedQuestion?.invalidAttempt == 1 ? 10 : (selectedQuestion?.invalidAttempt == 2 ? 5 : 1);
				setRightOptionSelected(true);
				selectedQuestion.attempted = true;
			} else {
				const updatedOptions = selectedQuestion?.options?.map(option => {
					if (selectedOptionArray.includes(option.id)) {
						return { ...option, attempted: false };
					}
					return option;
				});
			}
			const body = {
				questionId: selectedQuestion?.id,
				invalidAttempt: selectedQuestion?.invalidAttempt,
				type: type,
				isKc: true,
				answer: "",
				optionIds: selectedOptionArray,
				kcpoints: kcpoints,
				activityTime: "",
			};
			setPrevSelectedOption(selectedOptionArray);
			KnowladgecheckHandler(body);
			console.log('isCorrectAttemptBody : ', body);
			//	console.log('result : ', result, selectedOptionArray, prevSelectedOption, body, JSON.stringify(selectedOptionArray) != JSON.stringify(prevSelectedOption));
			/*
			let optIdsArray = [];
			let selectedCorrectOptions = [];
			let selectedIncorrectOptions = [];

			setInval(question.invalidAttempt)

			question["options"].map((value) => {
				if (value.attempted) {
					optIdsArray.push(value?.id);
					if (value.isCorrect) {
						selectedCorrectOptions.push(value.id);
					} else {
						selectedIncorrectOptions.push(value.id);
					}
				}
			});

			if (data.currentScene === data.totalSence - 1) {
				const isCorrectAttemptBody = {
					questionId: question.id,
					invalidAttempt: 1,
					type: type,
					answer: "",
					optionIds: optIdsArray,
				};

				if (optIdsArray?.length > 0) {
					if (loggedInUser?.role?.name == "PROVIDER" && !question.attempted) {
						setChangePoint(true);
						play();
						setTimeout(() => {
							setChangePoint(false);
						}, 3000);
						setEarnPoint(earnPoint + question?.points);
						setExceriseLearnPointState(
							exceriseLearnPointState + question?.points
						);
						data?.handleProviderPoint(earnPoint + question?.points);
						totalLearnPoint += question?.points;
						exceriseLearnPoint += question?.points;
					}
					question.attempted = true;
					KnowladgecheckHandler(isCorrectAttemptBody);
					setTotalExceriseAttempted(totalExceriseAttempted + 1);
					totalExceriseQuestion += 1;
				}
			} else {
				const allCorrectSelected = question.options
					?.filter((option) => option.isCorrect)
					?.every((option) => selectedCorrectOptions?.includes(option.id));
				const noIncorrectSelected = selectedIncorrectOptions.length === 0;

				if (isContinue && optIdsArray?.length > 0) {
					const invalidAttempt = question.invalidAttempt + 1;

					setNuggetDisplay(true);

					if ((allCorrectSelected && noIncorrectSelected) || invalidAttempt === 3) {

						setIsOptionTrue(true)
						const isCorrectAttemptBody = {
							questionId: question.id,
							invalidAttempt: invalidAttempt,
							type: type,
							isKc: true,
							answer: "",
							optionIds: invalidAttempt === 3 ? selectedCorrectOptions.concat(selectedIncorrectOptions) : selectedCorrectOptions,
							kcpoints:
								invalidAttempt === 1 ? 10 : invalidAttempt === 2 ? 5 : 1,
							activityTime: "",
						};
						// question.attempted = true;

						if (!optionIdsArr.includes(oneOpt)) {
							let arr = [...optionIdsArr];
							arr?.push(oneOpt);
							setOptionIdsArr(arr);

							KnowladgecheckHandler(isCorrectAttemptBody);
						}

						setTotalExceriseAttempted(totalExceriseAttempted + 1);
						totalExceriseQuestion += 1;
						// setEarnPoint(earnPoint + isCorrectAttemptBody?.kcpoints);
						setIsLoading(true);
						return;
					} else {
						setIsOptionTrue(false)
						const isCorrectAttemptBody = {
							questionId: question.id,
							invalidAttempt: invalidAttempt,
							type: type,
							isKc: true,
							answer: "",
							optionIds: selectedCorrectOptions.concat(selectedIncorrectOptions),
							kcpoints: 0,
							activityTime: "",
						};
						// question.attempted = true;
						question.invalidAttempt = invalidAttempt;

						if (!optionIdsArr.includes(oneOpt)) {
							let arr = [...optionIdsArr];
							arr?.push(oneOpt);
							setOptionIdsArr(arr);
							KnowladgecheckHandler(isCorrectAttemptBody);
						}
						// setIsLoading(true);
						// setEarnPoint(earnPoint + isCorrectAttemptBody?.kcpoints);
						return;

					}
				}
			}
			setOptionArr([]); */
		}
		let answer;
		if (type == "COMMENTQ") {
			let question = data?.kc[selectedQuestionIndex];
			answer = document.getElementById("questionResponse" + selectedQuestionIndex).value.trim();

			question.options[0].answer = answer;

			const exceriseBody = {
				questionId: question?.id,
				invalidAttempt: 0,
				type: type,
				answer: answer,
				optionIds: [],
			};
			if (!answer == "") {
				// if (loggedInUser?.role?.name == "PROVIDER" && !question.attempted) {
				// 	setChangePoint(true);
				// 	play();
				// 	setTimeout(() => {
				// 		setChangePoint(false);
				// 	}, 3000);
				// 	setEarnPoint(earnPoint + question?.points);
				// 	setExceriseLearnPointState(
				// 		exceriseLearnPointState + question?.points
				// 	);
				// 	data?.handleProviderPoint(earnPoint + question?.points);
				// 	totalLearnPoint += question?.points;
				// 	setTotalExceriseAttempted(totalExceriseAttempted + 1);
				// 	totalExceriseQuestion += 1;
				// 	exceriseLearnPoint += question?.points;
				// }				
				question.attempted = true;
				KnowladgecheckHandler(exceriseBody);
			}
		}
		//	console.log('INnnnnnnnnnnnnnnnnnnn : ', data?.currentScene, data?.totalSence, selectedQuestionIndex, data?.kc?.length)
		if (data?.currentScene == data?.totalSence - 1 && selectedQuestionIndex == data?.kc?.length - 1) {
			/*
			if (loggedInUser?.role?.name == "PROVIDER") {
				data?.handleCongratulationModel(
					exceriseLearnPoint,
					totalLearnPoint,
					totalQuestionAttempted,
					totalExceriseQuestion
				);
				return;
			}
			*/
			//	console.log('INnnnnnnnnnnnnnnnnnnn : ', answer, type)
			if (answer == "") {
				//	console.log('INnnnnnnnnnnnnnnnnnnn');
				data?.handleCongratulationModel();
				return;
			}
			setShowCongratulationModel(true);
			return;
		}
	}

	useEffect(() => {
		if (data?.tab === "Series") {
			moveToNumber(0)
		}
	}, [data?.tab === "Series"])

	const moveToNumber = (key) => {
		data?.kcStateUpdateHandler(data?.currentScene, key);
		setSelectedQuestionIndex(key);
		setNuggetDisplay(false);
		setRightOptionSelected(false);
		setSubmitDisabled(true);
		setShowNuggetArray([]);
	}

	const moveToNext = (isNext, isContinue) => {
		let count = selectedQuestionIndex;
		let totalQuestion = data?.kc?.length;
		let totalLearnPoint = earnPoint;
		let totalExceriseQuestion = totalExceriseAttempted;
		let exceriseLearnPoint = exceriseLearnPointState;
		// if (data?.currentScene == data?.totalSence - 1 && count == totalQuestion - 1 && isContinue) {
		// 	if (loggedInUser?.role?.name == "PROVIDER") {
		// 		data?.handleCongratulationModel(
		// 			exceriseLearnPoint,
		// 			totalLearnPoint,
		// 			totalQuestionAttempted,
		// 			totalExceriseQuestion
		// 		);
		// 		return;
		// 	}
		// 	if (answer == "" || type == "CHECKBOXQ" || type == "RADIOQ") {
		// 		data?.handleCongratulationModel();
		// 		return;
		// 	}
		// 	setShowCongratulationModel(isContinue);
		// 	return;
		// }

		if (isNext) {
			++count;
			if (count == totalQuestion) {
				data?.handleSeriesSceneStep(true);
			}
		} else {
			--count;
		}
		data?.kcStateUpdateHandler(data?.currentScene, count);
		setSelectedQuestionIndex(count);
		setNuggetDisplay(false);
		setRightOptionSelected(false);
		setSubmitDisabled(true);
		setShowNuggetArray([]);
		console.log('Test Test: ', data?.kc[count]);
		//	setInstructions(instructions);
	}

	const handleNext = (isNext, isContinue, type, instructions, kcdata) => {
		//	setRecentCorrect(false);
		if (loggedInUser?.role?.name != "PROVIDER") {
			//	setIsLoading(true);
		}
		if (modalData?.type == "courseHint") {
			dispatch(
				showModal({
					type: modalData?.type === "courseHint" ? "" : "courseHint",
				})
			);
		}
		let count = selectedQuestionIndex;
		let totalQuestion = data?.kc?.length;
		let totalLearnPoint = earnPoint;
		let totalExceriseQuestion = totalExceriseAttempted;
		let exceriseLearnPoint = exceriseLearnPointState;

		if (type == "RADIOQ" && optionIndexVal != undefined && isContinue) {

			if (data?.currentScene !== data?.totalSence - 1) {

				let question = data?.kc[questionIndexVal];
				setInval(question.invalidAttempt)
				let points = parseInt(question?.points);
				setQueIndex(question?.options[optionIndexVal].sequence);
				data?.handleSetPlayVideo(false);
				//for exercise
				if (data?.currentScene == data?.totalSence - 1) {
					setRecentCorrect(false);
					setChangePoint(false);
					//	alert(question["options"][optionIndexVal]?.id);
					setSelectedRedioOptionId(question["options"][optionIndexVal]?.id);
					let isBool = false;
					for (var i = 0; i < data?.kc?.length - 1; i++) {
						if (!data?.kc[i].attempted) {
							isBool = true;
						}
					}
					setIsResult(isBool);
					if (
						question["options"][optionIndexVal]?.help &&
						question["options"][optionIndexVal]?.help != "None"
					) {
						setNugget(question["options"][optionIndexVal]?.help);
						setNuggetDisplay(true);
						setNuggetTitle("Food for Thought!");
						setRightNugget(true);
					}
				}

				let invalidattempt = question.invalidAttempt;
				let option = question?.options[optionIndexVal];
				data["kc"][questionIndexVal].invalidAttempt = invalidattempt + 1;
				if (
					!option.isUserSelected &&
					!question.attempted &&
					data?.currentScene !== data?.totalSence - 1
				) {

					option.isUserSelected = true;
					option.attempted = true;

					const checking = data?.kc[selectedQuestionIndex]?.options.filter(
						(vl, ky) => vl.isUserSelected == true
					);
					const kcpoints =
						checking?.length === 1 ? 10 : checking?.length === 2 ? 5 : 1;
					const isCorrectAttemptBody = {
						questionId: data.kc[selectedQuestionIndex].id,
						invalidAttempt: data["kc"][questionIndexVal].invalidAttempt,
						type: data?.kc[selectedQuestionIndex]?.type,
						answer: "",
						optionIds: [option.id],
						kcpoints: question?.options[optionIndexVal]?.isCorrect ? kcpoints : 0,
					};

					KnowladgecheckHandler(isCorrectAttemptBody);
					counter = 0;
					if (question?.options[optionIndexVal]?.isCorrect) {

						setRecentCorrect(true);
						// setChangePoint(true);
						question.attempted = true;
					}
					if (!data["kc"][questionIndexVal]?.attempted) {

						play1();
					}
					if (loggedInUser?.role?.name == "PROVIDER") {
						let score = question?.options[optionIndexVal]?.isCorrect ? kcpoints : 0;
						let totalPoint = earnPoint + score;

						data?.handleProviderPoint(totalPoint);
						play();
						setTimeout(() => {
							setChangePoint(false);
						}, 3000);

						setTotalQuestionAttempted(totalQuestionAttempted + 1);
					}

				} else {

					setRecentCorrect(false);
				}
				question["options"][optionIndexVal].attempted = true;
				data["kc"][questionIndexVal] = question;
				setNugget(nuggetVal);

				if (data?.currentScene !== data?.totalSence - 1) {


					if (question?.options[optionIndexVal]?.isCorrect) {

						setIsOptionTrue(true)
						setNuggetDisplay(true);
						setNuggetTitle("Well Done!");
						let isAllNotAttempted = false;
						if (data?.seriesScenesData[data?.currentScene]?.kcRequired) {

							data["kc"]?.map((val, index) => {
								if (!val?.attempted) {

									isAllNotAttempted = true;
								}
							});
							data?.handleKcEnableDisabled(isAllNotAttempted);
						}

						setRightNugget(true);
					} else {
						setNuggetDisplay(true);
						setIsOptionTrue(false)
						setNuggetTitle("Close! Keep Going!");
						setRightNugget(false);
						return;
					}
				}
			} else {

				let question = data?.kc[selectedQuestionIndex];

				if (selectedRadioOptionId != "") {
					const isCorrectAttemptBody = {
						questionId: data.kc[selectedQuestionIndex].id,
						invalidAttempt: 0,
						type: type,
						answer: "",
						optionIds: [selectedRadioOptionId],
					};
					setSelectedRedioOptionId("");
					question["options"].map((value, index) => {
						if (value.attempted) {
							data.kc[selectedQuestionIndex]["options"][index].attempted = false;
						}
					});
					data.kc[selectedQuestionIndex]["options"][
						QueIndex - 1
					].attempted = true;
					if (loggedInUser?.role?.name == "PROVIDER" && !question.attempted) {
						setChangePoint(true);
						play();
						setTimeout(() => {
							setChangePoint(false);
						}, 3000);
						setEarnPoint(earnPoint + question?.points);
						setExceriseLearnPointState(
							exceriseLearnPointState + question?.points
						);
						data?.handleProviderPoint(earnPoint + question?.points);
						totalLearnPoint += question?.points;
						exceriseLearnPoint += question?.points;
						setTotalExceriseAttempted(totalExceriseAttempted + 1);
						totalExceriseQuestion += 1;
					}
					KnowladgecheckHandler(isCorrectAttemptBody);
					question.attempted = true;
				}
			}
			return
		}

		if (type === "CHECKBOXQ") {

			let question = data?.kc[selectedQuestionIndex];
			let optIdsArray = [];
			let selectedCorrectOptions = [];
			let selectedIncorrectOptions = [];

			setInval(question.invalidAttempt)

			question["options"].map((value) => {
				if (value.attempted) {
					optIdsArray.push(value?.id);
					if (value.isCorrect) {
						selectedCorrectOptions.push(value.id);
					} else {
						selectedIncorrectOptions.push(value.id);
					}
				}
			});

			if (data.currentScene === data.totalSence - 1) {
				const isCorrectAttemptBody = {
					questionId: question.id,
					invalidAttempt: 1,
					type: type,
					answer: "",
					optionIds: optIdsArray,
				};

				if (optIdsArray?.length > 0) {
					if (loggedInUser?.role?.name == "PROVIDER" && !question.attempted) {
						setChangePoint(true);
						play();
						setTimeout(() => {
							setChangePoint(false);
						}, 3000);
						setEarnPoint(earnPoint + question?.points);
						setExceriseLearnPointState(
							exceriseLearnPointState + question?.points
						);
						data?.handleProviderPoint(earnPoint + question?.points);
						totalLearnPoint += question?.points;
						exceriseLearnPoint += question?.points;
					}
					question.attempted = true;
					KnowladgecheckHandler(isCorrectAttemptBody);
					setTotalExceriseAttempted(totalExceriseAttempted + 1);
					totalExceriseQuestion += 1;
				}
			} else {
				const allCorrectSelected = question.options
					?.filter((option) => option.isCorrect)
					?.every((option) => selectedCorrectOptions?.includes(option.id));
				const noIncorrectSelected = selectedIncorrectOptions.length === 0;

				if (isContinue && optIdsArray?.length > 0) {
					const invalidAttempt = question.invalidAttempt + 1;

					setNuggetDisplay(true);

					if ((allCorrectSelected && noIncorrectSelected) || invalidAttempt === 3) {

						setIsOptionTrue(true)
						const isCorrectAttemptBody = {
							questionId: question.id,
							invalidAttempt: invalidAttempt,
							type: type,
							isKc: true,
							answer: "",
							optionIds: invalidAttempt === 3 ? selectedCorrectOptions.concat(selectedIncorrectOptions) : selectedCorrectOptions,
							kcpoints:
								invalidAttempt === 1 ? 10 : invalidAttempt === 2 ? 5 : 1,
							activityTime: "",
						};
						// question.attempted = true;

						if (!optionIdsArr.includes(oneOpt)) {
							let arr = [...optionIdsArr];
							arr?.push(oneOpt);
							setOptionIdsArr(arr);

							KnowladgecheckHandler(isCorrectAttemptBody);
						}

						setTotalExceriseAttempted(totalExceriseAttempted + 1);
						totalExceriseQuestion += 1;
						// setEarnPoint(earnPoint + isCorrectAttemptBody?.kcpoints);
						setIsLoading(true);
						return;
					} else {
						setIsOptionTrue(false)
						const isCorrectAttemptBody = {
							questionId: question.id,
							invalidAttempt: invalidAttempt,
							type: type,
							isKc: true,
							answer: "",
							optionIds: selectedCorrectOptions.concat(selectedIncorrectOptions),
							kcpoints: 0,
							activityTime: "",
						};
						// question.attempted = true;
						question.invalidAttempt = invalidAttempt;

						if (!optionIdsArr.includes(oneOpt)) {
							let arr = [...optionIdsArr];
							arr?.push(oneOpt);
							setOptionIdsArr(arr);
							KnowladgecheckHandler(isCorrectAttemptBody);
						}
						// setIsLoading(true);
						// setEarnPoint(earnPoint + isCorrectAttemptBody?.kcpoints);
						return;

					}
				}
			}
			setOptionArr([]);
		}

		var answer;
		if (type == "COMMENTQ") {
			let question = data?.kc[selectedQuestionIndex];
			answer = document.getElementById("questionResponse" + count).value.trim();

			question["options"][0]["answer"] = answer;

			const isCorrectAttemptBody = {
				questionId: data.kc[selectedQuestionIndex].id,
				invalidAttempt: 0,
				type: type,
				answer: answer,
				optionIds: [],
			};
			if (!answer == "") {
				if (loggedInUser?.role?.name == "PROVIDER" && !question.attempted) {
					setChangePoint(true);
					play();
					setTimeout(() => {
						setChangePoint(false);
					}, 3000);
					setEarnPoint(earnPoint + question?.points);
					setExceriseLearnPointState(
						exceriseLearnPointState + question?.points
					);
					data?.handleProviderPoint(earnPoint + question?.points);
					totalLearnPoint += question?.points;
					setTotalExceriseAttempted(totalExceriseAttempted + 1);
					totalExceriseQuestion += 1;
					exceriseLearnPoint += question?.points;
				}
				question.attempted = true;

				KnowladgecheckHandler(isCorrectAttemptBody);
			}
			setNuggetDisplay(false);
			setRecentCorrect(false);
		}
		if (
			data?.currentScene == data?.totalSence - 1 &&
			selectedQuestionIndex == totalQuestion - 1 &&
			isContinue
		) {
			if (loggedInUser?.role?.name == "PROVIDER") {
				data?.handleCongratulationModel(
					exceriseLearnPoint,
					totalLearnPoint,
					totalQuestionAttempted,
					totalExceriseQuestion
				);
				return;
			}
			if (answer == "" || type == "CHECKBOXQ" || type == "RADIOQ") {
				//	setShowPleaseWait(true);
				data?.handleCongratulationModel();
				return;
			}
			setShowCongratulationModel(isContinue);
			return;
		}

		if (selectedQuestionIndex == totalQuestion - 1 && isContinue) {
			data?.handleSeriesSceneStep(true);
		} else {
			if (true) {
				if (isNext) {
					if (totalQuestion - 1 > count) {
						++count;
					}
				} else {
					--count;
				}
			}
		}
		data?.kcStateUpdateHandler(data?.currentScene, count);
		setSelectedQuestionIndex(count);
		setNuggetDisplay(false);
		setInstructions(instructions);
	};

	const handleCloseNugget = () => {
		setNuggetDisplay(false);
		setHideQues(true);
		setShowNuggetArray([]);
	};

	const handleCommentChange = (value, index) => {
		if (value?.trim()?.length > 0) {
			data?.handleSetPlayVideo(false);
			setSubmitDisabled(false);
		}
		if (
			data?.currentScene == data?.totalSence - 1 &&
			data?.kc?.length - 1 === index
		) {
			if (value?.length > 0) {
				data?.handleSetPlayVideo(false);
				let isBool = false;
				for (var i = 0; i < data?.kc?.length - 1; i++) {
					if (!data?.kc[i].attempted) {
						isBool = true;
					}
				}
				setIsResult(isBool);
			} else {
				setIsResult(true);
			}
		}
	};

	const [showNuggetArray, setShowNuggetArray] = useState([]);
	const [getOPtndata, setOptionData] = useState();
	const handleOption = (questionIndex, optionIndex, nugget, questionId, isChecked) => {
		/*
		if (allSceneDataArray[data?.currentScene]?.showTooltip) {
			data?.setDeletePlan({ title: "Lessons", message: sceneTextMessage })
			return;
		} on 18-11-2024
		*/
		let options = getSequnceSort(data?.kc[questionIndex]?.options);
		if (loggedInUser?.role?.name == "PROVIDER") {
			let question = data?.kc[questionIndex];
			question.invalidAttempt += 1;
			if ((options[optionIndex]?.isCorrect && !question?.attempted) || question.invalidAttempt == 3) {
				let totalPoint = earnPoint + (question.invalidAttempt == 1 ? 10 : (question.invalidAttempt == 2 ? 5 : 1));
				data?.handleProviderPoint(totalPoint);
				play();
				setTimeout(() => {
					setChangePoint(false);
				}, 3000);
				options[optionIndex].attempted = true;
				question.attempted = true;
				setNuggetDisplay(true);
				setRightOptionSelected(true);
			} else {
				setShowNuggetArray([options[optionIndex]]);
				setNuggetDisplay(false);
			}
			setNuggetTitle(options[optionIndex].isCorrect ? 'Well Done!' : 'Close! Keep Going!');
			options[optionIndex].isUserSelected = true;
			return;
		}
		options?.map((opt, index) => {
			opt.attempted = false;
			if (index === optionIndex) {
				setOptionData(opt);
			}
		})
		options[optionIndex].attempted = true;
		console.log('Result : ', options);
		//	for exercise
		if (data?.currentScene !== data?.totalSence - 1) {
			//	when lesson radio is submitted and want to nugget again
			if (data?.kc[questionIndex]?.attempted) {
				setShowNuggetArray([options[optionIndex]]);
				setNuggetTitle(options[optionIndex].isCorrect ? 'Well Done!' : 'Close! Keep Going!');
				setNuggetDisplay(false);
				return true;
			}
		}
		setSubmitDisabled(false);
		setNuggetDisplay(false);
		//	Comment add by Me
		/*
		setQuestionIndexVal(questionIndex);
		setOptionIndexVal(optionIndex);
		setNuggetVal(nugget);
		*/


		// if (
		//   getSequnceSort(socialActivityData?.records[0]?.series?.scenes)[
		//     data?.currentScene
		//   ]?.showTooltip
		// ) {
		// console.log('===>>>> step 2')
		//   return;
		// }
		// let question = data?.kc[questionIndex];
		// let points = parseInt(question?.points);
		// setQueIndex(question.options[optionIndex].sequence);
		// data?.handleSetPlayVideo(false);
		// //for exercise
		// if (data?.currentScene == data?.totalSence - 1) {
		//   setRecentCorrect(false);
		//   setChangePoint(false);
		//   //	alert(question["options"][optionIndex]?.id);
		//   setSelectedRedioOptionId(question["options"][optionIndex]?.id);
		//   let isBool = false;
		//   for (var i = 0; i < data?.kc?.length - 1; i++) {
		//     if (!data?.kc[i].attempted) {
		//       isBool = true;
		//     }
		//   }
		//   setIsResult(isBool);
		//   if (
		//     question["options"][optionIndex]?.help &&
		//     question["options"][optionIndex]?.help != "None"
		//   ) {
		//     setNugget(question["options"][optionIndex]?.help);
		//     setNuggetDisplay(true);
		//     setNuggetTitle("Food for Thought!");
		//     setRightNugget(true);
		//   }
		// }

		// let invalidattempt = question.invalidAttempt;
		// let option = question?.options[optionIndex];
		// data["kc"][questionIndex].invalidAttempt = invalidattempt + 1;

		// console.log('===>>>> step !option.isUserSelected' , !option.isUserSelected)
		// console.log('===>>>> step !question.attempted' , !question.attempted)
		// console.log('===>>>> step data?.currentScene !== data?.totalSence - 1' , !option.isUserSelected)
		// console.log('===>>>> step option.isUserSelected' , !option.isUserSelected)

		// if (!option.isUserSelected && !question.attempted && data?.currentScene !== data?.totalSence - 1) {
		// console.log('===>>>> step 3')
		//   option.isUserSelected = true;
		//   option.attempted = true;

		//   const checking = data?.kc[selectedQuestionIndex]?.options.filter(
		//     (vl, ky) => vl.isUserSelected == true
		//   );
		//   const kcpoints =
		//     checking?.length === 1 ? 10 : checking?.length === 2 ? 5 : 1;
		//   const isCorrectAttemptBody = {
		//     questionId: data.kc[selectedQuestionIndex].id,
		//     invalidAttempt: data["kc"][questionIndex].invalidAttempt,
		//     type: data?.kc[selectedQuestionIndex]?.type,
		//     answer: "",
		//     optionIds: [option.id],
		//     kcpoints: question?.options[optionIndex]?.isCorrect ? kcpoints : 0,
		//   };
		//   console.log('===>>>> step 4 isCorrectAttemptBody' , isCorrectAttemptBody)
		//   KnowladgecheckHandler(isCorrectAttemptBody);
		//   counter = 0;
		//   if (question?.options[optionIndex]?.isCorrect) {
		//     console.log('===>>>> step 5')
		//     setRecentCorrect(true);
		//     // setChangePoint(true);
		//     question.attempted = true;
		//   }
		//   if (!data["kc"][questionIndex]?.attempted) {
		//   console.log('===>>>> step 6')
		//     play1();
		//   }
		//   // if (loggedInUser?.role?.name == "PROVIDER") {
		//   //   let score = question?.options[optionIndex]?.isCorrect ? kcpoints : 0;
		//   //   let totalPoint = earnPoint + score;

		//   //   data?.handleProviderPoint(totalPoint);
		//   //   play();
		//   //   setTimeout(() => {
		//   //     setChangePoint(false);
		//   //   }, 3000);

		//   //   setTotalQuestionAttempted(totalQuestionAttempted + 1);
		//   // }
		// } else {
		// console.log('===>>>> step 7')
		//   setRecentCorrect(false);
		// }
		// question["options"][optionIndex].attempted = true;
		// data["kc"][questionIndex] = question;
		// setNugget(nugget);

		// if (data?.currentScene !== data?.totalSence - 1) {
		// console.log('===>>>> step 8')
		//   if (question?.options[optionIndex]?.isCorrect) {
		//   console.log('===>>>> step 9')
		//     setNuggetTitle("Well Done!");
		//     let isAllNotAttempted = false;
		//     if (data?.seriesScenesData[data?.currentScene]?.kcRequired) {
		//     console.log('===>>>> step 10')
		//       data["kc"]?.map((val, index) => {
		//         if (!val?.attempted) {
		//         console.log('===>>>> step 11')
		//           isAllNotAttempted = true;
		//         }
		//       });
		//       data?.handleKcEnableDisabled(isAllNotAttempted);
		//     }
		//     /*
		//                   isAllNotAttempted = false;
		//                   data["kc"]?.map((val, index) => {
		//                   if (!val?.attempted) {
		//                   isAllNotAttempted = true;
		//                   }
		//                   });
		//                   data?.handleKcSceneComplete(isAllNotAttempted);
		//                   */
		//     setRightNugget(true);
		//   } else {
		//   console.log('===>>>> step 12')
		//     setNuggetTitle("Close! Keep Going!");
		//     setRightNugget(false);
		//   }
		// }

		// if (nugget) {
		// console.log('===>>>> step 13')
		//   setNuggetDisplay(true);
		// } else {
		// console.log('===>>>> step 14')
		//   setNuggetDisplay(false);
		// }
	};

	useEffect(() => {
		if (data?.tab === "Series") {
			setShowData(data?.seriesScenesData);
		} else {
			setShowData(data);
			setShowMyJournal(false);
		}
		//	console.log('data-selectedQuestionIndex: ', data)
	}, [data]);

	useEffect(() => {
		setOptionArr([]);
	}, [selectedQuestionIndex]);

	const [isSubmitDisabled, setSubmitDisabled] = useState(true);
	const handleCheckBoxOption2 = (questionIndex, optionIndex, nugget, isChecked) => {
		//	console.log('questionIndex, optionIndex, nugget, isChecked : ', questionIndex, optionIndex, nugget, isChecked);
		/*
		if (allSceneDataArray[data?.currentScene]?.showTooltip) {
			data?.setDeletePlan({ title: "Lessons", message: sceneTextMessage })
			return;
		} on 18-11-2024
		*/
		let question = data?.kc[questionIndex];
		let options = getSequnceSort(question?.options);
		setOptionData(options[optionIndex]);
		if (loggedInUser?.role?.name === "PROVIDER") {
			let totalCorrect = 0, totalSelectedCorrect = 0;
			if (!question?.attempted) {
				options[optionIndex].attempted = !options[optionIndex].attempted;
				options[optionIndex].isUserSelected = true;
				question?.options?.map((item, index) => {
					if (item?.isCorrect) {
						totalCorrect++;
					}
					if (item?.attempted && item?.isCorrect) {
						totalSelectedCorrect++;
					}
				})
				let result = totalCorrect === totalSelectedCorrect;
				if (result) {
					setNuggetTitle('Well Done!');
					setNuggetDisplay(true);
					setRightOptionSelected(true);
					question.attempted = true;
					let totalPoint = earnPoint + 10;
					data?.handleProviderPoint(totalPoint);
					play();
					setTimeout(() => {
						setChangePoint(false);
					}, 3000);
				} else {
					if (options[optionIndex].attempted) {
						setShowNuggetArray([options[optionIndex]]);
						setNuggetTitle(options[optionIndex].isCorrect ? 'Well Done!' : 'Close! Keep Going!');
						setNuggetDisplay(false);
					} else {
						setShowNuggetArray([]);
					}
				}
			} else {
				setShowNuggetArray([options[optionIndex]]);
				setNuggetTitle(options[optionIndex].isCorrect ? 'Well Done!' : 'Close! Keep Going!');
				setNuggetDisplay(false);
			}
			return true;
		}
		if (data?.currentScene !== data?.totalSence - 1) {
			//	when lesson radio is submitted and want to nugget again
			if (question?.attempted) {
				setShowNuggetArray([options[optionIndex]]);
				setNuggetTitle(options[optionIndex].isCorrect ? 'Well Done!' : 'Close! Keep Going!');
				setNuggetDisplay(false);
				return true;
			}
		}
		options[optionIndex].attempted = !options[optionIndex].attempted;
		if (question?.options?.some((item) => item?.attempted)) {
			setSubmitDisabled(false);
		} else {
			setSubmitDisabled(true);
		}
	}

	const handleCheckBoxOption = (questionIndex, optionIndex, nugget, isChecked) => {

		data?.handleSetPlayVideo(false);
		let question = data?.kc[questionIndex];
		question["options"][optionIndex].selected = isChecked;
		question["options"][optionIndex].attempted =
			!question["options"][optionIndex].attempted;
		data["kc"][questionIndex] = question;
		setQueIndex(question?.options[optionIndex].sequence);

		let questions = question["options"][optionIndex]?.question;
		let help = question["options"][optionIndex]?.help;
		setOneOpt(question["options"][optionIndex]?.id);
		// if(isChecked) {
		//   setOptionArr(prevNumbers => {
		//       return [
		//         ...prevNumbers,
		//         { optionIndex, questions, help }
		//       ];

		//     }    
		//   );
		// }else {
		//   setOptionArr(prevNumbers => 
		//     prevNumbers.filter(item => item.optionIndex !== optionIndex)
		//   );
		// }

		if (isChecked) {
			setOptionArr(prevNumbers => {
				const updatedArray = [
					...prevNumbers,
					{ optionIndex, questions, help }
				];
				return updatedArray.sort((a, b) => a.optionIndex - b.optionIndex);
			});
		} else {
			setOptionArr(prevNumbers => {
				const filteredArray = prevNumbers.filter(item => item.optionIndex !== optionIndex);
				return filteredArray.sort((a, b) => a.optionIndex - b.optionIndex);
			});
		}

		if (data?.currentScene == data?.totalSence - 1) {
			let attemptedCount = 0;
			question?.options?.map((val, index) => {
				if (val?.attempted) {
					attemptedCount++;
				}
			});
			if (attemptedCount > 0) {
				let isBool = false;
				for (var i = 0; i < data?.kc?.length - 1; i++) {
					if (!data?.kc[i].attempted) {
						isBool = true;
					}
				}
				setIsResult(isBool);
			} else {
				setIsResult(true);
			}
		}

		const selectedHelps = question?.options
			.filter(option => option.selected && option.help && option.help !== "None")
			.map(option => option.help);

		const combinedHelps = selectedHelps.join();

		const selectedOptions = question?.options?.filter(option => option.selected)?.map(option => option.id);
		const correctOptions = question?.options?.filter(option => option.isCorrect)?.map(option => option.id);
		const sortedSelectedOptions = [...selectedOptions]?.sort();
		const sortedCorrectOptions = [...correctOptions]?.sort();

		const arraysAreEqual = (a, b) => {
			if (a.length !== b.length) return false;
			for (let i = 0; i < a.length; i++) {
				if (a[i] !== b[i]) return false;
			}
			return true;
		};

		const allSelectedCorrect = arraysAreEqual(sortedSelectedOptions, sortedCorrectOptions);

		//  setAllSelectedCorrect(allSelectedCorrect)

		if (nugget && nugget != "None") {
			setNugget(combinedHelps);
			if (question.type === 'CHECKBOXQ' && data.currentScene !== data.totalSence - 1) {
				setNuggetTitle(allSelectedCorrect ? 'Well Done!' : 'Close! Keep Going!');
			} else {
				setNuggetTitle("Food for Thought!");
			}
			setRightNugget(true);
		}
	};

	const viewCCG = (data) => {
		dispatch(
			showModal({ type: modalData?.type === "courseHint" ? "" : "courseHint" })
		);
	};

	useEffect(() => {
		if (
			growthSurveyData &&
			growthSurveyData?.records[0]?.preGrowthSurveyBehavior?.length > 0 &&
			growthSurveyData?.records[0]?.postGrowthSurveyBehavior?.length > 0
		) {
			const combinedPreSurveyQuestions = [
				...growthSurveyData.records[0].preGrowthSurveyBehavior,
				...growthSurveyData.records[0].preGrowthSurveyKnowledge,
				...(growthSurveyData.records[0].preGrowthSurveyScenario || []),
			];
			const sortedPreSurveyQuestions = combinedPreSurveyQuestions.sort(
				(a, b) => a.surveySequence - b.surveySequence
			);

			const combinedPostSurveyQuestions = [
				...growthSurveyData.records[0].postGrowthSurveyBehavior,
				...growthSurveyData.records[0].postGrowthSurveyKnowledge,
				...(growthSurveyData.records[0].postGrowthSurveyScenario || []),
			];
			const sortedPostSurveyQuestions = combinedPostSurveyQuestions.sort(
				(a, b) => a.surveySequence - b.surveySequence
			);
			setQuizData({
				pre: sortedPreSurveyQuestions,
				post: sortedPostSurveyQuestions,
			});
			setStartPopup(false);
		}
	}, [growthSurveyData]);

	let quizObj = [];
	if (
		growthSurveyData?.records[0]?.preProgress === 0 &&
		socialActivityData?.records[0]?.courseComp < 100
	) {
		quizObj = quizData?.pre;
	} else {
		quizObj = quizData?.post;
	}

	const handlePreviewData = (objData, ind) => {
		const updatedObjData = { ...objData, ind: ind };
		dispatch(selectOptionPre([updatedObjData]));
	};

	//Review Functionality
	useEffect(() => {
		if (growthSurveyData) {
			let quizData;
			if (growthSurveyData?.records[0]?.preProgress > 0) {
				if (showReviewData?.flag && showReviewData?.type === "preBehaviour") {
					quizData = growthSurveyData?.records[0]?.preGrowthSurveyBehavior;
				} else if (
					showReviewData?.flag &&
					showReviewData?.type === "preKnowledgeAndScenerio"
				) {
					quizData = [
						...growthSurveyData.records[0].preGrowthSurveyKnowledge,
						...(growthSurveyData.records[0].preGrowthSurveyScenario || []),
					];
				}
			}
			if (growthSurveyData?.records[0]?.postProgress > 0) {
				if (showReviewData?.flag && showReviewData?.type === "postBehaviour") {
					quizData = growthSurveyData.records[0].postGrowthSurveyBehavior;
				} else if (
					showReviewData?.flag &&
					showReviewData?.type === "postKnowledgeAndScenerio"
				) {
					quizData = [
						...growthSurveyData.records[0].postGrowthSurveyKnowledge,
						...(growthSurveyData.records[0].postGrowthSurveyScenario || []),
					];
				}
			}

			const sortedPostSurveyQuestions = quizData?.sort(
				(a, b) => a.surveySequence - b.surveySequence
			);
			setQuizObj(sortedPostSurveyQuestions);
			setStartPopup(false);
		}
	}, [growthSurveyData, quizData, showReviewData]);

	useEffect(() => { }, [selectOptData]);

	const handleQuiz = () => {
		dispatch(setSurveyAss(true));
	};

	const handleSubmit = () => {
		setStartPopup(true);
	};

	const [showMyJournal, setShowMyJournal] = useState(false);
	const [textAreaContent, setTextAreaContent] = useState("");

	const handleMyJournal = () => {
		setShowMyJournal(true)
	}

	const handleCloseMyJournal = () => {
		setShowMyJournal(false);
	};

	const handleBackClick = () => {
		saveToJornual();
		handleCloseMyJournal();
	};



	const saveToJornual = () => {
		const uaId = socialActivityData?.records[0]?.uaId || socialActivityDataForMyJournal?.records[0]?.uaId;
		const reqBody = {
			userActivityId: uaId,
			desc: "",
		};
		if (uaId) {
			dispatch(myJournal(reqBody));
		} else {
			console.error('User activity id is not defind');
		}
		setTextAreaContent();
	}


	return (
		<React.Fragment>
			{data?.tab !== "empthyQuiz" && (
				<>
					{showMyJournal ?
						(
							<div className="heading p-0 border-0">
								<div className="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
									<h2 data-toggle="collapse" className="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
										<span> <img src={image.myJournal} className='mr-2' alt="" />
											My Journal
										</span>
									</h2>
								</div>
							</div>
						)
						:
						(
							<div className="heading">
								{console.log('data?.tab', data)}
								<h2 className="flex">
									{data?.rptitle?.length > 25 ? (
										<ReactTooltip
											place="bottom"
											id={
												data?.tab == "Series"
													? data?.kc?.length > 0
														? data?.currentScene == data?.totalSence - 1
															? "Exercise"
															: "Knowledge Check"
														: data?.rptitle
													: data?.tab
											}
										>
											<p>
												{data?.tab == "Series"
													? data?.kc?.length > 0
														? data?.currentScene == data?.totalSence - 1
															? "Exercise"
															: "Knowledge Check"
														: data?.rptitle
													: data.tab === "References"
														? "Additional Resources"
														: data?.tab}
											</p>

										</ReactTooltip>
									) : (
										""
									)}
									<span
										className="flexone"
										data-for={
											data?.tab == "Series"
												? data?.kc?.length > 0
													? data?.currentScene == data?.totalSence - 1
														? "Exercise"
														: "Knowledge Check"
													: textTrim(data?.rptitle, 50)
												: textTrim(data?.tab, 50)
										}
										data-event-off=""
										data-tip
									>
										{data?.tab == "Series" ? (
											<img
												src={
													data?.kc?.length > 0
														? data?.currentScene == data?.totalSence - 1
															? image.RPExcercise
															: image.Scenenavigation
														: image.scenelessonicon
												}
												className="mr-2"
												alt=""
											/>
										) : (
											<>
												{data?.tab == "Characters" ? (
													<img src={image.RPcharacher} className="mr-2" alt="" />
												) : data?.tab === "empthyQuiz" ? (
													<img src={image.empathyicon} className="mr-2" alt="" />
												) : data?.tab === "growth" ? (
													<img src={image.Powericon} className="mr-2" alt="" />
												) : (
													<img src={image.SceneRefrence} className="mr-2" alt="" />
												)}
											</>
										)}
										{data?.tab == "Series" ? (
											data?.kc?.length > 0 ? (
												data?.currentScene == data?.totalSence - 1 ? (
													"Exercise"
												) : (
													"Knowledge Check"
												)
											) : (
												textTrim(data?.rptitle, 30)
											)
										) : (
											<>
												{data.tab === "References" ? (
													"Additional Resources"
												) : (
													<>
														{data?.tab === "empthyQuiz" ? (
															"How Empathetic Are You?"
														) : (
															<>
																{/* Growth Survey 9 May 2024 */}
																{data?.tab === "growth"
																	? surveyAssOpen || showReviewData?.flag
																		? "Growth Survey Preview"
																		: growthSurveyData?.records[0]?.preProgress > 0 ||
																			growthSurveyData?.records[0]?.postProgress > 0
																			? "Growth Survey Review"
																			: "What are Growth Surveys?"
																	: // : typeof showReviewData?.flag !== "undefined"
																	// ? "Growth Survey Review"
																	// : "What are Growth Surveys?"
																	(data?.tab === "Characters" ? "Meet The Characters" : data?.tab)}
															</>
														)}
													</>
												)}
											</>
										)}
									</span>

									{data?.tab === "Series" && (
										<span>
											<span
												className={`${changePoint ? "earnplusCoin" : "earnnoCoin"}`}
											>
												{earnPoint === 0 ? earnPoint : kFormatter(earnPoint)}
											</span>
											<img src={image.money_bag} alt="" />
										</span>
									)}
								</h2>
							</div>
						)
					}
				</>
			)}

			{data?.kc?.length > 1 && data?.tab == "Series" && !showMyJournal && (
				<>
					<div className="AllNuggQuestionList QuestionListSlide">
						<ul>
							<li
								className={`pointer ${selectedQuestionIndex === 0 || isLoading || isExerciseSubmit
									? "DissableArrow"
									: ""
									}`}
								// onClick={() =>
								// 	handleNext(
								// 		false,
								// 		false,
								// 		data?.kc[selectedQuestionIndex]?.type,
								// 		data?.kc[selectedQuestionIndex]?.instructions,
								// 		data?.kc[selectedQuestionIndex]
								// 	)
								// }
								onClick={() => moveToNext(false, false)}
							>
								<span key={data?.kc[selectedQuestionIndex]?.id}>
									<i className="fa-regular fa-angle-left"></i>
									Back
								</span>
							</li>

							{data?.kc?.map((QAttempt, key) => (
								<li
									key={key}
									style={{ cursor: "pointer" }}
									className={
										data?.kc[key]?.attempted == true
											? "bg-success text-light"
											: key == selectedQuestionIndex && "QAttemptAct"
									}
									onClick={() => moveToNumber(key)}
								>
									{key + 1}
								</li>
							))}
							<li
								className={`pointer ${selectedQuestionIndex === data?.kc?.length - 1 ||
									isLoading ||
									isExerciseSubmit
									? "DissableArrow"
									: ""
									}`}
								// onClick={() =>
								// 	handleNext(
								// 		true,
								// 		false,
								// 		data?.kc[selectedQuestionIndex]?.type,
								// 		data?.kc[selectedQuestionIndex]?.instructions,
								// 		data?.kc[selectedQuestionIndex]
								// 	)
								// }
								onClick={() => moveToNext(true, false)
								}
							>
								<span key={data?.kc[selectedQuestionIndex]?.id}>
									Next
									<i className="fa-regular fa-angle-right"></i>
								</span>
							</li>
						</ul>
					</div>
				</>
			)}

			{showData ? (
				<>
					<div className="lSidebarcontentwrap CourseCardWrapper fullHeight100">
						{data && data?.tab == "Series" && data?.kc?.length > 0 && !showMyJournal && (
							<div className="intructionsBox ScenecerelateddQuiz Analysistxt  seneriotext">
								<p className="d-inline">
									<span key={data?.tab}>
										<i className="fa-solid fa-quote-left"></i>
									</span>
									<em>
										<span
											className="m-0"
											dangerouslySetInnerHTML={{
												__html: data?.kc[selectedQuestionIndex]?.instructions
													? data?.kc[selectedQuestionIndex]?.instructions
													: "Please view the video or illustration on your left before attempting the questions below.",
											}}
										/>
									</em>
									<span key={data?.kc[selectedQuestionIndex]?.id}>
										<i className="fa-solid fa-quote-right"></i>
									</span>
								</p>

								{/* <p className="m-0">
						{instructions != null
							? instructions
							: "Please review the comic strip on your left before attempting the questions."}
					</p> */}
							</div>
						)}

						{showMyJournal && data?.tab == "Series" &&
							<MyJournal onBackClick={handleCloseMyJournal}
								textAreaContent={textAreaContent}
								setTextAreaContent={setTextAreaContent}
							/>
						}

						{showNuggetArray?.length === 1 && data?.tab == "Series" && !showMyJournal && (
							// Nugget Area for attempted
							<div className="NuggetPopup downgradepopup">
								<div className="NuggetPopupTitle flex modal-header">
									<div className="NuggetVickyImage">
										<h4 className="flexone">
											<img src={image.Powericon} alt="" className="mr-2" />
											{nuggetTitle}
										</h4>
									</div>
									<button className="btn btn-primary" data-dismiss="modal" onClick={() => handleCloseNugget()}>
										<i className="fa-regular fa-xmark m-0"></i>
									</button>
								</div>
								<div className="NuggetPopupDesc d-flex align-items-start justify-content-between pt-1">
									<div className="NugeetsSolution">
										{
											showNuggetArray?.map((option, index) => (
												<div className="otherOptionssol" key={index}>
													<p>
														<div className="nuggetsolitems">
															<h6>
																<strong>
																	{" "}
																	Option {`${option?.sequence}`}:
																</strong>{" "}
																<span
																	dangerouslySetInnerHTML={{
																		__html: option?.question,
																	}}
																/>{" "}
															</h6>
															<p
																dangerouslySetInnerHTML={{
																	__html: option?.help,
																}}
															/>{" "}
														</div>
														<Vicky
															text={
																"option " +
																option?.sequence +
																" ..." +
																option?.question +
																"  ..." +
																option?.help
															}
															id={option?.sequence}
															data={data}
														/>
													</p>
													<br />
												</div>
											))
										}
									</div>
								</div>
								<div className="NuggetPopupFooter d-flex">
									<div className="input-group full-Width-group basic_details_form">
										<div className="form-group BDsubmitbutton d-flex m-0 ">
											<button
												type="button"
												className="btn-blue btn-login d-block mb-5 ml-auto mr-2 cancelbutton"
												onClick={() => handleCloseNugget()}
											>
												<span
													key={
														data?.kc?.length > 0 &&
														data?.kc[selectedQuestionIndex].id
													}
												>
													<i className="fa-solid fa-xmark mr-2"></i>
												</span>
												Close
											</button>
										</div>
									</div>
								</div>
							</div>
						)}

						{isNuggetDisplay && data?.tab == "Series" && !showMyJournal && (
							// Nugget Area For not attempted
							<div className="NuggetPopup downgradepopup">
								<div className="NuggetPopupTitle flex modal-header">
									<div className="NuggetVickyImage">
										<h4 className="flexone">
											<img src={image.Powericon} alt="" className="mr-2" />
											{nuggetTitle}
										</h4>
									</div>
									<button className="btn btn-primary" data-dismiss="modal" onClick={() => handleCloseNugget()}>
										<i className="fa-regular fa-xmark m-0"></i>
									</button>
								</div>
								<div className="NuggetPopupDesc d-flex align-items-start justify-content-between pt-1">
									<div className="NugeetsSolution">
										{
											getSequnceSort(data?.kc[selectedQuestionIndex]?.options)?.map((option, index) => (
												option?.attempted && (
													<div className="otherOptionssol" key={index}>
														<p>
															<div className="nuggetsolitems">
																<h6>
																	<strong>
																		{" "}
																		Option {`${option?.sequence}`}:
																	</strong>{" "}
																	<span
																		dangerouslySetInnerHTML={{
																			__html: option?.question,
																		}}
																	/>{" "}
																</h6>
																<p
																	dangerouslySetInnerHTML={{
																		__html: option?.help,
																	}}
																/>{" "}
															</div>
															<Vicky
																text={
																	"option " +
																	option?.sequence +
																	" ..." +
																	option?.question +
																	"  ..." +
																	option?.help
																}
																id={option?.sequence}
																data={data}
															/>
														</p>
														<br />
													</div>
												)
											))
										}
										{/* <div className="nuggetsolitems">
										{hideQues && optionArr?.length > 0 ? (
											optionArr.map((option, optionIndex) => (
												<div key={optionIndex}>
													{" "}
													<h6>
														<strong> Option {option?.optionIndex + 1}:</strong>{" "}
														<span
															dangerouslySetInnerHTML={{
																__html: option?.questions,
															}}
														/>{" "}
													</h6>
													<p
														dangerouslySetInnerHTML={{
															__html: option?.help,
														}}
													/>
												</div>
											))
										) : (
											<>
												{hideQues && (
													<h6>
														<strong> Option {QueIndex}:</strong>{" "}
														<span
															dangerouslySetInnerHTML={{
																__html:
																	data?.kc[selectedQuestionIndex]?.options[
																		QueIndex - 1
																	]?.question,
															}}
														/>{" "}
													</h6>
												)}
												<p
													dangerouslySetInnerHTML={{
														__html: nugget,
													}}
												/>
											</>
										)}
									</div> */}
										{isRightOptionSelected && (
											getSequnceSort(data?.kc[selectedQuestionIndex]?.options)?.map(
												(option, optionIndex) =>
													!option?.isUserSelected && (
														<React.Fragment key={optionIndex}>
															{counter++ == 0 && (
																<React.Fragment>
																	<h5>
																		Nice work! But here are the other choices
																		you haven’t seen, to get some better
																		insight...
																	</h5>
																	<br />
																</React.Fragment>
															)}
															<div className="otherOptionssol">
																<p>
																	<div className="nuggetsolitems">
																		<h6>
																			<strong>
																				{" "}
																				Option {`${option?.sequence}`}:
																			</strong>{" "}
																			<span
																				dangerouslySetInnerHTML={{
																					__html: option?.question,
																				}}
																			/>{" "}
																		</h6>
																		<p
																			dangerouslySetInnerHTML={{
																				__html: option?.help,
																			}}
																		/>{" "}
																	</div>
																	<Vicky
																		text={
																			"option " +
																			option?.sequence +
																			" ..." +
																			option?.question +
																			"  ..." +
																			option?.help
																		}
																		id={option?.sequence}
																		data={data}
																	/>
																</p>
																<br />
															</div>
														</React.Fragment>
													)
											)
										)}
									</div>

									{/* {nugget && (
									<Vicky
										text={nugget}
										title={
											"option" +
											QueIndex +
											" ..." +
											data?.kc[selectedQuestionIndex]?.options[QueIndex - 1]
												?.question +
											"  ..."
										}
										id={QueIndex}
										data={data}
									/>
								)} */}
								</div>
								<div className="NuggetPopupFooter d-flex">
									<div className="input-group full-Width-group basic_details_form">
										<div className="form-group BDsubmitbutton d-flex m-0 ">
											<div className="flexone pl-2 haveAttempts">
												{/* {inval !== 2 && !isOptionTrue &&
												<p className="flexone">
													<img src={image.exclamationTrangle} className="mr-2" />
													You have {inval === 0 ? '2' : '1'} more attempt{inval === 0 ? 's' : ''} left.
												</p>
											} */}
												{
													!isRightOptionSelected && (
														<p className="flexone">
															<img src={image.exclamationTrangle} className="mr-2" />
															You have {3 - data?.kc[selectedQuestionIndex]?.invalidAttempt} more attempt{(3 - data?.kc[selectedQuestionIndex]?.invalidAttempt) > 1 ? 's' : ''} left.
														</p>
													)
												}
											</div>
											<button
												type="button"
												className="btn-blue btn-login d-block mb-5 ml-auto mr-2 cancelbutton"
												onClick={() => handleCloseNugget()}
											>
												<span
													key={
														data?.kc?.length > 0 &&
														data?.kc[selectedQuestionIndex].id
													}
												>
													<i className="fa-solid fa-xmark mr-2"></i>
												</span>
												Close
											</button>
										</div>
									</div>
								</div>
							</div>
						)}
						{data?.tab == "Characters" && !showMyJournal && (
							<>
								{/* <div className="LessionDtlOverview">
							<div className="d-flex align-items-start flex-wrap">
							<div
								ref={textContainerRef}
								className="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar"
							>
								<p
								dangerouslySetInnerHTML={{
									__html: data?.charDesc
									? formatTextWithSpans(data.charDesc)
									: "",
								}}
								/>
							</div>
							<Vicky text={data?.charDesc} data={data} />
							</div>
						</div> */}
								<div className="LessionDtlOverview">
									<div className="d-flex align-items-start flex-wrap">
										<div className="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar">
											<p
												dangerouslySetInnerHTML={{
													__html: data?.charDesc && data?.charDesc,
												}}
											/>
										</div>
										<Vicky text={data?.charDesc} data={data} />
									</div>
								</div>
								{/* <div className="input-group full-Width-group basic_details_form pt-0 continuebtn ">
								<button
									className="btn-blue btn-login d-block float-right w-auto"
									// onClick={() => data?.setSelectedTab("Series")}
									onClick={() => data.selectTabs("Series")}
								>
									<i className="fa-solid fa-paper-plane mr-2"></i>
									Go to Lessons
								</button>
							</div> */}
							</>
						)}
						{data?.tab == "References" && !showMyJournal && (
							<div className="LessionDtlOverview">
								<div className="d-flex align-items-start flex-wrap">
									<div
										className="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar"
										dangerouslySetInnerHTML={{ __html: data?.reading }}
									></div>
									<Vicky text={data?.reading} data={data} />
								</div>
							</div>
						)}
						{data?.tab == "empthyQuiz" && !showMyJournal && <Sidebar />}
						{data?.tab == "Series" && data?.kc?.length == 0 && !showMyJournal && (
							<>
								<div className="LessionDtlOverview" key={Math.random()}>
									<div className="d-flex align-items-start flex-wrap">
										<div className="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar">
											<p
												dangerouslySetInnerHTML={{
													__html: data?.application && data?.application,
												}}
											/>
										</div>
										<Vicky text={data?.application} data={data} />
									</div>
								</div>
							</>
						)}

						{/* Growth Survey 9 May 2024 */}
						{data?.tab == "growth" && !showMyJournal && (
							<GrowthSurveySidebar
								data={data}
							/>
						)}
						{
							!showMyJournal && data?.tab == "Series" && (
								<div className="ScenecerelateddQuiz p-0">
									{
										<div
											className="signupType rangetypeQuestion textAreatypeQuestion mb-2 p-3"
											key={data?.kc && data?.kc[selectedQuestionIndex]?.question}
										>
											{data?.kc?.length > 0 && data?.tab == "Series" && !showMyJournal && (
												<h4 className="mb-2 d-flex">
													<span>
														<img src={image.SceneQuestionicon} alt="" />
													</span>
													<div>
														<span
															dangerouslySetInnerHTML={{
																__html:
																	data?.kc && data?.kc[selectedQuestionIndex]?.question,
															}}
														/>
														{
															(data?.kc[selectedQuestionIndex]?.type == "RADIOQ" || data?.kc[selectedQuestionIndex]?.type == "CHECKBOXQ") && (
																<span className="pointer" onClick={() => data?.handleShowScoringPopUp(true, data?.kc[selectedQuestionIndex]?.type)}><img src={image.chat_icon} className="chat_icon" alt="" /></span>
															)
														}
													</div>
												</h4>
											)}
											{data &&
												data?.kc &&
												data?.kc[selectedQuestionIndex]?.type == "RADIOQ" &&
												data?.tab == "Series" && !showMyJournal &&
												getSequnceSort(data?.kc[selectedQuestionIndex]?.options)?.map(
													(option, optionIndex) => (
														<label
															className="Selcheckbox align-items-start d-block"
															key={optionIndex}
														>
															<span className="fw-bold">{option?.sequence}</span>
															{". "}
															{option?.question}
															{data &&
																data?.kc[selectedQuestionIndex]?.attempted &&
																data?.currentScene != data?.totalSence - 1 ? (
																<input
																	type="radio"
																	id={"option" + selectedQuestionIndex}
																	name={"option" + selectedQuestionIndex}
																	checked={
																		data?.kc[selectedQuestionIndex]?.attempted
																			? option?.isCorrect
																			: false
																	}
																	onClick={(event) => {
																		handleOption(
																			selectedQuestionIndex,
																			optionIndex,
																			option?.help,
																			option?.id,
																			event.target.checked
																		);
																	}}
																/>
															) : (
																// Ajmer
																<input
																	type="radio"
																	id={"option" + selectedQuestionIndex}
																	name={"option" + selectedQuestionIndex}
																	onClick={(event) => {
																		handleOption(
																			selectedQuestionIndex,
																			optionIndex,
																			option?.help,
																			option?.id,
																			event.target.checked
																		);
																	}}
																	checked={(data?.currentScene == data?.totalSence - 1 && option?.attempted) || option?.attempted}
																// defaultChecked={
																// 	data?.currentScene == data?.totalSence - 1 &&
																// 		option?.attempted
																// 		? true
																// 		: false
																// }
																/*
															disabled={
																isLoading || //added for school enhancement
																data?.isNextButtonDisable
																// || allSceneDataArray[data?.currentScene]?.showTooltip
															} on 18-11-2024
															*/
																/>
															)}
															{option.isUserSelected &&
																!option.isCorrect &&
																data?.currentScene !== data?.totalSence - 1 && (
																	<>
																		<ReactTooltip
																			id="#dobulecheckedmsgIcon"
																			className={"tooltip"}
																		>
																			Already Selected
																		</ReactTooltip>

																		<span
																			data-for="#dobulecheckedmsgIcon"
																			data-event-off=""
																			data-tip
																		>
																			<i class="fa-light fa-check-double ps-2 pt-1 dobulecheckedmsgIcon"></i>
																		</span>
																	</>
																)
															}
															<span className="checkmark"></span>
															{/* {allSceneDataArray[data?.currentScene]?.showTooltip && (
															<div className="aboutProgdesc">
																<div class="Courseporogresspopup">
																	<p className="m-0">{sceneTextMessage}</p>
																</div>
															</div>
														)} */}
														</label>
													)
												)
											}

											{data?.kc &&
												data?.tab == "Series" &&
												data?.kc[selectedQuestionIndex]?.type == "CHECKBOXQ" && !showMyJournal && (
													<>
														<div className="signupType checktypequestion m-0">
															{data &&
																data?.kc &&
																getSequnceSort(
																	data?.kc[selectedQuestionIndex]?.options
																)?.map((option, optionIndex) => (
																	<label className="Selcheckbox align-items-start" key={optionIndex}>
																		<span className="fw-bold">
																			{option?.sequence}
																		</span>
																		{". "}
																		{option?.question}
																		{data &&
																			data?.kc[selectedQuestionIndex]?.attempted &&
																			data?.currentScene != data?.totalSence - 1 ? (
																			<input
																				type="checkbox"
																				id={"option" + selectedQuestionIndex}
																				name={"option" + selectedQuestionIndex}
																				checked={
																					data?.kc[selectedQuestionIndex]?.attempted
																						? option?.isCorrect
																						: false
																				}
																				onClick={(event) =>
																					handleCheckBoxOption2(
																						selectedQuestionIndex,
																						optionIndex,
																						option?.help,
																						event.target.checked
																					)
																				}
																			/>
																		) : (
																			<input
																				type="checkbox"
																				id="Public"
																				name="Question"
																				value="PUBLIC"
																				onClick={(event) =>
																					handleCheckBoxOption2(
																						selectedQuestionIndex,
																						optionIndex,
																						option?.help,
																						event.target.checked
																					)
																				}
																				checked={(data?.currentScene == data?.totalSence - 1 && option?.attempted) || option?.attempted}
																			// defaultChecked={
																			// 	data?.currentScene == data?.totalSence - 1 &&
																			// 		option?.attempted
																			// 		? true
																			// 		: false
																			// }

																			// defaultChecked={
																			// 	option?.attempted
																			// }
																			// defaultChecked={isNextClick ? '' : option?.attempted}

																			// disabled={allSceneDataArray[data?.currentScene]
																			// 	?.showTooltip
																			// }
																			/>
																		)}
																		{/* start Added By Alfaiz on 23-04-2024  for add checked icon and tooptip */}
																		{option.isUserSelected &&
																			!option.isCorrect &&
																			data?.currentScene !== data?.totalSence - 1 && (
																				<>
																					<ReactTooltip
																						id="#dobulecheckedmsgIcon"
																						className={"tooltip"}
																					>
																						Already Selected
																					</ReactTooltip>

																					<span
																						data-for="#dobulecheckedmsgIcon"
																						data-event-off=""
																						data-tip
																					>
																						<i class="fa-light fa-check-double ps-2 pt-1 dobulecheckedmsgIcon"></i>
																					</span>
																				</>
																			)
																		}
																		<span className="checkmark"></span>
																		{/* {allSceneDataArray[data?.currentScene]
																		?.showTooltip && (
																			<div className="aboutProgdesc">
																				<div class="Courseporogresspopup">
																					<p className="m-0">{sceneTextMessage}</p>
																				</div>
																			</div>
																		)} */}
																	</label>
																))}
														</div>
													</>
												)}
											{/* {readOnly = { data?.kc[selectedQuestionIndex]?.attempted} */}
											{data?.kc &&
												data?.tab == "Series" &&
												data?.kc[selectedQuestionIndex]?.type == "COMMENTQ" && !showMyJournal && (
													<>
														<div className="textareabox">
															<textarea
																name="questionResponse"
																id={"questionResponse" + selectedQuestionIndex}
																className="w-100"
																defaultValue={
																	data?.kc[selectedQuestionIndex]?.options[0]?.answer
																}
																onChange={(e) =>
																	handleCommentChange(
																		e.target.value,
																		selectedQuestionIndex
																	)
																}
															/*
																disabled={
																	allSceneDataArray[data?.currentScene]?.showTooltip
																}
																placeholder={
																	allSceneDataArray[data?.currentScene]?.showTooltip
																		? sceneTextMessage
																		: ""
														} on 18-11-2024
														*/
															></textarea>
														</div>
													</>
												)}


											{data?.tab == "Series" && data?.kc?.length > 0 && !showMyJournal && false && (
												<>
													<div className="input-group full-Width-group basic_details_form pt-0 continuebtn">
														<div className="form-group BDsubmitbutton flex m-0">
															{/* Added by Alfaiz ansari 18-04-2024 for course guide and view report showing in dropdown*/}
															<div className="learner_pLan_dropdown ActivitycrdTitle">
																<strong
																	className="Activitymenubar ml-2 pointer "
																	aria-haspopup="true"
																	aria-expanded="false"
																	id="barlistdropdown"
																	data-toggle="dropdown"
																>
																	<i className="fa-solid fa-bars fa-lg"></i>
																</strong>
																<ul
																	className="dropdown-menu dropdown-menu-end lPDropdwnList CourseGuideDropdown"
																	aria-labelledby="barlistdropdown"
																>
																	{
																		loggedInUser?.role?.name === "TEACHER" && data?.currentScene !== data?.totalSence - 1 && (
																			<li className="pointer">
																				<p onClick={() =>
																					dispatch(
																						showTeacherReport({
																							show: true,
																							quesId:
																								data?.kc[selectedQuestionIndex],
																						})
																					)
																				}
																				>
																					<img src={image.viewReport1} alt="" />
																					View Report
																				</p>
																			</li>
																		)
																	}
																	<>
																		{(socialActivityData?.records[0]?.isEnrolled || earnPoint > 0) && (
																			<li className="pointer">
																				<p onClick={() => handleMyJournal()}>
																					<img src={image.myJournal} alt="" />
																					My Journal
																				</p>
																			</li>
																		)}
																		<li className="pointer">
																			<p onClick={() => viewCCG(true)}>
																				<img src={image.courseguide} alt="" />
																				Course Guide
																			</p>
																		</li>
																	</>
																	{/* {loggedInUser?.role?.name === "TEACHER" ? (
															<>
																{data.seriesScenesData?.length - 1 !==
																	data.currentScene && (
																		<li className="pointer">
																			<p
																				onClick={() =>
																					dispatch(
																						showTeacherReport({
																							show: true,
																							quesId:
																								data?.kc[selectedQuestionIndex]
																									?.id,
																						})
																					)
																				}
																			>
																				<img src={image.viewReport1} alt="" />
																				View Report
																			</p>
																		</li>
																	)}
																<li className="pointer">
																	<p onClick={() => viewCCG(true)}>
																		<img src={image.myJournal} alt="" />
																		My Journal
																	</p>
																</li>
																<li className="pointer">
																	<p onClick={() => viewCCG(true)}>
																		<img src={image.courseguide} alt="" />
																		Course Guide
																	</p>
																</li>
															</>
														) : (
															<>
																<li className="pointer">
																	<p onClick={() => viewCCG(true)}>
																		<img src={image.myJournal} alt="" />
																		My Journal
																	</p>
																</li>
																<li className="pointer">
																	<p onClick={() => viewCCG(true)}>
																		<img src={image.courseguide} alt="" />
																		Course Guide
																	</p>
																</li>
															</>
														)} */}
																</ul>
															</div>
															{/* End Added by Alfaiz ansari 18-04-2024 for  course guide and view report showing in dropdown*/}

															{showPleaseWait ? (
																<button
																	type="button"
																	className="btn-blue btn-login d-block mb-5 changepasswordbtn"
																	key={Math.random()}
																	disabled
																>
																	<span className="RounAnimation mr-1"></span>
																	Please Wait...
																</button>
															) : (
																<></>

																// (data?.kc[selectedQuestionIndex]?.attempted && data?.currentScene != data?.totalSence - 1) || loggedInUser?.role?.name === "PROVIDER" ? (
																// 	<button
																// 		type="submit"
																// 		className="btn-blue btn-login d-block mb-5 ml-auto"
																// 		onClick={() =>
																// 			moveToNext(
																// 				true, false
																// 			)
																// 		}
																// 	>

																// 		Next
																// 		<span key={Math.random()}><i className="fa-solid fa-arrow-right ml-2 m-0"></i>
																// 		</span>
																// 	</button>
																// ) : (
																// 	<button
																// 		type="submit"
																// 		className="btn-blue btn-login d-block mb-5 ml-auto"
																// 		disabled={isSubmitDisabled}
																// 		onClick={
																// 			() =>
																// 				handleNext2(
																// 					true,
																// 					// true,
																// 					!data?.kc[selectedQuestionIndex]?.attempted ? true : false,
																// 					data?.kc[selectedQuestionIndex]?.type,
																// 					data?.kc[selectedQuestionIndex]?.instructions,
																// 					data?.kc[selectedQuestionIndex]
																// 				)
																// 		}
																// 	>
																// 		<span key={Math.random()}>
																// 			<i className="fa-solid fa-paper-plane mr-2 m-0"></i>
																// 		</span>
																// 		Submit
																// 	</button>
																// )
																// <button
																// 	disabled={
																// 		(false && data?.isKcRequiredDisabled &&
																// 			data?.kc?.length - 1 ===
																// 			selectedQuestionIndex &&
																// 			isResult) ||
																// 			isLoading ||
																// 			isExerciseSubmit
																// 			? true
																// 			: ""
																// 	}
																// 	type="submit"
																// 	className="btn-blue btn-login d-block mb-5 ml-auto"
																// 	onClick={() => 
																// 		handleNext(
																// 			true,
																// 			// true,
																// 			!data?.kc[selectedQuestionIndex]?.attempted ? true : false,
																// 			data?.kc[selectedQuestionIndex]?.type,
																// 			data?.kc[selectedQuestionIndex]?.instructions,
																// 			data?.kc[selectedQuestionIndex]
																// 		)
																// 	}
																// >
																// 	{data?.kc[selectedQuestionIndex]?.attempted ? "Next" : "Submit"}{" "}
																// 	<React.Fragment>
																// 		{!data?.kc[selectedQuestionIndex]?.attempted ? <i className="fa-solid fa-paper-plane ml-2 m-0"></i> : <i className="fa-solid fa-arrow-right ml-2 m-0"></i>}
																// 	</React.Fragment>

																// </button>
															)}
														</div>
													</div>
												</>
											)}
											{console.log('data?.kc[selectedQuestionIndex].attempted', data?.kc[selectedQuestionIndex])}
											{data &&
												data?.tab == "Series" && data?.kc?.length === 0 && !showMyJournal && false && (
													<>
														<div className="input-group full-Width-group basic_details_form pt-0 continuebtn">
															<div className="form-group BDsubmitbutton flex m-0">
																{/* Added by Alfaiz ansari 18-04-2024 for course guide and view report showing in dropdown*/}
																<div className="learner_pLan_dropdown ActivitycrdTitle">
																	<strong
																		className="Activitymenubar ml-2 pointer "
																		aria-haspopup="true"
																		aria-expanded="false"
																		id="barlistdropdown"
																		data-toggle="dropdown"
																	>
																		<i className="fa-solid fa-bars fa-lg"></i>
																	</strong>
																	<ul
																		className="dropdown-menu dropdown-menu-end lPDropdwnList CourseGuideDropdown"
																		aria-labelledby="barlistdropdown"
																	>
																		{/* {
																		loggedInUser?.role?.name === "TEACHER" && (
																			<li className="pointer">
																				<p onClick={() =>
																					dispatch(
																						showTeacherReport({
																							show: true,
																							quesId:
																								data?.kc[selectedQuestionIndex]
																									?.id,
																						})
																					)
																				}
																				>
																					<img src={image.viewReport1} alt="" />
																					View Report
																				</p>
																			</li>
																		)
																	} */}
																		<>
																			{(socialActivityData?.records[0]?.isEnrolled || earnPoint > 0) && (
																				<li className="pointer">
																					<p onClick={() => handleMyJournal()}>
																						<img src={image.myJournal} alt="" />
																						My Journal
																					</p>
																				</li>
																			)}
																			<li className="pointer">
																				<p onClick={() => viewCCG(true)}>
																					<img src={image.courseguide} alt="" />
																					Course Guide
																				</p>
																			</li>
																		</>
																		{/* {loggedInUser?.role?.name === "TEACHER" &&
																data?.kc[selectedQuestionIndex]?.id ? (
																<>
																	<li className="pointer">
																		<p
																			onClick={() =>
																				dispatch(
																					showTeacherReport({
																						show: true,
																						quesId:
																							data?.kc[selectedQuestionIndex]
																								?.id,
																					})
																				)
																			}
																		>
																			<img src={image.viewReport1} alt="" />
																			View Report
																		</p>
																	</li>
																	<li className="pointer">
																		<p onClick={() => viewCCG(true)}>
																			<img src={image.myJournal} alt="" />
																			My Journal
																		</p>
																	</li>
																	<li className="pointer">
																		<p onClick={() => viewCCG(true)}>
																			<img src={image.courseguide} alt="" />
																			Course Guide
																		</p>
																	</li>
																</>
															) : (
																<>
																	<li className="pointer">
																		<p onClick={() => viewCCG(true)}>
																			<img src={image.myJournal} alt="" />
																			My Journal
																		</p>
																	</li>
																	<li className="pointer">
																		<p onClick={() => viewCCG(true)}>
																			<img src={image.courseguide} alt="" />
																			Course Guide
																		</p>
																	</li>
																</>
															)} */}
																	</ul>
																</div>
																{/* End Added by Alfaiz ansari 18-04-2024 for  course guide and view report showing in dropdown*/}
																{/* <button
																disabled={data?.isNextButtonDisable ? true : ""}
																type="submit"
																className="btn-blue btn-login d-block mb-5 ml-auto"
																onClick={() => {
																	data?.handleSeriesSceneStep(true);
																}}
															>
																Next{" "}
																<span key={data?.tab}>
																	<i className="fa-solid fa-arrow-right ml-2 m-0"></i>
																</span>
															</button> */}
															</div>
														</div>
													</>
												)}
										</div>
										//	))
									}
								</div>
							)
						}
					</div>
					<div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
						<div class="form-group  d-flex justify-content-end align-items-center m-0 ">
							{data?.tab == "growth" && (
								<div>
									{loggedInUser?.role?.name !== "PROVIDER" && surveyAssOpen ? (
										<div className="input-group full-Width-group basic_details_form pt-0 p-0">
											<div className="form-group flex justify-content-end m-0">

												<button
													className="btn-blue btn-login float-right"
													onClick={() => handleSubmit()}
													disabled={
														Array.isArray(quizObj) && !quizObj.every((question) =>
															question?.options?.some((option) => option?.isUserSelected)
														)
													}
												>
													<i className="fa-solid fa-paper-plane mr-2"></i>Submit
												</button>
											</div>

										</div>
									) : (
										<div>
											{(growthSurveyData?.records[0]?.preProgress === 0 &&
												growthSurveyData?.records[0]?.postProgress ===
												0 &&
												(socialActivityData?.records[0]?.courseComp <
													28 ||
													socialActivityData?.records[0]?.courseComp ===
													100)) ||
												(socialActivityData?.records[0].courseIsCompleted &&
													growthSurveyData?.records[0]?.postProgress ===
													0) ? (
												<button
													className="btn-blue btn-login d-block float-right w-auto"
													onClick={() => handleQuiz()}
												>
													<i className="fa-solid fa-paper-plane mr-2"></i>
													Begin Survey
												</button>
											) : (
												growthSurveyData?.records[0]?.postProgress !==
												100 && (
													<button
														className="btn-blue btn-login d-block float-right w-auto"
														onClick={() => data?.selectTabs("Series")}
													>
														<i className="fa-solid fa-paper-plane mr-2"></i>
														Go to Lessons
													</button>
												)
											)}
										</div>
									)}
								</div>
							)}
							{data?.tab == "Series" && (
								<div className="form-group BDsubmitbutton flex m-0">
									<div className="learner_pLan_dropdown ActivitycrdTitle w-auto mr-3">
										<strong
											className="Activitymenubar ml-2 pointer "
											aria-haspopup="true"
											aria-expanded="false"
											id="barlistdropdownfooter"
											data-toggle="dropdown"
										>
											<i className="fa-solid fa-bars fa-lg"></i>
										</strong>
										<ul
											className="dropdown-menu dropdown-menu-end lPDropdwnList CourseGuideDropdown"
											aria-labelledby="barlistdropdownfooter"
										>
											<>
												{
													(loggedInUser?.role?.name === "TEACHER" && data?.currentScene !== data?.totalSence - 1 && data?.kc?.length > 0) && (
														<li className="pointer">
															<p onClick={() =>
																dispatch(
																	showTeacherReport({
																		show: true,
																		quesId:
																			data?.kc[selectedQuestionIndex],
																	})
																)
															}
															>
																<img src={image.viewReport1} alt="" />
																View Report
															</p>
														</li>
													)
												}
												{(socialActivityData?.records[0]?.isEnrolled || earnPoint > 0) && (
													<li className="pointer">
														<p onClick={() => handleMyJournal()}>
															<img src={image.myJournal} alt="" />
															My Journal
														</p>
													</li>
												)}
												<li className="pointer">
													<p onClick={() => viewCCG(true)}>
														<img src={image.courseguide} alt="" />
														Course Guide
													</p>
												</li>
											</>

										</ul>

									</div>
									{showMyJournal ? (

										<button
											type="submit"
											className="btn-blue btn-login d-block mb-5 ml-auto back_button"
											onClick={handleBackClick}
										>
											<i className="fa-solid fa-arrow-left mr-2 m-0"></i>
											Back
										</button>

									) : data &&
										data?.tab == "Series" && data?.kc?.length === 0 && !showMyJournal ? (
										<button
											disabled={data?.isNextButtonDisable ? true : ""}
											type="submit"
											className="btn-blue btn-login d-block mb-5 ml-auto"
											onClick={() => {
												data?.handleSeriesSceneStep(true);
											}}
										>
											Next{" "}
											<span key={data?.tab}>
												<i className="fa-solid fa-arrow-right ml-2 m-0"></i>
											</span>
										</button>
									)
										: (data?.kc[selectedQuestionIndex]?.attempted && data?.currentScene != data?.totalSence - 1) || loggedInUser?.role?.name === "PROVIDER" ? (
											<button
												type="submit"
												className="btn-blue btn-login d-block mb-5 ml-auto"
												onClick={() =>
													moveToNext(
														true, false
													)
												}
											>

												Next
												<span key={Math.random()}><i className="fa-solid fa-arrow-right ml-2 m-0"></i>
												</span>
											</button>
										) : (
											<button
												type="submit"
												className="btn-blue btn-login d-block mb-5 ml-auto"
												disabled={isSubmitDisabled}
												onClick={
													() =>
														handleNext2(
															true,
															// true,
															!data?.kc[selectedQuestionIndex]?.attempted ? true : false,
															data?.kc[selectedQuestionIndex]?.type,
															data?.kc[selectedQuestionIndex]?.instructions,
															data?.kc[selectedQuestionIndex]
														)
												}
											>
												<span key={Math.random()}>
													<i className="fa-solid fa-paper-plane mr-2 m-0"></i>
												</span>
												Submit
											</button>
										)}

								</div>
							)}
							{data?.tab == "Characters" && (
								<button
									className="btn-blue btn-login d-block float-right w-auto"
									// onClick={() => data?.setSelectedTab("Series")}
									onClick={() => data.selectTabs("Series")}
								>
									<i className="fa-solid fa-paper-plane mr-2"></i>
									Go to Lessons
								</button>
							)}

						</div>
					</div>
				</>
			) : (
				<ShimmerCategoryList items={3} categoryStyle="STYLE_SIX" />
			)}

			{
				startPopup && (
					<ConfirmationPopup
						setStartPopup={setStartPopup}
						courseId={param?.courseId}
						assSurveyId={
							socialActivityData?.records[0]?.courseIsCompleted
								? growthSurveyData?.records[0].postAssementSurveyId
								: growthSurveyData?.records[0].preAssementSurveyId
						}
						finalArray={selectOptData?.finalArraySubmit}
					/>
				)
			}
		</React.Fragment >
	);
};

export default LessonSidebar;