import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ReactTooltip from "react-tooltip";
import { breadcrumb, resetResponse, setCourseModal, showModal, showModalObj } from "../../redux/actions";
import { getAllUsersDetail, getDimSkillCourses, getDimensionPlanData, getEditDimSkillCourses, getJourny, getJournyCourses, getLearnerPlanTags, getUpdateLearnerPlan } from "../../redux/actions/APIs";
import { getCourseDetails, getDashboard, showGrowthModal, showSkillModal } from "../../redux/actions/Home";
import * as image from "../../resources/images";
import { PATHS } from "../../utils";
import { convertedNumber, getAllCourses, getCapitalized, getSequnceSort, getUsStatnderdTime, getdateWithCurretnTime, isDimension, redirectLeftOfScreen, textTrim } from "../../utils/helper";
import { useForm } from "../../utils/Packages";
import Home from "../Home";
import DeletePlanActivities from "./DeletePlanActivities";
import LearnerPlanRightPanel from "./LearnerPlanRightPanel";
import PathwayHalfScreen from "./PathwayHalfScreen";

const LearnerPlan = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams();
	const location = useLocation();

	const { defaultChildData,
		learnerPlanResponse,
		response,
		getDimPlanData,
		signupresponse,
		getDimJournyResponse,
		learnerTagsResponse,
		dimSkillCourseResp,
		modalData,
		getDimJournyCoursesResponse,
		addLearner,
		alluserdetails,
		loggedInUser,
		getMyPlanData,
		getNewPlanResponse
	} = useSelector((state) => state.collections);

	const {
		register,
		control,
		handleSubmit,
		watch,
		getValues,
		setValue,
		reset,
		setError,
		clearErrors,
		unregister,
		formState: { errors },
	} = useForm({ mode: "onTouched" });

	const {
		dashboardData, showoverModal, courseDetails
	} = useSelector((state) => state.home);

	const getLearnerName = () => {
		return getCapitalized(defaultChildData?.firstName);
	}

	const [selectedSitting, setSelectedSitting] = useState(2);
	const [selectedMinutes, setSelectedMinutes] = useState(30);
	const [userDate, setUserDate] = useState(new Date());
	const [screen, setScreen] = useState(1);
	const [selectedOption, setSelectedOption] = useState(location?.state?.show ? location?.state?.show : "recommended");
	const [tagData, setTagData] = useState();
	const [tagSelectedCourse, setTagSelectedCourse] = useState([]);
	const [loader, setLoader] = useState(false);
	const [planStartPopUp, setPlanStartPopUp] = useState(false);
	const [showRedirectPopUp, setShowRediretPopUp] = useState(false);
	const [getJourneyId, setJourneyId] = useState();
	const [coursesArr, setCoursesArr] = useState([]);
	const [pathwayTree, setPathwayTree] = useState([]);
	const [deletePlan, setDeletePlan] = useState()
	const [getCourseId, setCourseId] = useState([]);
	const [getSkillId, setSkillId] = useState([]);
	const [dimSkillCoursePlanTree, setDimSkillCoursePlanTree] = useState([]);
	const [collapseDim, setcollaspeDim] = useState(true)
	const [getDImSkillData, setDimSkillData] = useState([]);
	const [getDimName, setDimName] = useState([]);
	const [showPathwayShimmar, setShowPathwayShimmar] = useState(false);
	const [coursePlanTree, setCoursePlanTree] = useState([]);
	const [editPlan, setEditPlan] = useState(false);
	const [getMyEditPlan, setMyEditPlan] = useState("New Plan");
	const [getMyPlanCourses, setMyPlanCourses] = useState([]);
	const [dimensionTree, setDimensionTree] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [selectedTab, setSelectedTab] = useState("");

	let sittings = ["1", "2", "3", "4", "5", "6", "7"];
	let minutes = ["15", "30", "45", "60"];

	useEffect(() => {
		console.log('getUrlSegment : ', window?.location?.pathname?.split('/'), alluserdetails);
		if (alluserdetails) {
			if (window?.location?.pathname?.split('/')[1] === "learnerplan" && !alluserdetails?.records[0]?.isActive) {
				history.push(PATHS.EMAIL_VARIFICATION)
			}
		}
	}, [alluserdetails])

	useEffect(() => {
		if (addLearner?.success) {
			handleMoreOptions("createNewPlan");
		}
	}, [addLearner])

	/*
	useEffect(() => {
		if (signupresponse) {            
			if (signupresponse?.success) {
				dispatch(showModal({ type: "LearnerPlanRedirectionPopUp" }));
			}
		}
	}, [signupresponse])
	*/

	useEffect(() => {
		console.log('getMyPlanData ===', getMyPlanData)
		if (location?.showPopup === "welcomepopup" && getMyPlanData) {
			if (getMyPlanData?.records?.length === 0) {
				dispatch(showModalObj({ isWelcomepopup: true }));
				dispatch(showModal({ type: "LearnerPlanRedirectionPopUp" }));
			}
			else if (getMyPlanData?.records?.length === 1) {
				dispatch(showModalObj({ isWelcomepopup: true }));
				dispatch(showModal({ type: "LearnerPlanRedirectionPopUp" }));
			} else {
				dispatch(showModal({ type: "DimensionHintPopup" }));
				dispatch(resetResponse());
				history.push({
					pathname: PATHS.HOME,
				});
			}
		}
	}, [location, getMyPlanData])

	const handleRedirectPopUp = (result) => {
		setShowRediretPopUp(result);
		//  dispatch(showModal({ type: "DimensionHintPopup" }));
	}

	useEffect(() => {
		if (params) {
			dispatch(getAllUsersDetail());
			dispatch(getDashboard(params?.id));
			redirectLeftOfScreen(params?.id);
			//  dispatch(showModal({ type: "DimensionHintPopup" }));
		}
	}, [params?.id])



	useEffect(() => {
		dispatch(breadcrumb({ title: "Home", subTitle: "Plan" }));
		if (selectedOption !== "changePace") {
			setSelectedSitting(2);
			setSelectedMinutes(30);
		}
	}, [params?.id])

	/*
	useEffect(() => {
		if (dashboardData && dashboardData?.userPlans) {
			if (!dashboardData?.userPlans?.isrecommendedplan) {
				setSelectedSitting(dashboardData?.userPlans?.pace?.sittings);
				setSelectedMinutes(dashboardData?.userPlans?.pace?.duration);
			}
		}
	}, [dashboardData])
	*/

	const closePlan = () => {
		setDeletePlan();
		dispatch(showModal())
	}

	const [totalLevel, setTotalLevel] = useState(5);
	const courseDetailPage = (obj) => {
		setTotalLevel(obj?.totalLevel);
		dispatch(getCourseDetails(obj?.id, defaultChildData.id));
	};

	useEffect(() => {
		if (courseDetails) {
			courseDetails.isLearnerRightPanel = true;
			courseDetails.totalLevel = totalLevel;
			dispatch(setCourseModal(courseDetails));
		}
	}, [courseDetails]);

	const handleSitting = (value) => {
		setSelectedSitting(value)
		setSeleccted([]);
		setIsFlexible();
		setFlexibLeDays([]);
	}
	const handleMinutes = (value) => {
		setSelectedMinutes(value)
	}
	const handlePopUpOpen = (type) => {
		console.log("handlePopUpOpen :");
		if (type === "startdate") {
			dispatch(showModal({ type: "learnerPlanInfo", title: "Start Date", icon: image.Calendericon, message: "This start date is for the start of the Plan. Start dates for individual courses can be set in the 'Show Details' button next to the course name in the right panel at any time." }));
		} else if (type === "recommended") {
			dispatch(showModal({ type: "learnerPlanInfo", title: "Recommended Plan", icon: image.Calendericon, message: "More info on each course is available by clicking the arrow next to the course name." }));
		} else if (type === "filter") {

			dispatch(showModal({
				type: "learnerPlanInfo", title: "Life Events", icon: image.Calendericon, message: "Find courses to help your child navigate challenging life experiences."
			}));
		} else if (type === "pathway") {
			dispatch(showModal({
				type: "learnerPlanInfo", title: "Pathways", icon: image.Calendericon, message: "Find a Pathway to help your child build skills in complex categories."
			}));
		} else if (type === "dimension") {
			dispatch(showModal({ type: "learnerPlanInfo", title: "Custom Plan", icon: image.Calendericon, message: "We will walk you through each step to choose what dimensions you want your child to focus on, then choose the skills within those dimensions, and finally the courses that match those skills." }));
		} else if (type === "whyplan") {
			dispatch(showModal({
				type: "learnerPlanInfo", title: "Why A Plan?", icon: image.Calendericon, message: `
Creating a Plan helps parents and students work Bloomster into a regular habit and maintain consistency for maximum effectiveness.
                    We've organized essential skills into five key dimensions of growth, offering courses designed to empower your children with soft skills relevant to their development.
At Bloomster, we understand the unique challenges of parenting in the digital age.Our mission is to support you in nurturing confident, well- rounded individuals who can thrive in a world of constant change and unpredictability.
Thank you for taking the time to build a Plan to set your child up for success.` }));
		}
	}
	const handleMoreOptions = (type, result) => {
		if (type === "changePace") {
			setScreen(1);
			setSelectedOption(type);
		} else if (type === "createNewPlan") {
			if ((getMyEditPlan === "Edit Plan" || screen > 1) && result) {
				setDeletePlan({ isCreatePlan: true, message: 'Your changes will be lost.' });
				return
			}
			setScreen(1);
			clearErrors("planName");
			setSelectedOption("recommended");
			setSelectedSitting(2);
			setSelectedMinutes(30);
			setEditPlan(false);
			setCoursePlanTree([]);
			setEditPlan(false);
			setMyEditPlan("New Plan");
			setShowEnrollPlan(false);
			setValue("planName", "");
			setMinDate();
			dispatch(getUpdateLearnerPlan(defaultChildData?.id))
			dispatch(getLearnerPlanTags());
			dispatch(getJourny());
			dispatch(getDimensionPlanData())
			dispatch(getJournyCourses());
		}
		else if (type === "courseCatlog") {
			if ((getMyEditPlan === "Edit Plan" || screen > 1) && result) {
				setDeletePlan({ isCourseCatlog: true, message: 'Your changes will be lost.' });
				return
			}
			history.push(PATHS.COURSEPAGE_STR + defaultChildData?.id);
		}

		if (["createNewPlan", "changePace", "resetLearnerPlan"]?.includes(type)) {
			// this is for selected week days
			setSeleccted([]);
			setIsFlexible();
			setFlexibLeDays([]);
			// this is tags filter 
			setTagData([]);
			setTagSelectedCourse([]);
			setDimensionTree([]);

			// this is pathway
			setCoursesArr([]);
			setPathwayTree([]);

			// this is dimension            
			setCourseId([]);
			setSkillId([]);
			setDimName([]);
			setDimSkillData([]);
			setDimSkillCoursePlanTree([]);
		}
	}
	const handleBack = (result) => {
		if ((dimensionTree?.length > 0 || coursesArr?.length > 0 || getSkillId?.length > 0) && getMyEditPlan === "New Plan") {
			if ([3, 4, 5, 6]?.includes(screen) && !result) {
				setDeletePlan({ message: 'Your changes will be lost.' });
				return;
			}
		}
		if ([3, 4, 5]?.includes(screen) && getMyEditPlan === "Edit Plan") {
			if ([3, 4]?.includes(screen)) {
				setEditPlan(true);
			}
			if (screen === 3) {
				setCoursePlanTree([...dimensionTree]);
			} else if (screen === 4) {
				setCoursePlanTree(getRemainingCourses(getDImSkillData));
			} else if (screen === 5) {
				setCoursePlanTree(getRemainingCourses(dimSkillCoursePlanTree));
			}
		}
		if (screen === 2) {
			setScreen(1);
			if (getMyEditPlan !== "Edit Plan") {
				setSelectedOption("recommended");
				handleMoreOptions("createNewPlan");
			}
		} else if (screen === 3) {
			setScreen(2);
			dispatch(getLearnerPlanTags());
			setDimensionTree([]);
			setTagSelectedCourse([]);
		} else if (screen === 4) {
			setScreen(2);
			setSkillId([]);
			dispatch(getDimensionPlanData());
			setDimSkillData([]);
			setDimName([]);
		} else if (screen === 5) {
			setScreen(4);
			dispatch(getDimSkillCourses());
			setDimSkillCoursePlanTree([]);
			handleDimSkillBack();
			setDimName([]);
		} else if (screen === 6) {
			setScreen(2);
			setPathwayTree([]);
			dispatch(getJourny());
			dispatch(getJournyCourses());
			setCoursesArr([]);
		}
	}

	const handleNext = () => {
		if (getValues("planName") === "") {
			setError("planName", { type: "required", message: "Plan name is required" });
			return;
		}
		if (screen === 1) {
			if (getMyEditPlan === "Edit Plan") {
				if (selctedDays?.length === 0 && getFlexibLeDays?.length === 0) {
					setDeletePlan({ title: "Select Days", message: `Please select one of the options for the days your child will do coursework. You can select ‘I’m Flexible’ if you are not sure.` })
					return;
				} else if (selctedDays?.length != selectedSitting && getFlexibLeDays?.length != selectedSitting) {
					setDeletePlan({ title: "Select Days", message: `Please select ${selectedSitting - selctedDays?.length} more day${(selectedSitting - selctedDays?.length) > 1 ? 's' : ''}.` })
					return;
				}
				setSelectedOption("recommended");
			}
			setScreen(2);
			if (getMyEditPlan === "New Plan") {
				if (selectedOption === "recommended") {
					setValue("planName", getNewPlanResponse?.records[0]?.uiLabel)
				} else {
					setValue("planName", "");
				}
			}
			// 
			return;
			// if (selectedOption === "filter") {
			//     dispatch(getLearnerPlanTags(params?.id));
			//     setLoader(true);
			// } else if (selectedOption === "pathway") {
			//     dispatch(getJourny(true, params?.id));
			//     setLoader(true);
			// } else if (selectedOption === "dimension") {
			//     dispatch(getDimensionPlanData(params?.id))
			//     setLoader(true);
			// }
		} else if (screen === 2) {
			if (getMyEditPlan === "New Plan") {
				if (selctedDays?.length === 0 && getFlexibLeDays?.length === 0) {
					setDeletePlan({ title: "Select Days", message: `Please select one of the options for the days your child will do coursework. You can select ‘I’m Flexible’ if you are not sure.` })
					return;
				} else if (selctedDays?.length != selectedSitting && getFlexibLeDays?.length != selectedSitting) {
					setDeletePlan({ title: "Select Days", message: `Please select ${selectedSitting - selctedDays?.length} more day${(selectedSitting - selctedDays?.length) > 1 ? 's' : ''}.` })
					return;
				}
			}
			if (selectedOption === "filter") {
				dispatch(getLearnerPlanTags(params?.id));
				setLoader(true);
			} else if (selectedOption === "pathway") {
				dispatch(getJourny(true, params?.id));
				setLoader(true);
			} else if (selectedOption === "dimension") {
				dispatch(getDimensionPlanData(params?.id))
				setLoader(true);
			}
			// setScreen(2);
			// setSelectedOption("recommended");
		} else if (screen === 4 && getSkillId?.length > 0) {
			setDimName([]);
			setLoader(true);
			if (getMyEditPlan === "Edit Plan") {
				let courseArr = getAllCourses(getDImSkillData);

				let courseIds = [];
				courseArr?.map((value) => {
					if (value?.isSelected) courseIds?.push(value?.id);
				});
				let courseObj = { skillIds: getSkillId, courseIds: courseIds }
				dispatch(getEditDimSkillCourses(params?.id, courseObj));
			} else {
				dispatch(getDimSkillCourses(params?.id, getSkillId));
			}
		}
	}

	useEffect(() => {
		if (screen === 2 && loader) {
			if (learnerTagsResponse?.success && selectedOption === "filter") {  // filter
				setLoader(false);
				setShowLoader(false);
				setScreen(3);
				setEditPlan(false);
				if (getMyEditPlan === "Edit Plan") {
					setDimensionTree(handlEditeCoursePLan("filter"))
					editFilterCourses(coursePlanTree);
				}
			} else if (getDimJournyResponse?.success && selectedOption === "pathway") {  //  pathway                
				setLoader(false);
				setShowLoader(false);
				setScreen(6);
			}
			else if (getDimPlanData?.success && selectedOption === "dimension") {   // dimension
				setLoader(false);
				setShowLoader(false);
				setScreen(4);
				setEditPlan(false);
				if (getMyEditPlan === "Edit Plan") {
					handlEditeCoursePLan("dimension");
				}
			}
		}
	}, [getDimJournyResponse, learnerTagsResponse, getDimPlanData])

	const handleOptionClick = (value) => {
		setSelectedOption(value);
	}
	const showDimModal = (data, type) => {
		if (isDimension(data?.name) && type === "dim") {
			dispatch(showGrowthModal(data));
		} else {
			getDimPlanData?.records?.map((dim) => {
				dim?.skills?.map((skill) => {
					if (skill?.id === data?.id) {
						data.dimName = dim?.name;
					}
				})
			})
			dispatch(showSkillModal(data));
		}
	};

	useEffect(() => {
		if (learnerTagsResponse) {
			setTagData(learnerTagsResponse?.records);
		}
	}, [learnerTagsResponse])

	const editFilterCourses = (dimTreeArrayCopy) => {
		console.log("editFilterCourses : ", dimTreeArrayCopy);
		let copyArray = [];
		dimTreeArrayCopy?.map((dim, index) => {
			dim?.skills?.map((skill, key) => {
				skill?.courses?.map((course, i) => {
					if (course?.isSelected) {
						copyArray?.push(course);
					}

					/*
					const key = getMyPlanCourses?.findIndex(item => item?.id === course?.id);
					if (key !== -1) {
						copyArray?.push(course);
					}
					*/
				})
			})
		})
		setMyPlanCourses(copyArray)
	}

	const uilableCourses = (myCourse) => {
		console.log("uilableCourses : ", tagData);
		let isExistingCourse = true;
		tagData?.map((tags, index) => {
			if (tags?.isSelectedTag) {
				tags?.dimensions?.map((dim, dimKey) => {
					dim?.skills?.map((skill, sKey) => {
						skill?.courses?.map((course, cKey) => {
							if (course?.name === myCourse?.name) {
								myCourse.uiLabel = tags?.uiLabel;
								isExistingCourse = false;
							}
						})
					})
				})
			}
		})

		return isExistingCourse;
	}

	const handleTagSelectedCourse = (selectedDim, index) => {
		let copyArray = [...tagSelectedCourse];
		tagData[index].isSelectedTag = !tagData[index].isSelectedTag;
		const existsCourse = copyArray?.filter(item => item.uiLabel === selectedDim?.uiLabel);
		if (existsCourse?.length > 0) {
			existsCourse?.map((value, index) => {
				let isExistingCourse = uilableCourses(value);
				const key = copyArray?.findIndex(item => item?.name === value?.name && isExistingCourse);
				if (key !== -1) {
					copyArray.splice(key, 1);
				}
			})
		} else {
			selectedDim?.dimensions?.map((dim, index) => {
				dim?.skills?.map((skill, key) => {
					skill?.courses?.map((course, i) => {
						const key = copyArray?.findIndex(item => item?.name === course?.name);
						if (key === -1) {
							course.uiLabel = selectedDim?.uiLabel;
							course.dimId = dim?.id;
							course.dimName = dim?.name;
							course.skillId = skill?.id;
							course.skillName = skill?.name;
							course.isSelected = course?.rank ? true : false;
							if (getMyPlanCourses?.find((item) => item?.id === course?.id)) {
								course.isSelected = true;
							}
							copyArray.push(course);
						}
					})
				})
			})
		}
		setTagSelectedCourse(copyArray);

		let dimTreeArrayCopy = [];
		if (getMyEditPlan === "Edit Plan") {
			dimTreeArrayCopy = handlEditeCoursePLan("filter");
		}
		//  let selectedCourseArr = [...getMyPlanCourses, ...copyArray]        
		copyArray?.map((value, index) => {
			if (value?.isSelected) {
				const key = dimTreeArrayCopy?.findIndex(item => item?.name === value?.dimName);
				if (key === -1) {
					let obj = { id: value?.dimId, name: value?.dimName, skills: [{ id: value?.skillId, name: value?.skillName, isCompleted: true, courses: [{ id: value?.id, name: value?.name, isSelected: true, activities: value?.activities }] }] }
					dimTreeArrayCopy.push(obj);
				} else {
					const skillIndex = dimTreeArrayCopy[key]?.skills?.findIndex(item => item?.name === value?.skillName);
					if (skillIndex === -1) {
						dimTreeArrayCopy[key]?.skills?.push({ id: value?.skillId, name: value?.skillName, isCompleted: true, courses: [{ id: value?.id, name: value?.name, isSelected: true, activities: value?.activities }] });
					} else {
						let courseKey = dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.findIndex(item => item?.name === value?.name);;
						if (courseKey === -1) {
							dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.push({ id: value?.id, name: value?.name, isSelected: true, activities: value?.activities });

						}
					}
				}
			}
		})
		// let dimTreeArr = [...dimensionTree, ...dimTreeArrayCopy]        
		setDimensionTree(dimTreeArrayCopy);
		if (getMyEditPlan === "Edit Plan") {
			editFilterCourses(dimTreeArrayCopy);
		}
	}

	const handleTagCourseClick = (value, index) => {
		let filterCourses = getCourseLength();
		console.log("filterCourses : ", filterCourses?.length, filterCourses,);
		if (filterCourses?.length >= 7 && !filterCourses?.includes(value?.id)) {
			handleNumberOfCourses();
			return false;
		}
		tagSelectedCourse[index].isSelected = !tagSelectedCourse[index]?.isSelected;
		let dimTreeArrayCopy = [...dimensionTree];
		const courseIndex = tagSelectedCourse?.findIndex(item => item?.name === value?.name && !item?.isSelected);
		//  when add course
		if (courseIndex === -1) {
			const key = dimTreeArrayCopy?.findIndex(item => item?.name === value?.dimName);
			if (key === -1) {
				let obj = { id: value?.dimId, name: value?.dimName, skills: [{ id: value?.skillId, name: value?.skillName, isCompleted: true, courses: [{ id: value?.id, name: value?.name, isSelected: true, activities: value?.activities }] }] }
				dimTreeArrayCopy.push(obj);
			} else {
				const skillIndex = dimTreeArrayCopy[key]?.skills?.findIndex(item => item?.name === value?.skillName);
				if (skillIndex === -1) {
					dimTreeArrayCopy[key]?.skills?.push({ id: value?.skillId, name: value?.skillName, isCompleted: true, courses: [{ id: value?.id, name: value?.name, isSelected: true, activities: value?.activities }] });
				} else {
					dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.push({ id: value?.id, name: value?.name, isSelected: true, activities: value?.activities });
				}
			}
		} else {
			//  when remove course
			const key = dimTreeArrayCopy?.findIndex(item => item?.name === value?.dimName);
			const skillIndex = dimTreeArrayCopy[key]?.skills?.findIndex(item => item?.name === value?.skillName);
			if (dimTreeArrayCopy[key]?.skills?.length === 1 && dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.length === 1) {
				dimTreeArrayCopy.splice(key, 1);
			} else if (dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.length === 1) {
				dimTreeArrayCopy[key]?.skills?.splice(skillIndex, 1);
			} else {
				const index = dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.findIndex(item => item?.name === value?.name);
				dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.splice(index, 1);
			}
		}
		setDimensionTree(dimTreeArrayCopy);
		if (getMyEditPlan === "Edit Plan") {
			editFilterCourses(dimTreeArrayCopy);
			if (!tagSelectedCourse[index].isSelected) {
				handleCoursePlanTree(value);
			}


		}
	}

	const handleJourneyCourse = (data) => {
		setShowPathwayShimmar(true);
		setCoursesArr([]);
		setJourneyId(data?.id);
		dispatch(getJournyCourses(params?.id, data?.id));
	};

	const pathwayHalfScreen = (data) => {
		dispatch(showModal({ type: "pathwayHalfScreen", pathwayData: data }));
	};

	useEffect(() => {
		if (getDimJournyCoursesResponse?.records?.length > 0) {
			setShowPathwayShimmar(false);
			let selectCourses = [];
			getSequnceSort(getDimJournyCoursesResponse?.records)?.map((dim, index) => {
				getSequnceSort(dim?.skills)?.map((skill, sKey) => {
					skill?.courses?.map((courses, cKey) => {
						courses.isSelected = true;
						selectCourses?.push(courses?.id);
					})
				})
			})
			setCoursesArr(selectCourses);
			setPathwayTree(getDimJournyCoursesResponse?.records);
		}
	}, [getDimJournyCoursesResponse])

	const handleRemoveTagDimSkillCourse = (removeValue, dimension, skill, course, showPopup) => {
		let tagSelectedCourseCopy = [...tagSelectedCourse];
		let dimTreeArrayCopy = [];
		if (screen === 6) {
			dimTreeArrayCopy = [...pathwayTree];
		} else if (screen === 4) {
			dimTreeArrayCopy = [...getDImSkillData];
			if (showPopup) {
				showPopup = false;
				dimension?.skills?.map((skill, index) => {
					skill?.courses?.map((course, cKey) => {
						if (course?.isSelected) {
							showPopup = true;
						}
					})
				})
			}
		} else if (screen === 3) {
			dimTreeArrayCopy = [...dimensionTree];
		} else if ([1, 2]?.includes(screen)) {
			dimTreeArrayCopy = [...coursePlanTree];
		}
		if (removeValue === "dimension") {
			if (showPopup) {
				handleRemoveTagDimSkillCourseWithPopup(removeValue, dimension, skill, course, showPopup);
				return;
			}
			const key = dimTreeArrayCopy?.findIndex(item => item?.name === dimension?.name);
			dimTreeArrayCopy[key]?.skills?.map((skill, key) => {
				skill?.courses?.map((course, i) => {
					const key = tagSelectedCourseCopy?.findIndex(item => item?.name === course?.name);
					if (key !== -1) {
						tagSelectedCourseCopy[key].isSelected = false;
					}
				})
			})
			dimTreeArrayCopy.splice(key, 1);
		} else if (removeValue === "skill") {
			if (showPopup) {
				handleRemoveTagDimSkillCourseWithPopup(removeValue, dimension, skill, course, showPopup);
				return;
			}
			const key = dimTreeArrayCopy?.findIndex(item => item?.name === dimension?.name);
			if (dimTreeArrayCopy[key]?.skills?.length === 1) {
				dimTreeArrayCopy[key]?.skills?.map((skill, key) => {
					skill?.courses?.map((course, i) => {
						const key = tagSelectedCourseCopy?.findIndex(item => item?.name === course?.name);
						if (key !== -1) {
							tagSelectedCourseCopy[key].isSelected = false;
						}
					})
				})
				dimTreeArrayCopy.splice(key, 1);
			} else {
				const skillIndex = dimTreeArrayCopy[key]?.skills?.findIndex(item => item?.name === skill?.name);
				dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.map((course, i) => {
					const key = tagSelectedCourseCopy?.findIndex(item => item?.name === course?.name);
					if (key !== -1) {
						tagSelectedCourseCopy[key].isSelected = false;
					}
				})
				dimTreeArrayCopy[key]?.skills?.splice(skillIndex, 1);
			}
		} else {
			if (showPopup) {
				handleRemoveTagDimSkillCourseWithPopup(removeValue, dimension, skill, course, showPopup);
				return;
			}
			const key = dimTreeArrayCopy?.findIndex(item => item?.name === dimension?.name);
			const skillIndex = dimTreeArrayCopy[key]?.skills?.findIndex(item => item?.name === skill?.name);
			if (dimTreeArrayCopy[key]?.skills?.length === 1 && dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.length === 1) {
				dimTreeArrayCopy[key]?.skills?.map((skill, key) => {
					skill?.courses?.map((course, i) => {
						const key = tagSelectedCourseCopy?.findIndex(item => item?.name === course?.name);
						if (key !== -1) {
							tagSelectedCourseCopy[key].isSelected = false;
						}
					})
				})
				dimTreeArrayCopy.splice(key, 1);
			} else {
				if (dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.length === 1) {
					dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.map((course, i) => {
						const key = tagSelectedCourseCopy?.findIndex(item => item?.name === course?.name);
						if (key !== -1) {
							tagSelectedCourseCopy[key].isSelected = false;
						}
					})
					dimTreeArrayCopy[key]?.skills?.splice(skillIndex, 1);
				} else {
					const index = dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.findIndex(item => item?.name === course?.name);
					const i = tagSelectedCourseCopy?.findIndex(item => item?.name === course?.name);
					if (i !== -1) {
						tagSelectedCourseCopy[i].isSelected = false;
					}
					dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.splice(index, 1);
				}
			}
		}
		if (screen === 6) {
			setPathwayTree(dimTreeArrayCopy);
			let courseId = [];
			dimTreeArrayCopy?.map((dim, index) => {
				dim?.skills?.map((skill, key) => {
					skill?.courses?.map((course, cKey) => {
						courseId?.push(course?.id);
					})
				})
			})
			setCoursesArr(courseId);
		} else if (screen === 4) {
			setDimSkillData(dimTreeArrayCopy);
			let skillId = [];
			dimTreeArrayCopy?.map((dim, index) => {
				dim?.skills?.map((skill, key) => {
					skillId?.push(skill?.id);
				})
			})
			setSkillId(skillId);
			if (getMyEditPlan === "Edit Plan") {
				setCoursePlanTree(getRemainingCourses(dimTreeArrayCopy));
			}
		} else if (screen === 3) {
			setDimensionTree(dimTreeArrayCopy);
			setTagSelectedCourse(tagSelectedCourseCopy);
			if (getMyEditPlan === "Edit Plan") {
				editFilterCourses(dimTreeArrayCopy);
				handleCoursePlanTree(course, skill, dimension, removeValue);
			}
		} else if ([1, 2]?.includes(screen) && getMyEditPlan === "Edit Plan") {
			editFilterCourses(dimTreeArrayCopy);
			setCoursePlanTree(dimTreeArrayCopy);
		}
	}

	const handleRemoveTagDimSkillCourseWithPopup = (removeValue, dimension, skill, course, showPopup, dimType) => {
		let message = "";
		if (removeValue === "dimension") {
			message = "Click 'Ok' to delete all skills and courses selected for this dimension.";
		} else if (removeValue === "skill") {
			message = skill?.courses?.length === 0 || !skill?.isCompleted ? "Click 'Ok' to delete this skill." : "Click 'Ok' to delete this skill and all its selected courses."
		} else {
			message = "Click 'Ok' to delete this course."
		}
		setDeletePlan({ removeValue: removeValue, dimension: dimension, skill: skill, course: course, message, message, dimType: dimType });
	}

	const myHandleOnSkillClick = (dim, index, skill, skIndex) => {
		let getMySkill = skill;
		if (coursePlanTree?.length > 0) {
			coursePlanTree?.map((dim) => {
				dim?.skills?.map((mySkill) => {
					if (mySkill?.id === skill?.id) {
						getMySkill = mySkill;
					}
				})
			})
		}
		let arr = [...getDImSkillData];
		if (!arr?.find((item) => item?.name === dim?.name)) {
			let obj = { id: dim?.id, name: dim?.name, index: index, skills: [getMySkill] };
			arr.push(obj);
		} else {
			const dimKey = arr?.findIndex((item) => item?.name === dim?.name);
			const skilkKy = arr[dimKey]?.skills?.findIndex(
				(item) => item?.id === getMySkill?.id
			);
			if (skilkKy === -1) {
				arr[dimKey]?.skills?.push(getMySkill);
			}
			else {
				if (arr[dimKey]?.skills?.length === 1) {
					arr?.splice(dimKey, 1);
					// deletePlanActivities("dimension", dim, index);
				} else {
					arr[dimKey]?.skills?.splice(skilkKy, 1);
					// deletePlanActivities("skill", skill, index, skIndex);
					// handleRemoveTagDimSkillCourse("skill", dim, skill, "", true);
				}
			}
		}
		setDimSkillData(arr);
		let skillArr = [];
		arr?.map((dim, index) => {
			dim?.skills?.map((skill, key) => {
				skillArr?.push(skill?.id);
			});
		});
		setSkillId(skillArr);
	};

	const handlEditeCoursePLan = (type) => {
		let skillArr = [];
		let coppyArr = []
		coursePlanTree?.map((dim, index) => {
			coppyArr[index] = { ...dim, skills: [] };
			dim?.skills?.map((skill, key) => {
				skill.isCompleted = true;
				skillArr?.push(skill?.id);
				coppyArr[index].skills[key] = { ...skill, courses: [] }
				skill?.courses?.map((course, cKey) => {
					//  course.isSelected = true;
					if (course?.isSelected) {
						coppyArr[index].skills[key]?.courses?.push(course);
					}

				})
			})
		})
		console.log("coppyArr : ", coppyArr);

		if (type === "filter") {
			// setDimensionTree(coppyArr);
			return coppyArr;
		} else if (type === "dimension") {
			setDimSkillData(coppyArr);
			setSkillId(skillArr);
		}
	}

	const handleDimSkillBack = () => {
		let dimArr = [];
		let arr = [];
		if (getMyEditPlan === "Edit Plan") {
			arr = [...dimSkillCoursePlanTree]
		} else {
			arr = [...getDImSkillData];
		}
		arr?.map((dim, index) => {
			dim?.skills?.map((skill, key) => {
				if (getSkillId?.includes(skill?.id)) {
					let key = dimArr?.findIndex((item) => item?.name === dim?.name)
					if (key === -1) {
						dim.skills = [skill]
						dimArr?.push(dim);
					} else {
						dimArr[key].skills?.push(skill);

					}
					skill?.courses?.map((course, cKey) => {
						console.log("getDImSkillData 1111 : ", !getCourseId?.includes(course?.id))
						if (!getCourseId?.includes(course?.id)) {
							course.isSelected = false;
						}
						if (!skill?.courses?.find((item) => item?.isSelected === true)) {
							skill.isCompleted = false;
						}
					})
				}
			})
		})
		setDimSkillData(dimArr);
		setCourseId([]);
	}

	const handleShowSkillCourses = () => {
		setLoader(true);
		dispatch(getDimSkillCourses(params?.id, getSkillId));
		setDimName([]);
	};

	useEffect(() => {
		if (dimSkillCourseResp?.records && screen === 4) {
			let courseId = [];
			setLoader(false);
			setShowLoader(false);
			setScreen(5);
			getSequnceSort(dimSkillCourseResp?.records)?.map((dim, i) => {
				dim.isSelected = true;
				dim.isCompleted = false;
				const dimKey = coursePlanTree?.findIndex((item) => item?.name === dim?.name);
				getSequnceSort(dim?.skills)?.map((skill, key) => {
					skill.isSelected = true;
					skill.isCompleted = false;
					const sKey = coursePlanTree[dimKey]?.skills?.findIndex((item) => item?.name === skill?.name);
					if (coursePlanTree[dimKey]?.skills[sKey]?.courses?.length > 0) {
						skill.isCompleted = true;
					} if (dim?.skills?.every(item => item?.isCompleted)) {
						dim.isCompleted = true;
					}
					skill?.courses?.map((course, index) => {
						course.isSelected = false;
						const cKey = coursePlanTree[dimKey]?.skills[sKey]?.courses?.findIndex((item) => item?.id === course?.id && item?.isSelected);
						if (skill.isCompleted === true && cKey !== -1) {
							course.isSelected = true;
							courseId?.push(course?.id);
						}
					})
				})
			})
			setDimSkillCoursePlanTree(dimSkillCourseResp?.records);
			setCourseId(courseId);
		}
	}, [dimSkillCourseResp])

	const handleDimSkillCourseClick = (
		dimIndex,
		skillIndex,
		courseIndex,
		course
	) => {
		if (getCourseId?.length >= 7 && !getCourseId?.includes(course?.id)) {
			handleNumberOfCourses();
			return false;
		}
		let myArray = [...dimSkillCoursePlanTree];
		// skill and courses greentickarrow
		if (myArray[dimIndex].skills[skillIndex].courses[courseIndex].isSelected) {
			myArray[dimIndex].skills[skillIndex].courses[courseIndex].isSelected = false;
			if (!myArray[dimIndex].skills[skillIndex]?.courses?.find((val) => val?.isSelected === true)
			) {
				// myArray[dimIndex].skills[skillIndex].isSelected = false;
				myArray[dimIndex].skills[skillIndex].isCompleted = false;
			}
		} else {
			myArray[dimIndex].skills[skillIndex].courses[courseIndex].isSelected = true;
			myArray[dimIndex].skills[skillIndex].isSelected = true;
			myArray[dimIndex].skills[skillIndex].isCompleted = true;
			myArray[dimIndex].isSelected = true;
		}

		// dimension greentickarrow
		if (myArray[dimIndex]?.skills?.find((val) => val?.isSelected === true && val?.isCompleted === false)
		) {
			myArray[dimIndex].isCompleted = false;
		} else {
			myArray[dimIndex].isCompleted = true;
		}

		if (myArray[dimIndex]?.skills?.find((val) => val?.isSelected === true)) {
			myArray[dimIndex].isSelected = true;
		} else {
			// myArray[dimIndex].isSelected = false;
		}

		setDimSkillCoursePlanTree(myArray);
		let skillsId = [];
		let courseId = [];
		myArray?.map((dim, index) => {
			dim?.skills?.map((skill, key) => {
				if (skill?.isSelected) {
					skillsId?.push(skill?.id);
				}
				skill?.courses?.map((course, cKey) => {
					if (course?.isSelected) {
						courseId?.push(course?.id);
					}
				});
			});
		});
		setSkillId(skillsId);
		setCourseId(courseId);
	};

	const handleRemoveDimensionSkillCourse = (removeValue, dimension, skill, course, showPopup) => {
		let tagSelectedCourseCopy = [...tagSelectedCourse];

		if (showPopup) {
			showPopup = false;
			dimension?.skills?.map((skill, index) => {
				skill?.courses?.map((course, cKey) => {
					if (course?.isSelected) {
						showPopup = true;
					}
				})
			})
		}

		let dimTreeArrayCopy = [...dimSkillCoursePlanTree];
		if (removeValue === "dimension") {
			if (showPopup) {
				handleRemoveTagDimSkillCourseWithPopup(removeValue, dimension, skill, course, showPopup, "removeDimension");
				return;
			}
			const key = dimTreeArrayCopy?.findIndex(item => item?.name === dimension?.name);
			dimTreeArrayCopy[key].isSelected = false;
			dimTreeArrayCopy[key].isCompleted = false;
			dimTreeArrayCopy[key]?.skills?.map((skill, sKey) => {
				skill.isSelected = false;
				skill.isCompleted = false;
				skill.courses?.map((courses, cKey) => {
					courses.isSelected = false;
				})
			})

		} else if (removeValue === "skill") {
			if (showPopup) {
				handleRemoveTagDimSkillCourseWithPopup(removeValue, dimension, skill, course, showPopup, "removeDimension");
				return;
			}
			const key = dimTreeArrayCopy?.findIndex(item => item?.name === dimension?.name);
			const skillIndex = dimTreeArrayCopy[key]?.skills?.findIndex(item => item?.name === skill?.name);

			dimTreeArrayCopy[key].skills[skillIndex].isSelected = false;
			dimTreeArrayCopy[key].skills[skillIndex].isCompleted = false;
			dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.map((course, i) => {
				course.isSelected = false;
				course.isCompleted = false;
			})

			let skillCoutnt = 0;
			dimTreeArrayCopy[key].skills?.map((skill, key) => {
				if (skill?.isSelected) {
					skillCoutnt++;
				}
			})

			if (skillCoutnt === 0) {
				dimTreeArrayCopy[key].isSelected = false;
				dimTreeArrayCopy[key].isCompleted = false;
			} else {
				if (!dimTreeArrayCopy[key]?.skills?.find((item) => item?.isSelected === true && item?.isCompleted === false)) {
					dimTreeArrayCopy[key].isSelected = true;
					dimTreeArrayCopy[key].isCompleted = true;
				}
			}
		} else {
			if (showPopup) {
				handleRemoveTagDimSkillCourseWithPopup(removeValue, dimension, skill, course, showPopup, "removeDimension");
				return;
			}
			const key = dimTreeArrayCopy?.findIndex(item => item?.name === dimension?.name);
			const skillIndex = dimTreeArrayCopy[key]?.skills?.findIndex(item => item?.name === skill?.name);
			const index = dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.findIndex(item => item?.name === course?.name);

			let courseCoutn = 0;
			dimTreeArrayCopy[key]?.skills[skillIndex]?.courses?.map((course, key) => {
				if (course?.isSelected) {
					courseCoutn++;
				}
			})
			let skillCoutnt = 0;
			dimTreeArrayCopy[key].skills?.map((skill, key) => {
				if (skill?.isSelected) {
					skillCoutnt++;
				}
			})

			if (courseCoutn === 1) {
				dimTreeArrayCopy[key].skills[skillIndex].isSelected = false;
				dimTreeArrayCopy[key].skills[skillIndex].isCompleted = false;
			}
			if (skillCoutnt === 1 && courseCoutn === 1) {
				dimTreeArrayCopy[key].isSelected = false;
				dimTreeArrayCopy[key].isCompleted = false;
			}
			dimTreeArrayCopy[key].skills[skillIndex].courses[index].isSelected = false;
		}

		setDimSkillCoursePlanTree(dimTreeArrayCopy);

		let skillsId = [];
		let courseId = [];
		dimTreeArrayCopy?.map((dim, index) => {
			dim?.skills?.map((skill, key) => {
				if (skill?.isSelected) {
					skillsId?.push(skill?.id);
				}
				skill?.courses?.map((course, cKey) => {
					if (course?.isSelected) {
						courseId?.push(course?.id);
					}
				})
			})
		})
		setSkillId(skillsId);
		setCourseId(courseId);
	}
	const DimCollespeExp = (event) => {
		// const element = document.getElementsByClassName("minus_plus_icons")
		// let areaexpand = element.getAttribute("aria-expanded")
		let areaexpand = event.currentTarget.getAttribute('aria-expanded')
		//  let areaexpand = event.target.attributes.getNamedItem('aria-expanded');

		setcollaspeDim(areaexpand)
		console.log(collapseDim, "truefalse")
	}

	const handleCollapse = (dimName) => {
		let dimArr = [...getDimName]
		let dimKey = dimArr?.findIndex((item) => item?.name === dimName);

		console.log("getDimName method : ", dimArr?.includes(dimName));

		if (dimKey === -1) {
			let obj = { name: dimName, type: "close" }
			dimArr?.push(obj);
		}
		else {
			if (dimArr[dimKey]?.type === "open") {
				dimArr[dimKey].type = "close"
			} else {
				dimArr[dimKey].type = "open"
			}
		}
		setDimName(dimArr);
	}

	const handleNumberOfCourses = (type) => {
		if (type) {
			setDeletePlan({ title: "Cannot Create Plan", message: "You have not selected courses for one or more skills. You can delete skills or courses using the trash icon in the right panel." })

		} else {
			setDeletePlan({ title: " Max Course Limit", message: "You have already selected the maximum (7) number of courses in this plan. You can delete a course to add this course to the plan." })

		}
	}

	const getCourseLength = () => {
		if (dimensionTree) {
			let courseArrId = [];
			dimensionTree?.map((dim, index) => {
				dim?.skills?.map((skill, key) => {
					skill?.courses?.map((course, count) => {
						courseArrId?.push(course?.id);
					})
				})
			})
			return courseArrId;
		}
	}

	const progressCourseDisabled = (type, dimSkillId) => {
		let isProgress = false;
		if (type === "course") {
			coursePlanTree?.map((dim, index) => {
				dim?.skills?.map((skill, key) => {
					skill?.courses?.map((course, cKey) => {
						if (course?.id === dimSkillId && (course?.isStarted || course?.isCompleted)) {
							isProgress = true;
						}
					})
				})
			})
		} else if (type === "skill") {
			// this is run for skill case
			coursePlanTree?.map((dim, index) => {
				const sklkey = dim?.skills?.findIndex((val) => val?.id === dimSkillId)
				if (dim?.skills[sklkey]?.courses?.find((val) => val?.isStarted === true || val?.isCompleted)) {
					isProgress = true;
				}
			})
		} else {
			// this is run for dimension case
			coursePlanTree?.map((dim, index) => {
				if (dim?.id === dimSkillId) {
					dim?.skills?.map((skill, key) => {
						skill?.courses?.map((course, cKey) => {
							if (course?.isStarted || course?.isCompleted) {
								isProgress = true;
							}
						})
					})
				}
			})
		}

		return isProgress;
	}

	const getRemainingCourses = (dimTreeArrayCopy) => {
		let myPlanTree = [...dimTreeArrayCopy]
		let dimArr = [];
		console.log("myPlanTree 1 : ", myPlanTree);
		myPlanTree?.map((dim, index) => {
			dim?.skills?.map((skill, key) => {
				if (skill?.courses?.length > 0 && skill?.isCompleted) {

					skill?.courses?.map((course, cKey) => {
						if (course.isSelected) {
							if (getMyPlanCourses?.find(item => item?.name === course?.name && item?.isStarted)) {
								course.isStarted = true;
							}
						}

					})
					const dimKey = dimArr?.findIndex((item) => item?.name === dim?.name)
					if (dimKey === -1) {
						let obj = { ...dim, skills: [skill] }
						dimArr?.push(obj);
					} else {
						dimArr[dimKey]?.skills?.push(skill);
					}
				}
			})
		})
		console.log("dimArr 2 : ", dimArr);
		return dimArr;
	}

	const timezoneObj = [
		{ "key": "US/Eastern (ET)", "value": "US/Eastern" },
		{ "key": "US/Central (CT)", "value": "US/Central" },
		{ "key": "US/Mountain (MT)", "value": "US/Mountain" },
		{ "key": "US/Pacific (PT)", "value": "US/Pacific" }
	]

	const [selectedTimeZone, setSelectedTimeZone] = useState(0);

	let weekDays = [
		{ value: "Monday", key: "Mon" },
		{ value: "Tuesday", key: "Tue" },
		{ value: "Wednesday", key: "Wed" },
		{ value: "Thursday", key: "Thu" },
		{ value: "Friday", key: "Fri" },
		{ value: "Saturday", key: "Sat" },
		{ value: "Sunday", key: "Sun" }
	]
	const [selctedDays, setSeleccted] = useState([]);
	const [isFlexible, setIsFlexible] = useState(false);
	const [getFlexibLeDays, setFlexibLeDays] = useState([]);

	const handleWeekDays = (weekKey, index) => {
		if (selctedDays?.length >= selectedSitting && !selctedDays?.includes(weekKey)) {
			setDeletePlan({ title: "Select Days", message: `You have already selected ${selectedSitting} days for ${selectedSitting} sessions.` })
			return;
		}
		let daysOfArr = [...selctedDays];
		const key = daysOfArr?.findIndex((item) => item === weekKey);
		if (key !== -1) {
			daysOfArr?.splice(key, 1);
		} else {
			daysOfArr?.push(weekKey);
		}
		setSeleccted(daysOfArr);
		setIsFlexible(false);
		setFlexibLeDays([]);
	}

	const handleFlexible = () => {
		setSeleccted([]);
		setIsFlexible(!isFlexible);
		let weekArr = [];
		let currentDate = new Date(userDate);
		if (!isFlexible) {
			for (let i = 0; i < selectedSitting; i++) {
				let weekDay = new Date(currentDate).toLocaleString("en-us", { weekday: 'short' });
				weekArr?.push(weekDay);
				currentDate.setDate(currentDate.getDate() + 1);
			}
			setFlexibLeDays(weekArr);
		} else {
			setFlexibLeDays([]);
		}
	}

	const handleCoursePlanTree = (selectCourse, selecteSkill, selecteDimension, removeValue) => {
		if (coursePlanTree?.length > 0) {
			let isRemoveCourse = false;
			coursePlanTree?.map((dim, index) => {
				dim?.skills?.map((skill, key) => {
					skill?.courses?.map((course, ckey) => {
						console.log("course?.courseType : ", course, "----- ", selectCourse);
						if (selectCourse?.id === course?.id) {
							coursePlanTree[index]?.skills[key]?.courses?.splice(ckey, 1);
							isRemoveCourse = true;
						}
					})
					if ((skill?.courses?.length === 0 || (selecteSkill?.id === skill?.id && removeValue === "skill"))) {
						coursePlanTree[index]?.skills?.splice(key, 1);
						isRemoveCourse = true;
					}
				})
				if ((dim?.skills?.length === 0 || (selecteDimension?.id === dim?.id && removeValue === "dimension"))) {
					coursePlanTree?.splice(index, 1);
					isRemoveCourse = true;
				}
			})
			setCoursePlanTree(coursePlanTree);
		}
	}

	const [getUsTimeZone, setUsTimeZone] = useState("US/Eastern");
	useEffect(() => {
		if (loggedInUser?.timeZone) {
			setUsTimeZone(loggedInUser?.timeZone)
		}
	}, [loggedInUser])

	const [minDate, setMinDate] = useState(new Date());

	const [showEnrollPlan, setShowEnrollPlan] = useState(false);
	useEffect(() => {
		if (location?.state?.show === "EditCoursePlan") {
			setSelectedSitting(location?.state?.myPlan?.sittings);
			setSelectedMinutes(location?.state?.myPlan?.duration);
			setMinDate(new Date(getUsStatnderdTime(location?.state?.myPlan?.planStartDate, getUsTimeZone)));
			setUserDate(new Date(getdateWithCurretnTime(getUsStatnderdTime(location?.state?.myPlan?.planStartDate, getUsTimeZone))));
			setValue("planName", location?.state?.myPlan?.planName);
		}
	}, [location?.state])

	return (
		<Home>
			<div className="d-flex flex-wrap SpecialLeftpanel w-100">
				<div className="d-flex w-100 align-items-start overflow-visible">
					<div className="LeftbarPannel p-0 justify-content-between" id="">
						<div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
							<h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between">
								<span>
									{" "}
									<img src={image.Powericon} className="mr-2" alt="" />
									Let's {getMyEditPlan === "Edit Plan" ? "Edit" : "Create"} {getLearnerName()}'s Plan
								</span>
							</h2>
						</div>
						<div className="CourseCardWrapper fullHeight100 position-relative">

							<div class="backpageStrip flex">
								{/* {
									screen !== 1 ? (
										<a href="#" onClick={() => handleBack()}>
											<span class=""><i class="fa-solid fa-angle-left mr-1"></i></span>
											Back
										</a>
									) : (<div></div>)
								} */}
								{/* {
                                    (selectedOption !== "changePace" && [1, 2, 4]?.includes(screen)) && (
                                        <a href="#" onClick={() => handleNext()}>Next
                                            <span class=""><i class="fa-solid fa-angle-right ml-1"></i></span>
                                        </a>
                                    )
                                } */}
								{/* {((selectedOption !== "changePace" && screen === 1 && !showEnrollPlan) || (selectedOption !== "recommended" &&
									[2, 4]?.includes(screen))) && (
										<a href="#" onClick={() => handleNext()} className={(loader || (screen === 4 && getSkillId?.length === 0)) ? "disabledevent" : ""}>
											Next
											<span class="">
												<i class="fa-solid fa-angle-right ml-1"></i>
											</span>
										</a>
									)} 
									(getAllCourses(coursePlanTree)?.length === 1) ||
									*/}
								<a href="#" onClick={() => handleBack()} className={screen === 1 ? "disabledevent" : ""}>
									<span >
										<i class="fa-solid fa-angle-left mr-1"></i>
									</span>
									Back
								</a>
								{ }
								<a href="#" onClick={() => handleNext()} className={([3, 5, 6]?.includes(screen) || (selectedOption === "recommended" && screen === 2) ||
									(getAllCourses(location?.state?.myPlan?.dimensions)?.length === 1) || (screen == 4 && getSkillId?.length === 0)) ? "disabledevent" : ""}>
									Next
									<span class="">
										<i class="fa-solid fa-angle-right ml-1"></i>
									</span>
								</a>
							</div>
							{/* screen 1 */}
							{
								((screen === 2 && getMyEditPlan !== "Edit Plan") || (screen === 1 && getMyEditPlan === "Edit Plan")) && (
									<>
										<div className="learner_plan setup_one back_next_pos">
											{/* <div class="backpageStrip flex pt-0 pb-3"><a href="#"><span class=""><i class="fa-solid fa-angle-left mr-1"></i></span>Back</a></div> */}
											<div className="paceSteps mt-0">
												<div className="">
													<h3 className="d-flex align-items-start paceseltitle">
														<span>
															Select a pace goal for {getLearnerName()} to  complete  course(s) in their Plan.
														</span>
													</h3>
													<p className="pt-3">
														The pace selected is used to build the timeline of the Plan. Pace can be changed at any time and courses are completed at your own pace. After selecting your pace, click 'Next' to choose how to build your plan.
													</p>
												</div>
												<div className="ScenecerelateddQuiz p-0 d-flex align-items-start justify-content-between">
													<div className="mt-3 pt-3 w-40">
														<div className="input-group">
															<h3 className="text-nowrap mb-3">Plan Name: <span class="mandatoryField">*</span> </h3>
															<div className="form-group">
																<input
																	type="text"
																	// placeholder="Last Name"
																	className={`form-control ${errors.planName ? "is-invalid" : ""
																		}`}
																	{...register("planName", {
																		// required: {
																		// 	value: true,
																		// 	message: "Plan name is required",
																		// },
																	})}
																/>
																<div className="invalid-feedback">
																	{errors?.planName?.message}
																</div>
															</div>
														</div>
														<div className="plan_startdate mt-3 ">
															<div className={`input-group calender`}>
																<div className={`form-group  `}>
																	<h3 className="mr-3 text-nowrap mb-3"> Start Date: <span class="mandatoryField">*</span>
																		{/* <span onClick={() => handlePopUpOpen("startdate")}>
                                                                <img src={image.chat_icon} className="ml-2 ichat_icon pointer" />
                                                                    </span> */}
																	</h3>
																	<label className={`flexone position-relative  m-0`}>
																		<span className="clenderIcon">
																			<img src={image.Calendericon} />
																		</span>
																		<DatePicker className="form-control signupChildClass p-0 w-100"
																			clearIcon={null}
																			oneTap
																			onChange={(value) => {
																				setUserDate(getdateWithCurretnTime(value))
																			}}
																			value={userDate}
																			dayPlaceholder={"dd"}
																			monthPlaceholder={"mm"}
																			yearPlaceholder={"yyyy"}
																			format="MM/dd/yyyy"
																			placement={"topEnd"}
																			minDate={minDate}
																		/>
																	</label>

																</div>
																<p>This is the start date for the start of the Plan or for the first course which you have not started yet.</p>
															</div>
														</div>
														{
															loggedInUser?.timeZone === null && (
																<div className="plan_Time_Zonedrp mt-3">
																	<div className="plan_startdate mt-3">
																		<div className="input-group calender learner_pLan_dropdown ">
																			<div className="form-group   text-nowrap">
																				<h3 className="mr-3 mb-3"> Time Zone<span class="mandatoryField">*</span> </h3>
																				<label className="flexone position-relative w-100 timezonelabel m-0">
																					<span aria-haspopup="true"
																						className="pointer dropdownDiv flex w-100"
																						aria-expanded="false"
																						data-toggle="dropdown">
																						<span className="pe-2 dropdownLabel">{timezoneObj[selectedTimeZone]?.key}</span>
																						<i className="fa fa-angle-down fa-lg" aria-hidden="true"></i>
																					</span>
																					<ul className="dropdown-menu dropdown-menu-end lPDropdwnList" aria-labelledby="">
																						{
																							timezoneObj?.map((value, key) => (
																								<li onClick={() => setSelectedTimeZone(key)}>
																									{value?.key}
																								</li>
																							))
																						}
																					</ul>
																				</label>
																				{/* <span >
                                                                            <img src={image.chat_icon} className="ml-2 ichat_icon pointer" />
                                                                        </span> */}
																			</div>
																			<p>Please provide your time zone. All dates and times will be shown according to this time zone.</p>
																		</div>
																	</div>
																</div>
															)
														}
													</div>
													<div class="signupType m-0 pt-3 mt-3">
														<div class="PaceModeSel mb-3">
															<h3 className=""> Sessions Per Week: {" "}
																<span className="mandatoryField">*</span>
																<div className="selectecPaceWeek">
																	{
																		sittings?.map((value, index) => (
																			<label class="Selcheckbox m-0 ActiveQQst" key={index}>
																				<input type="radio" name="skill0" onClick={() => handleSitting(value)} checked={value == selectedSitting ? true : false} />
																				{value} <span>Session{value === "1" ? "" : "s"}</span>
																				<span class="checkmark"> </span>
																				{value === "2" && (<p className="recummended">(Recommended)</p>)}
																			</label>

																		))
																	}

																</div>
															</h3>
															{/* <p>We recommend at least {convertedNumber(2)} (2) sessions per week for optimal learning experience.</p> */}
														</div>
													</div>
													<div class="signupType m-0 pt-3 mt-3 seltimedaysit">
														<div class="PaceModeSel mb-3">
															<h3 className=""> Duration Per Session: {" "}
																<span className="mandatoryField">*</span>
																<div className="selectecPaceWeek">
																	{
																		minutes?.map((value, index) => (
																			<label class="Selcheckbox m-0 ActiveQQst" key={index}>
																				<input type="radio" name="minutes" checked={value == selectedMinutes ? true : false} onClick={() => handleMinutes(value)} />
																				{value} <span>minutes</span>
																				<span class="checkmark"> </span>
																				{value === "30" && (<p className="recummended">(Recommended)</p>)}
																			</label>
																		))
																	}
																</div>
															</h3>
															{/* <p>
                                                            We recommend at least 30 mins per session for optimal learning experience.
                                                        </p> */}
														</div>
													</div>
												</div>

												<div className="ScenecerelateddQuiz selec_Days_Custom  pl-0">
													<div class="signupType m-0  w-100">
														<div class="PaceModeSel mb-3 w-100">
															<h3 className=""> Select {convertedNumber(selectedSitting).toLowerCase()} ({selectedSitting}) Days you want to work on Bloomster.{" "}
																<span className="mandatoryField">*</span>
																<div className="selectecPaceWeek w-100 mb-0">
																	<label class="Selcheckbox m-0 ActiveQQst iamflexiblelabel border-0 pb-1">
																		<input type="checkbox" name="skill0" onClick={() => handleFlexible()}
																			checked={isFlexible && selctedDays?.length === 0}
																		/>
																		<span>I'm flexible: </span>
																		<span class="checkmark"> </span>
																	</label>
																</div>
																<p className="onlinertxt pt-1 pb-1">
																	Consecutive days based on your selection for Sessions Per Week.
																	{/* {selectedSitting} days starting my plan start date. */}
																</p>
																<p className="Mitual_days_sel">
																	<strong>Or, </strong>
																	select specific days you would like {getLearnerName()} to take the course(s) in this  Plan.
																</p>
																<div className="selectecPaceWeek w-100">
																	{
																		weekDays?.map((day, key) => (
																			<label class="Selcheckbox m-0 ActiveQQst border-0" key={key}>
																				<input type="checkbox" name="skill0" onClick={() => handleWeekDays(day?.key, key)}
																					checked={selctedDays?.includes(day?.key)}
																				/>
																				<span>{day?.value}</span>
																				<span class="checkmark"> </span>
																			</label>
																		))
																	}

																</div>
															</h3>
															{/* <p>We recommend at least {convertedNumber(2)} (2) sessions per week for optimal learning experience.</p> */}
														</div>
													</div>
												</div>
												{/* {getMyEditPlan === "Edit Plan" ?
													<>    {
														selectedOption !== "changePace" && !showEnrollPlan && (
															<div className="next_button p-10">

																<div className={`buttonDistribotion justify-content-between mt-4 pt-4`}>
																	<div></div>
																	<button
																		type="submit"
																		data-toggle="modal"
																		data-target="#schoolactivity75"
																		className="btn-blue btn-login d-block mb-5 w-auto"
																		onClick={() => handleNext()}
																	>Next
																		<i className="fa-solid fa-arrow-right m-0 ml-2"></i>
																	</button>
																</div>
															</div>
														)
													}
													</> : <div className="next_button p-10">
														<div className="buttonDistribotion justify-content-between">
															{true && (
																<button
																	type="submit"
																	onClick={() => handleBack()}
																	className="btn-blue btn-login d-block mb-5 w-auto"
																><i className="fa-solid fa-arrow-left m-0 mr-2"></i>Back
																</button>
															)}
															{!["recommended", "EditCoursePlan"]?.includes(selectedOption) && (
																<>
																	{
																		loader ? (
																			<div className="justify-content-end">
																				<button
																					className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
																					disabled
																				>
																					<span className="RounAnimation mr-1"></span> Please
																					Wait...
																				</button>
																			</div>) : (
																			<button
																				type="submit"
																				data-toggle="modal"
																				data-target="#schoolactivity75"
																				className="btn-blue btn-login d-block mb-5 w-auto"
																				onClick={() => handleNext()}
																			>
																				Next<span>
																					<i className="fa-solid fa-arrow-right m-0 ml-2"></i>
																				</span>
																			</button>
																		)}
																</>
															)}


														</div>
													</div>

												} */}
												{/* {
													selectedOption !== "changePace" && !showEnrollPlan && (
														<div className="next_button p-10">
															<div className={`buttonDistribotion justify-content-between mt-4 pt-4`}>
																<div></div>
																<button
																	type="submit"
																	data-toggle="modal"
																	data-target="#schoolactivity75"
																	className="btn-blue btn-login d-block mb-5 w-auto"
																	onClick={() => handleNext()}
																>Next
																	<i className="fa-solid fa-arrow-right m-0 ml-2"></i>
																</button>
															</div>
														</div>
													)
												} */}
											</div>
										</div>
										{/* <div class="input-group full-Width-group basic_details_form pagebuttonStrip position_sticky bottom-0">
                                            <div class="form-group BDsubmitbutton d-flex justify-content-between align-items-center m-0">
                                                <div class="w-100">
                                                    <div class="priceWrap p-0">
                                                        <button type="submit" class="btn-blue btn-login d-block w-auto mb-5 ml-auto" disabled="">
                                                            <i className='fa-solid fa-paper-plane mr-2'></i>Update Plan</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
									</>
								)
							}

							{/* screen 2 */}
							{
								((screen === 1 && getMyEditPlan !== "Edit Plan") || (screen === 2 && getMyEditPlan === "Edit Plan")) && (
									<div className="learner_plan p-4 setup_two ljpopups">
										<div className="">
											<div class="ScenecerelateddQuiz  flex position-relative flex-wrap" id="ScenecerelateddQuiz">
												<div class="signupType m-0 ">
													<h4 class="">
														<strong class="">Next let's build the courses for {getLearnerName()}'s Plan by selecting from one of the options below.</strong>
													</h4>
													{
														!editPlan && (
															<div class="learnerPlan_modelQue">
																<div>
																	<label class="Selcheckbox m-0 ActiveQQst learnerPlan_sellebel">
																		<div className="QQtitle">
																			<p className="palnquestiontitle">Recommend a Plan for {getLearnerName()}.</p>
																		</div>
																		<input type="radio" name="skill0" value="recommended" checked={selectedOption === "recommended"} onClick={() => handleOptionClick("recommended")} />
																		<span class="checkmark"></span>
																	</label>
																	<span onClick={() => handlePopUpOpen("recommended")}>
																		<img src={image.chat_icon} class="ml-2 ichat_icon pointer" alt="" />
																	</span>
																</div>
																<p className="onliner_Sel w-100">
																	We selected a few of our most popular courses to create a Plan for {getLearnerName()}.
																</p>
															</div>
														)
													}
													<div class="learnerPlan_modelQue">
														<div>
															<label class="Selcheckbox m-0 ActiveQQst learnerPlan_sellebel">
																<div className="QQtitle">
																	<p className="palnquestiontitle">
																		I want to filter courses based on life events {getLearnerName()} is experiencing.
																	</p>
																</div>
																<input type="radio" name="skill0" value="filter" checked={selectedOption === "filter"} onClick={() => handleOptionClick("filter")} />
																<span class="checkmark"></span>
															</label>
															<span onClick={() => handlePopUpOpen("filter")}>
																<img src={image.chat_icon} class="ml-2 ichat_icon pointer" alt="" />
															</span>
														</div>
														<p className="onliner_Sel w-100">
															You will select some situations that are common for kids aged 10-15 to experience.
														</p>
													</div>
													{/* {
                                                        !editPlan && (
                                                            <div class="learnerPlan_modelQue">
                                                                <div>
                                                                    <label class="Selcheckbox m-0 ActiveQQst learnerPlan_sellebel">
                                                                        <div className="QQtitle">
                                                                            <p className="palnquestiontitle">
                                                                                I want to select a pathway that focuses on an area where {getLearnerName()} needs help.
                                                                            </p>
                                                                        </div>
                                                                        <input type="radio" name="skill0" value="pathway" checked={selectedOption === "pathway"} onClick={() => handleOptionClick("pathway")} />
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    <span onClick={() => handlePopUpOpen("pathway")}>
                                                                        <img src={image.chat_icon} class="ml-2 ichat_icon pointer" alt="" />
                                                                    </span>
                                                                </div>
                                                                <p className="onliner_Sel w-100">
                                                                    Pathways are a set of courses curated by experts to combine skills for targeted growth.
                                                                </p>
                                                            </div>
                                                        )
                                                    } */}
													<div className="learnerPlan_modelQue">
														<div>
															<label class="Selcheckbox m-0 ActiveQQst learnerPlan_sellebel">
																<div className="QQtitle">
																	<p className="palnquestiontitle">
																		I want to select dimension(s) and skill(s) where {getLearnerName()} needs help.
																	</p>
																</div>
																<input type="radio" name="skill0" value="dimension" checked={selectedOption === "dimension"} onClick={() => handleOptionClick("dimension")} />
																<span class="checkmark"></span>
															</label>
															<span onClick={() => handlePopUpOpen("dimension")}>
																<img src={image.chat_icon} class="ml-2 ichat_icon pointer" alt="" />
															</span>
														</div>
														<p className="onliner_Sel w-100">
															Customize which dimension(s) and skill(s) you want {getLearnerName()} to focus on in the next few weeks.
														</p>
													</div>
												</div>
											</div>
											{/* {getMyEditPlan === "Edit Plan" ? (
												<div className="next_button p-10">
													<div className="buttonDistribotion justify-content-between">
														{true && (
															<button
																type="submit"
																onClick={() => handleBack()}
																className="btn-blue btn-login d-block mb-5 w-auto"
															><i className="fa-solid fa-arrow-left m-0 mr-2"></i>Back
															</button>
														)}

														{
															selectedOption !== "recommended" && (
																<>
																	{
																		loader ? (
																			<div className="justify-content-end">
																				<button
																					className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
																					disabled
																				>
																					<span className="RounAnimation mr-1"></span> Please
																					Wait...
																				</button>
																			</div>) : (
																			<button
																				type="submit"
																				data-toggle="modal"
																				data-target="#schoolactivity75"
																				className="btn-blue btn-login d-block mb-5 w-auto"
																				onClick={() => handleNext()}
																			>
																				Next<span>
																					<i className="fa-solid fa-arrow-right m-0 ml-2"></i>
																				</span>
																			</button>
																		)}
																</>
															)
														}
													</div>
												</div>
											) : (
												<div className="next_button p-10">
													<div className="buttonDistribotion justify-content-between">
														<button
															type="submit"
															data-toggle="modal"
															data-target="#schoolactivity75"
															className="btn-blue btn-login d-block mb-5 w-auto"
															onClick={() => handleNext()}
														>
															Next<span>
																<i className="fa-solid fa-arrow-right m-0 ml-2"></i>
															</span>
														</button>
													</div>
												</div>
											)} */}
											{/* <div className="next_button p-10">
												<div className="buttonDistribotion justify-content-between">
													<button
														type="submit"
														onClick={() => handleBack()}
														className="btn-blue btn-login d-block mb-5 w-auto"
													><i className="fa-solid fa-arrow-left m-0 mr-2"></i>Back
													</button>
													{
														selectedOption !== "recommended" && (
															<>
																{
																	loader ? (
																		<div className="justify-content-end">
																			<button
																				className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
																				disabled
																			>
																				<span className="RounAnimation mr-1"></span> Please
																				Wait...
																			</button>
																		</div>) : (
																		<button
																			type="submit"
																			data-toggle="modal"
																			data-target="#schoolactivity75"
																			className="btn-blue btn-login d-block mb-5 w-auto"
																			onClick={() => handleNext()}
																		>
																			Next<span>
																				<i className="fa-solid fa-arrow-right m-0 ml-2"></i>
																			</span>
																		</button>
																	)}
															</>
														)
													}
												</div>
											</div> */}
										</div>
									</div>
								)
							}
							{/* screen 3 */}
							{
								screen === 3 && (
									<div className="learner_plan p-4 setup_two ljpopups setup_three">
										<div className="">
											<div class="ScenecerelateddQuiz custom_pathways  flex position-relative flex-wrap" id="ScenecerelateddQuiz">
												<div class="signupType m-0 w-100 random_selection">
													<h4 class="w-100 flex-wrap p-0">
														<strong class="">Let's select the areas where you want {getLearnerName()} to focus. </strong>
														<p className="onliner_Sel w-100 p-0">We recommend up to 3 areas at a time. Plans can range from 2 to 7 courses.</p>
													</h4>
													<div className="" id="AreasFocus">
														{
															tagData?.map((value, index) => (
																<div class="learnerPlan_modelQue" >
																	<div>
																		<label class={`Selcheckbox m-0 ActiveQQst learnerPlan_sellebel`}>
																			<div class="QQtitle">
																				<p class="palnquestiontitle">{value?.uiLabel}</p>
																			</div>
																			<input type="checkbox" name="randomskills" onClick={() => handleTagSelectedCourse(value, index)} />
																			<span class="checkmark"></span>
																		</label>
																	</div>
																</div>
															))
														}
													</div>
												</div>
												{
													tagSelectedCourse?.length > 0 && (
														<div class="signupType m-0 w-100 pt-4">
															<h4 class="flex p-0">
																<strong class="">Here are recommended courses based on your selection(s) above.</strong>
															</h4>
															{
																tagSelectedCourse?.map((course, count) => (
																	<div class="learnerPlan_modelQue">
																		<div>
																			<label class={`Selcheckbox m-0 ActiveQQst learnerPlan_sellebel ${progressCourseDisabled("course", course?.id) ? "disabledchecked" : ""}`}>
																				<div class="QQtitle">
																					<p class="palnquestiontitle">{course?.name}</p>
																				</div>
																				<input type="checkbox" name="skill0" checked={course?.isSelected} onClick={() => handleTagCourseClick(course, count)} />
																				<span class="checkmark"></span>
																			</label>
																			<span className="coursedtlicons pointer" onClick={() => courseDetailPage(course)}><i class="fa-solid fa-up-right-from-square pl-2 pr-2"></i></span>
																		</div>
																		<p class="onliner_Sel w-100">
																			<React.Fragment>
																				{course?.oneliner?.length > 100
																					? (
																						<ReactTooltip id={course?.oneliner}>
																							<p>
																								{course?.oneliner}
																							</p>
																							{/* <p>{data?.name}</p> */}
																						</ReactTooltip>
																					) : (
																						""
																					)}
																				<div data-for={course?.oneliner} data-event-off="" data-tip>
																					<span>
																						{textTrim(course?.oneliner, 100)}
																					</span>
																				</div>
																			</React.Fragment>
																		</p>
																	</div>
																))
															}
														</div>
													)
												}
											</div>
										</div>
										{/* <div className="next_button p-10 pt-0">
											<div className="buttonDistribotion justify-content-between">
												<button
													type="submit"
													onClick={() => handleBack()}
													className="btn-blue btn-login d-block mb-5 w-auto"><i className="fa-solid fa-arrow-left m-0 mr-2"></i>Back
												</button>
											</div>
										</div> */}
									</div>
								)
							}
							{/* screen 4 */}
							{
								screen === 4 && (
									<div className="learner_plan p-4 setup_two ljpopups setup_three">
										<div className="">
											<div
												class="ScenecerelateddQuiz  custom_pathways flex position-relative flex-wrap"
												id="ScenecerelateddQuiz">
												<div class="signupType m-0 random_selection">
													<h4 class="flex-wrap">
														<strong class="">
															I want {getLearnerName()} to focus on the following dimensions.
														</strong>
														<p className="onliner_Sel w-100 p-0">
															We recommend up to 3 dimensions at a time. Plans can range from 2 to 7 courses.
														</p>
													</h4>
													<div className="Dimension_skill_tree">
														{getSequnceSort(getDimPlanData?.records)?.map((dim, index) => (
															<div className="DS_tree" key={index}>
																<h2>
																	<span
																		data-toggle="collapse"
																		className="minus_plus_icons"
																		href={`#CollespeDimandskl${dim?.name}`}
																		aria-expanded="true"
																		onClick={() => handleCollapse(dim?.name)}
																	>

																		{getDimName?.find((item) => item?.name === dim?.name) ? (

																			getDimName?.find((item) => item?.name === dim?.name && item?.type === "open")
																				?
																				<img src={image.minus_sign} className="mr-2" alt="minus" />
																				:
																				<img src={image.plus_sign} className="mr-2" alt="plus" />

																		) : (
																			<img src={image.minus_sign} className="mr-2" alt="minus" />
																		)
																		}
																	</span>
																	{dim?.name}
																	<span>
																		<img
																			src={image.chat_icon}
																			className="ml-2 ichat_icon pointer"
																			alt=""
																			onClick={() => showDimModal(dim, "dim")}
																		/>
																	</span>
																</h2>
																<div className="panel-collapse collapse show" id={`CollespeDimandskl${dim?.name}`}>
																	{
																		getSequnceSort(dim?.skills)?.map((skill, key) => (
																			!["Science", "Social Studies", "Technology", "Music"]?.includes(skill?.name) && (
																				<div key={key} className="learnerPlan_modelQue"
																				>
																					<div>
																						<label class={`Selcheckbox m-0 ActiveQQst learnerPlan_sellebel ${progressCourseDisabled("skill", skill?.id) ? "disabledchecked" : ""}`}>
																							<div class="QQtitle">
																								<p class="palnquestiontitle">
																									{skill?.name}
																								</p>
																							</div>
																							<input
																								type="checkbox"
																								name="randomskills"
																								onClick={() => myHandleOnSkillClick(dim, index, skill, key)}
																								checked={getSkillId?.includes(skill?.id)}
																								disabled={progressCourseDisabled("skill", skill?.id)}
																							/>
																							<span class="checkmark"></span>
																						</label>
																						<span>
																							<img
																								src={image.chat_icon}
																								className="ml-2 ichat_icon pointer"
																								alt=""
																								onClick={() => showDimModal(skill, "skill")}
																							/>
																						</span>
																					</div>
																				</div>
																			)
																		))
																	}
																</div>
															</div>
														)
														)}
													</div>
												</div>
											</div>

											{/* <div className="next_button p-10">
												<div className="buttonDistribotion justify-content-between">
													<button
														type="submit"
														onClick={() => handleBack()}
														className="btn-blue btn-login d-block mb-5 w-auto"
													><i className="fa-solid fa-arrow-left m-0 mr-2"></i>Back
													</button>
													{loader ? (
														<div className="justify-content-end">
															<button
																className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
																disabled
															>
																<span className="RounAnimation mr-1"></span> Please
																Wait...
															</button>
														</div>) : (
														<button
															type="submit"
															data-toggle="modal"
															data-target="#schoolactivity75"
															className="btn-blue btn-login d-block mb-5 w-auto"
															disabled={getSkillId?.length === 0}
															onClick={() => handleShowSkillCourses()}
														>
															Next
															<span><i className="fa-solid fa-arrow-right m-0 ml-2"></i></span>
														</button>
													)}
												</div>
											</div> */}
										</div>
									</div>
								)
							}
							{screen === 5 && (
								<div className="learner_plan p-4 setup_two ljpopups setup_three">
									<div
										class="ScenecerelateddQuiz  custom_pathways flex position-relative flex-wrap"
										id="ScenecerelateddQuiz"
									>
										<div class="signupType m-0 random_selection">
											<h4 class="flex-wrap">
												<strong class="">
													Here are the courses based on skills you selected in
													the previous step.
												</strong>
												<p className="onliner_Sel w-100 p-0">
													We recommend up to 3 dimensions at a time. Plans can
													range from 2 to 7 courses.
												</p>
											</h4>
											<div className="Dimension_skill_tree setup_five">
												{getSequnceSort(dimSkillCourseResp?.records)?.map((dim, index) => (
													<div className="DS_tree" key={index}>
														<div className="DimTitleSlect">
															<h2>
																<span
																	data-toggle="collapse"
																	className="minus_plus_icons"
																	href={`#CollespeDimandskl${dim?.name}`}
																	aria-expanded="true"
																	onClick={() => handleCollapse(dim?.name)}
																>
																	{getDimName?.find((item) => item?.name === dim?.name) ? (

																		getDimName?.find((item) => item?.name === dim?.name && item?.type === "open")
																			?
																			<img src={image.minus_sign} className="mr-2" alt="minus" />
																			:
																			<img src={image.plus_sign} className="mr-2" alt="plus" />

																	) : (
																		<img src={image.minus_sign} className="mr-2" alt="minus" />
																	)
																	}
																	{/* {collapseDim ?
                                                                        <img src={image.minus_sign} className="mr-2" alt="minus" />
                                                                        :
                                                                        <img src={image.plus_sign} className="mr-2" alt="plus" />
                                                                    }
                                                                    {console.log(collapseDim, "consolevalvue")} */}
																</span>
																{dim?.name}
																<span>
																	<img
																		src={image.chat_icon}
																		className="ml-2 ichat_icon pointer"
																		alt=""
																		onClick={() => showDimModal(dim, "dim")}
																	/>
																</span>
															</h2>
														</div>
														<div className="panel-collapse collapse show" id={`CollespeDimandskl${dim?.name}`}>
															{getSequnceSort(dim?.skills)?.map((skill, key) => (
																<div class="learnerPlan_modelQue" key={key}>
																	<h2>
																		{skill?.name}
																		<span>
																			<img
																				src={image.chat_icon}
																				className="ml-2 ichat_icon pointer"
																				alt=""
																				onClick={() => showDimModal(skill, "skill")}
																			/>
																		</span>
																	</h2>
																	{getSequnceSort(skill?.courses)?.map((course, cKey) => (
																		<div class="learnerPlan_modelQue custom_pathways" cKey={cKey}>
																			<div>
																				<label class={`Selcheckbox m-0 ActiveQQst learnerPlan_sellebel ${progressCourseDisabled("course", course?.id) ? "disabledchecked" : ""}`}>
																					<div class="QQtitle">
																						<p class="palnquestiontitle">
																							{course?.name}
																						</p>
																					</div>
																					<input type="checkbox" name="skill0" onClick={() => handleDimSkillCourseClick(index, key, cKey, course)}
																						checked={getCourseId?.includes(course?.id)}
																						disabled={progressCourseDisabled("course", course?.id)}
																					/>
																					<span class="checkmark"></span>
																				</label>
																				<span className="coursedtlicons pointer"
																					onClick={() => courseDetailPage(course)}
																				>
																					<i class="fa-solid fa-up-right-from-square pl-2 pr-2"></i>
																				</span>
																			</div>
																		</div>
																	))}
																</div>
															))}
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
									{/* <div className="next_button p-10">
										<div className="buttonDistribotion justify-content-between">
											<button
												type="submit"
												onClick={() => handleBack()}
												className="btn-blue btn-login d-block mb-5 w-auto"
											><i className="fa-solid fa-arrow-left m-0 mr-2"></i>Back
											</button>
										</div>
									</div> */}
								</div>
							)}
							{screen === 6 && (
								<div className="learner_plan setup_two ljpopups setup_three pathwayslearner p-4">
									<div
										class="ScenecerelateddQuiz  flex position-relative flex-wrap"
										id="ScenecerelateddQuiz"
									>
										<div class="signupType m-0 ">
											<h4 class="mb-3">
												<strong class="">
													Where do you think{" "}{getLearnerName()} needs support?
													{/* <img
                                                        src="/static/media/chat-icon.2a607af3ca378ac033c1.png"
                                                        class="ml-2 pointer jinfoicon"
                                                        alt=""
                                                    /> */}
												</strong>
											</h4>
											{getDimJournyResponse?.records?.map((value, key) => (
												<div class="learnerPlan_modelQue" key={key}>
													<div>
														<label class="Selcheckbox m-0 ActiveQQst learnerPlan_sellebel">
															<div class="QQtitle">
																<p class="palnquestiontitle">{value?.name}</p>
															</div>
															<input type="radio" name="skill0"
																onClick={() => handleJourneyCourse(value)}
															/>
															<span class="checkmark"></span>
														</label>
														<span className="coursedtlicons pointer" onClick={() => pathwayHalfScreen(value)}>
															<i class="fa-solid fa-up-right-from-square ml-2"></i>
														</span>
													</div>
													<p class="onliner_Sel w-100">
														{value?.oneliner}
													</p>
												</div>
											))}

										</div>
									</div>
									<div className="next_button p-10 pt-0">
										<div className="buttonDistribotion justify-content-between">
											<button
												type="submit"
												onClick={() => handleBack()}
												className="btn-blue btn-login d-block mb-5 w-auto"><i className="fa-solid fa-arrow-left m-0 mr-2"></i>Back
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
						<div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
							<div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 ">
								<div className="">
									<button class="btn-blue btn-login d-block float-right w-auto" disabled={[1]?.includes(screen)} onClick={() => handleBack()}><span><i className="fa-solid fa-arrow-left m-0 mr-2"></i>
									</span>Back</button>
								</div>
								{
									// !["recommended", "EditCoursePlan"]?.includes(selectedOption) && 
									//	(getAllCourses(coursePlanTree)?.length === 1) ||
									<>
										{
											[2, 4]?.includes(screen) && showLoader &&
												loader ? (
												<div className="justify-content-end">
													<button
														className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
														disabled
													>
														<span className="RounAnimation mr-1"></span> Please
														Wait...
													</button>
												</div>) : (
												<div className="w-100">
													<button class="btn-blue btn-login d-block float-right w-auto"
														onClick={() => { handleNext("showLoader"), [2, 4]?.includes(screen) && setShowLoader(true) }} disabled={[3, 5, 6]?.includes(screen) || (selectedOption === "recommended" && screen === 2) ||
															(getAllCourses(location?.state?.myPlan?.dimensions)?.length === 1) || (screen == 4 && getSkillId?.length === 0)}>Next
														<span>
															<i className="fa-solid fa-arrow-right m-0 ml-2"></i>
														</span>
													</button>
												</div>
											)}
									</>
								}
							</div>
						</div>
					</div>
					<div className="RightbarPannel p-0 rightpannelSticky newcoursecardpanel home_page_rgt_pnl setuptwo_rPnl">
						<LearnerPlanRightPanel
							screen={screen}
							selectedSitting={selectedSitting}
							selectedMinutes={selectedMinutes}
							dimensionTree={dimensionTree}
							userDate={userDate}
							selectedOption={selectedOption}
							handleMoreOptions={handleMoreOptions}
							coursesArr={coursesArr}
							getJourneyId={getJourneyId}
							pathwayTree={pathwayTree}
							handleRemoveTagDimSkillCourse={handleRemoveTagDimSkillCourse}
							dimSkillCoursePlanTree={dimSkillCoursePlanTree}
							getCourseId={getCourseId}
							handleRemoveDimensionSkillCourse={handleRemoveDimensionSkillCourse}
							handlePopUpOpen={handlePopUpOpen}
							getDImSkillData={getDImSkillData}
							showPathwayShimmar={showPathwayShimmar}
							handleNumberOfCourses={handleNumberOfCourses}
							setCoursePlanTree={setCoursePlanTree}
							coursePlanTree={coursePlanTree}
							editPlan={editPlan}
							setEditPlan={setEditPlan}
							progressCourseDisabled={progressCourseDisabled}
							setMyEditPlan={setMyEditPlan}
							getMyEditPlan={getMyEditPlan}
							setMyPlanCourses={setMyPlanCourses}
							setScreen={setScreen}
							timezoneValue={timezoneObj[selectedTimeZone]?.value}
							selctedDays={selctedDays}
							getFlexibLeDays={getFlexibLeDays}
							setSeleccted={setSeleccted}
							setDeletePlan={setDeletePlan}
							getValues={getValues}
							setError={setError}
							setShowEnrollPlan={setShowEnrollPlan}
							showEnrollPlan={showEnrollPlan}
							setValue={setValue}
							setSelectedTab={setSelectedTab}
						/>
					</div>
					{console.log("modalData?.showDeletePlnPop : ", modalData)}
					{deletePlan && (
						<DeletePlanActivities
							closePlan={closePlan}
							deletePlan={deletePlan}
							handleRemoveTagDimSkillCourse={handleRemoveTagDimSkillCourse}
							handleRemoveDimensionSkillCourse={handleRemoveDimensionSkillCourse}
							handleBack={handleBack}
							handleMoreOptions={handleMoreOptions}
						/>
					)}
					{
						modalData?.type === "pathwayHalfScreen" && <PathwayHalfScreen courseDetailPage={courseDetailPage} />
					}
				</div>
			</div>
		</Home>
	);
};

export default LearnerPlan;
