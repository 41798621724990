import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import { addDayNameInDate, formatDate, getDatesBetween, getMonthAndDayByDate, getProfileName, getSequnceSort, getUrlSegment, getUsStatnderdTime, nameSorting, showRoundValue, textTrim } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { MSG } from "../../utils/Messages";
import RoundProgress from "../controls/RoundProgress";
import { dimView, getDashboard } from "../../redux/actions/Home";
import { defaultChild, resetResponse, showModal } from "../../redux/actions";
import { PATHS } from "../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactTooltip from "react-tooltip";

const BurgerTab = ({ handleReports, reportType }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loggedInUser, defaultChildData } = useSelector(
        (state) => state.collections
    );
    const handleDashBoard = (obj) => {
        if (getUrlSegment()[0] === "home") {
            return;
        }
        dispatch(dimView());
        dispatch(showModal());
        dispatch(getDashboard());
        dispatch(defaultChild(defaultChildData));
        dispatch(getDashboard(defaultChildData?.id));
        dispatch(resetResponse());
        history.push({
            pathname: PATHS.HOME,
        });
    };
    return (
        <>
            <div className="tabgrid w-100 m-0 schoolstutabingthree ">

                <ul>
                    {(
                        <li className={`${getUrlSegment()[0] === "home" ? "active-tabs" : ""}`} onClick={() => handleDashBoard()}>
                            <span className="flexone"> <img src={image.viewReport} className='mr-2' alt="" />
                                Dashboard
                            </span>
                        </li>
                    )}

                    <li className={`${reportType == "OverallProgress" ? "active-tabs" : ""}`} onClick={() => handleReports("OverallProgress")}>
                        <span className={`flexone`} > <img src={image.student_ProfileIcons} className='mr-2' alt="" />
                            Student Progress
                        </span>
                    </li>
                    <li className={`${reportType == "GrowthSurvey" ? "active-tabs" : ""}`} onClick={() => handleReports("GrowthSurvey")}>
                        <span className={`flexone`}> <img src={image.growthSurveyIcon} className='mr-2' alt="" />
                            Growth Survey
                        </span>
                    </li>
                    {/*   <li className={`${reportType == "PlanSummary" ? "active-tabs" : ""}`} onClick={() => handleReports("PlanSummary")}>
                        <span className={`flexone`}> <img src={image.PlanSummaryIcon} className='mr-2' alt="" />
                            Advancement Summary
                          <React.Fragment>
                                           
                                                <ReactTooltip id={"AdvancementSummary"}>
                                                    <p>{"Advancement Summary"}</p>
                                                </ReactTooltip>
                                            <div className="courseName" data-for={"AdvancementSummary"} data-event-off="" data-tip>
                                                {textTrim("Advancement Summary", 13)}
                                            </div>

                        </span>
						</React.Fragment>  
                    </li>*/}
                    <li className={`learner_pLan_dropdown
                    ${reportType == "PlanSummary" ? "active-tabs" : ""}`}>
                        <span className={`flexone`}  
                         aria-expanded="false"
						 id="more_tabbing"
						 data-toggle="dropdown">
                         <span className="flexone">
						 <img src={image.moreitems} className='mr-2' alt="" />
                           More
                           </span>
                           <i class="fa-regular fa-chevron-down ml-3"></i>
                        </span>
                           <ul className="dropdown-menu dropdown-menu-end lPDropdwnList moteitem_list" aria-labelledby="more_tabbing">
                              <li className={`w-100 ${reportType == "PlanSummary" ? "active-tabs" : ""}`} onClick={() => handleReports("PlanSummary")}>
                                <span className={`flexone`}> 
                                   
                                <React.Fragment>
                                          <ReactTooltip id={"AdvancementSummary"}>
                                               <p>{"Advancement Summary"}</p>
                                           </ReactTooltip>
                                       <span className="courseName" data-for={"AdvancementSummary"} data-event-off="" data-tip>
                                       <img src={image.PlanSummaryIcon} className='mr-2' alt="" />
                                           {textTrim("Advancement Summary", 15)}
                                       </span>

                   
                                     </React.Fragment>  
                                   {/* Advancement Summary */}
                                </span>
                              </li>
                        </ul>
                    </li>

                </ul>

            </div>
        </>
    );
}
export default BurgerTab;