import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import { useDispatch, useSelector } from "react-redux";
import {
	addDayNameInDate,
	getDatesBetween,
	getInProgressCourse,
	getSequnceSort,
	getUsStatnderdTime,
} from "../../utils/helper";
import ReactTooltip from "react-tooltip";

const CalenderView = ({ closePopup }) => {
	const { modalData, loggedInUser } = useSelector((data) => data?.collections);

	const [calenderData, setCalenderData] = useState([]);
	const weekday = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
	useEffect(() => {
		let dateArr = [];
		let weekArr = [];
		if (modalData?.data) {
			getSequnceSort(modalData?.data)?.map((course, key) => {
				getSequnceSort(course?.activities)?.map((module, index) => {
					let modSequence = index + 1;
					let getAllDates = getDatesBetween(
						module,
						modSequence,
						course,
						modalData?.getUserTimeZone,
						modalData?.dayOfWeek
					);
					dateArr?.push(...getAllDates);
				});
			});
			if (dateArr?.length > 0 && modalData?.holidays?.length > 0 && false) {
				dateArr?.map((date, index) => {
					let addNewDay = handleHoliday(date, index, modalData?.dayOfWeek);
					let obj = {};
					if (addNewDay > 0) {
						let currentDate = new Date(
							dateArr[dateArr?.length - 1]?.currentDate
						);
						for (let i = 1; i <= addNewDay; i++) {
							currentDate.setDate(currentDate.getDate() + 1);
							let weekDayName = new Date(currentDate).toLocaleString("en-us", {
								weekday: "short",
							});
							if (modalData?.dayOfWeek?.includes(weekDayName)) {
								obj = {
									date: new Date(currentDate).toLocaleString("en-us", {
										month: "short",
										day: "numeric",
									}),
									courseName: date?.courseName,
									module: index,
									sittings: date?.sittings,
									day: weekDayName,
									duration: date?.duration,
									moduleKey: 0,
									currentDate: currentDate,
								};
							} else {
								obj = {
									date: new Date(currentDate).toLocaleString("en-us", {
										month: "short",
										day: "numeric",
									}),
									day: weekDayName,
									module: index,
									currentDate: currentDate,
								};
							}
							dateArr?.push(obj);
						}
					}
				});
			}
			if (dateArr?.length > 0) {
				let weekCount = 0;
				let weekWiseData = [];
				const weekKey = weekday?.findIndex((item) => item === dateArr[0]?.day);
				if (weekKey !== -1 && weekKey !== 0) {
					let startdate = new Date(
						getUsStatnderdTime(
							modalData?.data[0]?.startDate,
							modalData?.getUserTimeZone
						)
					);
					startdate?.setDate(startdate.getDate() - weekKey);
					for (let i = 0; i < weekKey; i++) {
						let wkObj = {
							date: startdate.toLocaleString("en-us", {
								month: "short",
								day: "numeric",
							}),
						};
						weekWiseData?.push(wkObj);
						startdate?.setDate(startdate.getDate() + 1);
					}
				}
				let showModule = 1;
				let count = 1;
				let corIndex = 0;
				dateArr?.map((date, index) => {
					const holidayKey = modalData?.holidays?.findIndex(
						(myDate) => myDate?.date === date?.currentDate
					);
					if (holidayKey !== -1) {
						date.sittings = "";
						date.courseName = "";
						date.duration = "";
						date.holidayname = modalData?.holidays[holidayKey]?.name;
						date.moduleName = "";
					}
					if (date?.sittings) {
						date.courseName = modalData?.data[corIndex]?.name;
						date.sittings = modalData?.data[corIndex]?.sittings;
						date.duration = modalData?.data[corIndex]?.duration;
						date.moduleName =
							modalData?.data[corIndex]?.activities[count - 1]?.name;
						date.isModuleProgress = modalData?.data[corIndex]?.activities[count - 1]?.isProgress;
						date.isModuleCompleted = modalData?.data[corIndex]?.activities[count - 1]?.isCompleted;
						date.courseKey = corIndex + 1;
						if (date?.duration == 15) {
							date.moduleKey = count;
							if (showModule === 4) {
								count++;
								showModule = 0;
							}
						} else if (date?.duration == 30 || date?.duration == 45) {
							date.moduleKey = count;
							if (showModule === 2) {
								count++;
								showModule = 0;
							}
						} else if (date?.duration == 60) {
							console.log("date?.sittings 222 : ", date);
							date.moduleKey = count;
							count++;
							showModule = 0;
						}
						if (count === 5) {
							count = 1;
							corIndex++;
						}
						showModule++;
					}
					weekWiseData?.push(date);
					if (weekWiseData?.length % 7 === 0) {
						weekArr?.push(weekWiseData);
						weekWiseData = [];
						weekCount++;
					}
					if (index + 1 === dateArr?.length && weekWiseData?.length % 7 !== 0) {
						let lasttdate = new Date(dateArr[dateArr?.length - 1]?.currentDate);
						lasttdate?.setDate(lasttdate.getDate() + 1);
						for (let i = weekWiseData?.length; i < 7; i++) {
							let wkObj = {
								date: lasttdate.toLocaleString("en-us", {
									month: "short",
									day: "numeric",
								}),
							};
							weekWiseData?.push(wkObj);
							lasttdate?.setDate(lasttdate.getDate() + 1);
						}
						weekArr[weekCount] = weekWiseData;
					}
				});
				//	setCalenderData(weekArr);
				let calenderSesonsData = weekArr?.filter((week) => week?.find((item) => item?.courseName || item?.holidayname));
				setCalenderData(calenderSesonsData);
			}
		}
	}, [modalData]);

	const handleHoliday = (dayObj, index, dayOfWeek) => {
		let currentDate = dayObj?.currentDate;
		let weekDayName = new Date(currentDate).toLocaleString("en-us", {
			weekday: "short",
		});
		let addNewDay = 0;
		if (
			modalData?.holidays?.find((myDate) => myDate?.date === currentDate) &&
			dayOfWeek?.includes(weekDayName)
		) {
			let firstSeetingKey = weekday?.findIndex((day) => day === weekDayName);
			console.log("firstSeetingKey : ", firstSeetingKey, dayOfWeek);
			let count = 0;
			let secondSittnigKey = -1;
			weekday?.map((day, index) => {
				if (
					index > firstSeetingKey &&
					dayOfWeek?.includes(day) &&
					secondSittnigKey === -1
				) {
					secondSittnigKey = index;
				}
			});

			if (secondSittnigKey !== -1) {
				addNewDay = secondSittnigKey - firstSeetingKey;
			} else {
				weekday?.map((day, index) => {
					if (dayOfWeek?.includes(day) && secondSittnigKey === -1) {
						secondSittnigKey = index;
					}
				});
				addNewDay = 6 - firstSeetingKey + secondSittnigKey + 1;
			}
		}
		return addNewDay;
	};

	useEffect(() => {
		const inProgressData = getInProgressCourse(calenderData);
	
		if (inProgressData) {
		  const currentDateElement = document.querySelector(
			`[data-current-date="${inProgressData.currentDate}"]`
		  );
	
		  if (currentDateElement) {
			currentDateElement.scrollIntoView({
			  behavior: "smooth",
			  block: "center",
			});
		  }
		}
	  }, [calenderData]);

	return (
		<div className="halfPagePOpup coursedetailpopuppage">
			<div className="modal d-flex" id="schoolactivity178" aria-hidden="true">
				<div
					className={`modal-dialog modal-lg ${["PARENT", "LEARNER"]?.includes(loggedInUser?.role?.name)
						? "calenderViewpHaifScreenPopUpConsumer"
						: "calenderViewpHaifScreenPopUpforTeacher"
						}`}
				>
					<div className="modal-content w-100 max-width-100">
						<div className="modal-header">
							<div className="heading border-0 p-0">
								<h2 className="flex">
									<span>
										<img src={image.calenderIcon1} alt="..." className="mr-2" />
										Plan Schedule
									</span>
									<div className="d-flex">
										<div className="pe-3">
											<a href="#">
												<img
													src={image.printIcon}
													alt=""
													style={{ maxWidth: "25px" }}
													className="me-2"
												/>
											</a>
										</div>
										<button
											data-dismiss="modal"
											className="btn btn-primary"
											onClick={() => closePopup()}
										>
											<i className="fa-regular fa-xmark m-0"></i>
										</button>
									</div>
								</h2>
							</div>
						</div>

						<div className="modal-body p-0">
							<div id="planCalendarView">
								<table>
									<thead>
										<tr>
											<th>Week</th>
											<th>Mon</th>
											<th>Tue</th>
											<th>Wed</th>
											<th>Thu</th>
											<th>Fri</th>
											{["PARENT", "LEARNER"]?.includes(
												loggedInUser?.role?.name
											) && (
													<>
														<th>Sat</th>
														<th>Sun</th>
													</>
												)}
										</tr>
									</thead>
									<tbody>
										{calenderData?.map((weeks, index) => (
											<tr key={index}>
												<td class={index % 2 === 0 ? "bg-color1" : "bg-color2"}>
													<p className="planDates">
														<i
															class="fa fa-calendar-o calenderIcon"
															aria-hidden="true"
														></i>
														{weeks[0]?.date} - {weeks[6]?.date}
													</p>
													<p className="week">Week {index + 1}</p>
												</td>

												{weeks?.map(
													(days, key) =>
														(["PARENT", "LEARNER"]?.includes(
															loggedInUser?.role?.name
														) ||
															(["TEACHER", "STUDENT"]?.includes(
																loggedInUser?.role?.name
															) &&
																key < 5)) && (
															<td
															data-current-date={days?.currentDate}
																class={
																	days?.holidayname
																		? "holidays"
																		: new Date(
																			days?.currentDate
																		).toDateString() ===
																			new Date().toDateString()
																			? "current"
																			: "bg-color1"
																}
																key={key}
															>
																<div className="d-flex align-items-baseline justify-content-between">
																	<p className="planDates">
																		<i
																			class="fa fa-calendar-o calenderIcon"
																			aria-hidden="true"
																		></i>
																		{days?.date}
																	</p>

																	{days?.isModuleProgress && days?.sittings && (
																		<img src={image.InProgressIcon} className="statusImg" />
																	)}
																	{days?.isModuleCompleted && days?.sittings && (
																		<img src={image.completedIcon} className="statusImg" />
																	)}
																</div>
																{days?.holidayname ? (
																	<div className="d-flex pt-2 align-items-center">
																		<p
																			className="courseName"
																			data-tip
																			data-for={`holiday-tooltip-${index}-${key}`}
																		>
																			{days?.holidayname}
																		</p>
																		{days?.holidayname?.length > 17 && (
																			<ReactTooltip
																				id={`holiday-tooltip-${index}-${key}`}
																				place="top"
																				className="tooltip text-center"
																			>
																				<p>{days?.holidayname}</p>
																			</ReactTooltip>
																		)}
																	</div>
																) : (
																	days?.courseName && (
																		<>
																			<div className="d-flex pt-2 align-items-center">
																				<span className="courseCircle me-1">
																					C{days.courseKey}
																				</span>
																				<p
																					className="courseName"
																					data-tip
																					data-for={`course-tooltip-${index}-${key}`}
																				>
																					{days?.courseName}
																				</p>
																				{days?.courseName?.length > 17 && (
																					<ReactTooltip
																						id={`course-tooltip-${index}-${key}`}
																						place="top"
																						className="tooltip text-center"
																					>
																						<p>{days?.courseName}</p>
																					</ReactTooltip>
																				)}
																			</div>

																			<div className="d-flex pt-2 align-items-center Module_Name_cal">
																				<span className="moduleCircle me-1">
																					M{days?.moduleKey}
																				</span>
																				<p
																					className="moduleName"
																					data-tip
																					data-for={`module-tooltip-${index}-${key}`}
																				>
																					{days?.moduleName}
																				</p>
																				{days?.moduleName?.length > 17 && (
																					<ReactTooltip
																						id={`module-tooltip-${index}-${key}`}
																						place="top"
																						className="tooltip text-center mywork"
																					>
																						<p>{days?.moduleName}</p>
																					</ReactTooltip>
																				)}
																			</div>
																		</>
																	)
																)}
															</td>
														)
												)}
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
						<div className="modal-footer">
							<div className="input-group full-Width-group basic_details_form flex m-0">
								<div className="form-group BDsubmitbutton d-flex m-0">
									<button
										type="submit"
										className="btn-blue btn-login d-block mb-5 m-0 ml-auto cancelbutton"
										onClick={() => closePopup()}
									>
										<i className="fa-solid fa-xmark mr-2"></i>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default CalenderView;
