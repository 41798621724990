import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { myJournal, getSocialActivityDetailForMyJournal } from "../../../../redux/actions/APIs";

const MyJournal = ({ onBackClick, textAreaContent, setTextAreaContent }) => {
	const {
		socialActivityData,
		my_journal_response,
		defaultChildData,
		socialActivityDataForMyJournal,
	} = useSelector((state) => state.collections);
	const dispatch = useDispatch();
	//	const [textAreaContent, setTextAreaContent] = useState("");
	const [isTyping, setIsTyping] = useState(false);
	let timeoutId;

	const handleChange = (value) => {
		setTextAreaContent(value);
		setIsTyping(true);
	};
	// console.log('Textarea Content my_journal_response  : 5', my_journal_response?.records[0])
	useEffect(() => {

		if (socialActivityData?.records[0]?.uaId === null) {
			dispatch(getSocialActivityDetailForMyJournal(defaultChildData.id, socialActivityData.records[0].activityId));
		}
		// console.log('Textarea Content my_journal_response useEffect 1', socialActivityData)
		if (socialActivityData != undefined) {
			// console.log('@@@@ Step 2 if')
			// console.log('Textarea Content my_journal_response useEffect 2 : ', socialActivityData?.records[0]?.myjournal)
			setTextAreaContent(socialActivityData?.records[0]?.myjournal)
			// console.log('Textarea Content my_journal_response useEffect 3 : ', socialActivityData?.records[0]?.myjournal)
		}
	}, []);

	// console.log('socialActivityDataForMyJournal' , socialActivityDataForMyJournal?.records[0]?.uaId)

	useEffect(() => {
		// console.log('@@@@ Step 3')
		if (my_journal_response != undefined) {
			// console.log('@@@@ Step 3 if')
			// console.log('Textarea Content my_journal_response  : 5', my_journal_response?.records[0])
			setTextAreaContent(my_journal_response?.records[0])
		}
	}, [my_journal_response]);

	useEffect(() => {
		if (isTyping && (socialActivityData?.records[0]?.uaId || socialActivityDataForMyJournal?.records[0]?.uaId)) {
			// console.log('@@@@ Step 4 if')
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				saveToJornual();
				setIsTyping(false);
			}, 3000);
		}
		return () => clearTimeout(timeoutId);
	}, [textAreaContent, isTyping]);

	const handleBackClick = () => {
		saveToJornual();
		onBackClick();
	};

	const saveToJornual = () => {
		const uaId = socialActivityData?.records[0]?.uaId || socialActivityDataForMyJournal?.records[0]?.uaId;
		const reqBody = {
			userActivityId: uaId,
			desc: textAreaContent
		};

		if (uaId) {
			// console.log('socialActivityDataForMyJournal Step 5 if' , uaId)
			dispatch(myJournal(reqBody));
		} else {
			// console.log('@@@@ Step 5 else')
			console.error('User activity id is not defind');
		}
	}

	return (
		<div>
			{/* {console.log('Textarea Content my_journal_response useEffect 4 : ', textAreaContent)} */}
			<div className="p-2 textAreatypeQuestion">
				<div className="pl-0">
					<textarea
						name=""
						id=""
						placeholder="What did you like or dislike about this course? What did you learn? What more or different did you expect from this course?"
						className="form-control myJournalTextArea"
						onChange={(e) => handleChange(e.target.value)}
						defaultValue={my_journal_response?.records[0] ? my_journal_response?.records[0] : socialActivityData?.records[0]?.myjournal}
					>
					</textarea>
				</div>
			</div>

			{/* <div className="input-group full-Width-group pt-0 continuebtn">
				<div className="form-group BDsubmitbutton m-0">
					<button
						type="submit"
						className="btn-blue btn-login d-block mb-5 ml-auto"
						onClick={handleBackClick}
					>
						<i className="fa-solid fa-arrow-left mr-2 m-0"></i>
						Back
					</button>
				</div>
			</div> */}
		</div>
	);
}

export default MyJournal;