import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerCategoryList } from "react-shimmer-effects";
import * as image from "../../../../resources/images";
import { Intelligences } from "../../../../utils/DataObjects";
import Vicky from "../../../controls/Vicky";
import { getUrlSegment, getSequnceSort } from "../../../../utils/helper";
import ReadMore from "../../../controls/ReadMore";
import { attemptedAcquisitionMultiSurvey } from "../../../../redux/actions/APIs";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const SmartQuizRightSidebar = ({ finalArray }) => {
  const [dataLoad, setDataLoad] = useState(false);
  const [quizData, setQuizData] = useState({});
  const [scSurvey, setScSurvey] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Survey Feedback");
  const [scSurveyId, setScSurveyId] = useState();
  const [loader, setLoader] = useState(false);
  const {
    multiQuizData,
    beginQuiz,
    smartQuizSidebarData,
    acquisitionMultiQuizData,
    userAcquisitionAttemptedResponse
  } = useSelector((state) => state.collections);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (multiQuizData && multiQuizData.records[0]?.multiintel) {
      setQuizData(multiQuizData.records[0]?.questions);
      setDataLoad(false);
    }
  }, [multiQuizData]);

  useEffect(() => {
    setScSurvey(false);
    // setSelectedTab('Survey Feedback')
    if (
      acquisitionMultiQuizData &&
      acquisitionMultiQuizData?.records[0]?.multiintel.length > 0
    ) {
      setScSurvey(true);
    }
    if (acquisitionMultiQuizData) {
      let scSurvey = acquisitionMultiQuizData?.records[0]?.questions;
      setScSurveyId(scSurvey[0]?.scSurveyId);
    }
  }, [acquisitionMultiQuizData]);

  const handleSurveyFeedback = (tabName) => {
    if (tabName === "Survey Feedback") {
      setSelectedTab("Survey Feedback");
    } else if ("preview") {
      setSelectedTab("preview");
    }
  };

  const handleSubmit = () => {
    console.log("final Arry right handleSubmit: ", finalArray, scSurveyId);
    setLoader(true);
    if (scSurveyId) {
      dispatch(attemptedAcquisitionMultiSurvey(
        params.id,
        scSurveyId,
        finalArray
      )
      );
    }

  };

  useEffect(() => {
    if (userAcquisitionAttemptedResponse?.success) {
      setLoader(false);
      setScSurvey(true);
    }
  }, [userAcquisitionAttemptedResponse]);

  return (
    <>
      {dataLoad ? (
        <ShimmerCategoryList items={5} categoryStyle="STYLE_SIX" />
      ) : (
        <>
          <div className={`${!scSurvey ? "heading d-flex" : ""}`}>
            {
              <h2
                className={`${!scSurvey
                  ? "flex w-100"
                  : "m-0 pt-0 pb-1 w-100 flex justify-content-between"
                  }`}
              >
                {getUrlSegment()[1] === "skillsacquisition" ? (
                  <>
                    {!scSurvey ? (
                      <span>
                        {" "}
                        <img
                          src={image.acquisitionsurvey}
                          className="mr-2"
                          alt=""
                        />
                        {!beginQuiz
                          ? "Survey Introduction"
                          : "Skill Acquisition Survey Preview"}
                      </span>
                    ) : (
                      <div className="tabgrid w-100 m-0">
                        <ul>
                          <li
                            class={`tabs flexone ${selectedTab === "Survey Feedback"
                              ? "active-tabs"
                              : ""
                              } w-50`}
                            onClick={() =>
                              handleSurveyFeedback("Survey Feedback")
                            }
                          >
                            <img src={image.acquisitionsurvey} alt="" />
                            Survey Feedback
                          </li>
                          <li
                            class={`tabs flexone ${selectedTab === "preview" ? "active-tabs" : ""
                              } w-50`}
                            onClick={() => handleSurveyFeedback("preview")}
                          >
                            <img src={image.preview} alt="" />
                            Preview
                          </li>
                        </ul>
                      </div>
                    )}
                  </>
                ) : (
                  <span>
                    <img
                      src={image.multipleintellQuiz}
                      className="mr-2"
                      alt=""
                    />
                    {!beginQuiz
                      ? "Multiple Intelligences"
                      : "Multiple Intelligences Preview"}
                  </span>
                )}
              </h2>
            }
          </div>

          {beginQuiz && (
            <>
              <div className=" p-0 allcourselj CourseCardWrapper fullHeight100 ">
                <div className="growthsurveyPreview p-0">
                  <div className="p-2 mt-2 mb-2">
                    {smartQuizSidebarData?.attemptedQuestions.map(
                      (attempted, index) => (
                        <div
                          key={index}
                          className={`pb-3 d-flex flex-column pl-2 ${quizData ? "pointer" : "pointer"
                            }`}
                          onClick={() =>
                            smartQuizSidebarData?.onQuestionClick(index)
                          }
                        >
                          <span className="question d-flex">
                            <i
                              className="fa fa-hand-o-right me-1 pt-2"
                              aria-hidden="true"
                            ></i>
                            <ReadMore limit={150} height={10}>
                              {attempted.question}
                            </ReadMore>
                          </span>
                          {attempted.options.map(
                            (val, key) =>
                              val.isUserSelected && (
                                <span className="answer" key={key}>
                                  {val.question}
                                </span>
                              )
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
                <div class="form-group BDsubmitbutton flex m-0">
                  <div class="w-100">
                    {
                      (loader ? (
                        <div className="priceWrap p-0">
                          <button
                            className="btn-blue btn-login d-block w-auto mb-5 ml-auto"
                            key={Math.random()}
                            disabled
                          >
                            <span className="RounAnimation mr-1"></span>
                            Please wait
                          </button>
                        </div>
                      ) : (
                        <div className="priceWrap p-0">
                          <button
                            type="submit"
                            className="btn-blue btn-login d-block w-auto mb-5 ml-auto"
                            onClick={() => {
                              handleSubmit();
                            }}
                            disabled={
                              smartQuizSidebarData?.attemptedQuestions?.length !== acquisitionMultiQuizData?.recordsCount ||
                              !(smartQuizSidebarData?.attemptedQuestions[acquisitionMultiQuizData?.recordsCount - 1]
                                ?.options.some(
                                  (option) => option.isUserSelected
                                ))
                            }
                          >
                            <i className="fa-solid fa-paper-plane mr-2"></i>
                            Submit
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}

          {!beginQuiz && getUrlSegment()[1] === "skillsacquisition" && (
            <>
              <div
                class={`${selectedTab === "preview" ? "" : "LessionDtlOverview"
                  } CourseCardWrapper fullHeight100 pb-0 `}
              >
                {!scSurvey ? (
                  <>
                    <div className=" CourseCardWrapper fullHeight100">
                      <div class="d-flex align-items-start flex-wrap">
                        <div class="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar">
                          <p>
                            <div>
                              <p>
                                Tests can be uncomfortable for people because they
                                might judge themselves on their score. This test is
                                different. It does not assess as right or wrong. It
                                simply helps people learn where they have acquired
                                certain skills related to the skills being
                                considered.
                              </p>
                              <p>&nbsp;</p>
                              <p>
                                You might find you are completely familiar with one,
                                more, all, or none of the skills included. Please
                                answer honestly, as the goal is to determine your
                                current level of comfort with that skill.
                              </p>
                              <p>&nbsp;</p>
                              <p>
                                There are five (5) questions for each skill covered
                                in the plan. Please go with your first instinct, and
                                don’t overthink your answers.
                              </p>
                              <p>&nbsp;</p>
                              <p>
                                Please click “Begin Survey” when you are ready. Have
                                fun and let’s see what you make of this!
                              </p>
                            </div>
                          </p>{" "}
                          <span class="AudioIcon ">
                            <img src={image.Audioicon} alt="icon" class="pointer" />
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip "><div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 "><div class="w-100"></div></div></div> */}

                  </>
                ) : (
                  <>
                    {selectedTab === "Survey Feedback" ? (
                      <div class="d-flex align-items-start flex-wrap">
                        <div class="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar">
                          <p>
                            <div>
                              <p>Were you surprised at your results?</p>
                              <p>&nbsp;</p>
                              <p>
                                Please remember there are not necessarily right or
                                wrong answers, but those that help clarify your
                                level of familiarity and comfort with certain
                                skill sets. You might decide there are areas you
                                want to develop further.
                              </p>
                              <p>&nbsp;</p>
                              {acquisitionMultiQuizData?.records[0]?.multiintel?.map(
                                (val, key) => (
                                  <>
                                    <p>
                                      <strong> {val.skillName}</strong>
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>
                                      {val.result < 71
                                        ? "This is a good beginning."
                                        : "You are off to a great start!"}{" "}
                                      You are currently at {val.result}% total
                                      acquisition of {val.skillName}.
                                    </p>

                                    <p>&nbsp;</p>
                                    <p>Here is what your score means:</p>
                                    <p>&nbsp;</p>
                                    {/* <p>
                      {"Insert text below dynamically based on the percentage range it correlates to"} {"see highlighted text box for dynamic content ->"}

                      </p> */}

                                    {val.result < 71 ? (
                                      <>
                                        <p>
                                          You are at a beginning to intermediate
                                          level. You are most likely brand new to
                                          this skill or have very limited
                                          experience with it. It is completely
                                          understandable to score in this zone
                                          because you may not have needed this
                                          skill at this point in your life. The
                                          best news is – you are about to advance
                                          your knowledge and real-world practice
                                          so you will be confident in your ability
                                          when you need to use this skill! We
                                          offer specific courses to assist with
                                          this skill acquisition so you will be
                                          better prepared for when you need it!
                                        </p>
                                        <p>&nbsp;</p>
                                      </>
                                    ) : (
                                      <>
                                        <p>
                                          You are at an advanced intermediate
                                          level. You know what this skill is and
                                          have experience using it. You would
                                          benefit most from practicing with this
                                          skill and introducing advanced concepts.
                                          It is completely understandable to score
                                          in this zone because you may not have
                                          needed to use this skill on a regular
                                          basis at this point in your life. The
                                          best news is – In addition to our
                                          introductory courses we offer additional
                                          courses for this skill acquisition and
                                          advanced courses on the course journey,
                                          as well!
                                        </p>
                                        <p>&nbsp;</p>
                                      </>
                                    )}
                                  </>
                                )
                              )}
                              <p>
                                Remember, this type of skill acquisition is
                                different for everyone depending upon our personal
                                experiences so no matter your results, you should
                                be proud!
                              </p>
                            </div>
                          </p>{" "}
                          {/* <span class="AudioIcon ">
                          <img
                            src={image.Audioicon}
                            alt="icon"
                            class="pointer"
                          />
                        </span> */}
                          {/* <Vicky
                            text={" Please remember there are not necessarily right or wrong answers, but those that help clarify your  level of familiarity and comfort with certain."}
                          /> */}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="ScenecerelateddQuiz p-0 allcourselj">
                          <div className="growthsurveyPreview p-0">
                            <div className="p-2 mt-2 mb-2">
                              {acquisitionMultiQuizData?.records[0]?.questions?.sort((a, b) => {
                                if (a.surveySequence !== b.surveySequence) {
                                  return a.surveySequence - b.surveySequence;
                                }
                                return a.sequence - b.sequence;
                              })?.map(
                                (attempted, index) => (
                                  <div
                                    key={index}
                                    className={`pb-3 d-flex flex-column pl-2`}
                                    onClick={() =>
                                      smartQuizSidebarData?.onQuestionClick(index)
                                    }
                                  >
                                    <span className="question d-flex">
                                      <i
                                        className="fa fa-hand-o-right me-1 pt-2"
                                        aria-hidden="true"
                                      ></i>
                                      <ReadMore limit={150} height={10}>
                                        {attempted.question}
                                      </ReadMore>
                                    </span>
                                    {attempted?.options.map(
                                      (val, key) =>
                                        val?.isUserSelected && (
                                          <span className="answer d-flex" key={key}>
                                            <span>
                                              <img
                                                src={image.answerIcon}
                                                className="mr-2"
                                              />
                                            </span>
                                            {val?.question}
                                          </span>
                                        )
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip "><div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 "><div class="w-100"></div></div></div>
            </>
          )}

          {!beginQuiz &&
            Intelligences?.desc &&
            getUrlSegment()[1] !== "skillsacquisition" &&
            multiQuizData?.records[0]?.multiintel &&
            multiQuizData?.records[0]?.multiintel?.length > 0 && (
              <>
                <div className="CourseCardWrapper fullHeight100">
                  <div className="LessionDtlOverview">
                    <div className="d-flex align-items-start flex-wrap">
                      <div className="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar">
                        <p
                          dangerouslySetInnerHTML={{ __html: Intelligences.desc }}
                        />
                        <Vicky text={Intelligences.desc} />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip "><div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 "><div class="w-100"></div></div></div>
              </>

            )}

          {!beginQuiz &&
            Intelligences?.desc &&
            getUrlSegment()[1] !== "skillsacquisition" &&
            multiQuizData?.records[0]?.multiintel?.length === 0 && (
              <>
                <div className=" CourseCardWrapper fullHeight100">
                  <div className="LessionDtlOverview">
                    <div className="d-flex align-items-start flex-wrap">
                      <div className="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar">

                        <p
                          dangerouslySetInnerHTML={{
                            __html: Intelligences.testDesc,
                          }}
                        />
                        <Vicky text={Intelligences.testDesc} />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip "><div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 "><div class="w-100"></div></div></div>
              </>

            )}
        </>
      )}
    </>
  );
};

export default SmartQuizRightSidebar;
