import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
	ShimmerPostItem
} from "react-shimmer-effects";
import ReactTooltip from "react-tooltip";
import {
	activePlanIndexNo,
	changePace,
	resetLearnerPlan,
	setClassSchedule,
	setCourseModal,
	showModal,
	showModalObj
} from "../../redux/actions";
import {
	getAllUsersDetail,
	getDimensionPlanData,
	getDimensionUpdatePlan,
	getJourny,
	getJournyCourses,
	getLearnerPlanTags,
	getMyPlan,
	getStartEnrollCourses,
	getUpdateLearnerPlan,
	updateLearnerPlan
} from "../../redux/actions/APIs";
import {
	getCourseDetails
} from "../../redux/actions/Home";
import * as image from "../../resources/images";
import { PATHS } from "../../utils";
import {
	addDayNameInDate,
	convertedNumber,
	dateSortingByStartDate,
	getCapitalized,
	getCourseEndDate,
	getDateByAddDays,
	getSequnceSort,
	getUrlSegment,
	getUsStatnderdTime,
	handleSeatingsDate,
	textTrim
} from "../../utils/helper";
import CourseProgresComponent from "../home/CourseProgresComponent";

const LearnerPlanRightPanel = ({ screen,
	selectedSitting,
	selectedMinutes,
	dimensionTree,
	userDate,
	selectedOption,
	handleMoreOptions,
	pathwayTree,
	coursesArr,
	getJourneyId,
	handleRemoveTagDimSkillCourse,
	dimSkillCoursePlanTree,
	getCourseId,
	handleRemoveDimensionSkillCourse,
	handlePopUpOpen,
	getDImSkillData,
	showPathwayShimmar,
	handleNumberOfCourses,
	editPlan,
	setEditPlan,
	setCoursePlanTree,
	coursePlanTree,
	progressCourseDisabled,
	setMyEditPlan,
	getMyEditPlan,
	setMyPlanCourses,
	gotoSelectedPlan,
	setScreen,
	timezoneValue,
	getDataFromCourseProgress,
	showPlanCourseData,
	selctedDays,
	getFlexibLeDays,
	setSeleccted,
	setDeletePlan,
	getValues,
	setError,
	setShowEnrollPlan,
	showEnrollPlan,
	setValue,
	singlePlancourseId,
	redirectToHomeButton,
	selectedTab, setSelectedTab, myPlan
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams();
	const location = useLocation();
	const currentDate = new Date();

	const {
		defaultChildData,
		learnerPlanResponse,
		response,
		getDimJournyCoursesResponse,
		getNewPlanResponse,
		signupresponse,
		loggedInUser,
		getMyPlanData,
		modalObj,
		activePlanIndex,
		coursePageObj,
		cnamePageObj
	} = useSelector((state) => state.collections);
	const { dashboardData, showoverModal, courseDetails } = useSelector(
		(state) => state.home
	);

	const [totalSelectedCourse, setTotalSelectedCourse] = useState(0);
	const [loader, setLoader] = useState(false);
	const [totalLevel, setTotalLevel] = useState(5);
	const [learnerPlanData, setLearnerPlanData] = useState([]);
	const [refreshShimmar, setRefreshShimmar] = useState(false);
	//	const [myCoursePlan, setMyCoursePlan] = useState(dashboardData?.userPlans?.courses);
	const [showCoursePlan, setShowCoursePlan] = useState(false);
	const [getPlanIndex, setPlanIndex] = useState();
	const [getUserTimeZone, setUserTimeZone] = useState(loggedInUser?.timeZone ? loggedInUser?.timeZone : "US/Eastern");

	useEffect(() => {
		dispatch(activePlanIndexNo({ index: 0 }))
		if (([1, 2]?.includes(screen) && selectedOption === "recommended") || selectedOption === "home") {
			setUserTimeZone(loggedInUser?.timeZone ? loggedInUser?.timeZone : timezoneValue)
		}
	}, [screen, timezoneValue, loggedInUser])

	useEffect(() => {
		if (getUrlSegment()[0] !== "courses") {
			dispatch(getMyPlan(defaultChildData?.id));
			//	to get recommended plan
			dispatch(getUpdateLearnerPlan(defaultChildData?.id));
			setShowCoursePlan(false);
		}
	}, [defaultChildData?.id, location?.state])

	useEffect(() => {
		if (getMyPlanData && getMyPlanData?.records?.length > 0) {
			setShowCoursePlan(true);
		}
	}, [getMyPlanData])

	useEffect(() => {
		if (courseDetails) {
			courseDetails.isLearnerRightPanel = true;
			courseDetails.totalLevel = totalLevel;
			dispatch(setCourseModal(courseDetails));
		}
	}, [courseDetails]);
	useEffect(() => {
		if (dimensionTree) {
			let total = 0;
			dimensionTree?.map((dim, index) => {
				dim?.skills?.map((skill, key) => {
					skill?.courses?.map((course, count) => {
						total++;
					});
				});
			});
			setTotalSelectedCourse(total);
		}
	}, [dimensionTree]);

	const getLearnerName = () => {
		return getCapitalized(defaultChildData?.firstName);
	};

	const courseDetailPage = (obj) => {
		setTotalLevel(obj?.totalLevel);
		dispatch(getCourseDetails(obj?.id, defaultChildData.id));
	};

	const handleShowPlanModule = (index, courses, myPlan, type, planId) => {
		let myPlanCourses = [];
		let myPlanMode = ""
		if (type === "editPlan") {
			myPlanCourses = handleSelectedPlanCourses(myPlan?.dimensions, "getCourseSeq");
			myPlanMode = "Edit Plan"
		} else {
			myPlanCourses = getSequnceSort(myPlan);
			myPlanMode = "New Plan"
		}
		courses.index = index;
		courses.planMode = myPlanMode;
		let sittings = myPlan?.sittings ? myPlan?.sittings : selectedSitting;
		let duration = myPlan?.duration ? myPlan?.duration : selectedMinutes;
		dispatch(changePace({ type: "showModuleDetail", courseName: { ...courses }, myPlanCourses: myPlanCourses, planMode: myPlanMode, planId: planId, sittings: sittings, duration: duration, timeZoneValue: timezoneValue }));
	};

	const handleCalculateStartDateEndDate = (data, sitting, duration) => {
		let newArray = [];
		getSequnceSort(data)?.map((courses, index) => {
			if (true) {
				getSequnceSort(courses?.activities)?.map((module, key) => {
					let getDay = handleSeatingsDate(
						parseInt(sitting),
						parseInt(duration),
						index,
						key + 1
					);
					let currentDate = new Date();
					if (index === 0 && key === 0) {
						currentDate = userDate ? new Date(userDate) : new Date();
					} else if (index !== 0 && key === 0) {
						currentDate = getDateByAddDays(
							getSequnceSort(data[index - 1]?.activities)[3]?.endDate, 1
						);
					} else {
						currentDate = getDateByAddDays(
							courses?.activities[key - 1]?.endDate, 1
						);
					}
					module.startDate = new Date(currentDate).toUTCString().toLocaleString("en-us", {
						month: "short",
						year: "numeric",
						day: "numeric",
					});
					module.endDate = new Date(
						getDateByAddDays(module.startDate, getDay - 1)
					).toUTCString().toLocaleString("en-us", {
						month: "short",
						year: "numeric",
						day: "numeric",
					});
				});
				courses.startDate = courses?.activities[0]?.startDate;
				courses.endDate = courses?.activities[3]?.endDate;
				courses.sittings = sitting;
				courses.duration = duration;
			}
			newArray.push(courses);
		});
		setLearnerPlanData(newArray);
	};

	/*
	  useEffect(() => {
		if (
		  dashboardData &&
		  dashboardData?.userPlans &&
		  location?.state?.show !== "recommended"
		) {
		  if (dashboardData?.userPlans?.isrecommendedplan) {
			handleCalculateStartDateEndDate(
			  dashboardData?.userPlans?.courses,
			  2,
			  30
			);
		  } else {
			setLearnerPlanData(getSequnceSort(dashboardData?.userPlans?.courses));
		  }
		}
	  }, [dashboardData]);
	  */

	useEffect(() => {
		if (getNewPlanResponse) {
			handleCalculateStartDateEndDate(
				getNewPlanResponse?.records,
				selectedSitting,
				selectedMinutes
			);
			setRefreshShimmar(false);
			if (getNewPlanResponse?.records?.length > 0 && ["home", "recommended"]?.includes(selectedOption)
				&& ["PARENT", "LEARNER"]?.includes(loggedInUser?.role?.name)) {
				setValue("planName", getNewPlanResponse?.records[0]?.uiLabel)
			}
		}
	}, [getNewPlanResponse]);

	useEffect(() => {
		if (selectedMinutes && learnerPlanData?.length > 0) {
			handleCalculateStartDateEndDate(
				learnerPlanData,
				selectedSitting,
				selectedMinutes
			);
		}
	}, [selectedMinutes]);

	useEffect(() => {
		if (selectedSitting && learnerPlanData?.length > 0) {
			handleCalculateStartDateEndDate(
				learnerPlanData,
				selectedSitting,
				selectedMinutes
			);
		}
	}, [selectedSitting]);

	useEffect(() => {
		if (userDate && learnerPlanData?.length > 0) {
			handleCalculateStartDateEndDate(
				learnerPlanData,
				selectedSitting,
				selectedMinutes
			);
		}
	}, [userDate]);

	const createRecommendedPlan = () => {
		if (selectedOption === "recommended" && getValues("planName") === "") {
			setError("planName", { type: "required", message: "Plan name is required" });
			return;
		}
		let daysOfWeekArr = [];
		if (selectedOption === "home" || getUrlSegment()[0] === "courses" || selectedOption == "recommended") {
			let currentDate = new Date();
			for (let i = 0; i < selectedSitting; i++) {
				let weekDay = new Date(currentDate).toLocaleString("en-us", { weekday: 'short' });
				daysOfWeekArr?.push(weekDay);
				currentDate.setDate(currentDate.getDate() + 1);
			}
		} else if ((screen === 2 && getMyEditPlan !== "Edit Plan") || (screen === 1 && getMyEditPlan === "Edit Plan")) {
			if (selctedDays?.length === 0 && getFlexibLeDays?.length === 0 && myPlan !== true) {
				setDeletePlan({ title: "Select Days", message: `Please select one of the options for the days your child will do coursework. You can select ‘I’m Flexible’ if you are not sure.` })
				return;
			} else if (selctedDays?.length != selectedSitting && getFlexibLeDays?.length != selectedSitting && myPlan !== true) {
				setDeletePlan({ title: "Select Days", message: `Please select ${selectedSitting - selctedDays?.length} more day${(selectedSitting - selctedDays?.length) > 1 ? 's' : ''}.` })
				return;
			}
			daysOfWeekArr = getFlexibLeDays?.length > 0 ? getFlexibLeDays : selctedDays;
		}
		let array = {
			name: getValues("planName"),
			sittings: selectedSitting,
			duration: selectedMinutes,
			planCount: getMyPlanData?.records?.length + 1,
			timeZone: loggedInUser?.timeZone ? loggedInUser?.timeZone : timezoneValue,
			planType: getNewPlanResponse?.records[0]?.planType,
			daysOfWeek: daysOfWeekArr,
			courses: [],
		};

		learnerPlanData?.map((value, key) => {
			let courseArr = {
				id: value?.id,
				duration: value?.duration,
				sittings: value?.sittings,
				startDate: value?.startDate,
				endDate: value?.endDate,
				sequence: key + 1,
				activities: [],
			};
			value?.activities?.map((data, index) => {
				let obj = {
					id: data?.id,
					startDate: data?.startDate,
					endDate: data?.endDate,
					duration: data?.duration,
					sittings: data?.sittings,
				};
				courseArr.activities.push(obj);
			});
			array.courses.push(courseArr);
		});
		setLoader(true);
		if (array?.courses?.length > 0 && selectedSitting % 2 == 1) {
			let course = array?.courses[array?.courses?.length - 1];
			course.activities[3].endDate = getCourseEndDate(array?.courses, course, daysOfWeekArr);
			course.endDate = course?.activities[3]?.endDate;
		}
		dispatch(updateLearnerPlan(defaultChildData?.id, loggedInUser?.id, array));
	};

	const createPlan = (courseTree) => {
		let newArray = [];
		let index = 0;
		courseTree?.map((dim, count) => {
			dim?.skills?.map((skill, key2) => {
				skill?.courses?.map((courses, i) => {
					if (courses?.isSelected || screen === 3) {
						let courseArr = {
							id: courses?.id,
							duration: selectedMinutes,
							sittings: selectedSitting,
							sequence: index + 1,
							startDate: "",
							endDate: "",
							activities: [],
						};
						getSequnceSort(courses?.activities)?.map((module, key) => {
							let getDay = handleSeatingsDate(
								parseInt(selectedSitting),
								parseInt(selectedMinutes),
								index,
								key + 1
							);
							let currentDate = new Date();
							if (index === 0 && key === 0) {
								currentDate = new Date(userDate);
							} else if (index !== 0 && key === 0) {
								currentDate = getDateByAddDays(
									newArray[index - 1]?.activities[3]?.endDate,
									1
								);
							} else {
								currentDate = getDateByAddDays(
									courses?.activities[key - 1]?.endDate,
									1
								);
							}
							module.startDate = new Date(currentDate).toUTCString().toLocaleString("en-us", {
								month: "short",
								year: "numeric",
								day: "numeric",
							});
							module.endDate = new Date(
								getDateByAddDays(module.startDate, getDay - 1)
							).toUTCString().toLocaleString("en-us", {
								month: "short",
								year: "numeric",
								day: "numeric",
							});
							let obj = {
								id: module?.id,
								startDate: module?.startDate,
								endDate: module?.endDate,
								duration: selectedMinutes,
								sittings: selectedSitting,
							};
							courseArr.activities.push(obj);
						});
						courseArr.startDate = courseArr.activities[0]?.startDate;
						courseArr.endDate = courseArr.activities[3]?.endDate;
						newArray.push(courseArr);
						index++;
					}
				});
			});
		});
		let array = {};
		if (screen === 6) {
			array = {
				name: getValues("planName"),
				sittings: selectedSitting,
				duration: selectedMinutes,
				ujourneyId: getJourneyId,
				planCount: getMyPlanData?.records?.length + 1,
				timeZone: loggedInUser?.timeZone ? loggedInUser?.timeZone : timezoneValue,
				planType: "",
				daysOfWeek: getFlexibLeDays?.length > 0 ? getFlexibLeDays : selctedDays,
				courses: newArray,
			};
		} else if ([3, 5]?.includes(screen)) {
			array = {
				name: getValues("planName"),
				sittings: selectedSitting,
				duration: selectedMinutes,
				courses: newArray,
				timeZone: loggedInUser?.timeZone ? loggedInUser?.timeZone : timezoneValue,
				planType: "",
				daysOfWeek: getFlexibLeDays?.length > 0 ? getFlexibLeDays : selctedDays,
				planCount: getMyPlanData?.records?.length + 1,
			};
		}
		setLoader(true);
		if (array?.courses?.length > 0 && selectedSitting % 2 == 1) {
			let course = array?.courses[array?.courses?.length - 1];
			course.activities[3].endDate = getCourseEndDate(array?.courses, course, array?.daysOfWeek);
			course.endDate = course?.activities[3]?.endDate;
		}
		dispatch(updateLearnerPlan(defaultChildData?.id, loggedInUser?.id, array));
	};

	const handleLearnerUpdatePlan = (courseTree) => {
		if (getValues("planName") === "") {
			setError("planName", { type: "required", message: "Plan name is required" });
			return;
		}
		if (screen === 1) {
			if (selctedDays?.length === 0 && getFlexibLeDays?.length === 0) {
				setDeletePlan({ title: "Select Days", message: `Please select one of the options for the days your child will do coursework. You can select ‘I’m Flexible’ if you are not sure.` })
				return;
			} else if (selctedDays?.length != selectedSitting && getFlexibLeDays?.length != selectedSitting) {
				setDeletePlan({ title: "Select Days", message: `Please select ${selectedSitting - selctedDays?.length} more day${(selectedSitting - selctedDays?.length) > 1 ? 's' : ''}.` })
				return;
			}
		}
		let newArray = [];
		let index = 0;
		courseTree?.map((dim, count) => {
			dim?.skills?.map((skill, key2) => {
				skill?.courses?.map((courses, i) => {
					if (courses?.isSelected || screen === 3) {
						if (!courses?.isStarte) {
							let courseArr = {
								id: courses?.id,
								duration: selectedMinutes,
								sittings: selectedSitting,
								sequence: index + 1,
								startDate: "",
								endDate: "",
								activities: [],
							};
							getSequnceSort(courses?.activities)?.map((module, key) => {
								let getDay = handleSeatingsDate(
									parseInt(selectedSitting),
									parseInt(selectedMinutes),
									index,
									key + 1
								);
								let currentDate = new Date();
								if (index === 0 && key === 0) {
									currentDate = new Date(userDate);
								} else if (index !== 0 && key === 0) {
									currentDate = getDateByAddDays(
										newArray[index - 1]?.activities[3]?.endDate,
										1
									);
								} else {
									currentDate = getDateByAddDays(
										courses?.activities[key - 1]?.endDate,
										1
									);
								}
								module.startDate = new Date(currentDate).toUTCString().toLocaleString("en-us", {
									month: "short",
									year: "numeric",
									day: "numeric",
								});
								module.endDate = new Date(
									getDateByAddDays(module.startDate, getDay - 1)
								).toUTCString().toLocaleString("en-us", {
									month: "short",
									year: "numeric",
									day: "numeric",
								});
								let obj = {
									id: module?.id,
									startDate: module?.startDate,
									endDate: module?.endDate,
									duration: selectedMinutes,
									sittings: selectedSitting,
								};
								courseArr.activities.push(obj);
							});
							courseArr.startDate = courseArr.activities[0]?.startDate;
							courseArr.endDate = courseArr.activities[3]?.endDate;
							newArray.push(courseArr);
							index++;
						} else {
							courses.skill = [];
							newArray.push(courses);
							index++;
						}
					}
				});
			});
		});
		let array = {
			name: getValues("planName"),
			sittings: selectedSitting,
			duration: selectedMinutes,
			iscourseupdate: true,
			userId: params?.id,
			planId: location?.state?.myPlan?.planId,
			timeZone: loggedInUser?.timeZone ? loggedInUser?.timeZone : timezoneValue,
			daysOfWeek: getFlexibLeDays?.length > 0 ? getFlexibLeDays : selctedDays,
			courses: newArray,
		};
		setLoader(true);
		if (array?.courses?.length > 0 && selectedSitting % 2 == 1) {
			let course = array?.courses[array?.courses?.length - 1];
			course.activities[3].endDate = getCourseEndDate(array?.courses, course, array?.daysOfWeek);
			course.endDate = course?.activities[3]?.endDate;
		}
		dispatch(getDimensionUpdatePlan(loggedInUser?.id, array));
	};

	/*
	useEffect(() => {
	  if (response?.success && getUrlSegment()[0] !== "myplan") {
		console.log("response?.success 111: ", response);
		//	dispatch(getDashboard(defaultChildData?.id));
		// dispatch(getMyPlan());
		dispatch(getMyPlan(defaultChildData?.id));
		// history.push(PATHS.HOME);
		if (response?.message == "Congratulations! You have successfully created a plan.") {
		  history.push(PATHS.STR_MYPLAN + defaultChildData?.id);
		}
		setLoader(false);
		dispatch(getLearnerPlanTags());
		dispatch(getJourny());
		dispatch(getDimensionPlanData())
		dispatch(getJournyCourses());
		refreshNewPlan();
		dispatch(getAllUsersDetail());
		// setMyLocation();   
	  }
	  if (getUrlSegment()[0] === "myplan") {
		dispatch(getMyPlan(params?.id))
	  }
	  if (response?.success && modalObj?.isWelcomepopup) {
		dispatch(showModal({ type: "DimensionHintPopup" }));
		dispatch(showModalObj());
	  }
	}, [response]);
	 
	useEffect(() => {
	  if (response?.message == " Your plan has been deleted successfully." && getMyPlanData) {
		if (getMyPlanData?.records?.length > 1) {
		  history.push(PATHS.STR_MYPLAN + defaultChildData?.id);
		} else {
		  if (getUrlSegment()[0] === "myplan") {
			history.push(PATHS.HOME);
			return;
		  }
		  dispatch(getMyPlan(defaultChildData?.id));
		  dispatch(getAllUsersDetail());
		  refreshNewPlan();
		}
	  }
	 
	}, [response, getMyPlanData])
	*/

	useEffect(() => {
		if (response?.success) {
			if (myPlan === true && selectedTab === "Rcommnded Plan") { showCourseProgress(true) }
			if (response?.message != " Your plan has been deleted successfully.") {
				history.push(PATHS.STR_MYPLAN + defaultChildData?.id);
				if (getUrlSegment()[0] == "myplan" && ["recommended", "myplan"]?.includes(selectedOption)) {
					showCourseProgress(false);
				}
			} else {
				if (getMyPlanData?.records?.length > 1) {
					history.push(PATHS.STR_MYPLAN + defaultChildData?.id);
				} else {
					history.push(PATHS.HOME);
				}
			}
			setLoader(false);
			dispatch(getLearnerPlanTags());
			dispatch(getJourny());
			dispatch(getDimensionPlanData())
			dispatch(getJournyCourses());
			refreshNewPlan();
			dispatch(getAllUsersDetail());
			dispatch(getMyPlan(defaultChildData?.id));
		}
		if (response?.success && modalObj?.isWelcomepopup) {
			dispatch(showModal({ type: "DimensionHintPopup" }));
			dispatch(showModalObj());
		}
	}, [response]);


	const refreshNewPlan = () => {
		//	setRefreshPage(true);
		setRefreshShimmar(true);
		dispatch(getUpdateLearnerPlan(defaultChildData?.id));
	};

	/* Class Schedule method 7 may 2024 */
	const handleClassSchedule = (type, planData) => {
		if (type !== "ParentCourseSchedule") {
			dispatch(setClassSchedule(type));
		} else {
			dispatch(setClassSchedule(planData));
		}
	};

	const handleWhyAPlanPopup = () => {
		dispatch(showModal({ type: "WhyAPlanPopup" }));
	};

	const handleWhyThisPlanPopup = () => {
		dispatch(showModal({ type: "whyThisPlanPopup" }));
	};

	const getMyPlanCourses = (myPlanData) => {
		let myPlanCourses = [...myPlanData];
		myPlanCourses?.map((dim, index) => {
			dim?.skills?.map((skill, sKey) => {
				skill?.courses?.map((course, cKey) => {
					course.isSelected = true;
				})
			})
		})

		return myPlanCourses;
	}

	const handleEditPlan = (index, myPlan) => {
		history.push({
			pathname: PATHS.STR_LEARNER_PLAN + defaultChildData?.id,
			state: {
				show: "EditCoursePlan",
				screen: 1,
				index: index,
				myPlan: myPlan,
				catlog: getUrlSegment()[0],
			},
		});
	};

	const handleSelectedPlanCourses = (myPlanData, type) => {
		let myPlanCourses = [...myPlanData];
		let coursesArr = [];
		let myPlanTree = [];
		myPlanCourses?.map((dim, index) => {
			let dimObj = { ...dim, skills: [] }
			dim?.skills?.map((skill, sKey) => {
				let skilObj = { ...skill, courses: [] }
				dimObj?.skills?.push(skilObj);
				skill?.courses?.map((course, cKey) => {
					dimObj?.skills[sKey]?.courses?.push(course);
					course.isSelected = true;
					coursesArr?.push({ ...course });
				})
			})
			myPlanTree?.push(dimObj);
		})

		if (type) {
			return getSequnceSort(coursesArr);
		} else {
			setMyPlanCourses(getSequnceSort(coursesArr));
			return getSequnceSort(myPlanTree);;
		}
	}


	useEffect(() => {
		if (location?.state?.show === "EditCoursePlan") {
			// setCoursePlanTree(getCoursesInTreeFormat());
			setPlanIndex(location?.state?.index)
			setCoursePlanTree(handleSelectedPlanCourses(location?.state?.myPlan?.dimensions));
			setEditPlan(true);
			setMyEditPlan("Edit Plan");
			let dayOfWeekArr = location?.state?.myPlan?.dayOfWeek ? location?.state?.myPlan?.dayOfWeek?.split(",") : [];
			setSeleccted(dayOfWeekArr);
			let isEnrollCourse = handleSelectedPlanCourses(location?.state?.myPlan?.dimensions, "getCourseSeq");
			console.log("isEnrollCourse : ", isEnrollCourse);
			if (isEnrollCourse?.length === 1) {
				setShowEnrollPlan(true);
			}
		}
	}, [location?.state])

	const getCoursePlanTreeLenth = (coppyArr) => {
		let courseId = [];
		coppyArr?.map((dim) => {
			dim?.skills?.map((skill) => {
				skill?.courses?.map((course) => {
					if (course?.isSelected) {
						courseId?.push(course?.id);
					}

				})
			})
		})
		return courseId;
	}

	const handleReload = (index, myPlan) => {
		history.replace({
			...history.location,
			state: {
				show: "EditCoursePlan",
				screen: 1,
				index: index,
				myPlan: myPlan,

			},
		});
		setScreen(1);
		dispatch(resetLearnerPlan());
		handleMoreOptions("resetLearnerPlan");
	}

	const handleClenderVaiew = (myPlan) => {
		let data = handleSelectedPlanCourses(myPlan?.dimensions, "getCourseSeq");
		let dayOfWeek = myPlan?.dayOfWeek ? myPlan?.dayOfWeek?.split(",") : [];
		let holidaysArr = [];
		myPlan?.holidays?.map((myDate, key) => {
			let obj = { date: addDayNameInDate(myDate?.date), name: myDate?.name }
			holidaysArr?.push(obj);
		})
		dispatch(showModal({ type: "CalenderVaiew", data: data, getUserTimeZone: getUserTimeZone, dayOfWeek: dayOfWeek, holidays: holidaysArr }));
	}

	const [showProgress, setShowProgress] = useState(false);
	const showCourseProgress = (result) => {
		setSelectedTab("")
		setShowProgress(result);
	}
	const handleOpenpopup = (result, data, course, progress) => {
		if (progress > 0) {
			getDataFromCourseProgress(result, data, course);
		}
	};
	let courCount = 0;
	const righpanescrl = () => {
		const elementtop = document.getElementById("learner_planone0");
		let scrol_top = elementtop.scrollTop
		if (scrol_top > 10) {
			elementtop.classList.add("Arrow_collesped")
		}
		else {
			elementtop.classList.remove("Arrow_collesped")
		}
	}

	const handleMyPlan = (index) => {
		// setActiveIndex(index);
		if (index) {
			dispatch(activePlanIndexNo({ index: index }));
			dispatch(getMyPlan());
			history.push({
				pathname: PATHS.STR_MYPLAN + defaultChildData?.id,
				state: { showPlanPopup: true },
				index: index,
				selectedFrom: selectedOption,
			});
		} else {
			if (getMyPlanData?.records?.length > 0) {
				history.push({
					pathname: PATHS.STR_MYPLAN + defaultChildData?.id,
				});
			}
		}
		// history.push({
		// 	pathname: PATHS.STR_MYPLAN + defaultChildData?.id,
		// 	state: { showPlanPopup: true }
		// });
	};


	const handlePOpop = (myPlan) => {
		// console.log("handlePopUpOpen 111:");
		dispatch(showModal({ showDeletePlnPop: true, planId: myPlan?.planId, message: "Are you sure to delete plan" }));
		// setDeletePlan({ planId: myPlan?.planId, message: "Are you sure to delete plan" });
	}

	const handleDeletePlan = (myPlan) => {
		let planCourses = handleSelectedPlanCourses(myPlan?.dimensions, "getCourseSeq");
		let showPlanCourses = [];
		dateSortingByStartDate(getMyPlanData?.records)?.reverse()?.map((myPlanData, index) => {
			if (myPlanData?.planId !== myPlan?.planId) {
				myPlanData?.dimensions?.map((dim, index) => {
					dim?.skills?.map((skill, key) => {
						skill?.courses?.map((course, count) => {
							const corKey = planCourses?.findIndex((val) => val?.id === course?.id);
							if (corKey !== -1) {
								showPlanCourses?.push(course?.name);
							}
						})
					})
				})
			}
		})
		console.log("showPlanCourses : ", planCourses, showPlanCourses);
		dispatch(showModal({ type: "showDeletePlanPop", planId: myPlan?.planId, planName: myPlan?.planName, showPlanCourses: showPlanCourses, message: "Are you sure you want to delete this Plan?" }))
	}

	const editChild = (url) => {
		if (url) {
			history.push({
				pathname: url,
			});
		}
	};



	const handleClick = (index) => {
		dispatch(activePlanIndexNo({ index: index }))
		// console.log('index === >>>> 2>>>' , index)
		// setActiveIndex(index);
	};


	const handleMoreOptionsMethod = (type) => {
		if (type === "createNewPlan") {
			history.push({
				pathname: PATHS.STR_LEARNER_PLAN + defaultChildData?.id,
				state: {
					show: "recommended",
					screen: 1,
				},
			});
		}
	};

	const [courseData, setCourseData] = useState([]);
	const [isShowEnroll, setIsShowEnroll] = useState(false);

	const redirectToHome = (tab) => {
		dispatch(getUpdateLearnerPlan());
		dispatch(getUpdateLearnerPlan(defaultChildData?.id));
		setSelectedTab(tab)
		setShowProgress(false);
		if (location?.type != "dimCatlog") {
			_getCourseCataloge();
		}
	}

	const _getCourseCataloge = () => {
		if (location?.type != "dimCatlog"
		) {
			dispatch(getStartEnrollCourses(params.id));
		}
	};

	useEffect(() => {
		if (getNewPlanResponse?.records?.length > 0 && selectedTab === "Rcommnded Plan") {
			setCourseData(getSequnceSort(getNewPlanResponse?.records));
		}
	}, [getNewPlanResponse, selectedTab])


	const ArrowRemove = (e) => {
		const header = document.querySelector(".Multi_learner_plan");
		const scrollTop = header.scrollY;
		scrollTop >= 0
			? header.classList.add("removeArrow")
			: header.classList.remove("removeArrow");
	};

	const hadleNextBackInMyPlan = () => {
		return (
			<>
				{["TEACHER", "STUDENT"]?.includes(loggedInUser?.role?.name) && selectedOption === "myplan" &&
					<div class={`d-flex justify-content-between ps-3 pe-3 pt-2 pb-2  `}>
						<div className={`backpageStrip p-0 ${!showProgress && "pe-none disabled"}`} onClick={() => showCourseProgress(false)}>
							<a href="javascript:void(0)" class={`flexone ${!showProgress && "disabledevent"} `}>
								<span>
									<i class="fa-solid fa-angle-left mr-1"></i>
								</span>
								Back
							</a>
						</div>
						<div className={`backpageStrip p-0 ${true && "pe-none disabled"}`} onClick={() => showCourseProgress(true)}>
							<a href="javascript:void(0)" class={`flexone ${true && "disabledevent"}`} >
								Next
								<span class="">
									<i class="fa-solid fa-angle-right ml-1"></i>
								</span>
							</a>
						</div>
					</div>
				}
			</>
		)
	}

	return (
		<>
			<div className="heading p-0 border-0">
				{
					//(["TEACHER", "STUDENT"]?.includes(loggedInUser?.role?.name) && selectedOption === "home"))
					getUrlSegment()[0] === "myplan" && (
						// <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
						// 	<div className="tabgrid w-100 m-0">
						// 		<ul>
						// 			<li class={`tabs flexone ${!showProgress ? "active-tabs" : ""}  w-50`} onClick={() => showCourseProgress(false)}>
						// 				<img src={image.leanerPlan} alt="" />
						// 				My Plans
						// 			</li>
						// 			<li class={`tabs flexone ${showProgress ? "active-tabs" : ""}  w-50`} onClick={() => showCourseProgress(true)}>
						// 				<img src={image.leanerPlan1} alt="" />
						// 				Plan Progress
						// 			</li>
						// 		</ul>
						// 	</div>
						// </h2>
						<div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
							<h2
								data-toggle="collapse"
								class="m-0 pt-0 pb-1 w-100 flex justify-content-between "
							>
								{selectedTab === "Rcommnded Plan" && (
									<span className="flexone">
										<i class="fa-light fa-rocket-launch mr-2 mt-2"></i>
										Recommend A Plan
									</span>
								)}
								{selectedTab !== "Rcommnded Plan" && (
									<span className="flexone">
										{showProgress ? (
											<img src={image.leanerPlan1} alt="" className="mr-2" />
										) : (
											<img src={image.leanerPlan} className="mr-2" alt="" />
										)}
										<>{showProgress ? "Plan Progress" : "My Plans"}</>
									</span>
								)}
								{
									loggedInUser?.role?.name !== "TEACHER" && (
										<div className="learner_pLan_dropdown">
											<span
												aria-haspopup="true"
												className="pointer"
												aria-expanded="false"
												id="leaneringplndwn"
												data-toggle="dropdown"
											>
												<i class="fa-sharp fa-solid fa-bars"></i>
											</span>
											<ul
												className="dropdown-menu dropdown-menu-end lPDropdwnList"
												aria-labelledby="leaneringplndwn"
											>
												<>
													<li class="" onClick={() => showCourseProgress(false)}>
														<img src={image.leanerPlan} alt="" className="mr-2" />
														My Plans
													</li>
													{["PARENT", "LEARNER"]?.includes(loggedInUser?.role?.name) &&
														<>
															<li
																class=""
																onClick={() =>
																	handleMoreOptionsMethod("createNewPlan", true)
																}
															>
																<img src={image.newleaner_plan} className="mr-2" />
																Create Plan
																{/* {getMyPlanData?.records?.length > 0
																? "Add A Plan"
																: "Create New Plan"} */}
															</li>
															<li
																class=""
																onClick={() => redirectToHome("Rcommnded Plan")}
															>
																<span>
																	<i class="fa-light fa-rocket-launch mr-2 mt-2"></i>
																</span>
																Recommend A Plan
															</li>
														</>
													}
													<li class="" onClick={() => showCourseProgress(true)}>
														<img
															src={image.leanerPlan1}
															alt=""
															className="mr-2"
														/>
														Plan Progress
													</li>
												</>
											</ul>
										</div>
									)
								}
							</h2>
						</div>
					)
				}
				{["TEACHER", "STUDENT"]?.includes(loggedInUser?.role?.name) &&
					selectedOption === "home" && (
						<div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
							<h2
								data-toggle="collapse"
								class="m-0 pt-0 pb-1 w-100 flex justify-content-between "
							>
								<span className="flexone">
									{" "}
									<img src={image.leanerPlan} className="mr-2" alt="" />
									<>My Plans</>
								</span>
								{
									<div className="learner_pLan_dropdown">
										<span
											aria-haspopup="true"
											className="pointer"
											aria-expanded="false"
											id="leaneringplndwn"
											data-toggle="dropdown"
										>
											<i class="fa-sharp fa-solid fa-bars"></i>
										</span>
										<ul
											className="dropdown-menu dropdown-menu-end lPDropdwnList"
											aria-labelledby="leaneringplndwn"
										>
											<li onClick={() => handleMyPlan()}>
												<p>
													<i class="fa-light fa-rocket-launch mr-2 mt-2"></i>Go
													To My Plans
												</p>
											</li>
										</ul>
									</div>
								}
							</h2>
						</div>
					)}
				{!["myplan", "courses"]?.includes(getUrlSegment()[0]) &&
					["PARENT", "LEARNER"]?.includes(loggedInUser?.role?.name) && (
						<div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
							<h2
								data-toggle="collapse"
								class="m-0 pt-0 pb-1 w-100 flex justify-content-between "
							>
								<span className="flexone">
									{" "}
									<img src={image.leanerPlan} className="mr-2" alt="" />
									{getMyPlanData?.records?.length > 0 &&
										selectedOption === "home" && (
											<>{getLearnerName()}'s Plan</>
										)}
									{(getMyPlanData?.records?.length === 0 ||
										getMyEditPlan === "New Plan") &&
										!["filter", "pathway", "dimension"]?.includes(
											selectedOption
										) && (
											<>
												{/* {getLearnerName()}'s Recommended Plan */}
												Recommended Plan
											</>
										)}
									{(["filter", "pathway", "dimension"]?.includes(
										selectedOption
									) ||
										getMyEditPlan === "Edit Plan") && (
											<>{getLearnerName()}'s Draft Plan</>
										)}
								</span>
								{loggedInUser?.role?.name === "PARENT" &&
									selectedOption !== "myplan" && (
										<div className="learner_pLan_dropdown">
											<span
												aria-haspopup="true"
												className="pointer"
												aria-expanded="false"
												id="leaneringplndwn"
												data-toggle="dropdown"
											>
												<i class="fa-sharp fa-solid fa-bars"></i>
											</span>
											<ul
												className="dropdown-menu dropdown-menu-end lPDropdwnList"
												aria-labelledby="leaneringplndwn"
											>
												<li onClick={() => handleWhyAPlanPopup()}>
													<p>
														{" "}
														<img
															src={image.SceneQuestionicon}
															className="mr-2"
														/>
														Why A Plan?
													</p>
												</li>
												<li>
													<p
														onClick={() =>
															handleMoreOptions("createNewPlan", true)
														}
													>
														{" "}
														<img src={image.newleaner_plan} className="mr-2" />
														Create Plan
														{/* {getMyPlanData?.records?.length > 0
															? "Add A Plan"
															: "Create New Plan"} */}
													</p>
												</li>
												<li>
													<p
														onClick={() =>
															handleMoreOptions("courseCatlog", true)
														}
													>
														{" "}
														<img src={image.mortarboard} className="mr-2" />
														Course Catalog
													</p>
												</li>
												{/* <li onClick={() => createRecommendedPlan()}>
													<p> <img src={image.mortarboard} className="mr-2" /> Rcommnded Plan</p>
												</li> */}
												{/* Class Schedule 7 may 2024 */}
												{/* {loggedInUser?.role?.name === "TEACHER" && (
											<li>
												<p onClick={() => handleClassSchedule("classSchedule")}>
													{" "}
													<img src={image.newleaner_plan} className='mr-2' />
													Class Schedule
												</p>
											</li>
										)} */}
											</ul>
										</div>
									)}
							</h2>
						</div>
					)}
			</div>

			{getMyPlanData?.records?.length > 0 &&
				(selectedOption === "home" || selectedOption === "myplan") &&
				!showProgress ? (
				<>
					<div className="CourseCardWrapper fullHeight100 ">
						{/* {["TEACHER", "STUDENT"]?.includes(loggedInUser?.role?.name) && selectedOption === "myplan" &&
							<div class={`d-flex justify-content-between ps-3 pe-3 pt-2 pb-2  `}>
								<div className={`backpageStrip p-0 ${!showProgress && "pe-none disabled"}`} onClick={() => showCourseProgress(false)}>
									<a href="javascript:void(0)" class="flexone">
										<span>
											<i class="fa-solid fa-angle-left mr-1"></i>
										</span>
										Back
									</a>
								</div>
								<div className={`backpageStrip p-0 ${showProgress && "pe-none disabled"}`} onClick={() => showCourseProgress(true)}>
									<a href="javascript:void(0)" class="flexone">
										Next
										<span class="">
											<i class="fa-solid fa-angle-right ml-1"></i>
										</span>
									</a>
								</div>
							</div>
						} */}
						{hadleNextBackInMyPlan()}
						<div class="learning_plan_summry  ScenecerelateddQuiz p-0  setuptwo_rPnl learner_plan_list">
							<div class="learner_Planrighpnl  ">
								{dateSortingByStartDate(getMyPlanData?.records)
									?.reverse()
									?.map(
										(myPlan, index) =>
											(!singlePlancourseId ||
												handleSelectedPlanCourses(
													myPlan?.dimensions,
													"getCourseSeq"
												)[0]?.id === singlePlancourseId) && (
												<>
													<div class="lerner_jounry_plan m-0 " key={index}>
														<div class="Multi_learner_plan pointer">
															<h5 class="flex">
																<i class="fa-light fa-rocket-launch mr-2 mt-2"></i>
																<p class="timint_date mr-auto ml-2 mt-1">
																	{/* <span>{myPlan?.planStartDate} - {myPlan?.planEndDate}</span> */}
																	<span
																		className="user_planname"
																		onClick={() => {
																			handleClick(index);
																			selectedOption === "myplan"
																				? gotoSelectedPlan(index)
																				: handleMyPlan(index);
																		}}
																	>
																		{/* <React.Fragment>
																		{myPlan?.planName?.length > 32 ? (
																			<ReactTooltip id={myPlan?.planName}>
																			 <p>{myPlan?.planName}</p>
																			</ReactTooltip>
																		) : (
																			""
																		)}
																		<div className="courseName" data-for={myPlan?.planName} data-event-off="" data-tip>
																			{textTrim(myPlan?.planName, 40)}
																		</div>
																	</React.Fragment> */}
																		{/* {myPlan?.planName} */}
																		<React.Fragment>
																			{myPlan?.planName?.length > 32 ? (
																				<ReactTooltip id={myPlan?.planName + "showtooltip"} place='bottom' className={"tooltip"}>
																					<p>
																						<p>{myPlan?.planName}</p>
																					</p>
																				</ReactTooltip>
																			) : (
																				""
																			)}
																			<div data-for={myPlan?.planName + "showtooltip"} data-event-off="" data-tip>
																				<span>
																					{textTrim(myPlan?.planName, 32)}
																				</span>
																			</div>
																		</React.Fragment>
																	</span>
																</p>
																<p className="multiplelening_dpdwn learner_pLan_dropdown">
																	<span
																		aria-haspopup="true"
																		aria-expanded="false"
																		id="lernercennter"
																		className=""
																		data-toggle="dropdown"
																	>
																		<i class="fa-solid fa-bars mr-3 pr-2"></i>
																	</span>
																	<ul
																		className="dropdown-menu lPDropdwnList dropdown-menu-end"
																		aria-labelledby="lernercennter"
																	>
																		{loggedInUser?.role?.name === "PARENT" && (
																			<>
																				<li
																					className={`pointer _Course Summary`}
																					onClick={() =>
																						handleEditPlan(index, myPlan)
																					}
																				>
																					<p>
																						<i class="fa-light fa-pencil active mr-2"></i>
																						Edit Plan
																					</p>
																				</li>
																				{/* <li
																					className={`pointer _Course Summary`}
																					onClick={() => handleDeletePlan(myPlan)}
																				>
																					<p>
																						<i class="fa-light fa-trash-can active mr-2"></i>
																						Delete Plan
																					</p>
																				</li> */}

																				{/* {myPlan?.dimensions?.every(dimension =>
																					dimension?.skills?.every(skill =>
																						skill?.courses?.every(course => course?.isCompleted === false)
																					) */}
																				{myPlan?.dimensions?.some((dimension) =>
																					dimension?.skills?.some((skill) =>
																						skill?.courses?.some(
																							(course) =>
																								course?.isCompleted === false
																						)
																					)
																				) && (
																						<li
																							className={`pointer _Course Summary`}
																							onClick={() =>
																								handleDeletePlan(myPlan)
																							}
																						>
																							<p>
																								<i className="fa-light fa-trash-can active mr-2"></i>
																								Delete Plan
																							</p>
																						</li>
																					)}
																			</>
																		)}
																		{/* <li
																	className={`pointer _Course Summary`}
																	onClick={() => handleClassSchedule("ParentCourseSchedule", myPlan)}
																>
																	<p>
																		<img src={image.courseScheduleIcon} alt='' className="mr-2" />
																		Course schedule
																	</p>
																</li> */}
																		<li
																			className={`pointer _Course Summary`}
																			onClick={() => handleClenderVaiew(myPlan)}
																		>
																			<p>
																				<img
																					src={image.calenderIcon1}
																					alt=""
																					className="mr-2"
																				/>
																				Plan Schedule{" "}
																			</p>
																		</li>
																		{/* <li
																		className={`pointer _Course Summary`}
																		// onClick={() => handleClenderVaiew(myPlan)}
																		onClick={() => handleWhyThisPlanPopup()}
																	>
																		<p>
																			<img src={image.whythisplan} alt='' className="mr-2" />
																			Why This Plan 																	</p>
																	</li> */}
																		{/* <li
																		className={`pointer _Course Summary`}
																		onClick={() => handleClenderVaiew(myPlan)}
																	>
																		<p>
																			<img src={image.acquisitionsurvey} alt='' className="mr-2" />
																			Skill Acquisition Survey																</p>
																	</li> */}
																		{/* {(loggedInUser?.role?.name === "TEACHER" || loggedInUser?.role?.name === "STUDENT") &&
																		<li
																			className={`pointer _Course Summary`}
																			onClick={() => handleWhyThisPlanPopup()}
																		>
																			<p>
																				<img src={image.whythisplan} alt='' className="mr-2" />
																				Why This Plan
																			</p>
																		</li>
																	} */}
																		{(loggedInUser?.role?.name === "TEACHER" ||
																			loggedInUser?.role?.name === "STUDENT") && (
																				<>
																					{selectedOption === "myplan" && (
																						<li class="pointer _Course Summary"
																							onClick={() => showCourseProgress(true)}>
																							<p>
																								<img
																									src={image.leanerPlan1}
																									alt=""
																									className="mr-2"
																								/>
																								Plan Progress
																							</p>
																						</li>
																					)}
																					<li
																						className={`pointer _Course Summary`}
																						onClick={() =>
																							editChild(
																								PATHS.SKILLSACQUISITION_STR +
																								defaultChildData?.id
																							)
																						}
																					>
																						<p>
																							<img
																								src={image.acquisitionsurvey}
																								alt=""
																								className="mr-2"
																							/>
																							Skill Acquisition Survey
																						</p>
																					</li>
																				</>
																			)}
																	</ul>
																	<span
																		// className="LP_chevron"
																		// data-toggle="collapse"
																		// href={"#learner_planone" + index}
																		// aria-expanded={index > 0 && !singlePlancourseId ? "false" : "true"}
																		className="LP_chevron"
																		data-toggle="collapse"
																		href={`#learner_planone${index}`}
																		aria-expanded={
																			activePlanIndex?.index === index
																				? "true"
																				: "false"
																		}
																	>
																		<i class="fa-regular fa-chevron-down"></i>
																	</span>
																</p>
															</h5>
															<div className="flexone MultiplanPacedesc">
																<div className="flexone">
																	<p className="mr-3">
																		Sessions:
																		<strong>
																			{" "}
																			{myPlan?.sittings
																				? convertedNumber(myPlan?.sittings)
																				: 2}{" "}
																			({myPlan?.sittings})
																		</strong>
																	</p>
																	<p>
																		Duration:
																		<strong> {myPlan?.duration} minutes</strong>
																	</p>
																</div>
																<div className="ml-3"></div>
															</div>
														</div>
														<div
															class={`panel-collapse collapse ${activePlanIndex?.index === index ? "show" : ""
																}  ${getMyPlanData?.records?.length > 1
																	? "Plandetal_collapse"
																	: "planheighCollaspe"
																}`}
															id={"learner_planone" + index}
															onScroll={() => righpanescrl()}
														>
															{handleSelectedPlanCourses(
																myPlan?.dimensions,
																"getCourseSeq"
															)?.map((course, cKey) => (
																<div className="LPrpcomponent" key={cKey}>
																	<div className="d-flex align-items-center plan_course_name">
																		<div className="User_inisal courseCircle mr-3">
																			<span>C{cKey + 1}</span>
																		</div>
																		<div className="userPLan_start">
																			<p className="outCometxt">
																				Course {cKey + 1}:
																				{course?.isStarted && (
																					<span>
																						<img
																							src={image.InProgressIcon}
																							className="statusImg ml-2"
																						/>
																					</span>
																				)}
																				{course?.isCompleted && (
																					<span>
																						<img
																							src={image.completedIcon}
																							className="statusImg ml-2"
																						/>
																					</span>
																				)}
																			</p>
																			<p className="flexone ">
																				<React.Fragment>
																					{course?.name?.length > 35 && (
																						<ReactTooltip
																							id={`course-tooltip-${cKey}`}
																							place="top"
																							className="tooltip text-center"
																						>
																							<p>{course?.name}</p>
																						</ReactTooltip>
																					)}
																					<div
																						data-for={
																							course?.name?.length > 35
																								? `course-tooltip-${cKey}`
																								: null
																						}
																						data-tip={
																							course?.name?.length > 35
																								? true
																								: false
																						}
																						data-event-off="click"
																					>
																						<strong
																							className="pointer Course_NamePland"
																							onClick={() =>
																								courseDetailPage(course)
																							}
																						>
																							{textTrim(course?.name, 40)}
																						</strong>


																					</div>
																				</React.Fragment>
																			</p>
																			<p className="timint_date">
																				{getUsStatnderdTime(
																					course?.startDate,
																					getUserTimeZone
																				)}{" "}
																				-{" "}
																				{getUsStatnderdTime(
																					course?.endDate,
																					getUserTimeZone
																				)}
																			</p>
																		</div>
																	</div>
																	{["PARENT", "LEARNER"]?.includes(
																		loggedInUser?.role?.name
																	) && (
																			<div className="flexone LPdestination_mark">
																				<div className="User_inisal mr-2">
																					<i className="fa-sharp fa-solid fa-location-dot"></i>
																				</div>
																				<div className="userPLan_start course_destination">
																					<p className="outCometxt">Outcome:</p>
																					<p>
																						<React.Fragment>
																							{course?.outcome?.length > 78 && (
																								<ReactTooltip
																									id={`outcome-tooltip-${cKey}`}
																									place="top"
																									className="tooltip text-center"
																								>
																									<p>
																										{getLearnerName()}{" "}
																										{course?.outcome}
																									</p>
																								</ReactTooltip>
																							)}
																							<div
																								data-for={
																									course?.outcome?.length > 78
																										? `outcome-tooltip-${cKey}`
																										: null
																								}
																								data-tip={
																									course?.outcome?.length > 78
																										? true
																										: false
																								}
																								data-event-off="click"
																							>
																								<span>
																									{getLearnerName()}{" "}
																									{textTrim(course.outcome, 78)}
																								</span>
																							</div>
																						</React.Fragment>
																					</p>
																				</div>
																			</div>
																		)}
																</div>
															))}

															<div class="Contionur_Arrow">
																<h4 class="flexone align-items-start">
																	<span class="p-0 mr-2 flagchecked">
																		<i class="fa-solid fa-flag-checkered"></i>
																	</span>
																	{defaultChildData?.id ===
																		loggedInUser?.children[0]?.id &&
																		loggedInUser?.role?.name === "TEACHER"
																		? defaultChildData?.firstName +
																		" " +
																		defaultChildData?.lastName
																		: getLearnerName()}{" "}
																	{""}
																	has grown through this Plan and is
																	ready for a new set of courses!
																</h4>
															</div>
														</div>
													</div>
													{/* <div class="learner_planordr_three m-0">
													<div class="form-group BDsubmitbutton d-flex m-0">
														<div class="buttonDistribotion justify-content-between  align-items-center">
															<button type="button" class="btn-blue btn-login d-block mb-5">
																<span><i class="fa-solid fa-arrow-left-long"></i></span>Back</button>
														</div>
													</div>
												</div> */}
												</>
											)
									)}
							</div>
						</div>

					</div>
					<div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
						<div class="form-group BDsubmitbutton d-flex justify-content-between align-items-center m-0 ">
							<div>

							</div>
						</div>
					</div>
					{/* <div class="input-group full-Width-group basic_details_form pt-0 continuebtn ">
						  <div class="form-group justify-content-end flex m-0">
						    <div>
						    <button class="btn-blue btn-login d-block float-right w-auto">
						    <i class="fa-solid fa-paper-plane mr-2"></i>Update
						   </button>
						   </div>
						  </div>
						</div> */}
				</>
			) : (
				learnerPlanData?.length == 0 ||
				(!getMyPlanData && (
					<ShimmerPostItem
						card
						title
						cta
						imageType="thumbnail"
						imageWidth={80}
						imageHeight={80}
						contentCenter
					/>
				))
			)}
			{(getMyPlanData?.records?.length === 0 ||
				selectedOption === "recommended") &&
				selectedOption !== "myplan" && (
					<>
						{!refreshShimmar &&
							((screen === 1 || screen === 2) && (selectedOption === "recommended" || selectedOption === "home")) &&
							getNewPlanResponse?.records &&
							coursePlanTree?.length === 0 ? (
							<>
								<div class="learning_plan_summry  ScenecerelateddQuiz p-0  setuptwo_rPnl recummenddedpln CourseCardWrapper fullHeight100">
									<div class="learner_Planrighpnl pb-3">
										<div class="lerner_jounry_plan m-0">
											<div
												class="Multi_learner_plan"
											// data-toggle="collapse"
											// href="#learner_planone"
											// aria-expanded="true"
											>
												<h5 class="flex">
													<i class="fa-light fa-rocket-launch mr-2"></i>
													<p class="timint_date mr-auto ml-2 mt-1">
														{learnerPlanData && (
															<span className="user_planname">
																{getValues("planName")}
																{/* {getUsStatnderdTime(learnerPlanData[0]?.startDate, getUserTimeZone)} - {getUsStatnderdTime(learnerPlanData[learnerPlanData?.length - 1]?.endDate, getUserTimeZone)} */}
															</span>
														)}
													</p>
												</h5>
												<div className="flexone MultiplanPacedesc">
													<div className="flexone">
														<p className="mr-3">
															Sessions:
															<strong>
																{" "}
																{convertedNumber(selectedSitting)} (
																{selectedSitting})
															</strong>
														</p>
														<p>
															Duration:<strong> {selectedMinutes} minutes</strong>
														</p>
													</div>
													<div className="ml-3"></div>
												</div>
											</div>
											<div
												class="panel-collapse collapse show planheighCollaspe "
												id="learner_planone"
											>
												{getSequnceSort(learnerPlanData)?.map((course, index) => (
													<div class="LPrpcomponent ">
														<div class="d-flex align-items-center plan_course_name">
															{/* <div class="User_inisal mr-2">
															<i class="fa-solid fa-graduation-cap"></i>
														</div> */}
															<div className="User_inisal courseCircle mr-3">
																<span>C{index + 1}</span>
															</div>
															<div class="userPLan_start">
																{/* <span className="inprogress_Course complete_course">Complete</span> */}
																{/* <span className="inprogress_Course">In Progress</span> */}
																{/* <p class="outCometxt">Course: {course?.isStarted && (<span className="inprogress_Course">In Progress</span>)}</p> */}
																<p class="outCometxt">Course {index + 1}:</p>
																<p class="flexone">
																	<div
																		data-for="Making Responsible Choices"
																		data-event-off=""
																		data-tip="true"
																	>
																		<strong class="pointer Course_NamePland">
																			<React.Fragment>
																				{course?.name?.length > 35 ? (
																					<ReactTooltip id={course?.name}>
																						<p>{course?.name}</p>
																					</ReactTooltip>
																				) : (
																					""
																				)}
																				<div
																					data-for={course.name}
																					data-event-off=""
																					data-tip
																				>
																					<strong
																						className="pointer Course_NamePland"
																						onClick={() =>
																							courseDetailPage(course)
																						}
																					>
																						{textTrim(course.name, 35)}
																					</strong>
																					{course?.isCompleted && (
																						<span>
																							<img
																								src={image.completedIcon}
																								className="statusImg ml-2"
																							/>
																						</span>
																					)}
																					{course?.isStarted && (
																						<span>
																							<img
																								src={image.inprogresscheck}
																								className="statusImg ml-2"
																							/>
																						</span>
																					)}
																				</div>
																			</React.Fragment>
																		</strong>
																	</div>
																	{/* <span class="pointer" onClick={() => courseDetailPage(course)}>
																	<i class="fa-solid fa-up-right-from-square"></i>
																</span> */}
																</p>
																<p className="timint_date">
																	{getUsStatnderdTime(
																		course?.startDate,
																		getUserTimeZone
																	)}{" "}
																	-{" "}
																	{getUsStatnderdTime(
																		course?.endDate,
																		getUserTimeZone
																	)}
																	{/* <span><i class="fa-light fa-calendar ml-3 p-0"></i></span> */}
																	{/* <a href="javascript:void(0)" onClick={() => handleShowPlanModule(index, course, learnerPlanData, "newPlan")} className="ml-2">Show details</a> */}
																</p>
															</div>
														</div>
														<div class="flexone LPdestination_mark">
															<div class="User_inisal mr-2">
																{" "}
																<i class="fa-sharp fa-solid fa-location-dot"></i>
															</div>
															<div class="userPLan_start course_destination">
																<p class="outCometxt">Outcome:</p>
																<p>
																	<div
																		data-for="is starting to make more responsible choices!"
																		data-event-off=""
																		data-tip="true"
																	>
																		<span>
																			<React.Fragment>
																				{course?.outcome?.length > 78 ? (
																					<ReactTooltip id={course?.outcome}>
																						<p>
																							{getLearnerName()} {course?.outcome}
																						</p>
																					</ReactTooltip>
																				) : (
																					""
																				)}
																				<div
																					data-for={course.outcome}
																					data-event-off=""
																					data-tip
																				>
																					<span>
																						{getLearnerName()}{" "}
																						{textTrim(course.outcome, 78)}
																					</span>
																				</div>
																			</React.Fragment>
																		</span>
																	</div>
																</p>
															</div>
														</div>
													</div>
												))}
												<div class="Contionur_Arrow padding65">
													<h4 class="flexone align-items-start">
														<span class="p-0 mr-2 flagchecked">
															<i class="fa-solid fa-flag-checkered"></i>
														</span>
														{getLearnerName()} has grown through this
														plan and is ready for a new set of courses!
													</h4>
												</div>
											</div>
										</div>
									</div>

								</div>
								{["PARENT", "LEARNER"]?.includes(loggedInUser?.role?.name) && (
									<div className="learner_planordr_three m-0">
										<div className="form-group BDsubmitbutton d-flex m-0">
											<div className="buttonDistribotion justify-content-between  align-items-center">
												{loader ? (
													<div className="buttonDistribotion">
														<div className="justify-content-end">
															<button
																className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
																disabled
															>
																<span className="RounAnimation mr-1"></span>{" "}
																Please Wait...
															</button>
														</div>
													</div>
												) : (
													<>
														<p className="courserefresh">
															<a
																href="javascript:void(0)"
																onClick={() => refreshNewPlan()}
															>
																<i class="fa-light fa-arrows-rotate mr-2"></i>
																Refresh Plan
															</a>
														</p>
														{learnerPlanData?.length > 0 && (
															<button
																type="button"
																className="btn-blue btn-login d-block mb-5"
																onClick={() => createRecommendedPlan()}
															>
																<span>
																	<i class="fa-solid fa-paper-plane mr-2"></i>
																</span>
																Select Plan
															</button>
														)}
													</>
												)}
											</div>
										</div>
									</div>
								)}
							</>
						) : (
							<>
								{refreshShimmar && (
									<ShimmerPostItem
										card
										title
										cta
										imageType="thumbnail"
										imageWidth={80}
										imageHeight={80}
										contentCenter
									/>
								)}
							</>
						)}
					</>
				)}
			{/* For edit plan */}
			{[1, 2]?.includes(screen) && getMyEditPlan === "Edit Plan" && (
				<>
					<div className="learning_plan_summry  ScenecerelateddQuiz p-0  setuptwo_rPnl CourseCardWrapper fullHeight100">
						<div className="learnerplanOrderone">
							<div className="Selected_paced ">
								<>
									<p>
										<span>Sessions Per Week: </span>{" "}
										{convertedNumber(selectedSitting)} ({selectedSitting}){" "}
									</p>
									<p>
										<span>Duration Per Session: </span> {selectedMinutes} minutes
									</p>
								</>
							</div>
						</div>
						<div className="learner_Planrighpnl px-3 pb-3">
							<div className=" p-0 allcourselj pb-5 mb-3">
								<div className="DraftSelctionList">
									{coursePlanTree?.length === 0 ? (
										<div className="DS_List">
											<h4 className="flexone pt-0">
												<i class="fa-solid fa-play mr-2"></i>Dimension(s)
											</h4>
											<span>
												<i class="fa-regular fa-hyphen mr-2"></i>
												<i>Dimension(s) will be added per your selections.</i>
											</span>
											<div className="DS_List">
												<h4 className="flexone">
													<i class="fa-solid fa-play mr-2"></i>Skill(s)
												</h4>
												<span>
													<i class="fa-regular fa-hyphen mr-2"></i>
													<i>Skill(s) will be added per your selections.</i>
												</span>
												<div className="DS_List">
													<h4 className="flexone">
														<i class="fa-solid fa-play mr-2"></i>Course(s)
													</h4>
													<span>
														<i class="fa-regular fa-hyphen mr-2"></i>
														<i>Course(s) will be added per your selections.</i>
													</span>
												</div>
											</div>
										</div>
									) : (
										<div className="DS_List">
											{/* <h4 className={`flexone mb-2 pt-0 dim_Name_att`}><i class="fa-solid fa-play mr-2"></i > Dimension(s) </h4> */}
											<ul className="DSdimensionList">
												{coursePlanTree?.map((value, index) => (
													<li className="dimensionNamehg">
														<span>
															<img src={image.greentickarrow} className="mr-2" />
															{value?.name}
															{!progressCourseDisabled("dimension", value?.id) &&
																!showEnrollPlan && (
																	<span
																		className="pointer"
																		onClick={() =>
																			handleRemoveTagDimSkillCourse(
																				"dimension",
																				value,
																				"",
																				"",
																				true
																			)
																		}
																	>
																		{/* <i class="fa-regular fa-trash-can"></i> */}
																		{/* <i class="fa-light fa-square-xmark"></i> */}
																		<i class="fa-regular fa-circle-xmark"></i>
																	</span>
																)}
														</span>
														<div className="DS_List">
															{/* <h4 className={`flexone`}><i class="fa-solid fa-play mr-2"></i > Skill(s)</h4> */}
															{value?.skills?.map((skill, key) => (
																<ul className="DSskillsList">
																	<li>
																		<img
																			src={image.greentickarrow}
																			className="mr-2"
																		/>
																		{skill?.name}
																		{!progressCourseDisabled(
																			"skill",
																			skill?.id
																		) &&
																			!showEnrollPlan && (
																				<span
																					className="pointer"
																					onClick={() =>
																						handleRemoveTagDimSkillCourse(
																							"skill",
																							value,
																							skill,
																							"",
																							true
																						)
																					}
																				>
																					{/* <i class="fa-regular fa-trash-can"></i> */}
																					{/* <i class="fa-light fa-square-xmark"></i> */}
																					<i class="fa-regular fa-circle-xmark"></i>
																				</span>
																			)}
																		<div className="DS_List">
																			{/* <h4 className={`flexone`}><i class="fa-solid fa-play mr-2"></i > Course(s)</h4> */}
																			{skill?.courses?.map(
																				(course, count) =>
																					course?.isSelected && (
																						<ul className="DSCoursenameList">
																							<li
																								className={`${course?.name.length > 36
																									? "longCourse_Name"
																									: ""
																									}`}
																							>
																								<span>
																									<img
																										src={image.mortarboard}
																										className="mr-2"
																									/>
																									{/* {course?.name} */}
																									<React.Fragment>
																										{course?.name?.length > 36 ? (
																											<ReactTooltip
																												id={course?.name}
																											>
																												<p>{course?.name}</p>
																											</ReactTooltip>
																										) : (
																											""
																										)}
																										<div
																											data-for={course.name}
																											data-event-off=""
																											data-tip
																										>
																											<p className="pointer">
																												{textTrim(
																													course.name,
																													36
																												)}
																											</p>
																										</div>
																									</React.Fragment>
																									{!progressCourseDisabled(
																										"course",
																										course?.id
																									) &&
																										!showEnrollPlan && (
																											<span
																												className="pointer"
																												onClick={() =>
																													handleRemoveTagDimSkillCourse(
																														"course",
																														value,
																														skill,
																														course,
																														true
																													)
																												}
																											>
																												{/*
                                                       */}
																												{/* <i class="fa-light fa-square-xmark"></i> */}
																												<i class="fa-regular fa-circle-xmark"></i>
																											</span>
																										)}
																								</span>
																							</li>
																						</ul>
																					)
																			)}
																		</div>
																	</li>
																</ul>
															))}
														</div>
													</li>
												))}
											</ul>
										</div>
									)}
								</div>
							</div>
						</div>

					</div>
					{
						<div className="learner_planordr_three m-0">
							<div className="form-group BDsubmitbutton d-flex m-0">
								<div className="buttonDistribotion">
									{getMyEditPlan === "Edit Plan" && !showEnrollPlan && (
										<div className="flex w-100">
											<a
												className="back_button"
												href="javascript:void(0)"
												onClick={() =>
													handleReload(
														getPlanIndex,
														dateSortingByStartDate(
															getMyPlanData?.records
														)?.reverse()[getPlanIndex]
													)
												}
											>
												<i class="fa-sharp fa-regular fa-rotate-left mr-2"></i>
												Reset
											</a>
										</div>
									)}
									{((getCoursePlanTreeLenth(coursePlanTree)?.length >= 2 &&
										getCoursePlanTreeLenth(coursePlanTree)?.length <= 6) ||
										showEnrollPlan) &&
										(loader ? (
											<div className="justify-content-end">
												<button
													className="btn-blue btn-login d-block ml-auto mr-2  w-auto pleasewaitbtn"
													disabled
												>
													<span className="RounAnimation mr-1"></span>
													Please Wait...
												</button>
											</div>
										) : (
											<button
												type="button"
												className="btn-blue btn-login d-block ml-auto mr-2  w-auto pleasewaitbtn"
												onClick={() => handleLearnerUpdatePlan(coursePlanTree)}
											>
												<span>
													<i class="fa-solid fa-paper-plane mr-2"></i>
												</span>
												Update Plan
											</button>
										))}
								</div>
							</div>
						</div>
					}
				</>
			)}

			{(screen === 3 || selectedOption === "filter") && !editPlan && (
				<>
					<div className="learning_plan_summry  ScenecerelateddQuiz p-0  setuptwo_rPnl CourseCardWrapper fullHeight100">
						<div className="learnerplanOrderone">
							<div className="Selected_paced ">
								<>
									<p>
										<span>Sessions Per Week: </span>{" "}
										{convertedNumber(selectedSitting)} ({selectedSitting}){" "}
									</p>
									<p>
										<span>Duration Per Session: </span> {selectedMinutes} minutes
									</p>
								</>
							</div>
						</div>
						<div className="learner_Planrighpnl px-3 pb-3">
							<div className=" p-0 allcourselj pb-5 mb-3">
								<div className="DraftSelctionList">
									{totalSelectedCourse == 0 ? (
										<div className="DS_List">
											<h4 className="flexone pt-0">
												<i class="fa-solid fa-play mr-2"></i>Dimension(s)
											</h4>
											<span>
												<i class="fa-regular fa-hyphen mr-2"></i>
												<i>Dimension(s) will be added per your selections.</i>
											</span>
											<div className="DS_List">
												<h4 className="flexone">
													<i class="fa-solid fa-play mr-2"></i>Skill(s)
												</h4>
												<span>
													<i class="fa-regular fa-hyphen mr-2"></i>
													<i>Skill(s) will be added per your selections.</i>
												</span>
												<div className="DS_List">
													<h4 className="flexone">
														<i class="fa-solid fa-play mr-2"></i>Course(s)
													</h4>
													<span>
														<i class="fa-regular fa-hyphen mr-2"></i>
														<i>Course(s) will be added per your selections.</i>
													</span>
												</div>
											</div>
										</div>
									) : (
										<div className="DS_List">
											{/* <h4 className={`flexone mb-2 pt-0 dim_Name_att`}><i class="fa-solid fa-play mr-2"></i>Dimension(s) </h4> */}
											<ul className="DSdimensionList">
												{dimensionTree?.map((value, index) => (
													<li className="dimensionNamehg ">
														<span>
															<img src={image.greentickarrow} className="mr-2" />
															{value?.name}
															{!progressCourseDisabled(
																"dimension",
																value?.id
															) && (
																	<span
																		className="pointer"
																		onClick={() =>
																			handleRemoveTagDimSkillCourse(
																				"dimension",
																				value,
																				"",
																				"",
																				true
																			)
																		}
																	>
																		{/* <i class="fa-regular fa-trash-can"></i> */}
																		{/* <i class="fa-light fa-square-xmark"></i> */}
																		<i class="fa-regular fa-circle-xmark"></i>
																	</span>
																)}
														</span>
														<div className="DS_List">
															{/* <h4 className={`flexone`}><i class="fa-solid fa-play mr-2"></i>Skill(s)</h4> */}
															{value?.skills?.map((skill, key) => (
																<ul className="DSskillsList">
																	<li>
																		<img
																			src={image.greentickarrow}
																			className="mr-2"
																		/>
																		{skill?.name}
																		{!progressCourseDisabled(
																			"skill",
																			skill?.id
																		) && (
																				<span
																					className="pointer"
																					onClick={() =>
																						handleRemoveTagDimSkillCourse(
																							"skill",
																							value,
																							skill,
																							"",
																							true
																						)
																					}
																				>
																					{/* <i class="fa-regular fa-trash-can"></i> */}
																					{/* <i class="fa-light fa-square-xmark"></i> */}
																					<i class="fa-regular fa-circle-xmark"></i>
																				</span>
																			)}
																		<div className="DS_List">
																			{/* <h4 className={`flexone`}><i class="fa-solid fa-play mr-2"></i>Course(s)</h4> */}
																			{skill?.courses?.map((course, count) => (
																				<ul className="DSCoursenameList">
																					<li
																						className={`${course?.name.length > 32
																							? "longCourse_Name"
																							: ""
																							}`}
																					>
																						<span>
																							<img
																								src={image.mortarboard}
																								className="mr-2"
																							/>
																							{/* {course?.name} */}
																							<React.Fragment>
																								{course?.name?.length > 32 ? (
																									<ReactTooltip id={course?.name}>
																										<p>{course?.name}</p>
																									</ReactTooltip>
																								) : (
																									""
																								)}
																								<div
																									data-for={course.name}
																									data-event-off=""
																									data-tip
																								>
																									<p className="pointer Course_Name_tip">
																										{textTrim(course.name, 32)}
																									</p>
																								</div>
																							</React.Fragment>
																							{!progressCourseDisabled(
																								"course",
																								course?.id
																							) && (
																									<span
																										className="pointer"
																										onClick={() =>
																											handleRemoveTagDimSkillCourse(
																												"course",
																												value,
																												skill,
																												course,
																												true
																											)
																										}
																									>
																										{/* <i class="fa-regular fa-trash-can"></i> */}
																										{/* <i class="fa-light fa-square-xmark"></i> */}
																										<i class="fa-regular fa-circle-xmark"></i>
																									</span>
																								)}
																						</span>
																					</li>
																				</ul>
																			))}
																		</div>
																	</li>
																</ul>
															))}
														</div>
													</li>
												))}
											</ul>
										</div>
									)}
								</div>
							</div>
						</div>

					</div>
					{
						<div className="learner_planordr_three m-0">
							<div className="form-group BDsubmitbutton d-flex m-0">
								<div className="buttonDistribotion">
									{getMyEditPlan === "Edit Plan" && (
										<div className="flex w-100">
											<a
												className="back_button"
												href="javascript:void(0)"
												onClick={() =>
													handleReload(
														getPlanIndex,
														dateSortingByStartDate(
															getMyPlanData?.records
														)?.reverse()[getPlanIndex]
													)
												}
											>
												<i class="fa-sharp fa-regular fa-rotate-left mr-2"></i>
												Reset
											</a>
										</div>
									)}
									{totalSelectedCourse > 1 &&
										totalSelectedCourse < 8 &&
										(loader ? (
											<div className="justify-content-end">
												<button
													className="btn-blue btn-login d-block ml-auto mr-2  w-auto pleasewaitbtn"
													disabled
												>
													<span className="RounAnimation mr-1"></span> Please
													Wait...
												</button>
											</div>
										) : (
											<>
												{getMyEditPlan === "New Plan" ? (
													<button
														type="button"
														className="btn-blue btn-login d-block mb-5"
														onClick={() => createPlan(dimensionTree)}
													>
														<i class="fa-solid fa-paper-plane mr-2"></i>Create
														Plan
													</button>
												) : (
													<button
														type="button"
														className="btn-blue btn-login d-block mb-5 pleasewaitbtn"
														onClick={() =>
															handleLearnerUpdatePlan(dimensionTree)
														}
													>
														<span>
															<i class="fa-solid fa-paper-plane mr-2"></i>
														</span>
														Update Plan
													</button>
												)}
											</>
										))}
								</div>
							</div>
						</div>
					}
				</>
			)}
			{screen !== 5 && selectedOption === "dimension" && !editPlan && (
				<>
					<div className="learning_plan_summry  ScenecerelateddQuiz p-0  setuptwo_rPnl CourseCardWrapper fullHeight100">
						<div className="learnerplanOrderone">
							<div className="Selected_paced ">
								<>
									<p>
										<span>Sessions Per Week: </span>{" "}
										{convertedNumber(selectedSitting)} ({selectedSitting}){" "}
									</p>
									<p>
										<span>Duration Per Session: </span> {selectedMinutes} minutes
									</p>
								</>
							</div>
						</div>
						<div className="learner_Planrighpnl px-3 pb-3">
							<div className=" p-0 allcourselj pb-5 mb-3">
								<div className="DraftSelctionList">
									{getDImSkillData?.length === 0 ? (
										<div className="DS_List">
											<h4 className="flexone pt-0">
												<i class="fa-solid fa-play mr-2"></i>Dimension(s)
											</h4>
											<span>
												<i class="fa-regular fa-hyphen mr-2"></i>
												<i>Dimension(s) will be added per your selections.</i>
											</span>
											<div className="DS_List">
												<h4 className="flexone">
													<i class="fa-solid fa-play mr-2"></i>Skill(s)
												</h4>
												<span>
													<i class="fa-regular fa-hyphen mr-2"></i>
													<i>Skill(s) will be added per your selections.</i>
												</span>
												<div className="DS_List">
													<h4 className="flexone">
														<i class="fa-solid fa-play mr-2"></i>Course(s)
													</h4>
													<span>
														<i class="fa-regular fa-hyphen mr-2"></i>
														<i>Course(s) will be added per your selections.</i>
													</span>
												</div>
											</div>
										</div>
									) : (
										<div className="DS_List">
											{/* <h4 className={`flexone mb-2 pt-0 dim_Name_att`}><i class="fa-solid fa-play mr-2"></i>Dimension(s) </h4> */}
											<ul className="DSdimensionList">
												{getDImSkillData?.map((value, index) => (
													<li className="dimensionNamehg ">
														<span>
															<i class="fa-regular fa-hyphen mr-2"></i>
															{value?.name}
															{!progressCourseDisabled(
																"dimension",
																value?.id
															) && (
																	<span
																		className="pointer"
																		onClick={() =>
																			handleRemoveTagDimSkillCourse(
																				"dimension",
																				value,
																				"",
																				"",
																				true
																			)
																		}
																	>
																		{/* <i class="fa-regular fa-trash-can"></i> */}
																		{/* <i class="fa-light fa-square-xmark"></i> */}
																		<i class="fa-regular fa-circle-xmark"></i>
																	</span>
																)}
														</span>
														<div className="DS_List">
															{/* <h4 className={`flexone`}><i class="fa-solid fa-play mr-2"></i>Skill(s)</h4> */}
															{value?.skills?.map((skill, key) => (
																<ul className="DSskillsList">
																	<li>
																		<i class="fa-regular fa-hyphen mr-2"></i>
																		{skill?.name}
																		{!progressCourseDisabled(
																			"skill",
																			skill?.id
																		) && (
																				<span
																					className="pointer"
																					onClick={() =>
																						handleRemoveTagDimSkillCourse(
																							"skill",
																							value,
																							skill,
																							"",
																							true
																						)
																					}
																				>
																					{/* <i class="fa-regular fa-trash-can"></i> */}
																					{/* <i class="fa-light fa-square-xmark"></i> */}
																					<i class="fa-regular fa-circle-xmark"></i>
																				</span>
																			)}
																		{skill?.isCompleted ? (
																			<div className="DS_List">
																				{skill?.courses?.map(
																					(course, count) =>
																						course?.isSelected && (
																							<ul className="DSCoursenameList">
																								<li
																									className={`${course?.name.length > 32
																										? "longCourse_Name"
																										: ""
																										}`}
																								>
																									<span>
																										<img
																											src={image.mortarboard}
																											className="mr-2"
																										/>
																										{/* {course?.name} */}
																										<React.Fragment>
																											{course?.name?.length >
																												32 ? (
																												<ReactTooltip
																													id={course?.name}
																												>
																													<p>{course?.name}</p>
																												</ReactTooltip>
																											) : (
																												""
																											)}
																											<div
																												data-for={course.name}
																												data-event-off=""
																												data-tip
																											>
																												<p className="pointer Course_Name_tip">
																													{textTrim(
																														course.name,
																														32
																													)}
																												</p>
																											</div>
																										</React.Fragment>
																										{!progressCourseDisabled(
																											"course",
																											course?.id
																										) && (
																												<span
																													className="pointer"
																													onClick={() =>
																														handleRemoveTagDimSkillCourse(
																															"course",
																															value,
																															skill,
																															course,
																															true
																														)
																													}
																												>
																													{/* <i class="fa-regular fa-trash-can"></i> */}
																													{/* <i class="fa-light fa-square-xmark"></i> */}
																													<i class="fa-regular fa-circle-xmark"></i>
																												</span>
																											)}
																									</span>
																								</li>
																							</ul>
																						)
																				)}
																			</div>
																		) : (
																			<div className="DS_List">
																				<h4 className="flexone">
																					<i class="fa-solid fa-play mr-2"></i>
																					Course(s)
																				</h4>
																				<span>
																					<i class="fa-regular fa-hyphen mr-2"></i>
																					<i>
																						Course(s) will be added per your
																						selections.
																					</i>
																				</span>
																			</div>
																		)}
																	</li>
																</ul>
															))}
														</div>
													</li>
												))}
											</ul>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					{/* <div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip "><div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 "><div class="w-100"></div></div></div> */}

					{getMyEditPlan === "Edit Plan" && (
						<div className="learner_planordr_three m-0">
							<div className="form-group BDsubmitbutton d-flex m-0">
								<div className="buttonDistribotion">
									<div className="flex w-100">
										<a
											className="back_button"
											href="javascript:void(0)"
											onClick={() =>
												handleReload(
													getPlanIndex,
													dateSortingByStartDate(
														getMyPlanData?.records
													)?.reverse()[getPlanIndex]
												)
											}
										>
											<i class="fa-sharp fa-regular fa-rotate-left mr-2"></i>
											Reset
										</a>
									</div>
									{getCoursePlanTreeLenth(getDImSkillData)?.length >= 2 &&
										getCoursePlanTreeLenth(getDImSkillData)?.length <= 7 &&
										(loader ? (
											<div className="justify-content-end">
												<button
													className="btn-blue btn-login d-block ml-auto mr-2  w-auto pleasewaitbtn"
													disabled
												>
													<span className="RounAnimation mr-1"></span> Please
													Wait...
												</button>
											</div>
										) : (
											<button
												type="button"
												className="btn-blue btn-login d-block mb-5 pleasewaitbtn pleasewaitbtn"
												onClick={() => {
													const conditionMet = getDImSkillData?.some((item) =>
														item?.skills?.some(
															(skl) =>
																(skl?.isSelected === true &&
																	skl?.isCompleted === false) ||
																skl?.courses?.length === 0
														)
													);

													if (conditionMet) {
														handleNumberOfCourses("createplan");
													} else {
														handleLearnerUpdatePlan(getDImSkillData);
													}
												}}
											>
												<span>
													<i class="fa-solid fa-paper-plane mr-2"></i>
												</span>
												Update Plan
											</button>
										))}
								</div>
							</div>
						</div>
					)}

				</>
			)}
			{[5]?.includes(screen) && (
				<>
					<div className="learning_plan_summry  ScenecerelateddQuiz p-0  setuptwo_rPnl CourseCardWrapper fullHeight100">
						<div className="learnerplanOrderone">
							<div className="Selected_paced ">
								<>
									<p>
										<span>Sessions Per Week: </span>{" "}
										{convertedNumber(selectedSitting)} ({selectedSitting}){" "}
									</p>
									<p>
										<span>Duration Per Session: </span> {selectedMinutes} minutes
									</p>
								</>
							</div>
						</div>
						<div className="learner_Planrighpnl px-3 pb-3">
							<div className=" p-0 allcourselj pb-5 mb-3">
								<div className="DraftSelctionList">
									{!dimSkillCoursePlanTree?.find(
										(value) => value?.isSelected === true
									) ? (
										<div className="DS_List">
											<h4 className="flexone pt-0">
												<i class="fa-solid fa-play mr-2"></i>Dimension(s)
											</h4>
											<span>
												<i class="fa-regular fa-hyphen mr-2"></i>
												<i>Dimension(s) will be added per your selections.</i>
											</span>
											<div className="DS_List">
												<h4 className="flexone">
													<i class="fa-solid fa-play mr-2"></i>Skill(s)
												</h4>
												<span>
													<i class="fa-regular fa-hyphen mr-2"></i>
													<i>Skill(s) will be added per your selections.</i>
												</span>
												<div className="DS_List">
													<h4 className="flexone">
														<i class="fa-solid fa-play mr-2"></i>Course(s)
													</h4>
													<span>
														<i class="fa-regular fa-hyphen mr-2"></i>
														<i>Course(s) will be added per your selections.</i>
													</span>
												</div>
											</div>
										</div>
									) : (
										<div className="DS_List">
											{/* <h4 className={`flexone mb-2 pt-0 dim_Name_att`}><i class="fa-solid fa-play mr-2"></i>Dimension(s) </h4> */}
											<ul className="DSdimensionList">
												{dimSkillCoursePlanTree?.map(
													(value, index) =>
														value?.isSelected && (
															<li className="dimensionNamehg ">
																{value?.isCompleted ? (
																	<img
																		src={image.greentickarrow}
																		className="mr-2"
																	/>
																) : (
																	<span>
																		<i class="fa-regular fa-hyphen mr-2"></i>
																	</span>
																)}
																{value?.name}
																{!progressCourseDisabled(
																	"dimension",
																	value?.id
																) && (
																		<span
																			className="pointer"
																			onClick={() =>
																				handleRemoveDimensionSkillCourse(
																					"dimension",
																					value,
																					"",
																					"",
																					true
																				)
																			}
																		>
																			{/* <i class="fa-regular fa-trash-can"></i> */}
																			{/* <i class="fa-light fa-square-xmark"></i> */}
																			<i class="fa-regular fa-circle-xmark"></i>
																		</span>
																	)}
																<div className="DS_List">
																	{/* <h4 className={`flexone`}><i class="fa-solid fa-play mr-2"></i>Skill(s)</h4> */}
																	{value?.skills?.map(
																		(skill, key) =>
																			skill?.isSelected && (
																				<ul className="DSskillsList">
																					<li>
																						{skill?.isCompleted ? (
																							<img
																								src={image.greentickarrow}
																								className="mr-2"
																							/>
																						) : (
																							<span>
																								<i class="fa-regular fa-hyphen mr-2"></i>
																							</span>
																						)}
																						{skill?.name}
																						{!progressCourseDisabled(
																							"skill",
																							skill?.id
																						) && (
																								<span
																									className="pointer"
																									onClick={() =>
																										handleRemoveDimensionSkillCourse(
																											"skill",
																											value,
																											skill,
																											"",
																											true
																										)
																									}
																								>
																									{/* <i class="fa-regular fa-trash-can"></i> */}
																									{/* <i class="fa-light fa-square-xmark"></i> */}
																									<i class="fa-regular fa-circle-xmark"></i>
																								</span>
																							)}

																						{skill?.isCompleted ? (
																							<div className="DS_List">
																								{/* <h4 className={`flexone`}><i class="fa-solid fa-play mr-2"></i>Course(s)</h4> */}
																								{skill?.courses?.map(
																									(course, count) =>
																										course?.isSelected && (
																											<ul className="DSCoursenameList">
																												<li
																													className={`${course?.name.length >
																														32
																														? "longCourse_Name"
																														: ""
																														}`}
																												>
																													<span>
																														<img
																															src={
																																image.mortarboard
																															}
																															className="mr-2"
																														/>
																														{/* {course?.name} */}
																														<React.Fragment>
																															{course?.name
																																?.length > 32 ? (
																																<ReactTooltip
																																	id={
																																		course?.name
																																	}
																																>
																																	<p>
																																		{course?.name}
																																	</p>
																																</ReactTooltip>
																															) : (
																																""
																															)}
																															<div
																																data-for={
																																	course.name
																																}
																																data-event-off=""
																																data-tip
																															>
																																<p className="pointer Course_Name_tip">
																																	{textTrim(
																																		course.name,
																																		32
																																	)}
																																</p>
																															</div>
																														</React.Fragment>
																														{!progressCourseDisabled(
																															"course",
																															course?.id
																														) && (
																																<span
																																	className="pointer"
																																	onClick={() =>
																																		handleRemoveDimensionSkillCourse(
																																			"course",
																																			value,
																																			skill,
																																			course,
																																			true
																																		)
																																	}
																																>
																																	{/* <i class="fa-regular fa-trash-can"></i> */}
																																	{/* <i class="fa-light fa-square-xmark"></i> */}
																																	<i class="fa-regular fa-circle-xmark"></i>
																																</span>
																															)}
																													</span>
																												</li>
																											</ul>
																										)
																								)}
																							</div>
																						) : (
																							<div className="DS_List">
																								<h4 className="flexone">
																									<i class="fa-solid fa-play mr-2"></i>
																									Course(s)
																								</h4>
																								<span>
																									<i class="fa-regular fa-hyphen mr-2"></i>
																									<i>
																										Course(s) will be added per
																										your selections.
																									</i>
																								</span>
																							</div>
																						)}
																					</li>
																				</ul>
																			)
																	)}
																</div>
															</li>
														)
												)}
											</ul>
										</div>
									)}
								</div>
							</div>
						</div>

					</div>
					{
						<div className="learner_planordr_three m-0">
							<div className="form-group BDsubmitbutton d-flex m-0">
								<div className="buttonDistribotion">
									{getMyEditPlan === "Edit Plan" && (
										<div className="flex w-100">
											<a
												className="back_button"
												href="javascript:void(0)"
												onClick={() =>
													handleReload(
														getPlanIndex,
														dateSortingByStartDate(
															getMyPlanData?.records
														)?.reverse()[getPlanIndex]
													)
												}
											>
												<i class="fa-sharp fa-regular fa-rotate-left mr-2"></i>
												Reset
											</a>
										</div>
									)}
									{getCourseId?.length >= 2 &&
										getCourseId?.length <= 7 &&
										(loader ? (
											<div className="justify-content-end">
												<button
													className="btn-blue btn-login d-block ml-auto mr-2  w-auto pleasewaitbtn"
													disabled
												>
													<span className="RounAnimation mr-1"></span> Please
													Wait...
												</button>
											</div>
										) : (
											<>
												{getMyEditPlan === "New Plan" ? (
													<button
														type="button"
														className="btn-blue btn-login d-block mb-5"
														onClick={() => {
															dimSkillCoursePlanTree?.find(
																(item) =>
																	item?.isSelected === true &&
																	item?.isCompleted === false
															)
																? handleNumberOfCourses("createplan")
																: createPlan(dimSkillCoursePlanTree);
														}}
													>
														<i class="fa-solid fa-paper-plane mr-2"></i>Create
														Plan
													</button>
												) : (
													<button
														type="button"
														className="btn-blue btn-login d-block mb-5 pleasewaitbtn"
														onClick={() =>
															dimSkillCoursePlanTree?.find(
																(item) =>
																	item?.isSelected === true &&
																	item?.isCompleted === false
															)
																? handleNumberOfCourses("createplan")
																: handleLearnerUpdatePlan(
																	dimSkillCoursePlanTree
																)
														}
													>
														<span>
															<i class="fa-solid fa-paper-plane mr-2"></i>
														</span>
														Update Plan
													</button>
												)}
											</>
										))}
								</div>
							</div>
						</div>
					}
				</>
			)}
			{(screen === 6 || selectedOption === "pathway") && (
				<>
					<div className="learning_plan_summry  ScenecerelateddQuiz p-0  setuptwo_rPnl CourseCardWrapper fullHeight100">
						<div className="learnerplanOrderone">
							<div className="Selected_paced ">
								<>
									<p>
										<span>Sessions Per Week: </span>{" "}
										{convertedNumber(selectedSitting)} ({selectedSitting}){" "}
									</p>
									<p>
										<span>Duration Per Session: </span> {selectedMinutes} minutes
									</p>
								</>
							</div>
						</div>
						<div className="learner_Planrighpnl px-3 pb-3">
							<div className=" p-0 allcourselj pb-5 mb-3">
								<div className="DraftSelctionList">
									{pathwayTree?.length === 0 && !showPathwayShimmar ? (
										<div className="DS_List">
											<h4 className="flexone pt-0">
												<i class="fa-solid fa-play mr-2"></i>Dimension(s)
											</h4>
											<span>
												<i class="fa-regular fa-hyphen mr-2"></i>
												<i>Dimension(s) will be added per your selections.</i>
											</span>
											<div className="DS_List">
												<h4 className="flexone">
													<i class="fa-solid fa-play mr-2"></i>Skill(s)
												</h4>
												<span>
													<i class="fa-regular fa-hyphen mr-2"></i>
													<i>Skill(s) will be added per your selections.</i>
												</span>
												<div className="DS_List">
													<h4 className="flexone">
														<i class="fa-solid fa-play mr-2"></i>Course(s)
													</h4>
													<span>
														<i class="fa-regular fa-hyphen mr-2"></i>
														<i>Course(s) will be added per your selections.</i>
													</span>
												</div>
											</div>
										</div>
									) : (
										<>
											{!showPathwayShimmar ? (
												<div className="DS_List">
													{/* <h4 className={`flexone mb-2 pt-0 dim_Name_att`}><i class="fa-solid fa-play mr-2"></i>Dimension(s) </h4> */}
													<ul className="DSdimensionList">
														{pathwayTree?.map((value, index) => (
															<li className="dimensionNamehg ">
																<span>
																	<img
																		src={image.greentickarrow}
																		className="mr-2"
																	/>
																	{value?.name}
																	<span
																		className="pointer"
																		onClick={() =>
																			handleRemoveTagDimSkillCourse(
																				"dimension",
																				value,
																				"",
																				"",
																				true
																			)
																		}
																	>
																		{/* <i class="fa-regular fa-trash-can"></i> */}
																		{/* <i class="fa-light fa-square-xmark"></i> */}
																		<i class="fa-regular fa-circle-xmark"></i>
																	</span>
																</span>
																<div className="DS_List">
																	{/* <h4 className={`flexone`}><i class="fa-solid fa-play mr-2"></i>Skill(s)</h4> */}
																	{value?.skills?.map((skill, key) => (
																		<ul className="DSskillsList">
																			<li>
																				<img
																					src={image.greentickarrow}
																					className="mr-2"
																				/>
																				{skill?.name}
																				<span
																					className="pointer"
																					onClick={() =>
																						handleRemoveTagDimSkillCourse(
																							"skill",
																							value,
																							skill,
																							"",
																							true
																						)
																					}
																				>
																					{/* <i class="fa-regular fa-trash-can"></i> */}
																					{/* <i class="fa-light fa-square-xmark"></i> */}
																					<i class="fa-regular fa-circle-xmark"></i>
																				</span>
																				<div className="DS_List">
																					{/* <h4 className={`flexone`}><i class="fa-solid fa-play mr-2"></i>Course(s)</h4> */}
																					{skill?.courses?.map(
																						(course, count) =>
																							course?.isSelected && (
																								<ul className="DSCoursenameList">
																									<li
																										className={`${course?.name.length > 32
																											? "longCourse_Name"
																											: ""
																											}`}
																									>
																										<span>
																											<img
																												src={image.mortarboard}
																												className="mr-2"
																											/>
																											{/* {course?.name} */}
																											<React.Fragment>
																												{course?.name?.length >
																													32 ? (
																													<ReactTooltip
																														id={course?.name}
																													>
																														<p>{course?.name}</p>
																													</ReactTooltip>
																												) : (
																													""
																												)}
																												<div
																													data-for={course.name}
																													data-event-off=""
																													data-tip
																												>
																													<p className="pointer Course_Name_tip">
																														{textTrim(
																															course.name,
																															32
																														)}
																													</p>
																												</div>
																											</React.Fragment>
																											<span
																												className="pointer"
																												onClick={() =>
																													handleRemoveTagDimSkillCourse(
																														"course",
																														value,
																														skill,
																														course,
																														true
																													)
																												}
																											>
																												{/* <i class="fa-regular fa-trash-can"></i> */}
																												{/* <i class="fa-light fa-square-xmark"></i> */}
																												<i class="fa-regular fa-circle-xmark"></i>
																											</span>
																										</span>
																									</li>
																								</ul>
																							)
																					)}
																				</div>
																			</li>
																		</ul>
																	))}
																</div>
															</li>
														))}
													</ul>
												</div>
											) : (
												<ShimmerPostItem
													card
													title
													cta
													imageType="thumbnail"
													imageWidth={80}
													imageHeight={120}
													contentCenter
												/>
											)}
										</>
									)}
								</div>
							</div>
						</div>

					</div>
					{coursesArr?.length >= 2 && coursesArr?.length <= 6 && (
						<div className="learner_planordr_three m-0">
							<div className="form-group BDsubmitbutton d-flex m-0">
								<div className="buttonDistribotion">
									{loader ? (
										<div className="justify-content-end">
											<button
												className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
												disabled
											>
												<span className="RounAnimation mr-1"></span> Please
												Wait...
											</button>
										</div>
									) : (
										<button
											type="button"
											className="btn-blue btn-login d-block mb-5"
											onClick={() => createPlan(pathwayTree)}
										>
											<i class="fa-solid fa-paper-plane mr-2"></i>Create Plan
										</button>
									)}
								</div>
							</div>
						</div>
					)}
				</>
			)}
			{console.log("CourseProgresComponent 1 : ", showProgress, showPlanCourseData?.length, showPlanCourseData)}
			{showProgress && (
				<>
					<div className="CourseCardWrapper fullHeight100 ">
						{hadleNextBackInMyPlan()}
						<div className="ScenecerelateddQuiz p-0 allcourselj">
							{/* <div class="d-flex justify-content-between ps-3 pe-3 pt-2"><div class="backpageStrip p-0"><a href="javascript:void(0)" class="flexone"><span class=""><i class="fa-solid fa-angle-left mr-1"></i></span>Back</a></div><div class="disabledevent backpageStrip p-0"><a href="#">Next<span class=""><i class="fa-solid fa-angle-right ml-1"></i></span></a></div></div> */}

							{showPlanCourseData?.length > 0 &&
								getSequnceSort(showPlanCourseData)?.map(
									(cour, index) =>
										cour.isreporting &&
										(cour?.progress > 0 || cour?.isEnrolled) && (
											<CourseProgresComponent
												index={index}
												courCount={courCount++}
												skill={cour?.skill}
												cour={cour}
												showProgress={showProgress}
												handleOpenpopup={handleOpenpopup}
											/>
										)
								)}
						</div>
					</div>
					<div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip "><div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 "><div class="w-100"></div></div></div>
				</>
			)}
		</>
	);
};

export default LearnerPlanRightPanel;