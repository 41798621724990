import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { resetEmailResponse, resetLoginResponse, showModal } from "../../redux/actions";
import { getCapitalized } from "../../utils/helper";
const MyLearnerPlanRedirectPopup = ({ handleRedirectPopUp }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { defaultChildData, loggedInUser } = useSelector((state) => state.collections);

    const hideShowBox = () => {
        dispatch(showModal());
        dispatch(resetLoginResponse());
        dispatch(resetEmailResponse());
    };

    return (
        <div className="sharepopup  AreyousurePopup areyousurenewpopup welcome_new_poup">
            <div className="modal  show d-flex" id="schoolactivity161" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content courseInformation schoolcourse ">
                        <div className="modal-body p-5 pb-3 mx-3">
                            <div className="closepopup_icon pointer" onClick={() => hideShowBox()}>
                                <i class="fa-sharp fa-light fa-circle-xmark"></i>
                            </div>
                            <div className="sharewithfrnd ">
                                <h3 className="text-center">Welcome to the Plan builder!</h3>
                                {/* <p className="mb-3 pb-2 pt-2 mt-3 text-left" >
                                    You do not currently have a Plan for {getCapitalized(defaultChildData?.firstName)}.
                                    Building a plan only takes a few minutes and can be changed or added at any time. Click below to begin, you will see a recommended plan to start with.
                                </p> */}
                                <p className="mb-3 pb-2 pt-2 mt-3 text-left" >
                                    You do not have a Plan for {getCapitalized(defaultChildData?.firstName)}. We recommend creating one, as it allows you to schedule one or more courses for {getCapitalized(defaultChildData?.firstName)}, and facilitates an optimal learning experience. Building a Plan only takes a few minutes and can be changed or added anytime. Click below to begin.
                                </p>
                            </div>
                            {loggedInUser?.role?.name === "PARENT" && (
                                <div className="welcomeAgain_Poup skipnow p-5 pt-2 pb-2">
                                    <ul className="flex pointer">
                                        <li onClick={() => dispatch(showModal())}>
                                            <p className="pointer">Skip Now</p>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            <div className="buttonDistribotion justify-content-center flex-wrap mt-3 skipnow">
                                {
                                    loggedInUser?.role?.name === "PARENT" ? (
                                        <button
                                            type="button"
                                            className="btn-blue btn-login d-block mb-5 w-auto"
                                            onClick={() => handleRedirectPopUp(false)}
                                        >
                                            Create Plan
                                        </button>
                                    ) : (
                                        <ul className="flex ">
                                            <li onClick={() => dispatch(showModal())}>
                                                <p className="pointer">Skip Now</p>
                                            </li>
                                        </ul>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MyLearnerPlanRedirectPopup;