import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { breadcrumb, showPanel } from "../../redux/actions";
import { getDashBoarPlanSummaryData, getGrowthSurverReport, getOverAllProgress, getPlanSummaryReport, getSkillProgress, getSocialActivityDetail, intelligenceGrowthSurvey, reportSpecific } from "../../redux/actions/APIs";
import * as image from "../../resources/images";
import { PATHS } from "../../utils";
import Home from "../Home";
import LessonCongratulationsPopup from "../dimensions/course/vickycourses/LessonCongratulationsPopup";
import GrowthSurveyRightPanel from "../learnerplan/GrowthSurveyRightPanel";
import LearnerPlanRightPanel from "../learnerplan/LearnerPlanRightPanel";
import CongratulationCard from "../widget/CongratulationCard";
import BurgerTab from "./BurgerTab";
import ConnectedSkillsReport from "./ConnectedSkillsReport";
import GrowthSurveyReport from "./GrowthSurveyReport";
import OverallProgressReport from "./OverallProgressReport";
import PlanSummaryReport from "./PlanSummaryReport";

const StudentReports = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [filteredStudentData, setFilteredStudentData] = useState();

	const { defaultChildData, loggedInUser, reportProgData, growthSurverData, planSummaryData, getMyPlanData, skillProgressData, showPanelObj } = useSelector((state) => state.collections);

	const reportType = location?.state?.type;
	const dimObj = location?.state?.dimObj;
	const studentId = location?.state?.studentId;
	const usertype = location?.state?.usertype;
	const reportDim = location?.state?.reportDim;
	const progressType = reportType === "OverallProgress" ? "Student Progress" : (reportType === "GrowthSurvey" ? "Growth Survey" : reportType === "PlanSummary" ? "Advancement Summary" : "Dimensional Progress")

	useEffect(() => {
		if (studentId !== undefined) {
			const filteredData = skillProgressData?.records?.filter(record => record?.id === studentId);
			setFilteredStudentData(filteredData);
		}
	}, [studentId, skillProgressData])

	useEffect(() => {
		dispatch(breadcrumb({
			title: "Report",
			subTitle: progressType,
			profileName: dimObj ? dimObj?.name : "",
		}));
	}, [location?.state])

	useEffect(() => {
		if (loggedInUser) {
			if (reportType === "OverallProgress") {
				dispatch(getOverAllProgress(loggedInUser?.id));
			} else if (reportType === "GrowthSurvey") {
				dispatch(getGrowthSurverReport(loggedInUser?.id));
			} else if (reportType === "PlanSummary") {
				dispatch(getPlanSummaryReport(loggedInUser?.id));
			} else if (reportType === "connectedSkillReport") {
				dispatch(getSkillProgress(dimObj?.id, loggedInUser?.id));
			}
		}

	}, [reportType, loggedInUser, location?.state])

	const handleReports = (value) => {
		history.push({
			pathname: PATHS.STUDENT_REPORTS,
			state: { type: value },
		});
	}

	const showStudentProfile = (studentId, courseId) => {
		history.push({
			pathname: PATHS.STUDENT_PROFILE,
			state: {
				studentId: studentId,
				courseId: courseId,
				type: usertype === "student" ? location?.state?.profileType : reportType,
				studentType: progressType,
				dimObj: usertype === "teacher" ? dimObj : location?.state?.profileType == reportType ? reportDim : "",
			},
		});
	}
	const backToHome = () => {
		history.push({
			pathname: PATHS.HOME,
		});
	}


	useEffect(() => {
		dispatch(reportSpecific());
		dispatch(intelligenceGrowthSurvey());
		dispatch(getDashBoarPlanSummaryData());
		dispatch(showPanel());
	}, [])

	const [showPlanCourseData, setShowPlanCourseData] = useState([]);
	const gotoSelectedPlan = (planIndex) => {
		let courses = [];
		getMyPlanData?.records[planIndex]?.dimensions?.map((dim, index) => {
			dim?.skills?.map((skill, key) => {
				skill?.courses?.map((course, cKey) => {
					course.skill = skill;
					course.dimension = { key: dim?.id, value: dim?.name }
					courses.push(course);
				})
			})
		})
		setShowPlanCourseData(courses);
	}

	useEffect(() => {
		if (getMyPlanData?.records?.length > 0) {
			gotoSelectedPlan(0);
		}
	}, [getMyPlanData])

	const [showCongratulationModel, setShowCongratulationModel] = useState(false);
	const [congratulationPopup, setCongratulationPopup] = useState(false);
	const [courseDetail, setCourseDetail] = useState([]);
	const [courseActivityId, setCourseActivityId] = useState([]);

	const handleCongratulationPopup = (vl) => {
		setCongratulationPopup(vl);
	};

	const getDataFromCourseProgress = (result, data, course) => {
		setCourseDetail(course);
		setCourseActivityId(data);
		dispatch(getSocialActivityDetail());
		if (course?.isAcademic) {
			setCongratulationPopup(result);
		} else {
			setShowCongratulationModel(result);
		}
	};

	const handleReportHeadder = (value) => {
		let Obj = {};
		Obj = reportType === "OverallProgress" ? { "type": "Student Progress", "icon": image.student_ProfileIcons } :
			reportType === "GrowthSurvey" ? { "type": "Growth Survey", "icon": image.growthSurveyIcon1 } :
				reportType === "PlanSummary" ? { "type": "Advancement Summary", "icon": image.PlanSummaryIcon } : { "type": "Skills Connection", "icon": image.skillsConnectionIcon }

		return Obj[value];
	}

	const handleBackReview = () => {
		dispatch(showPanel());
	};

	return (
		<div>
			<Home>
				<div className="d-flex flex-wrap SpecialLeftpanel w-100">
					<div className="d-flex w-100 align-items-start overflow-visible">
						<div className="LeftbarPannel p-0" id="">
							<div class="form-title mt-0 mb-0 Enrollcoursetitle heading p-0">
								{/* <h2
										data-toggle="collapse"
										class="m-0 pt-0 pb-1 w-100 flex justify-content-between "
									>
										<span className="flexone">
											{" "}
											<img src={handleReportHeadder("icon")} className="mr-2 report_Iocns" alt="" />
											{handleReportHeadder("type")}
										</span>
									</h2> */}
								{loggedInUser?.role?.name !== "STUDENT" && <BurgerTab reportType={reportType} handleReports={handleReports} />}

							</div>
							<div className="CourseCardWrapper fullHeight100 ">

								{location?.state?.type === "OverallProgress" && (
									<OverallProgressReport studentData={reportProgData?.records} showStudentProfile={showStudentProfile} />
								)}
								{location?.state?.type === "GrowthSurvey" && (
									<GrowthSurveyReport growthData={growthSurverData?.records} showStudentProfile={showStudentProfile} />
								)}
								{location?.state?.type === "PlanSummary" && (
									<PlanSummaryReport planSummaryData={planSummaryData?.records} showStudentProfile={showStudentProfile} />
								)}
								{location?.state?.type === "connectedSkillReport" && (
									<ConnectedSkillsReport skillProgressData={filteredStudentData && filteredStudentData?.length > 0 ? filteredStudentData : skillProgressData?.records} showStudentProfile={showStudentProfile}
										usertype={usertype}
										backToHome={backToHome}
										dimObj={dimObj}
										studentId={studentId}
										loggedInUser={loggedInUser}
									/>
								)}
							</div>
							<div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
								{/* <div class="form-group BDsubmitbutton d-flex justify-content-right align-items-center m-0 ">
								
								
											
												<div className="w-100">
													<button class="btn-blue btn-login d-block float-right w-auto"
														>Next
														<span>
															<i className="fa-solid fa-arrow-right m-0 ml-2"></i>
														</span>
													</button>
												</div>
											
							</div> */}
							</div>
						</div>
						{showPanelObj?.type === "showGrowthSurveyPanel" ?
							<GrowthSurveyRightPanel handleBackReview={handleBackReview} />
							:
							<div className='RightbarPannel p-0 rightpannelSticky newcoursecardpanel home_page_rgt_pnl'>
								<LearnerPlanRightPanel
									screen={1}
									selectedSitting={2}
									selectedMinutes={30}
									selectedOption={"home"}
									coursePlanTree={[]}
									timezoneValue={"US/Eastern"}
									showPlanCourseData={showPlanCourseData}
									getDataFromCourseProgress={getDataFromCourseProgress}
								/>
							</div>
						}
						{/* <div className="RightbarPannel p-0 rightpannelSticky newcoursecardpanel setuptwo_rPnl">
              <div className="heading p-0 border-0">
                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                  <h2
                    data-toggle="collapse"
                    class="m-0 pt-0 pb-1 w-100 flex justify-content-between "
                  >
                    <span>
                      {" "}
                      <img
                        src={image.DiemensionProgress}
                        className="mr-2"
                        alt=""
                      />
                      Progress: My Pathway
                    </span>
                  </h2>
                </div>
              </div>
            </div> */}
					</div>
				</div>

				{showCongratulationModel && !courseDetail?.isAcademic && (
					<CongratulationCard
						courseActivityId={courseActivityId}
						handleOpenpopup={getDataFromCourseProgress}
						isContinueButtonClick={false}
					/>
				)}

				{congratulationPopup && courseDetail?.isAcademic && (
					<LessonCongratulationsPopup
						handleCongratulationPopup={handleCongratulationPopup}
						courseActivityId={courseActivityId}
					/>
				)}
			</Home>
		</div>
	);
};
export default StudentReports;