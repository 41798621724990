/** @format */

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
export { PrivateRoute, PublicRoute };

export const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;
//  export const API_BASE_PATH = "https://vickyknowsapi-uat.link/api/v1"; //premium dev
// export const API_BASE_PATH = "https://vickyknowsapi-dev.link/api/v1";
//export const API_BASE_PATH = "http://192.168.1.22:5000/api/v1"
//  export const API_BASE_PATH = "http://192.168.6.45:5000/api/v1";

export const TOAST_TIMER = 5000;
export const PATHS = {
  LANDINGPAGE: "/",
  USERSIGNUP: "/signup",
  USERSIGNIN: "/signin",
  VICKY: "/vicky-landingPage",
  HOME: "/home",
  INIT_ASSESS: "/profile/initial-assessment/:id",
  INIT_ASSESS_STR: "/profile/initial-assessment/",
  CHANGEPASSWORD: "/profile/change-password/:id",
  CHANGEPASSWORD_STR: "/profile/change-password/",
  AREA: "/area",
  BASICDETAILS: "/profile/basic-details/:id",
  BASICDETAILS_STR: "/profile/basic-details/",
  EDUCATION: "/profile/education/:id",
  EDUCATION_STR: "/profile/education/",
  EDIT_EDUCATION: "/profile/edit-education/:eduId/:id",
  EDIT_EDUCATION_STR: "/profile/edit-education/",
  EXPERIENCE: "/profile/experience/:id",
  EXPERIENCE_STR: "/profile/experience/",

  EDIT_EXPERIENCE: "/profile/edit-experience/:expId/:id",
  EDIT_EXPERIENCE_STR: "/profile/edit-experience/",

  PROFILEPERSONALITY: "/profile/personality/:id",
  PROFILEPERSONALITY_STR: "/profile/personality/",

  PROFILESMARTQUIZ: "/profile/smartquiz/:id",
  PROFILESMARTQUIZ_STR: "/profile/smartquiz/",

  SKILLSACQUISITION: "/profile/skillsacquisition/:id",
  SKILLSACQUISITION_STR: "/profile/skillsacquisition/",



  EMPATHYQUIZ: "/profile/empathy-quiz/:id",
  EMPATHYQUIZ_STR: "/profile/empathy-quiz/",

  INTERESTS: "/profile/interest/:id",
  INTERESTS_STR: "/profile/interest/",

  SCHOOLINFO: "/profile/school-information/:id",
  SCHOOLINFO_STR: "/profile/school-information/",
  EDITSCHOOL: "/profile/edit-school-information/:schId/:id",
  EDITSCHOOL_STR: "/profile/edit-school-information/",
  ENRICHMENTINFO: "/profile/enrichment-info/:id",
  ENRICHMENTINFO_STR: "/profile/enrichment-info/",
  EDIT_ENRICHMENTINFO: "/profile/edit-enrichment/:enrId/:id",
  EDIT_ENRICHMENTINFO_STR: "/profile/edit-enrichment/",
  CREATELGOIN: "/create-login/:id",
  CREATELGOIN_STR: "/create-login/",

  RESETPASSWORD: "/reset-password/:token",
  RESETPASSWORD_STR: "/reset-password/",
  VERIFYPASSWORD: "/verify-user/:token",
  VERIFYPASSWORD_STR: "/verify-user/",

  VERIFYERROR: "/verify-error",

  UNSUBSCRIBE: "/unsubscribe/:token",
  UNSUBSCRIBE_STR: "/unsubscribe/",

  DIMENSION: "/dimensions/:dimId/:id",
  DIMENSION_STR: "/dimensions/",

  COURSE: "/base/courses/:id",
  COURSE_STR: "/base/courses/",

  // PARENT_COURSE: "/base/parent-courses/:id",
  // PARENT_COURSE_STR: "/base/parent-courses/",

  LEARNER_JOURNEY_SKILL: "/journey/skill-courses/:id",
  LEARNER_JOURNEY_SKILL_STR: "/journey/skill-courses/",

  LEARNER_JOURNEY: "/journey/:id",
  LEARNER_JOURNEY_STR: "/journey/",

  ACCESS_DENIED: "/access-denied",
  INTERESTLIST: "/interest/Interestlist/:id",
  INTERESTLIST_STR: "/interest/Interestlist/",
  PARENTJOURNEY: "/base/parentjourney",
  BACKGROUNDCHECK: "/profile/BackgroundCheck/:id",
  BACKGROUNDCHECK_STR: "/profile/BackgroundCheck/",

  ADDCOURSE: "/profile/addcourse/:id",
  ADDCOURSE_STR: "/profile/addcourse/",

  EDITCOURSE: "/profile/editcourse/:id/:courseId",
  EDITCOURSE_STR: "/profile/editcourse/",
  ADDCURRICULUM: "/profile/addcurriculum/:id/:courseId",
  ADDCURRICULUM_STR: "/profile/addcurriculum/",
  PROVIDERASSESSMENTFORM: "/profile/providerassessmentform/:id/:courseId",
  PROVIDERASSESSMENTFORM_STR: "/profile/providerassessmentform/",
  ABOUTUS: "/profile/about-us/:id",
  ABOUTUS_STR: "/profile/about-us/",
  SCHOOLACTIVITY: "/dimension/schoolactivity",
  WHYHOLISTICGROWTH: "/base/Whyholisticgrowth",
  SUCCESS: "/base/success",
  TOKENERROR: "/base/tokenerror",
  CHATBOX: "/base/chatbox",
  PROVIDERFLOW: "/providerflow",

  SOCIALCOURSE: "/dimension/social-lessons/:id/:courseId/:skillId/:activityId",
  SOCIALCOURSE_STR: "/dimension/social-lessons/",
  PUBLIC_ADDCOURSE: "/add-content",
  SOCIALLESSONGRP: "/base/socialgrp",
  NETWORK: "/network/:id",
  NETWORK_STR: "/network/",
  BLOG: "/blog",
  HOWVICKYCANHELPS: "/Vickyhelp",
  INTEL_DETAIL: "/intellectual-details/:id/:courseId",
  INTEL_DETAIL_STR: "/intellectual-details/",

  CHATBOXPATH: "/messaging/:id",
  CONTENTBUILDERPATH: "/contentbuilder/",
  CHATBOXPATH_STR: "/messaging/",
  CHECKOUT: "/base/checkout",
  Preview: "/base/preview",
  LANDING: "/base/landing",

  HELP: "/help",
  // HELPDETAIL: "/helpdetail",
  PERSONALITY: "/personality",
  PERSONALPROFILE: "/profilepersonality",

  HELPCOURSE: "/helpdetail/courses",
  HELPUSERPROFILE: "/helpdetail/userprofile-view",
  HELPDIMSKILL: "/helpdetail/dim-skill",
  HELPHOLISTICVIEW: "/helpdetail/holistic-progress-view",
  HELPBILLING: "/helpdetail/billing",
  HELPQUERY: "/helpdetail/query",
  PROGRESSSCORING: "/helpdetail/progressandscore",
  BLOGDETAILTWO: "/blogdetail/blogdetailstwo",
  BLOGDETAILSTHREE: "/blogdetail/blogdetailsthree",
  BLOGDETAIL: "/blogdetail/blogdetails",
  BLOGDETAILSFOUR: "/blogdetail/blogdetailsfour",
  VERIFYRESET: "/verify",
  LAUNCHINGSOON: "/base/LaunchingSoon",
  FEEDBACKPATH: "dimensions/course/social/feedbackform",
  BLOGDETAILSFIVE: "/blogdetail/blogdetailsfive",
  FEEDBACKPATH: "/feedback/",
  PRIVACYPOLICY: "/termsandprivacypolicy",
  USERAGGREMENT: "/privacypolicy",
  VICKYGUIDE: "/base/VickyGuide",
  SMARTQUIZ: "/base/smartquiz",
  EMPATHYQUIZZ: "/base/empathyquiz",

  SETTING: "/setting",
  LESSIONDETAIL: "/lesson-details/:id/:courseId/:skillId/:activityId",
  LESSIONDETAIL_STR: "/lesson-details/",
  ENROLLEDPROG: "/enrolledprog",
  LANDINGPAGEDEMO1: "/Landingpagedemo1",

  COURSEPAGE_STR: "/courses/",
  COURSEPAGE: "/courses/:id",
  COURSESETTING: "/helpdetail/mycourseetting",
  LEARNER_PATHWAY: "/pathway/:id",
  LEARNER_PATHWAY_STR: "/pathway/",
  COURSESTATIC: "/coursestatic",
  DELETEACCOUNT: "/deleteaccount",
  CONTACTUS: "/contactus",
  REDIECTION: "/redirection",
  CONTACTUS: "/contactus",
  CERTIFICATEIMAGE: "/certificateimage",
  AFTERSIGNUP: "/aftersignup",
  AFTERSUBSCRIPTION: "/aftersubccription",
  LEARNER_PLAN: "/learnerplan/:id",
  STR_LEARNER_PLAN: "/learnerplan/",
  UI_TESTING_PAGE: "/uitestingpage/" /*Added by Alfaiz*/,
  MYPLAN: "/myplan/:id",
  STR_MYPLAN: "/myplan/",
  EMAIL_VARIFICATION: "/emailvarification",
  STUDENT_REPORTS: "/reports",
  STUDENT_PROFILE: "/studentprofile",
  CONNECTED_SKILL_REPORTS: "/report",
};

export const API_PATHS = {
  FREE_SIGNUP_API: `${API_BASE_PATH}/users`,
  INVITE_PARENT_SIGNUP_API: `${API_BASE_PATH}/users/individual/parent`,
  FREE_SIGNIN_API: `${API_BASE_PATH}/users/authenticate`,
  SELF_SIGNUP_API: `${API_BASE_PATH}/users/individual`,
  USER_DETAILBY_ID_API: `${API_BASE_PATH}/users`,
  UPDATE_BASIC_DETAIL_API: `${API_BASE_PATH}/users`,
  USER_EDUCATION_API: `${API_BASE_PATH}/education`,
  GET_USER_EDUCATION_API: `${API_BASE_PATH}/education/user`,
  STUDENT_EDU_ADD_API: `${API_BASE_PATH}/education/schools/`,
  COURSE_JOURNEY: `${API_BASE_PATH}/courses/journey/`,
  SETTING_DATA: `${API_BASE_PATH}/setting/course/`,
  REWARD_DATA: `${API_BASE_PATH}/setting/reward/`,
  GET_ALL_SCHOOLS_API: `${API_BASE_PATH}/education/schools`,
  GET_ALL_SCHOOLS_KEYWORD_API: `${API_BASE_PATH}/education/schools/find`,
  GET_ALL_FIELDS_API: `${API_BASE_PATH}/education/fields`,
  GET_DEGREES_API: `${API_BASE_PATH}/education/degrees`,
  CREATE_USER_EXPERIENCE_API: `${API_BASE_PATH}/experience`,
  SETTING_PASSWORD_VERIFY: `${API_BASE_PATH}/users/password/verification`,
  GET_INDUSTRIES_API: `${API_BASE_PATH}/experience/industries`,
  GET_USER_EXPERIENCE_API: `${API_BASE_PATH}/experience`,
  ADD_SCHOOL_API: `${API_BASE_PATH}/education/schools`,
  ADD_TEACHER_TOSCHOOL_API: `${API_BASE_PATH}/education/schools/teachers`,
  UPDATE_PROFILE_PICTURE_API: `${API_BASE_PATH}/users/upload`,
  CHANGE_PASSWORD_API: `${API_BASE_PATH}/users/password/reset`,
  GET_ALL_COMPANIES_API: `${API_BASE_PATH}/experience/companies`,
  GET_GRADE_LEVEL_API: `${API_BASE_PATH}/levels`,
  GET_COUNTRIES_API: `${API_BASE_PATH}/countries`,
  GET_STATE_API: `${API_BASE_PATH}/states`,
  // GET_LOGOUT: `${API_BASE_PATH}/users/jwt/expire`,
  UPDATE_UISTATE: `${API_BASE_PATH}/users/uistate`,
  LOGOUT_API: `${API_BASE_PATH}/users/jwt/expire`,
  ADD_CHILD_API: `${API_BASE_PATH}/users/child`,
  UPDATE_INIT_ASSESS: `${API_BASE_PATH}/users/init_assess/`,
  GET_COMPANY_BYKEYWORD_API: `${API_BASE_PATH}/experience/companies/find`,
  REMOVE_AVATAR_API: `${API_BASE_PATH}/users/delete/`,
  GET_EDUCATION_API: `${API_BASE_PATH}/education/`,
  UPDATE_EDUCATION_API: `${API_BASE_PATH}/education/`,
  GET_COURSE_API: `${API_BASE_PATH}/education/courses`,
  // UPDATE_EXPERIENCE_API: `${API_BASE_PATH}/experience/`,
  UPDATE_TEACHER_UPDATE: `${API_BASE_PATH}/education/schools/teachers/`,
  GET_ENRICH_KEYWORD_API: `${API_BASE_PATH}/education/enrichments/find`,
  ADD_ENRICHMENT_API: `${API_BASE_PATH}/education/enrichments`,
  ADD_TEACHER_TOENRICH_API: `${API_BASE_PATH}/education/enrichments/teachers`,
  CREATE_LOGIN_API: `${API_BASE_PATH}/users/learner/`,
  DELETE_EXPERIENCE_API: `${API_BASE_PATH}/experience/`,
  DELETE_ENRICHMENT_API: `${API_BASE_PATH}/education/enrichments/`,
  UPDATE_ENRICHMENT_API: `${API_BASE_PATH}/education/enrichments`,
  DEL_EDU_TEACHER: `${API_BASE_PATH}/education/schools/`,
  DEL_EDU_EN_TEACHER: `${API_BASE_PATH}/education/enrichments/`,
  UPDATE_EDUC_SCHOOL_API: `${API_BASE_PATH}/education/schools/`,
  GET_INTEREST_KEYWORD_API: `${API_BASE_PATH}/interests/find`,
  ADD_ABANDONED_API: `${API_BASE_PATH}/courses/abandoned`,
  GET_INTEREST_API: `${API_BASE_PATH}/interests`,

  UPLOAD_INTEREST_PICTURE_API: `${API_BASE_PATH}/interests/upload/`,
  GET_DIMENSIONS_API: `${API_BASE_PATH}/dimensions`,
  UPDATE_SCHOOL_API: `${API_BASE_PATH}/education/schools/`,

  FORGOT_API: `${API_BASE_PATH}/users/password/forgot`,
  SUBSCRIBER_API: `${API_BASE_PATH}/users/newsletter`,
  GET_TITLE_BYKEYWORD_API: `${API_BASE_PATH}/experience/titles/find`,
  GET_INDUSTRY_BYKEYWORD_API: `${API_BASE_PATH}/experience/industries/find`,
  GET_DIVISION_BYKEYWORD_API: `${API_BASE_PATH}/experience/divisions/find`,
  ACTIVITY_API: `${API_BASE_PATH}/courses/activities`,
  ACTIVITY_API_USERID_ACTIVITYID: `${API_BASE_PATH}/courses/activityDetails`,
  GET_META_DATA: `${API_BASE_PATH}/metadata`,
  COURSE_API: `${API_BASE_PATH}/courses/`,
  ABOUTAPI: `${API_BASE_PATH}/users/add/providerDetail`,
  UPLOAD_COURSE_API: `${API_BASE_PATH}/courses/upload/`,
  DOWNLOADIMAGEAPI: `${API_BASE_PATH}/users/download`,
  COURSE_ASSESSMENT_API: `${API_BASE_PATH}/courses/add/assessment/`,
  PROVIDER_COURSE_API: `${API_BASE_PATH}/courses/provider/`,
  DELETE_COURSE_API: `${API_BASE_PATH}/courses/provider/`,
  TEACHER_API: `${API_BASE_PATH}/teachers/`,
  SKILL_API: `${API_BASE_PATH}/users/skill/`,
  TITLE_API: `${API_BASE_PATH}/experience/titles`,
  SOCIAL_LOGIN: `${API_BASE_PATH}/users/authenticate/social`,
  COURSE_ALL: `${API_BASE_PATH}/courses/all/`,
  USERVERIFY_API: `${API_BASE_PATH}/users/verify/`,
  INTEREST_BY_LEVEL_ID: `${API_BASE_PATH}/users/level/skill/`,
  GET_ALL_INDUSTRIES_API: `${API_BASE_PATH}/industries`,
  GET_IND_COURSE_API: `${API_BASE_PATH}/courses/individual/score/`,
  GET_ALL_COURSE_API: `${API_BASE_PATH}/courses/all/scores/`,
  // COURSE_FILLTER: `${API_BASE_PATH}/courses/recommendation/`,
  ADD_CONTENT: `${API_BASE_PATH}/courses/upload/scenes`,
  ENROLL_COURSE: `${API_BASE_PATH}/courses/enroll/skill/course/`,
  SHARE_CERTIFICATE: `${API_BASE_PATH}/courses/activityresult/share/`,

  // Messaging App API's
  MSG_FRIENDS: `${API_BASE_PATH}/msg/friends`,
  MSG_FIND_FRIENDS: `${API_BASE_PATH}/msg/friends/find?name=`,
  MSG_ADD_FRIENDS: `${API_BASE_PATH}/msg/addFriend/`,
  MSG_ADD_Rooms_AND_PARTICIPANTS: `${API_BASE_PATH}/msg/addroomparticipant/`,
  MSG_SEND: `${API_BASE_PATH}/msg/send/`,
  MSG_ROOMS: `${API_BASE_PATH}/msg/groups/`,
  MSG_CONVERSATION: `${API_BASE_PATH}/msg/conversations/`,
  SHUFFLE_AREA: `${API_BASE_PATH}/courses/area/skill/shuffle/`,
  GET_USER_BY_USERNAME_API: `${API_BASE_PATH}/validating/username`,
  GET_VERIFY_USER_API: `${API_BASE_PATH}/verify/username`,
  STRIPE_API: `${API_BASE_PATH}/stripe/create`,
  STRIPE_PLANS: `${API_BASE_PATH}/stripe/plans`,
  STRIPE_CANCEL: `${API_BASE_PATH}/stripe/cancel`,
  STRIPE_UPGRADES: `${API_BASE_PATH}/stripe/upgrade/`,
  STRIPE_DOWNGRADES: `${API_BASE_PATH}/stripe/downgrade/`,
  STRIPE_PAYMENT_UPDATE: `${API_BASE_PATH}/stripe/paymentmethod/`,
  STRIPE_RENEW_API: `${API_BASE_PATH}/stripe/renew/subscription/`,
  UNSUBSCRIBE_NL: `${API_BASE_PATH}/users/newsletter/unsubscribe/`,
  PILOT_USER: `${API_BASE_PATH}/pilot-users`,
  VERIFY_PILOT: `${API_BASE_PATH}/pilotuser/fetchPilotUser/`,
  MODIFY_TOKEN: `${API_BASE_PATH}/pilotuser/modifyVericationCode/`,
  // Personality App API's
  PERSONALITY_TYPES: `${API_BASE_PATH}/personality/types/`,
  PERSONALITY_QUIZ: `${API_BASE_PATH}/personality/quiz/`,
  PERSONALITY_SURVEY: `${API_BASE_PATH}/personality/personalitysurvey/`,
  RESULT_PERSONALITY_TYPES: `${API_BASE_PATH}/personality/resultPerType/`,

  // Feedback API
  FEEDBACK: `${API_BASE_PATH}/personality/feedbacks`,
  // Feedback API
  FEEDBACK: `${API_BASE_PATH}/feedback/addFeedback`,

  // Pilot User API
  PILOT_USER: `${API_BASE_PATH}/pilotuser/addPilotUser`,

  // Invite a Friend
  Invite_Friend: `${API_BASE_PATH}/pilotuser/addInviteFriend`,

  // Invite a Parent
  Invite_Parent: `${API_BASE_PATH}/pilotuser/Invite/parent`,

  // Help API
  HELP_INFO: `${API_BASE_PATH}/help/addHelpInfo`,

  GET_INTELLIGENCE_QUES: `${API_BASE_PATH}/intelligences`,

  PICK_COURSE_API: `${API_BASE_PATH}/courses/random/`,
  COURSE_JOURNEY: `${API_BASE_PATH}/courses/journey/`,
  COURSE_ACTIVITY: `${API_BASE_PATH}/courses/activity/`,
  JOURNY_API: `${API_BASE_PATH}/dimensions/`,
  DIM_JOURNY_API: `${API_BASE_PATH}/dimensions/vicky/`,
  DIM_JOURNY_COURSES_API: `${API_BASE_PATH}/dimensions/journey/`,
  COURSE_DETAILS_API: `${API_BASE_PATH}/dimensions/courseDetailed/`,
  USERS_GRADE: `${API_BASE_PATH}/users/grade`,
  HOLISTIC_API: `${API_BASE_PATH}/dimensions/holistic/`,
  REWARD_SENDMAIL: `${API_BASE_PATH}/courses/sendrewardmail`,
  BILLING_GET_API: `${API_BASE_PATH}/users/subscription/`,
  INTELLIGENCES_API: `${API_BASE_PATH}/intelligences/skillevaluation/`,
  POST_INTELLIGENCES_API: `${API_BASE_PATH}/intelligences/`,
  GET_COURSE_SUMMARY_API: `${API_BASE_PATH}/courses/summary/`,
  GET_POPULAR_COURSE_API: `${API_BASE_PATH}/courses/dashboard/popularcourses/`,
  GET_COMING_SOON_API: `${API_BASE_PATH}/courses/upcomingcourses/`,
  GET_TAGS_API: `${API_BASE_PATH}/courses/tags/`,
  GET_EMPATHY_API: `${API_BASE_PATH}/personality/empathy/`,
  POST_SENDCOLLABEMAIL_API: `${API_BASE_PATH}/courses/`,
  // GET_POPULARCOURSES_API: `${API_BASE_PATH}/courses/popularcourses/`,
  GET_ALL_COURSES_TAG_API: `${API_BASE_PATH}/courses/searchTag/`,
  GET_DIM_SKILLS: `${API_BASE_PATH}/dimensions/dimSkill`,
  GET_DIM_MASTERDATA: `${API_BASE_PATH}/dimensions/masterdata/`,
  POST_DELETE_USER: `${API_BASE_PATH}/users/deleteuser`,
  //Hubspot contact
  HUBSPOT_SIGNUP_API: `${API_BASE_PATH}/contact/create`,
  STRIPE_PROMOCODE: `${API_BASE_PATH}/stripe/promocode`,
  COURSE_RATING_API: `${API_BASE_PATH}/courses`,
  LEARNER_PLAN: `${API_BASE_PATH}/dimensions/dimskill/plan`,
  DIMNSION_PLAN: `${API_BASE_PATH}/dimensions/plan/dimSkill`,
  DIMNSION_UPDATE_PLAN: `${API_BASE_PATH}/dimensions/update/plan/courses`,
  LEARNER_UPDATE_PLAN: `${API_BASE_PATH}/dimensions/plan/courses`,
  LEARNER_PLAN_TAGS: `${API_BASE_PATH}/dimensions/plan/filter`,
  DIM_SKILL_COURSES: `${API_BASE_PATH}/dimensions/plan/dimskill/courses`,
  VARIFICATION_EMAIL: `${API_BASE_PATH}/users/sendVerificationMail`,
  /* Growth Survey 16 May 2024 */
  GROWTH_SURVEY: `${API_BASE_PATH}/intelligences/gsassessment`,
  GROWTH_SURVEY_POST: `${API_BASE_PATH}/intelligences/gsassessment`,
  GET_MY_PLAN: `${API_BASE_PATH}/dimensions/user/plan/courses`,
  USERS_ENTRYPOP: `${API_BASE_PATH}/users/entryPop`,
  DELETE_PLAN: `${API_BASE_PATH}/dimensions/delete/plan`,
  KC_REPORT: `${API_BASE_PATH}/dimensions/kcreport`,
  REPORT_PROGRESS: `${API_BASE_PATH}/report/progress/report`,
  GROWTH_SURVER_REPORTS: `${API_BASE_PATH}/intelligences/growthsurveyreport`,
  PLAN_SUMMARY_REPORTS: `${API_BASE_PATH}/report/plan/summary`,
  REPOORT_SPECIFIC: `${API_BASE_PATH}/report/specific`,
  INTELLIGENCE_GROWTHSURVEY: `${API_BASE_PATH}/intelligences/userwithGrowthSurvey`,
  GET_SKILL_PROGRESS: `${API_BASE_PATH}/report/skillprogress`,
  DASHBORD_PLAN_SUMMARY: `${API_BASE_PATH}/report/dahboard/plan/summary`,
  INTELLIGENCE_COURSE_GROWTHSURVEY: `${API_BASE_PATH}/intelligences/userCoursewithGrowthSurvey`,
  REPOORT_COURSE_SPECIFIC: `${API_BASE_PATH}/report/course/specific`,
  COURSE_PLAN_SUMMARY: `${API_BASE_PATH}/report/course/plan/summary`,
  GET_DIMENSION_COURSES: `${API_BASE_PATH}/report/dimension/courses`,
  KC_REPORT_CHECKBOX: `${API_BASE_PATH}/dimensions/chkboxreport`,
  DIM_COURSE_PLAN: `${API_BASE_PATH}/dimensions/course/plan`,
  USER_WITH_DIMENSION_LEVEL_GROWTHSURVEY: `${API_BASE_PATH}/intelligences/userwithDimensionLevelGrowthSurvey`,
  GET_ACQUISITION_QUES: `${API_BASE_PATH}/intelligences/skillacquisition`,
  EDIT_PLAN_DIMSKILL: `${API_BASE_PATH}/dimensions/edit/plan/dimSkill`,
  EDIT_DIM_SKILL_COURSES: `${API_BASE_PATH}/dimensions/edit/plan/dimskill/courses`,
  UPDATE_HUBSPOT_CONTACT_RECORDS: `${API_BASE_PATH}/courses/contact`,
  SETTING_NOTIFICATION: `${API_BASE_PATH}/setting/notificationcat`,
  POST_SETTING_NOTIFICATION: `${API_BASE_PATH}/setting/notificationsetting`,
};