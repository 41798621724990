import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	resetEmailResponse,
	resetLoginResponse,
	resetMultiquizResponse,
	setCourseJourneyId,
	showModal,
} from "../../redux/actions";
import { useParams } from "../../utils/Packages";

import { getUsersEntryPopup } from "../../redux/actions/APIs";
import { PATHS } from "../../utils";
import { getCapitalized, getSequnceSort } from "../../utils/helper";

const DimRedirectPopup = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams();

	const {
		addLearner,
		loggedInUser,
		alluserdetails,
		getdimension,
		getSelectedCourseJourneyId,
		getMyPlanData,
		defaultChildData,
		modalData
	} = useSelector((state) => state.collections);

	let userData = "";
	if (addLearner?.recordId) {
		userData = addLearner?.recordId;
	} else if (loggedInUser?.children[0]?.id) {
		userData = loggedInUser?.children[0]?.id;
	} else if (loggedInUser?.role?.name === "LEARNER") {
		userData = loggedInUser?.id;
	}

	const hideShowBox = () => {
		dispatch(showModal());
		dispatch(resetLoginResponse());
		dispatch(resetEmailResponse());
	};

	const dimredirect = (userid) => {
		let previousState = alluserdetails?.records[0]?.uistate; //	localStorage.getItem("previousState");

		let isLearnerExists = false;
		let dimensions = getSequnceSort(getdimension?.records);

		if (previousState) {
			previousState = JSON.parse(previousState);

			let uistate = previousState?.uistate;
			for (let index = 0; index < uistate?.length; index++) {
				if (uistate[index]?.userid && uistate[index]?.userid === userid) {
					isLearnerExists = true;
					if (uistate[index]?.detail?.scene) {
						history.push(new URL(uistate[index]?.detail?.scene)?.pathname);
					} else if (uistate[index]?.page) {
						history.push(new URL(uistate[index]?.page)?.pathname);
					} else {
						history.push(
							PATHS.DIMENSION_STR + uistate[index]?.detail?.dimId + "/" + userid
						);
					}
					break;
				}
			}
			if (!isLearnerExists) {
				let obj = {
					userid: userid,
					detail: {
						dimension: dimensions[0]?.name,
						skill: "",
						course: "",
						scene: "",
						kc: "",
						dimId: dimensions[0]?.id,
					},
					page: "",
				};
				uistate.push(obj);
				localStorage.setItem("previousState", JSON.stringify(previousState));
				if (dimensions[0]?.id && userid) {
					history.push(PATHS.DIMENSION_STR + dimensions[0]?.id + "/" + userid);
				}
			}
		} else {
			if (dimensions[0]?.id && userid) {
				history.push(PATHS.DIMENSION_STR + dimensions[0]?.id + "/" + userid);
			}
		}
	};

	const _whereYouLeft = () => {
		hideShowBox();
		dimredirect(userData);
	};

	const viewEnrollCourse = () => {
		hideShowBox();
		history.push(
			PATHS.DIMENSION_STR +
			getSequnceSort(getdimension?.records)[0]?.id +
			"/" +
			params?.id
		);
	};

	const viewAvailableCourse = () => {
		dispatch(resetMultiquizResponse());
		hideShowBox();
		dispatch(setCourseJourneyId({ allCourse: true }));
	};

	const handleLearnerPlanPage = () => {
		hideShowBox();
		history.push(PATHS.STR_LEARNER_PLAN + loggedInUser?.children[0]?.id);
	};

	const viewSubcribeHalfScreen = () => {
		hideShowBox();
		dispatch(showModal({ type: "Subscription" }));
	};

	const handleMyPlan = () => {
		hideShowBox();
		history.push(PATHS.STR_MYPLAN + defaultChildData?.id);
	}

	const handleMyDimension = () => {
		hideShowBox();
		history.push(
			PATHS.DIMENSION_STR +
			getSequnceSort(getdimension?.records)[0]?.id +
			"/" +
			defaultChildData?.id
		);
	};

	const handleLearnerPlan = () => {
		hideShowBox();
		history.push(PATHS.STR_LEARNER_PLAN + defaultChildData?.id)
	}

	const redirectToHome = () => {
		hideShowBox();
		/*history.push({
			pathname: PATHS.HOME,
			//  state: { showGuidePopUp: true }
		});*/
	}

	const [showPopup, seShowtPopup] = useState(false);
	const handleDontShowPopup = (value) => {
		if (loggedInUser?.parentId) {
			dispatch(getUsersEntryPopup(loggedInUser?.parentId, value))
		}
		seShowtPopup(value);
	}

	return (
		<>
			{/* <div className="AddChildPopup hoosrSubModlPopup AreyousurePopup whereyouleft">
				<div className="modal d-flex" id="schoolactivity50" role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content courseInformation">
							<div className="modal-header p-0">
								<div className="heading w-100 border-0 p-0">
									<div className="NuggetPopupTitle border-0 w-100 flex">
										<div className=" d-flex align-items-center">
											<img src={image.greenflag} alt="" className="mr-2" />
											<h4>Welcome to {SITENAME.NAME} </h4>
										</div>
										<button
											className="btn btn-primary"
											data-dismiss="modal"
											onClick={() => hideShowBox()}
										>
											<i className="fa-regular fa-xmark m-0"></i>
										</button>
									</div>
								</div>
							</div>
							<div className="modal-body p-0">
								<div className="whereYouleftwtrap newwhrulft p-3 m-3">
									<div className="wlCard m-0">
										 <h3
											className="whleftbtn pointer"
											onClick={() => _whereYouLeft()}
										>
											<img src={image.booknavigation} alt="" />
											Pickup where you left off!
										</h3> 
										<h3
											className="whleftbtn pointer"
											onClick={() => handleLearnerPlanPage()}
										>
											<img src={image.booknavigation} alt="" />
											Create a Plan
										</h3>
									</div>
									<h2 className="h1 text-center m-minus-15 orbutton">
										<span className="bg-white">or</span>
									</h2>

									<div className="AlltypeDimensionbtn ">
										<div className="wlCard">
											<h3
												className="whleftbtn pointer"
												onClick={() => viewEnrollCourse()}
											>
												<img src={image.mortarboard} alt="" />
												View your enrolled courses
											</h3>
											<h3
												className="whleftbtn pointer"
												onClick={() => _whereYouLeft()}
											>
												<img src={image.booknavigation} alt="" />
												Pickup where you left off!
											</h3>
										</div>
									</div>
									<h2 className="h1 text-center m-minus-15 orbutton">
										<span className="bg-white">or</span>
									</h2>
									<div className="AlltypeDimensionbtn ">
										<div className="wlCard">
											<h3
												className="whleftbtn pointer"
												onClick={() => viewAvailableCourse()}
											>
												<img src={image.Explorecourses} alt="" />
												Explore most popular courses
											</h3>
											<h3
												className="whleftbtn pointer"
												onClick={() => viewSubcribeHalfScreen()}
											>
												<img src={image.Explorecourses} alt="" />
												Subbcribe for free accounts
											</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<div className="form-group BDsubmitbutton m-0 d-flex">
									<div className="buttonDistribotion">
										<button
											type="button"
											className="btn-blue btn-login d-block mb-5 cancelbutton"
											onClick={() => hideShowBox()}
										>
											<i className="fa-solid fa-xmark"></i> Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{!defaultChildData?.isUserPlan && !modalData?.isRecomndedPlan && loggedInUser?.role?.name === "PARENT" && (
				<div className="areyousurenewpopup welcome_new_poup pickwhwrereuleft">
					<div
						className="modal d-flex"
						id="schoolactivity167"
						role="dialog"
					>
						<div className="modal-dialog">
							<div className="modal-content courseInformation schoolcourse">
								<div className="modal-body p-5 mx-3 pb-2">
									<div className=" ">
										<div className="closepopup_icon pointer" onClick={() => hideShowBox()}>
											<i class="fa-sharp fa-light fa-circle-xmark"></i>
										</div>

										{/* <div className="imgSide w-50">
                <img src={image.thinkaboutfuture} />
				</div> */}
										<div className="">
											<h3 className="text-center">Hi, {getCapitalized(loggedInUser?.firstName)}</h3>
											{/* <p className="mb-3 pb-2 pt-2 mt-3 text-left">
												Welcome back! You do not currently have a Plan
												for {getCapitalized(defaultChildData?.firstName)}. Building a plan only takes a few
												minutes and can be changed or added at any time. Click
												below to begin, you will see a recommended plan to start
												with.
											</p> */}
											<p className="mb-3 pb-2 pt-2 mt-3 text-left">
												Welcome back! You do not have a Plan for {getCapitalized(defaultChildData?.firstName)}.
												We recommend creating one, as it allows you to schedule one or more courses for {getCapitalized(defaultChildData?.firstName)}, and facilitates an optimal learning experience. Building a Plan only takes a few minutes and can be changed or added anytime. Click below to begin.
											</p>
											<div className="buttonDistribotion justify-content-around mt-3">
												<button
													type="button"
													className="btn-blue btn-login d-block mb-5 w-auto rocket_icon"
													onClick={() => handleLearnerPlan()}
												>
													<i class="fa-light fa-rocket-launch mr-2"></i>Create Plan
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="welcomeAgain_Poup p-3 mb-3">
									<ul className="flex ">
										{/* <li>
                        <div className="parentdob mt-0">
                          <label className="Selcheckbox Qwrongopton mb-0">
                            <h5 className="QQtitle">Don't Show Again</h5>
                            <input type="checkbox" className="mr-2 termBox" />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </li> */}
										<li className="">
											<p onClick={() => redirectToHome()} className="pointer">Skip For Now</p>
										</li>
										<li className="">
											<p onClick={() => _whereYouLeft()} className="pointer">Pick up where you left off</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{(defaultChildData?.isUserPlan || modalData?.isRecomndedPlan) && (
				<div className="  areyousurenewpopup welcome_new_poup pickwhwrereuleft">
					<div className="modal d-flex" id="schoolactivity168" role="dialog">
						<div className="modal-dialog">
							<div className="modal-content courseInformation schoolcourse">
								<div className="modal-body p-5 mx-3 pb-2">
									<div className=" ">
										<div className="closepopup_icon pointer" onClick={() => hideShowBox()}>
											<i class="fa-sharp fa-light fa-circle-xmark"></i>
										</div>

										{/* <div className="imgSide w-50">
                <img src={image.thinkaboutfuture} />
				</div> */}
										<div className="">
											<h3 className="text-center">Hi, {getCapitalized(loggedInUser?.firstName)}</h3>
											<p className="mb-3 pb-2 pt-2 mt-3 text-left">
												Thank you for creating a Plan. The quickest way to access the planned material is through the My Plans tab or by pressing the My Plans button below.
											</p>
											<div className="buttonDistribotion justify-content-around mt-3">
												<button
													type="button"
													className="btn-blue btn-login d-block mb-5 w-auto rocket_icon pointer"
													onClick={() => handleMyPlan()}
												>
													<i class="fa-light fa-rocket-launch mr-2"></i>
													My Plan
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="welcomeAgain_Poup p-3 mb-3">
									<ul className="flex ">
										<li>
											<div className="parentdob mt-0" >
												<label className="Selcheckbox Qwrongopton mb-0 pointer">
													<h5 className="QQtitle">Don't Show Again</h5>
													<input type="checkbox" className="mr-2 termBox" onClick={() => handleDontShowPopup(!showPopup)} />
													<span className="checkmark"></span>
												</label>
											</div>
										</li>
										<li className="pointer">
											<p onClick={() => handleMyDimension()} className="pointer">My Dimensions</p>
										</li>
										<li className="pointer">
											<p onClick={() => _whereYouLeft()} className="pointer">Pick up where you left off</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default DimRedirectPopup;