import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { selectOptionPre, setSurveyAss } from "../../../../redux/actions";
import * as image from "../../../../resources/images";
import ConfirmationPopup from "../assessment/ConfirmationPopup";

const GrowthSurveySidebar = ({ data }) => {
  const {
    socialActivityData,
    growthSurveyData,
    surveyAssOpen,
    showReviewData,
    selectOptData,
    loggedInUser,
  } = useSelector((state) => state.collections);

  const dispatch = useDispatch();
  const param = useParams();

  const [quizObjData, setQuizObj] = useState([]);
  const [quizData, setQuizData] = useState({ pre: {}, post: {} });
  const [startPopup, setStartPopup] = useState(false);
  let quizObj = [];

  useEffect(() => {
    if (
      growthSurveyData &&
      growthSurveyData?.records[0]?.preGrowthSurveyBehavior?.length > 0 &&
      growthSurveyData?.records[0]?.postGrowthSurveyBehavior?.length > 0
    ) {
      const combinedPreSurveyQuestions = [
        ...growthSurveyData.records[0].preGrowthSurveyBehavior,
        ...growthSurveyData.records[0].preGrowthSurveyKnowledge,
        ...(growthSurveyData.records[0].preGrowthSurveyScenario || []),
      ];
      const sortedPreSurveyQuestions = combinedPreSurveyQuestions.sort(
        (a, b) => a.surveySequence - b.surveySequence
      );

      const combinedPostSurveyQuestions = [
        ...growthSurveyData.records[0].postGrowthSurveyBehavior,
        ...growthSurveyData.records[0].postGrowthSurveyKnowledge,
        ...(growthSurveyData.records[0].postGrowthSurveyScenario || []),
      ];
      const sortedPostSurveyQuestions = combinedPostSurveyQuestions.sort(
        (a, b) => a.surveySequence - b.surveySequence
      );
      setQuizData({
        pre: sortedPreSurveyQuestions,
        post: sortedPostSurveyQuestions,
      });
      setStartPopup(false);
    }
  }, [growthSurveyData]);

  if (
    growthSurveyData?.records[0]?.preProgress === 0 &&
    socialActivityData?.records[0]?.courseComp < 100
  ) {
    quizObj = quizData?.pre;
  } else {
    quizObj = quizData?.post;
  }

  const handlePreviewData = (objData, ind) => {
    const updatedObjData = { ...objData, ind: ind };
    dispatch(selectOptionPre([updatedObjData]));
  };

  //Review Functionality
  useEffect(() => {
    if (growthSurveyData) {
      let quizData;
      if (growthSurveyData?.records[0]?.preProgress > 0) {
        if (showReviewData?.flag && showReviewData?.type === "preBehaviour") {
          quizData = growthSurveyData?.records[0]?.preGrowthSurveyBehavior;
        } else if (
          showReviewData?.flag &&
          showReviewData?.type === "preKnowledgeAndScenerio"
        ) {
          quizData = [
            ...growthSurveyData.records[0].preGrowthSurveyKnowledge,
            ...(growthSurveyData.records[0].preGrowthSurveyScenario || []),
          ];
        }
      }
      if (growthSurveyData?.records[0]?.postProgress > 0) {
        if (showReviewData?.flag && showReviewData?.type === "postBehaviour") {
          quizData = growthSurveyData.records[0].postGrowthSurveyBehavior;
        } else if (
          showReviewData?.flag &&
          showReviewData?.type === "postKnowledgeAndScenerio"
        ) {
          quizData = [
            ...growthSurveyData.records[0].postGrowthSurveyKnowledge,
            ...(growthSurveyData.records[0].postGrowthSurveyScenario || []),
          ];
        }
      }

      const sortedPostSurveyQuestions = quizData?.sort(
        (a, b) => a.surveySequence - b.surveySequence
      );
      setQuizObj(sortedPostSurveyQuestions);
      setStartPopup(false);
    }
  }, [growthSurveyData, quizData, showReviewData]);

  useEffect(() => { }, [selectOptData]);

  const handleQuiz = () => {
    dispatch(setSurveyAss(true));
  };

  const handleSubmit = () => {
    setStartPopup(true);
  };

  return (
    <>
      <div className="growthsurveyPreview p-0">
        {(surveyAssOpen && selectOptData?.selectOptionData) ||
          surveyAssOpen ||
          quizObj < 0 ? (
          // ques ans preview
          <>
            <div className="p-2 mt-2 mb-2">
              {quizObj &&
                quizObj?.length > 0 &&
                quizObj?.map(
                  (opt, ind) =>
                    opt?.attempted && (
                      <div className="pb-3 d-flex flex-column pl-2" key={ind}>
                        <span
                          className="question pointer d-flex"
                          onClick={() => handlePreviewData(opt, ind)}
                        >
                          <i
                            className="fa fa-hand-o-right me-1 pt-2"
                            aria-hidden="true"
                          ></i>
                          {opt?.question}
                        </span>

                        {opt?.options.map(
                          (val, key) =>
                            val?.isUserSelected && (
                              <span className="answer" key={key}>
                                <span></span>
                                {val?.question}
                              </span>
                            )
                        )}
                      </div>
                    )
                )}
            </div>

            {/* {loggedInUser?.role?.name !== "PROVIDER" &&
              <div className="input-group full-Width-group basic_details_form pt-0 continuebtn">
                <div className="form-group flex justify-content-end m-0">
                  <div>
                    <button
                      className="btn-blue btn-login float-right"
                      onClick={() => handleSubmit()}
                      disabled={
                        Array.isArray(quizObj) && !quizObj.every((question) =>
                          question?.options?.some((option) => option?.isUserSelected)
                        )
                      }
                    >
                      <i className="fa-solid fa-paper-plane mr-2"></i>Submit
                    </button>
                  </div>
                </div>
              </div>} */}
          </>
        ) : (
          <>
            {growthSurveyData?.records[0] ? (
              growthSurveyData?.records[0]?.preProgress > 0 ||
                growthSurveyData?.records[0]?.postProgress > 0 ? (
                <div>
                  {showReviewData?.flag && (
                    <div>
                      <div className="p-2 mt-2 mb-2">
                        {quizObjData &&
                          quizObjData?.length > 0 &&
                          quizObjData?.map(
                            (opt, ind) =>
                              opt && (
                                <div
                                  className="pb-3 d-flex flex-column"
                                  key={ind}
                                >
                                  <span
                                    className="question pointer d-flex"
                                    onClick={() => handlePreviewData(opt, ind)}
                                  >
                                    <i
                                      className="fa fa-hand-o-right me-1 mt-2"
                                      aria-hidden="true"
                                    ></i>
                                    {opt?.question}
                                  </span>
                                  {opt?.options.map(
                                    (val, key) =>
                                      val?.isUserSelected && (
                                        <span
                                          className="answer d-flex"
                                          key={key}
                                        >
                                          <span>
                                            <img
                                              src={image.answerIcon}
                                              className="mr-2"
                                            />
                                          </span>
                                          {val?.question}
                                        </span>
                                      )
                                  )}
                                </div>
                              )
                          )}
                      </div>

                      {/* <div className="input-group full-Width-group basic_details_form pt-0 continuebtn emptyDivHeigth">
                        <div className="form-group BDsubmitbutton flex m-0">
                          <div></div>

                          <div>
                            {(growthSurveyData?.records[0]?.preProgress === 0 &&
                              growthSurveyData?.records[0]?.postProgress ===
                              0 &&
                              (socialActivityData?.records[0]?.courseComp <
                                28 ||
                                socialActivityData?.records[0]?.courseComp ===
                                100)) ||
                              (socialActivityData?.records[0].courseIsCompleted &&
                                growthSurveyData?.records[0]?.postProgress ===
                                0) ? (
                              <button
                                className="btn-blue btn-login d-block float-right w-auto"
                                onClick={() => handleQuiz()}
                              >
                                <i className="fa-solid fa-paper-plane mr-2"></i>
                                Begin Survey
                              </button>
                            ) : (
                              growthSurveyData?.records[0]?.postProgress !==
                              100 && (
                                <button
                                  className="btn-blue btn-login d-block float-right w-auto"
                                  onClick={() => data?.selectTabs("Series")}
                                >
                                  <i className="fa-solid fa-paper-plane mr-2"></i>
                                  Go to Lessons
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  )}

                  {!showReviewData?.flag && (
                    <div className="p-2 allcourselj">
                      <div className="p-2 rightPanelIntroScreen  position-relative">
                        <p>
                          You are off to a great start! You currently exhibit{" "}
                          {showReviewData?.growthAttempCalc}% total
                          understanding of {showReviewData?.skillName}.
                        </p>
                        <p>Here is what your score means:</p>

                        {showReviewData?.growthAttempCalc >= 0 &&
                          showReviewData?.growthAttempCalc < 26 &&
                          !showReviewData?.isPost && (
                            <>
                              <p>
                                Your scorse indicate a beginning comfort level
                                with this skill. You are most likely brand new
                                to this skill or have very limited experience
                                with it.
                              </p>
                              <p>
                                It is completely understandable to score in this
                                range because you may not have needed this skill
                                yet in your life. The best news is - you are
                                about to gain knowledge and practice so you will
                                be prepared for when you do need it!
                              </p>
                            </>
                          )}

                        {showReviewData?.growthAttempCalc > 25 &&
                          showReviewData?.growthAttempCalc < 51 &&
                          !showReviewData?.isPost && (
                            <>
                              <p>
                                Your scores suggest an advanced beginning
                                comfort level with this skill. You might be
                                aware of this skill is but have limited
                                experience applying it.
                              </p>
                              <p>
                                It is completely understandable to score in this
                                range because you may not have needed to use
                                this skill much in your life. The best news is -
                                you are about to gain knowledge and practice so
                                you will be prepared for when you do need it!
                              </p>
                            </>
                          )}

                        {showReviewData?.growthAttempCalc > 50 &&
                          showReviewData?.growthAttempCalc < 76 &&
                          !showReviewData?.isPost && (
                            <>
                              <p>
                                Your scores indicate an intermediatecomfort
                                level with this skill. You most likely are
                                familiar with this skill is and have some
                                experience using it. You would benefit most from
                                practicing with this skill and being introduced
                                to advanced concepts.
                              </p>
                              <p>
                                It is completely understandable to score in this
                                range because you may not have needed to use
                                this skill on a regular basis in your life. The
                                best news is - you are about to gain knowledge
                                and practice so you will be prepared for when
                                you do need it!
                              </p>
                            </>
                          )}

                        {showReviewData?.growthAttempCalc > 75 &&
                          !showReviewData?.isPost && (
                            <>
                              <p>
                                Your scores suggest an advanced intermediate
                                comfort level with this skill. You are familiar
                                and comfortable with this skill is and have
                                experience using it. You would benefit most from
                                practicing with this skill and being introduced
                                to more highly advanced concepts.
                              </p>
                              <p>
                                It is completely understandable to score in this
                                range because you may not have needed to use
                                this skill repeatedly in your life. The best
                                news is - you are about to gain knowledge and
                                practice so you will be prepared for when you do
                                need it!
                              </p>
                            </>
                          )}

                        {/*Post*/}

                        {showReviewData?.growthAttempCalc >= 0 &&
                          showReviewData?.growthAttempCalc < 26 &&
                          showReviewData?.isPost && (
                            <>
                              <p>
                                Rising Star: Your scores are in the beginning
                                learner making progress range! You may benefit
                                from reviewing the course material again and
                                discussing your knowledge check answers with
                                your parents or trusted adult. Practice makes
                                perfect, especially if you are newer to this
                                skill or have very limited experience with it,
                                so keep at it!
                              </p>
                            </>
                          )}

                        {showReviewData?.growthAttempCalc > 25 &&
                          showReviewData?.growthAttempCalc < 51 &&
                          showReviewData?.isPost && (
                            <>
                              <p>
                                Breakout Star: Your scores are in the advanced
                                beginning learner making good progress range!
                                It's clear you have learned some of the concepts
                                taught in the course and you may increase your
                                level even more by reviewing the course material
                                again and discussing your knowledge check
                                answers with your parents or trusted adult.
                                Practice makes perfect, especially if you are
                                newer to this skill or have limited experience
                                with it, so keep at it!
                              </p>
                            </>
                          )}

                        {showReviewData?.growthAttempCalc > 50 &&
                          showReviewData?.growthAttempCalc < 76 &&
                          showReviewData?.isPost && (
                            <>
                              <p>
                                Shooting Star: Your scores are in the
                                intermediate learner making great progress
                                raange! It's clear you have learned most of the
                                concepts taught in the course and applied these
                                concepts in the practice. You may increase your
                                level even more by reviewing the course material
                                again and discussing your knowledge check
                                answers with your parents or trusted adult.
                                Practice makes perfect, especially if you are
                                newer to this skill or have limited experience
                                with it, so keep at it!
                              </p>
                            </>
                          )}

                        {showReviewData?.growthAttempCalc > 75 &&
                          showReviewData?.isPost && (
                            <>
                              <p>
                                Superstar: Congratulations! Your scores are in
                                the advanced learner range. It's clear you have
                                learned most or all of the concepts taught in
                                the course and applied these concepts in the
                                practice. Continue practicing and share your
                                ideas with your parents or trusted adult.
                              </p>
                            </>
                          )}

                        <p>
                          Please remember, all growth is good growth so no
                          matter your results, you should be proud!
                        </p>
                        {!showReviewData?.isPost && (
                          <p>
                            Please click the 'Character' tab to get started on the
                            course and come back here to take the growth survey
                            again after you complete the course to see how much
                            you've grown!
                          </p>
                        )}

                        {showReviewData?.isPost && (
                          <p>
                            If you are looking to expand your knowledge and
                            experience on this skill even more, you can always
                            review the course material again, or explore related
                            courses.
                          </p>
                        )}
                        {/* <div className="input-group full-Width-group basic_details_form pt-0 continuebtn emptyDivHeigth">
                          <div className="form-group BDsubmitbutton flex m-0 ">
                            <div></div>

                            <div>
                              {(growthSurveyData?.records[0]?.preProgress ===
                                0 &&
                                growthSurveyData?.records[0]?.postProgress ===
                                0 &&
                                (socialActivityData?.records[0]?.courseComp <
                                  28 ||
                                  socialActivityData?.records[0]?.courseComp ===
                                  100)) ||
                                (socialActivityData?.records[0]
                                  .courseIsCompleted &&
                                  growthSurveyData?.records[0]?.postProgress ===
                                  0) ? (
                                <button
                                  className="btn-blue btn-login d-block float-right w-auto"
                                  onClick={() => handleQuiz()}
                                >
                                  <i className="fa-solid fa-paper-plane mr-2"></i>
                                  Begin Survey
                                </button>
                              ) : (
                                <>
                                  {growthSurveyData?.records[0]
                                    ?.postProgress !== 100 && (
                                      <button
                                        className="btn-blue btn-login d-block float-right w-auto"
                                        onClick={() => data?.selectTabs("Series")}
                                      >
                                        <i className="fa-solid fa-paper-plane mr-2"></i>
                                        Go to Lessons
                                      </button>
                                    )}
                                </>
                              )}
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="p-2 allcourselj ">
                    <div class="p-2 rightPanelIntroScreen position-relative">
                      <p>
                        The Growth Survey is an easy way for you to see how much
                        you gain from each course. Please take the survey now
                        before beginning the lessons and then again after the
                        course. Then you may compare them side by side!
                      </p>

                      <p>
                        The Growth Survey has 2 sections. The first section
                        includes personal reflection questions. These questions
                        will help you think about how you act when it comes to a
                        particular skill. The second section has scenario
                        questions. These will help you practice the skill and
                        apply what you learn to real world situations.
                      </p>

                      <p>
                        All growth is good growth so no matter your results, you
                        should be proud! This is not a test, there are no wrong
                        answers. Answer honestly to how the questions relate to
                        your life.
                      </p>

                      <p>
                        The growth survey points are included in your course total
                        so please be sure to take it!
                      </p>

                      <p>
                        The growth survey only takes a few minutes. Please click
                        the button to get started.
                      </p>


                    </div>
                  </div>
                  {/* <div className="input-group full-Width-group basic_details_form pt-0 continuebtn ">
                      <div className="form-group BDsubmitbutton flex m-0">
                        <div></div>
                        <div>
                          {socialActivityData?.records[0]?.courseComp < 28 ||
                            socialActivityData?.records[0]?.courseComp === 100 ? (
                            <button
                              className="btn-blue btn-login d-block float-right w-auto"
                              onClick={() => handleQuiz()}
                            >
                              <i className="fa-solid fa-paper-plane mr-2"></i>
                              Begin Survey
                            </button>
                          ) : (
                            <button
                              className="btn-blue btn-login d-block float-right w-auto"
                              onClick={() => data?.selectTabs("Series")}
                            >
                              <i className="fa-solid fa-paper-plane mr-2"></i>
                              Go to Lessons
                            </button>
                          )}
                        </div>
                      </div>
                    </div> */}
                </>
              )
            ) : (
              <ShimmerCategoryList items={4} categoryStyle="STYLE_SIX" />
            )}
          </>
        )}
      </div>
      {startPopup && (
        <ConfirmationPopup
          setStartPopup={setStartPopup}
          courseId={param?.courseId}
          assSurveyId={
            socialActivityData?.records[0]?.courseIsCompleted
              ? growthSurveyData?.records[0].postAssementSurveyId
              : growthSurveyData?.records[0].preAssementSurveyId
          }
          finalArray={selectOptData?.finalArraySubmit}
        />
      )}
    </>
  );
};

export default GrowthSurveySidebar;
