import React, { useState } from "react";
import AcquisitionMultiQuiz from "./AcquisitionMultiQuiz";
import Home from "../Home";
import * as image from "../../resources/images";
import SmartQuizRightSidebar from "../dimensions/course/social/SmartQuizRightSidebar";
import { useSelector } from "react-redux";
import { getCapitalized, getName } from "../../utils/helper";

const SkillsAcquisition = () => {
	const [finalArray, setFinalArray] = useState([]);
	const { beginQuiz, defaultChildData, getMyPlanData } = useSelector((state) => state.collections);
	return (
		<div>
			<Home>
				<div className="d-flex w-100 align-items-start overflow-visible">
					<div className="LeftbarPannel p-0 leftpannel_Acquisition justify-content-between">
						{!beginQuiz &&
							<div className="heading">
								<h2 class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
									<span className="flexone"> <img src={image.acquisitionsurvey} className='mr-2' alt="" />
										{getMyPlanData?.records[0]?.planName} - Skill Acquisition Survey
									</span>
								</h2>
							</div>}
						<AcquisitionMultiQuiz finalArray={finalArray} setFinalArray={setFinalArray} />{" "}
					</div>
					<div className="RightbarPannel p-0 rightpannelSticky newcoursecardpanel setuptwo_rPnl justify-content-between">
						<SmartQuizRightSidebar finalArray={finalArray} />
					</div>
				</div>
			</Home>
		</div>
	);
};

export default SkillsAcquisition;
