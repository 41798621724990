import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as image from "../../resources/images";
import { useDispatch, useSelector } from "react-redux";
import {
  breadcrumb,
  resetMultiquizResponse,
  selectOptionData,
  smartQuizData,
} from "../../redux/actions";
import {
  getMultiQuiz,
  userAttemptedMultiSurvey,
} from "../../redux/actions/APIs";
import ProgressBar from "../../components/controls/ProgressBar";
import IntelligenceQuizResult from "./IntelligenceQuizResult";
import { ShimmerCategoryList } from "react-shimmer-effects";
import {
  getDimShuffle,
  getRoundNumber,
  getSequnceSort,
} from "../../utils/helper";

import { beginMultiQuiz } from "../../redux/actions";
import SmartQuizRightSidebar from "../dimensions/course/social/SmartQuizRightSidebar";

const SmartMultiQuiz = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const {
    multiQuizData,
    userAttemptedResponse,
    breadcrumbData,
    beginQuiz,
    selectedPreData,
  } = useSelector((state) => state.collections);

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [totalAttempt, setTotalAttempt] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [finalArray, setFinalArray] = useState([]);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [quizQuestionCount, setQuizQuestionCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [loadRespone, setLoadResponse] = useState(false);
  const [number, setNumber] = useState([]);

  useEffect(() => {
    dispatch(resetMultiquizResponse());
    setLoadResponse(true);
    dispatch(getMultiQuiz());
    dispatch(beginMultiQuiz(false));
    dispatch(getMultiQuiz(params.id));
  }, [params.id]);

  useEffect(() => {
    if (userAttemptedResponse?.success) {
      setLoader(false);
      setTotalAttempt(0);
      dispatch(getMultiQuiz(params.id));
      dispatch(beginMultiQuiz(false));
    }
  }, [userAttemptedResponse, loader, totalAttempt]);

  useEffect(() => {
    if (!breadcrumbData) {
      dispatch(
        breadcrumb({
          title: "Check Your Smarts",
          icon: image.multipleintellQuiz,
        })
      );
    }
  }, [breadcrumbData]);

  useEffect(() => {
    if (multiQuizData) {
      window.scrollTo(0, 0);
      setQuizData(getDimShuffle(multiQuizData?.records[0]?.questions));
      setLoadResponse(false);
    }
  }, [multiQuizData]);

  const selectOption = (event, currentQuestionIndex, selectedOptIndx, opt) => {
    let isSelected = 2.5;
    let data = [...quizData];
    const isAlreadyAttempted = data[currentQuestionIndex]?.options?.some(
      (option) => option.isUserSelected
    );
    if (!isAlreadyAttempted) {
      setTotalAttempt(totalAttempt + isSelected);
    }
    data[currentQuestionIndex].options[selectedOptIndx].isUserSelected =
      event.target.checked;
    data[currentQuestionIndex].options.forEach((option, index) => {
      if (index !== selectedOptIndx) {
        option.isUserSelected = false;
      }
    });

    dispatch(
      smartQuizData({
        attemptedQuestions: quizData.filter((q) => q.attempted),
        onQuestionClick: handleQuestionClick,
      })
    );

    setQuizData(data);
    setIsOptionSelected(true);
    let selectedFinalAnswer = [...finalArray];
    let selectedData = {
      surveyId: data[currentQuestionIndex].surveyId,
      quesId: data[currentQuestionIndex].id,
      optId: data[currentQuestionIndex].options[selectedOptIndx].id,
      point: quizData[currentQuestionIndex].options[selectedOptIndx].points,
    };
    selectedFinalAnswer[currentQuestionIndex] = selectedData;
    setFinalArray(selectedFinalAnswer);
  };

  const handleContinue = (nextQuestionIndex, type, selectedQuestionIndex) => {
    let data = [...quizData];
    if (type === "Next") {
      window.scrollTo(0, 0);
      data[nextQuestionIndex].attempted = true;
      setSelectedQuestionIndex(nextQuestionIndex);
    } else if (type === "Back") {
      window.scrollTo(0, 0);
      setSelectedQuestionIndex(nextQuestionIndex);
    }
    dispatch(
      smartQuizData({
        attemptedQuestions: quizData.filter((q) => q.attempted),
        onQuestionClick: handleQuestionClick,
      })
    );
    setQuizData(data);
  };

  const handleSubmit = () => {
    setLoader(true);
    setNumber([]);
    dispatch(userAttemptedMultiSurvey(params.id, finalArray));
  };
  let quizResponse = multiQuizData && multiQuizData?.records[0]?.multiintel;

  const handleQuestionClick = (quesIndex) => {
    setSelectedQuestionIndex(quesIndex);
  };

  const handleQuiz = () => {
    dispatch(beginMultiQuiz(true));
    setTotalAttempt(0);
    setSelectedQuestionIndex(0);
    if (multiQuizData) {
      let data = [...quizData];
      data[0].attempted = true;
      setQuizData(data);
      window.scrollTo(0, 0);
    }
    dispatch(
      smartQuizData({
        attemptedQuestions: quizData.filter((q) => q.attempted),
        onQuestionClick: handleQuestionClick,
      })
    );
  };

  return (
    <>
      {/* <div className="LeftbarPannel p-0"> */}
      {userAttemptedResponse || (multiQuizData && quizResponse?.length > 0) ? (
        <IntelligenceQuizResult
          quizResponse={quizResponse}
          loadRespone={loadRespone}
        />
      ) : !multiQuizData || (!quizResponse && !loadRespone) ? (
        <div className="quizblok">
          <ShimmerCategoryList items={5} categoryStyle="STYLE_SIX" />{" "}
        </div>
      ) : !loadRespone ? (
        quizResponse?.length === 0 && !beginQuiz ? (
          <>
          <div className="CourseCardWrapper fullHeight100">
            <div className="smartquizbanner multipleintelligence mt-3">
              <img src={image.howyousmartbanner} alt="" />
            </div>
            </div>
            <div class="LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ">
                <div class="form-group  d-flex justify-content-center align-items-center m-0 ">
                   <div>
                   <div className=" beginAssessmentbtn">
                    <button
                        onClick={() => handleQuiz()}
                        className="btn-blue btn-login d-block  m-auto w-auto"
                     >
                      <i className="fa-solid fa-paper-plane mr-2"></i>Begin Assessment
                     </button>
                   </div>
                   </div>
                </div>
           </div>
           </>
         
        ) : (
          beginQuiz && (
            <div className="fullHeight100 skillAquisationSurver">
              <div className="Course_height">
                <div className="skill_aquization_survery ">
                  <div className="quizblok skill_aquization_survery">
                    <div className="backpageStrip p-0 false">
                      <a
                        href="#"
                        className={`flexone false ${
                          selectedQuestionIndex === 0 ? "disabledevent" : ""
                        }`}
                        onClick={() =>
                          handleContinue(
                            selectedQuestionIndex - 1,
                            "Back",
                            selectedQuestionIndex
                          )
                        }
                      >
                        <span className="">
                          <i className="fa-solid fa-angle-left mr-1"></i>
                        </span>
                        Back
                      </a>
                    </div>
                    <ProgressBar value={totalAttempt} data={totalAttempt} />

                    <span className="procompt">
                      {/* {totalAttempt > 0 && getRoundNumber(totalAttempt)}{" "}
                      {totalAttempt > 0 && "%"} {totalAttempt == 0 && "0%"} */}
                      {selectedQuestionIndex + 1}/{multiQuizData?.recordsCount}
                    </span>
                    <div className="backpageStrip p-0 false">
                      <span className=" false">
                        <a
                          href="#"
                          className={`false ${
                            !quizData[selectedQuestionIndex]?.options.some(
                              (option) => option.isUserSelected
                            ) ||
                            selectedQuestionIndex + 1 ===
                              multiQuizData?.recordsCount
                              ? "disabledevent"
                              : ""
                          }`}
                          onClick={() => {
                            handleContinue(
                              selectedQuestionIndex + 1,
                              "Next",
                              selectedQuestionIndex
                            );
                          }}
                        >
                          Next
                          <span className="">
                            <i className="fa-solid fa-angle-right ml-1"></i>
                          </span>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="smartquizwrapper skill_aquization_survery">
                    <div className="smqzQueslist">
                      <div className="signupType m-0 mb-3">
                        <div key={Math.random()}>
                          <h3 className="mb-3">
                            <span>
                              <img src={image.questionIcon} />
                            </span>{" "}
                            {quizData &&
                              quizData[selectedQuestionIndex]?.question}
                          </h3>
                          <div className="pt-3 questionOptions">
                            {quizData &&
                              quizData[selectedQuestionIndex]?.options &&
                              getSequnceSort(
                                quizData[selectedQuestionIndex]?.options
                              )?.map((opt, optInd) => (
                                <label
                                  className={`Selcheckbox ${
                                    quizData[selectedQuestionIndex].options[
                                      optInd
                                    ].isUserSelected
                                      ? "selActivelabel"
                                      : ""
                                  }`}
                                  key={optInd}
                                >
                                  {opt?.question}

                                  <input
                                    type="radio"
                                    id={opt.id}
                                    value={opt?.id}
                                    checked={opt?.isUserSelected}
                                    onChange={(event) =>
                                      selectOption(
                                        event,
                                        selectedQuestionIndex,
                                        optInd,
                                        quizData[selectedQuestionIndex]
                                      )
                                    }
                                  ></input>

                                  <span className="checkmark"></span>
                                </label>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-group full-Width-group basic_details_form pagebuttonStrip bottom-0">
                  <div className="form-group BDsubmitbutton d-flex justify-content-between align-items-center m-0">
                    {selectedQuestionIndex > 0 && (
                      <button
                        type="button"
                        className="btn-blue btn-login d-block mb-5 ml-auto back_button"
                        onClick={() =>
                          handleContinue(
                            selectedQuestionIndex - 1,
                            "Back",
                            selectedQuestionIndex
                          )
                        }
                      >
                        <i className="fa-solid fa-arrow-left mr-2"></i> Back
                      </button>
                    )}

                    {quizData && quizData.length > 0 && (
                      <div className="w-100">
                        {selectedQuestionIndex + 1 <
                          multiQuizData?.recordsCount && (
                          <div className="priceWrap p-0">
                            <button
                              type="submit"
                              className="btn-blue btn-login d-block w-auto mb-5 ml-auto"
                              // disabled={!quizData[selectedQuestionIndex]?.attempted}
                              disabled={
                                !quizData[selectedQuestionIndex]?.options.some(
                                  (option) => option.isUserSelected
                                )
                              }
                              onClick={() => {
                                handleContinue(
                                  selectedQuestionIndex + 1,
                                  "Next",
                                  selectedQuestionIndex
                                );
                              }}
                            >
                              Next
                              <i className="fa-solid fa-arrow-right ml-2 m-0"></i>
                            </button>
                          </div>
                        )}
                        {selectedQuestionIndex + 1 ===
                          multiQuizData?.recordsCount &&
                          (loader ? (
                            <div className="priceWrap p-0">
                              <button
                                className="btn-blue btn-login d-block w-auto mb-5 ml-auto"
                                key={Math.random()}
                                disabled
                              >
                                <span className="RounAnimation mr-1"></span>
                                Please wait
                              </button>
                            </div>
                          ) : (
                            <div className="priceWrap p-0">
                              <button
                                type="submit"
                                className="btn-blue btn-login d-block w-auto mb-5 ml-auto"
                                onClick={() => {
                                  handleSubmit();
                                }}
                                // disabled={
                                //   !quizData[selectedQuestionIndex]?.attempted
                                // }
                                disabled={
                                  !quizData[
                                    selectedQuestionIndex
                                  ]?.options.some(
                                    (option) => option.isUserSelected
                                  )
                                }
                              >
                                <i className="fa-solid fa-paper-plane mr-2"></i>
                                Submit
                              </button>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        )
      ) : (
        <ShimmerCategoryList items={5} categoryStyle="STYLE_SIX" />
      )}
    </>
  );
};

export default SmartMultiQuiz;
